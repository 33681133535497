//internal
import { useState,useContext,useEffect } from "react";
//external
import Cookies from "js-cookie";
import { MainContext } from "../../contexts/MainContexts";
import { FiSun } from "react-icons/fi";
import { FaMoon } from "react-icons/fa";
//Style
import "./lighting-mode.css";



const LightingMode = ({top,bottom,left,right}) => {

    const {lightingMode,setLightingMode}=useContext(MainContext);
    
    

   const changeStatus=()=>{
        if(lightingMode==="light"){
          Cookies.set("lightingMode","dark",{ expires: 365 });
          setLightingMode("dark");
        }else{
          Cookies.set("lightingMode","light",{ expires: 365 });
          setLightingMode("light");
        }
   }


  return (
    <div className="lightingMode" onClick={changeStatus}
    style={{top:top,right:right,bottom:bottom,left:left}}>
        <main>
          <section className="flexCenter" style={(lightingMode==="light") ? {background:"transparent"} : {background:"#343434"}}>
              <FaMoon color={(lightingMode==="light") ? "white" : "white"}/>
          </section>
          <section className="flexCenter" style={(lightingMode==="light") ? {background:"white"} : {background:"transparent"}} >
               <FiSun color={(lightingMode==="light") ? "#f6b33f" : "white"} />
          </section>
        </main>
      </div>
  )
}

export default LightingMode
//internal
import { useState, useEffect, useContext } from "react";

//external
import Cookies from "js-cookie";
import axios from "axios";
import { MainContext } from "../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import moment from "moment-jalaali";



//Component and Style 
import "./reports.css";
import "./lightMode.css";
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import Convert_Box from "../../components/convert-box/Convert_Box"
import LightingMode from "../../components/lighting-mode/LightingMode";
import LoadingCircle from "../../components/loading-circle/LoadingCircle";
//Image

import { BASE_URL } from '../../utils/url'




const Reports = () => {


  const baseURL = BASE_URL
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  const { t } = useTranslation();
  const { lightingMode, primary_dir } = useContext(MainContext);
  const navigate = useNavigate();

  const mm = (lightingMode === "light") ? "black" : "white"

  //desktop popup
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  //mobile popup dark_screen
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  //tablet popup dark_screen
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);

  //method
  const closeWindowForDesktop = () => {
    if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
    if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
    // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
    // if (tablet_Notifications == true) { setTablet_Notifications(false) }
    if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
  }

  const [userEarnings, setUserEarnings] = useState([]);
  const [loading, setLoading] = useState(false);


  //Mounting USEEffect
  useEffect(() => {

    //اگر توکن نداشت
    if (!Cookies.get("access_token")) {
      navigate("/judge", { replace: true })
    }

    const fetchData = async () => {
      try {

        setLoading(true) //شروع لودینگ

        const response_1 = await axios.get(`${baseURL}/user/referrals/earnings`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserEarnings(response_1.data.data);

        setLoading(false) // پایان لودینگ

      } catch (err) {
        setLoading(false) // پایان لودینگ
        if(err.response.data.message==="Unauthenticated."){
          Cookies.remove("access_token")
          navigate("/judge", { replace: true })
         }
      }
    }//end of method
    fetchData();
  }, [])


  return (
    <HelmetProvider>
      <div id="reports" className="flex flex-wrap" onClick={closeWindowForDesktop}>
        <Helmet>
          <title>{t("title_reports")}</title>
        </Helmet>

        {/* نوار برای موبایل و تبلت lg:hidden*/}
        {/* x < 1024 */}
        <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}

          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />

        {/* لینک های فیکس برای موبایل sm:hidden*/}
        {/* x < 640 */}
        <Fixed_Mobile_Links />

        {/* ساید بار برای حالت دسکتاپ hidden lg:block*/}
        {/* x > 1024 */}
        <Main_Sidebar />


        <main>

          {/* نوار برای دسکتاپ hidden lg:flex*/}
          {/* x > 1024 */}
          <Nav_Desktop
            desktop_ProfileMenu={desktop_ProfileMenu}
            desktop_Notifications={desktop_Notifications}
            desktop_ThreeDots={desktop_ThreeDots}

            setDesktop_Notifications={setDesktop_Notifications}
            setDesktop_ThreeDots={setDesktop_ThreeDots}
            setDesktop_ProfileMenu={setDesktop_ProfileMenu}
          />

          <div className="invite-container bg-white rounded-[20px] p-5 py-6">
            {/* دکمه */}
            <section className="w-fit flex items-center gap-x-4 mb-5 pb-3 px-2"
              style={{ borderBottom: "1px solid #e6e8f2" }}>

              <NavLink to={"/invite-friends"}
                style={({ isActive }) => {
                  return {
                    background: isActive ? "var(--maincolor2)" : "",
                    color: isActive ? "white" : mm,
                  }
                }}
                className="p-1 rounded"
              > {t("reports_1")}</NavLink>
              <NavLink to={"/reports"}
                style={({ isActive }) => {
                  return {
                    background: isActive ? "var(--maincolor2)" : "",
                    color: isActive ? "white" : mm,
                  }
                }}
                className="p-1 rounded"
              >{t("reports_2")}</NavLink>
            </section>
            {/*  */}

            {/*  */}
            {
              loading ? <LoadingCircle /> :
                (
                  <div className="table_parent  toggleColor">
                    <table className="w-full text-right">
                      <thead >
                        <tr className="mb-2">
                          <th>{t("reports_3")}</th>
                          <th>{t("reports_4")}</th>
                          <th>{t("reports_5")}</th>
                          <th>{t("reports_6")}</th>
                          <th>{t("reports_7")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          userEarnings && userEarnings.map(mm => (
                            <tr>
                              <td>
                                <div className="flex items-center gap-x-2">
                                  <img src={`${base_url_img}__${mm.coin_symbol}.png`} width={43} height={43} />
                                  <span>{mm.coin_symbol} | {mm.coin_name}</span>
                                </div>
                              </td>
                              <td>{mm.coin_symbol}</td>
                              <td>{mm.amount}</td>
                              <td>
                                <span>{moment(mm.last_earning_at.split(" ")[0], 'YYYY-MM-DD').format('jYYYY/jMM/jDD')}</span>
                                {" "}
                                <span>{" "} </span>
                                {" "}


                              </td>
                              <td>
                                <span>{mm.last_earning_at.split(" ")[1]}</span>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    {
                      userEarnings.length === 0 && 
                      <div className="flexCenter flex-col mt-5">
                        <img src={require('../../image/price-socket/Empty@2x.png')} width={200} />
                        <p>{t("noData_1")}</p>
                      </div>
                    }
                  </div>
                )
            }








          </div>
        </main>
        <LightingMode bottom="20px" left="30px" />
      </div>
    </HelmetProvider>
  )
}

export default Reports
// export default Reports
import "./Home.css";
import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Select from "react-select";
import { Pagination, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
// import { Select } from "@mui/material";
import axios from "axios";
import PriceSocket from "../components/price-socket/PriceSocket.jsx";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { MainContext } from "../contexts/MainContexts.js";
import { Link, useNavigate } from "react-router-dom";
import Convert_Box from "../components/convert-box/Convert_Box.jsx";
import { toast } from 'react-toastify';
import { BASE_URL } from '../utils/url'

export default function Home() {
  const navigate = useNavigate()

  const { lightingMode, user_lang } = useContext(MainContext);
  const { t } = useTranslation();

  const [forceRender, setForceRender] = useState();
  const [coinList, setCoinList] = useState([]);
  const [blog, setBlog] = useState([]);
  const [paired_CoinList, setPaired_CoinList] = useState([]);
  const [featured_List, setFeatured_List] = useState([]);
  const [input_First, setInput_First] = useState("");
  const [help_input_First, setHelp_Input_First] = useState("");
  const [input_Second, setInput_Second] = useState("");
  const [help_input_Second, setHelp_Input_Second] = useState("");

  const [base_Coin, setBase_Coin] = useState("");
  const [quote_Coin, setQuote_Coin] = useState("");

  const myCheck = `${base_Coin}_${quote_Coin}`;
  const min_base = paired_CoinList.find(
    (z) => z.name === myCheck
  )?.base_min_size;
  const max_base = paired_CoinList.find(
    (z) => z.name === myCheck
  )?.base_max_size;
  const checkList = featured_List.map((x) => x.name);
  const myIndex = checkList.findIndex((item) => item === myCheck);
  const mehdi_first = [
    ...new Set(featured_List.map((x) => x.name).map((y) => y.split("_")[0])),
  ].sort();
  const mehdi_second = [
    ...new Set(featured_List.map((x) => x.name).map((y) => y.split("_")[1])),
  ].sort();
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  // useeffect blog
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response_1_blog = await axios.get(
          BASE_URL + "/blog/latest-posts"
        );

        setBlog(response_1_blog.data);
      } catch (error) {
        // console.log(error.message);
      }
    };
    fetchBlog();
  },[]);
  // console.log(blog);
  // console.log(blog.map((b)=>{<div>{b.title}</div>}));
  //useEffect mounting
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          BASE_URL + "/coins"
        );
        const response_2 = await axios.get(
          BASE_URL + "/coin-pairs"
        );
        const response_3 = await axios.get(
          BASE_URL + "/ticker/featured"
        );

        setCoinList(response_1.data);
        setPaired_CoinList(response_2.data);
        setFeatured_List(response_3.data);
        //فقط در حالت مانتینگ
        setBase_Coin(
          [
            ...new Set(
              response_3.data.map((x) => x.name).map((y) => y.split("_")[0])
            ),
          ].sort()[0]
        );
        setQuote_Coin(
          [
            ...new Set(
              response_3.data.map((x) => x.name).map((y) => y.split("_")[1])
            ),
          ].sort()[1]
        );
      } catch (error) {
        // console.log(error.message);
      }
    }; //end of method
    fetchData();
  }, []);

  //useEffect forceRender اجباری
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          BASE_URL + "/coins"
        );
        const response_2 = await axios.get(
          BASE_URL + "/coin-pairs"
        );
        const response_3 = await axios.get(
          BASE_URL + "/ticker/featured"
        );

        setCoinList(response_1.data);
        setPaired_CoinList(response_2.data);
        setFeatured_List(response_3.data);

        // console.log("forceRender")
      } catch (error) {
        // console.log(error.message);
      }
    };
    fetchData();
  }, [forceRender]);

  //updating از بالا به پایین
  useEffect(() => {
    if (myIndex !== -1) {
      const myElement = featured_List.find((z) => z.name == myCheck);

      if (myElement) {
        setInput_Second(input_First * myElement.latest_price);
      }
    } else {
      setInput_Second(0);
    }
  }, [base_Coin, help_input_First]);

  //updating  از پایین به بالا
  useEffect(() => {
    if (myIndex !== -1) {
      const myElement = featured_List.find((z) => z.name == myCheck);

      if (myElement) {
        setInput_First(input_Second / myElement.latest_price);
      }
    } else {
      setInput_First(0);
    }
  }, [quote_Coin, help_input_Second]);
  //مدیریت تغییرات سلکت ها selectedOption
  //first
  const handleSelect_first = (selectedOption) => {
    setBase_Coin(selectedOption.value);
    setForceRender(Math.random());
  };
  //second
  const handleSelect_second = (selectedOption) => {
    setQuote_Coin(selectedOption.value);
    setForceRender(Math.random());
  };

  //optionList_first
  const optionList_first = coinList
    .filter((coin) => mehdi_first.indexOf(coin.symbol) > -1)
    .map((coin) => {
      return {
        value: coin.symbol,
        label: (
          <div className="w-full h-full">
            <img src={base_url_img + coin.icon} height="30px" width="30px" />
            <span>{coin.name}</span>
          </div>
        ),
      };
    });
  //optionList_second
  const optionList_second = coinList
    .filter((coin) => mehdi_second.indexOf(coin.symbol) > -1)
    .map((coin) => {
      return {
        value: coin.symbol,
        label: (
          <div className="option_style">
            <img src={base_url_img + coin.icon} height="30px" width="30px" />
            <span>{coin.name}</span>
          </div>
        ),
      };
    });

  // const customStyles = {
  //   control: base => ({
  //     ...base,
  //     height: "135px",
  //     with: "200px",
  //     background:"red"
  //   })
  // };


  /////////////////////////////////////////////////////////////// Convert box POST handling
  // const [base_coin, setBase_coin] = useState('')
  // const [quote_coin, setQuote_coin] = useState('')
  // const [amount_input_value, setAmount_input_value] = useState(0)
  // const [bitcoinAddress_input_value, setBitcoinAddress_input_value] = useState('')
  // const [telegramId_input_value, setTelegramId_input_value] = useState('')
  // const [email_input_value, setEmail_input_value] = useState('')
  
  // const handleCoinsChange = (base, quote) => {
  //   setBase_coin(base)
  //   setQuote_coin(quote)
  // }
  // const handleInputChange = (value) => {
  //   setAmount_input_value(value)
  // }

  // const [convetboxShowError, setConvetboxShowError] = useState(false)

  // function convetboxPostHandler(e) {
  //   e.target.disabled = true

  //   if (!amount_input_value || !bitcoinAddress_input_value || !email_input_value) {
  //     setConvetboxShowError(true)

  //     e.target.disabled = false
  //   } else {
  //     setConvetboxShowError(false)

  //     const data = {
  //       from: base_coin,
  //       to: quote_coin,
  //       amount: amount_input_value,
  //       customer_address: bitcoinAddress_input_value,
  //       email_address: email_input_value,
  //       telegram_id: telegramId_input_value
  //     }

  //     fetch(BASE_URL+'/direct-swap', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(data)
  //     })
  //       .then(res => res.json())
  //       .then(res => {
  //         e.target.disabled = false

  //         res.status && toast.success(res.status)
  //         res.errors && toast.error(Object.values(res.errors)[0][0])
  //       })
  //       .catch(err => {
  //         e.target.disabled = false

  //         toast.error('Something went wrong!')
  //       })
  //     }
  // }


  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
          <title>{t("title.1")}</title>
          <meta name="title" content={t("metatag.1")} />
          <meta name="description" content={t("metatag.2")} />
        </Helmet>
      <Header />
      {/* <!-- Section-one --> */}
      <section id="section-one">
        <img src={require("../image/bg/Hero-Pattern.png")} class="background" />
        <div class="title">
          <div class="text-left">
            <div class="frs">{t("home.1")}</div>
            <div class="sec">
              <span>{t("home.2")} </span>
              {t("home.3")}
            </div>
          </div>
          <div class="text-right">
            <div class="frs">{t("home.4")}</div>
            <div class="sec">{t("home.5")}</div>
          </div>
        </div>
      </section>
      {/* <!-- Top-section --> */}
      <section id="top-section">
        <div class="all">
          <div class="left">
            <img src={require("../image/home-page/Coins-Image.png")} />
          </div>
          <div class="right">
            {/* <section>
              <form>
                <div className="pay">
                  <label htmlFor="" className="title">
                    {t("home.6")}
                  </label>

                  <div className="end bt">
                    <input
                      type="text"
                      value={0}
                      onChange={(e) => {
                        setInput_First(e.target.value);
                        setHelp_Input_First(e.target.value);
                      }}
                      className="pio"
                      placeholder="0"
                    />

                    <Select
                      options={optionList_first}
                      onChange={handleSelect_first}
                      // styles={customStyles}
                      placeholder={optionList_first[0]?.label}
                    />
                  </div>
                  {myIndex !== -1 && (
                    <span style={{ padding: "25px", color: "#aaa" }}>
                      <span>Min: {min_base},</span> <span>Max: {max_base}</span>
                    </span>
                  )}
                </div>

                <div className="pay">
                  <label htmlFor="" className="title">
                    {t("home.7")}
                  </label>

                  <div style={{}} className="end bt">
                    <input
                      className="pio"
                      type="text"
                      value={input_Second}
                      onChange={(e) => {
                        setInput_Second(e.target.value);
                        setHelp_Input_Second(e.target.value);
                      }}
                    />

                    <Select
                      options={optionList_second}
                      onChange={handleSelect_second}
                      // styles={customStyles}
                      placeholder={optionList_second[1]?.label}
                    />

                    {myIndex == -1 && (
                      <span className="show-error">
                        قابلیت تبدیل وجود ندارد
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </section> */}


            <h3 className="absolute top-1 right-0 font-bold text-xl">محاسبه سریع</h3>
            <Convert_Box dontShowUserBalance={true} />
            {/* <div className="bitcoin-address">
              <div>
                <div className="title">{t("home.9")}</div>
                <input
                  type="text" 
                  className="home-convert_box-input" 
                  placeholder={t("home.59")}
                  value={bitcoinAddress_input_value}
                  onChange={e => setBitcoinAddress_input_value(e.target.value)}
                />
              </div>
              <div>
                <div className="title">{t("home.60")}</div>
                <input 
                  type="text" 
                  className="home-convert_box-input" 
                  placeholder={t("home.61")}
                  value={telegramId_input_value}
                  onChange={e => setTelegramId_input_value(e.target.value)}
                />
              </div>
            </div> */}
            {/* <div className="end">
              <div className="title">
                {t("home.10")}{" "}
              </div>
              <div className="done">
                <input
                  type="text"
                  className="home-convert_box-input"
                  value={email_input_value}
                  onChange={e => setEmail_input_value(e.target.value)}
                />
              </div>
            </div> */}
            {/* <Link to="/terms" className="text" >
              {t("home.13")}
              <span>{t("home.14")}</span>
            </Link> */}
            <div className="end">
              <button onClick={() => navigate('/swap')} className="relative flex gap-[2px] items-center justify-center group">
                {t("home.49")}
                <svg className="-rotate-[140deg] group-hover:-translate-y-[.1rem] group-hover:-translate-x-[.1rem] transition" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 -960 960 960" width="24"><path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/></svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Section-two --> */}
      {/* <section id="section-two">
        <div class="all">
          <div class="grid-item">
            <div class="carts">
              <div class="image"></div>
              <img
                src={require("../image/currency/Bitcoin.png")}
                class="logo"
              />
              <div class="time">24h</div>
              <div class="details dt1">
                <div class="text">{t("home.15")}</div>
                <div class="number">$28128.29</div>
              </div>
              <div class="details dt2">
                <div class="text">{t("home.16")}</div>
                <div class="number">
                  <span class="up"> 0.87%</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.474"
                    height="12.422"
                    viewBox="0 0 21.474 12.422"
                  >
                    <path
                      id="trending_up"
                      d="M93.706,360.177a.739.739,0,0,1-.232-.573.9.9,0,0,1,.232-.573l6.8-6.8a.832.832,0,0,1,.273-.191.853.853,0,0,1,.6,0,.832.832,0,0,1,.273.191l3.986,3.986,6.58-6.58h-2.648a.819.819,0,1,1,0-1.638h4.559a.794.794,0,0,1,.819.819v4.559a.809.809,0,0,1-.232.573.742.742,0,0,1-.56.246.84.84,0,0,1-.819-.819v-2.539l-7.153,7.153a.832.832,0,0,1-.273.191.853.853,0,0,1-.6,0,.832.832,0,0,1-.273-.191l-3.986-3.986-6.2,6.2a.758.758,0,0,1-.56.218A.8.8,0,0,1,93.706,360.177Z"
                      transform="translate(-93.474 -348)"
                      fill="#15de5f"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item">
            <div class="carts">
              <div class="image1"></div>
              <img
                src={require("../image/currency/Etherium.png")}
                class="logo"
              />
              <div class="time">24h</div>
              <div class="details dt1">
                <div class="text">{t("home.17")}</div>
                <div class="number">$1876.09</div>
              </div>
              <div class="details dt2">
                <div class="text">{t("home.18")}</div>
                <div class="number">
                  <span class="down">0.88%</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.474"
                    height="12.422"
                    viewBox="0 0 21.474 12.422"
                  >
                    <path
                      id="trending_down"
                      d="M93.706,348.246a.739.739,0,0,0-.232.573.9.9,0,0,0,.232.573l6.8,6.8a.832.832,0,0,0,.273.191.853.853,0,0,0,.6,0,.832.832,0,0,0,.273-.191l3.986-3.986,6.58,6.58h-2.648a.819.819,0,1,0,0,1.638h4.559a.794.794,0,0,0,.819-.819v-4.559a.809.809,0,0,0-.232-.573.742.742,0,0,0-.56-.246.84.84,0,0,0-.819.819v2.539l-7.153-7.153a.832.832,0,0,0-.273-.191.853.853,0,0,0-.6,0,.832.832,0,0,0-.273.191l-3.986,3.986-6.2-6.2a.758.758,0,0,0-.56-.218A.8.8,0,0,0,93.706,348.246Z"
                      transform="translate(-93.474 -348)"
                      fill="#f63f3f"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item">
            <div class="carts">
              <div class="image2"></div>
              <img src={require("../image/currency/shib.png")} class="logo" />
              <div class="time">24h</div>
              <div class="details dt1">
                <div class="text">{t("home.19")}</div>
                <div class="number">$00.29</div>
              </div>
              <div class="details dt2">
                <div class="text">{t("home.20")}</div>
                <div class="number">
                  <span class="up"> 0.87%</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.474"
                    height="12.422"
                    viewBox="0 0 21.474 12.422"
                  >
                    <path
                      id="trending_up"
                      d="M93.706,360.177a.739.739,0,0,1-.232-.573.9.9,0,0,1,.232-.573l6.8-6.8a.832.832,0,0,1,.273-.191.853.853,0,0,1,.6,0,.832.832,0,0,1,.273.191l3.986,3.986,6.58-6.58h-2.648a.819.819,0,1,1,0-1.638h4.559a.794.794,0,0,1,.819.819v4.559a.809.809,0,0,1-.232.573.742.742,0,0,1-.56.246.84.84,0,0,1-.819-.819v-2.539l-7.153,7.153a.832.832,0,0,1-.273.191.853.853,0,0,1-.6,0,.832.832,0,0,1-.273-.191l-3.986-3.986-6.2,6.2a.758.758,0,0,1-.56.218A.8.8,0,0,1,93.706,360.177Z"
                      transform="translate(-93.474 -348)"
                      fill="#15de5f"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item">
            <div class="carts">
              <div class="image3"></div>
              <img src={require("../image/currency/Tether.png")} class="logo" />
              <div class="time">24h</div>
              <div class="details dt1">
                <div class="text">{t("home.21")}</div>
                <div class="number">$1.06</div>
              </div>
              <div class="details dt2">
                <div class="text">{t("home.22")}</div>
                <div class="number">
                  <span class="up"> 0.87%</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.474"
                    height="12.422"
                    viewBox="0 0 21.474 12.422"
                  >
                    <path
                      id="trending_up"
                      d="M93.706,360.177a.739.739,0,0,1-.232-.573.9.9,0,0,1,.232-.573l6.8-6.8a.832.832,0,0,1,.273-.191.853.853,0,0,1,.6,0,.832.832,0,0,1,.273.191l3.986,3.986,6.58-6.58h-2.648a.819.819,0,1,1,0-1.638h4.559a.794.794,0,0,1,.819.819v4.559a.809.809,0,0,1-.232.573.742.742,0,0,1-.56.246.84.84,0,0,1-.819-.819v-2.539l-7.153,7.153a.832.832,0,0,1-.273.191.853.853,0,0,1-.6,0,.832.832,0,0,1-.273-.191l-3.986-3.986-6.2,6.2a.758.758,0,0,1-.56.218A.8.8,0,0,1,93.706,360.177Z"
                      transform="translate(-93.474 -348)"
                      fill="#15de5f"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <PriceSocket />
      {/* <!-- Section-three --> */}
      <section id="section-three">
        <div class="all">
          <div class="left">
            {user_lang === 'ar' ?
              <img src={
                lightingMode ==="light" ? require("../image/home-page/dashboard.png") : require("../image/home-page/dashboard-dark.png")
              } /> :
              <img src={
                lightingMode ==="light" ? require("../image/home-page/dashboard-en.png") : require("../image/home-page/dashboard-en-dark.png")
              } />
            }
          </div>
          <div class="right">
            <div className="details">
              <div>
                <div class="text1">{t("home.23")}</div>
                <div class="text2">{t("home.24")}</div>

                <p class="des">{t("home.25")}</p>
              </div>
              <ul>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.07"
                    height="36.386"
                    viewBox="0 0 36.07 36.386"
                  >
                    <g id="Safe" transform="translate(-2 -1.985)">
                      <path
                        id="Path_1733"
                        data-name="Path 1733"
                        d="M23.329,36.492l2.764,2.361a1.64,1.64,0,0,0,2.13,0l13.116-11.2a1.64,1.64,0,0,0,.575-1.247V9.233A1.64,1.64,0,0,0,40.55,7.617l-4.56-.779a1.64,1.64,0,0,1,.184.756V24.768a1.64,1.64,0,0,1-.576,1.248L23.328,36.492Z"
                        transform="translate(-3.844 -0.875)"
                        fill="#333"
                        fill-rule="evenodd"
                        opacity="0.15"
                      />
                      <path
                        id="Path_1734"
                        data-name="Path 1734"
                        d="M17.576,1.985,31.106,4.3A2.459,2.459,0,0,1,33.151,6.72V23.894a2.459,2.459,0,0,1-.862,1.872l-13.116,11.2a2.459,2.459,0,0,1-3.194,0L2.862,25.765A2.459,2.459,0,0,1,2,23.9V6.719A2.459,2.459,0,0,1,4.045,4.294Zm0,1.664L4.322,5.911a.82.82,0,0,0-.682.808V23.893a.82.82,0,0,0,.287.624l13.116,11.2a.82.82,0,0,0,1.066,0l13.116-11.2a.82.82,0,0,0,.287-.623V6.719a.82.82,0,0,0-.682-.808Z"
                        fill="#333"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1735"
                        data-name="Path 1735"
                        d="M9,12.063a1.639,1.639,0,0,1,1.363-1.616L18.837,9l8.474,1.447a1.64,1.64,0,0,1,1.363,1.616V22.8a1.64,1.64,0,0,1-.575,1.246l-8.2,7a1.64,1.64,0,0,1-2.128,0l-8.2-7A1.64,1.64,0,0,1,9,22.8Z"
                        transform="translate(-1.262 -1.264)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1736"
                        data-name="Path 1736"
                        d="M12,15a1.64,1.64,0,0,1,1.294-1.6L19.788,12l8.136,1.769a1.64,1.64,0,0,1,1.291,1.6v7.361a1.64,1.64,0,0,1-.56,1.235l-8.224,7.2a1.64,1.64,0,0,1-2.129.026l-5.712-4.76a1.64,1.64,0,0,1-.59-1.26Z"
                        transform="translate(-1.802 -1.805)"
                        fill="#f6b33f"
                        opacity="0.2"
                      />
                      <path
                        id="Path_1737"
                        data-name="Path 1737"
                        d="M18.657,7.985l8.612,1.471a2.459,2.459,0,0,1,2.045,2.423V22.617a2.459,2.459,0,0,1-.862,1.87l-8.2,7a2.459,2.459,0,0,1-3.194,0l-8.2-7A2.459,2.459,0,0,1,8,22.617V11.88a2.459,2.459,0,0,1,2.045-2.424Zm0,1.664-8.335,1.422a.82.82,0,0,0-.682.807V22.616a.82.82,0,0,0,.287.623l8.2,7a.82.82,0,0,0,1.066,0l8.2-7a.82.82,0,0,0,.287-.623V11.88a.82.82,0,0,0-.682-.808L18.657,9.648Z"
                        transform="translate(-1.081 -1.081)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1738"
                        data-name="Path 1738"
                        d="M22.919,20.459A2.459,2.459,0,1,1,20.459,18,2.459,2.459,0,0,1,22.919,20.459Z"
                        transform="translate(-2.884 -2.886)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1739"
                        data-name="Path 1739"
                        d="M20.279,18.64a1.64,1.64,0,1,0,1.64,1.64A1.64,1.64,0,0,0,20.279,18.64ZM17,20.279a3.279,3.279,0,1,1,3.279,3.279A3.279,3.279,0,0,1,17,20.279Z"
                        transform="translate(-2.704 -2.706)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                    </g>
                  </svg>
                  <span> {t("home.26")} </span>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.787"
                    height="32.554"
                    viewBox="0 0 36.787 32.554"
                  >
                    <g id="Privacy" transform="translate(2.158 -0.538)">
                      <g id="Layer_x0020_1" transform="translate(-2.158 0.538)">
                        <path
                          id="Path_1754"
                          data-name="Path 1754"
                          d="M26.234,3.714,29.14,8.747h0l.018.034,2.913,5.045-.482.279.484-.279a.558.558,0,0,1-.2.763l-.028.015a1.477,1.477,0,0,0,.028,2.545h0a.558.558,0,0,1,.2.763l-.02.032-2.913,5.045h0c-.009.015-.018.03-.028.044l-2.906,5.033-.482-.277.484.279a.558.558,0,0,1-.763.2l-.028-.017a1.479,1.479,0,0,0-2.188,1.3.558.558,0,0,1-.558.558H10.945a.558.558,0,0,1-.558-.558A1.479,1.479,0,0,0,8.17,28.269l-.279-.482.28.483a.558.558,0,0,1-.763-.2q-.013-.023-.024-.046L4.478,22.987h0l-.018-.034L1.545,17.91l.482-.279-.484.279a.558.558,0,0,1,.2-.763l.028-.015a1.477,1.477,0,0,0-.028-2.545h0a.558.558,0,0,1-.2-.763l.02-.032L4.477,8.747h0c.009-.015.018-.03.028-.044L7.409,3.67l.482.277-.484-.279a.558.558,0,0,1,.763-.2l.028.017a1.479,1.479,0,0,0,2.188-1.3.558.558,0,0,1,.558-.558H22.673a.558.558,0,0,1,.558.558,1.479,1.479,0,0,0,2.216,1.281l.279.482-.28-.483a.558.558,0,0,1,.763.2q.013.023.024.046Zm2.425,5.312-.482.277L25.494,4.656l-.114.033a2.595,2.595,0,0,1-3.205-1.947H11.443A2.593,2.593,0,0,1,8.237,4.69l-.114-.033-2.657,4.6c-.007.016-.015.031-.024.046l-.484-.279.482.279L2.757,13.954a2.615,2.615,0,0,1,.5.621,2.578,2.578,0,0,1,0,2.586,2.615,2.615,0,0,1-.5.621L5.422,22.4l.02.032-.484.279.482-.277,2.683,4.647.114-.033a2.595,2.595,0,0,1,3.205,1.947H22.175a2.593,2.593,0,0,1,3.205-1.947l.114.033,2.657-4.6c.007-.016.015-.031.024-.046l.484.279-.482-.279,2.684-4.649a2.615,2.615,0,0,1-.5-.621,2.578,2.578,0,0,1,0-2.586,2.615,2.615,0,0,1,.5-.621L28.2,9.337l-.02-.032.484-.279Z"
                          transform="translate(1.585 0.409)"
                        />
                        <path
                          id="Path_1755"
                          data-name="Path 1755"
                          d="M3.625,3.3a2.594,2.594,0,1,1-1.834.76A2.586,2.586,0,0,1,3.625,3.3ZM4.67,4.844A1.477,1.477,0,1,0,5.1,5.889,1.473,1.473,0,0,0,4.67,4.844Z"
                          transform="translate(-1.031 10.388)"
                        />
                        <path
                          id="Path_1756"
                          data-name="Path 1756"
                          d="M4.757,5.256a2.594,2.594,0,1,1-1.834.76A2.586,2.586,0,0,1,4.757,5.256ZM5.8,6.8a1.477,1.477,0,1,0,.433,1.045A1.473,1.473,0,0,0,5.8,6.8Z"
                          transform="translate(5.737 22.11)"
                        />
                        <path
                          id="Path_1757"
                          data-name="Path 1757"
                          d="M4.757,1.334a2.594,2.594,0,1,1-1.834.76A2.586,2.586,0,0,1,4.757,1.334ZM5.8,2.884a1.477,1.477,0,1,0,.433,1.045A1.473,1.473,0,0,0,5.8,2.884Z"
                          transform="translate(5.737 -1.334)"
                        />
                        <path
                          id="Path_1758"
                          data-name="Path 1758"
                          d="M7.021,1.334a2.594,2.594,0,1,1-1.834.76A2.586,2.586,0,0,1,7.021,1.334ZM8.065,2.884A1.477,1.477,0,1,0,8.5,3.928,1.473,1.473,0,0,0,8.065,2.884Z"
                          transform="translate(19.273 -1.334)"
                        />
                        <path
                          id="Path_1759"
                          data-name="Path 1759"
                          d="M8.153,3.3a2.594,2.594,0,1,1-1.834.76A2.586,2.586,0,0,1,8.153,3.3ZM9.2,4.844A1.477,1.477,0,1,0,9.63,5.889,1.473,1.473,0,0,0,9.2,4.844Z"
                          transform="translate(26.041 10.388)"
                        />
                        <path
                          id="Path_1760"
                          data-name="Path 1760"
                          d="M7.021,5.256a2.594,2.594,0,1,1-1.834.76A2.586,2.586,0,0,1,7.021,5.256ZM8.065,6.8A1.477,1.477,0,1,0,8.5,7.849,1.473,1.473,0,0,0,8.065,6.8Z"
                          transform="translate(19.273 22.11)"
                        />
                      </g>
                      <path
                        id="Path_1746"
                        data-name="Path 1746"
                        d="M32.152,27.076A10.076,10.076,0,1,1,22.076,17,10.076,10.076,0,0,1,32.152,27.076Z"
                        transform="translate(-5.812 -7.742)"
                        fill="#333"
                        opacity="0.15"
                      />
                      <path
                        id="Path_1747"
                        data-name="Path 1747"
                        d="M32.152,22.076A10.076,10.076,0,1,1,22.076,12,10.076,10.076,0,0,1,32.152,22.076Z"
                        transform="translate(-5.812 -5.261)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1748"
                        data-name="Path 1748"
                        d="M32.152,24.068c0,5.008-4.511,9.068-10.076,9.068S12,29.077,12,24.068,16.511,15,22.076,15,32.152,19.06,32.152,24.068Z"
                        transform="translate(-5.812 -6.75)"
                        fill="#f6b33f"
                        opacity="0.2"
                      />
                      <path
                        id="Path_1749"
                        data-name="Path 1749"
                        d="M26.046,38.667c-3.339,0-6.046,2.19-6.046,4.892a9.618,9.618,0,0,0,12.091,0C32.091,40.857,29.384,38.667,26.046,38.667Z"
                        transform="translate(-9.782 -18.493)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1750"
                        data-name="Path 1750"
                        d="M25.549,38.675c-3.165,0-5.542,2.059-5.542,4.388H19c0-3.074,3.037-5.4,6.549-5.4s6.549,2.321,6.549,5.4H31.091C31.091,40.734,28.715,38.675,25.549,38.675Z"
                        transform="translate(-9.286 -17.997)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1751"
                        data-name="Path 1751"
                        d="M21.58,12.008a9.572,9.572,0,1,0,9.572,9.572A9.572,9.572,0,0,0,21.58,12.008ZM11,21.58a10.58,10.58,0,1,1,10.58,10.58A10.58,10.58,0,0,1,11,21.58Z"
                        transform="translate(-5.316 -4.765)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1752"
                        data-name="Path 1752"
                        d="M32.053,23.527A3.527,3.527,0,1,1,28.527,20,3.527,3.527,0,0,1,32.053,23.527Z"
                        transform="translate(-12.263 -9.231)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1753"
                        data-name="Path 1753"
                        d="M28.03,20.008a3.023,3.023,0,1,0,3.023,3.023A3.023,3.023,0,0,0,28.03,20.008ZM24,23.03a4.03,4.03,0,1,1,4.03,4.03A4.03,4.03,0,0,1,24,23.03Z"
                        transform="translate(-11.767 -8.734)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                    </g>
                  </svg>
                  <span> {t("home.27")} </span>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.073"
                    height="31.74"
                    viewBox="0 0 35.073 31.74"
                  >
                    <g id="Cryptos" transform="translate(-5 -1.611)">
                      <path
                        id="Path_1770"
                        data-name="Path 1770"
                        d="M16.743,5.291A6.453,6.453,0,1,0,23.2,11.743,6.452,6.452,0,0,0,16.743,5.291ZM9,11.743a7.743,7.743,0,1,1,7.743,7.743A7.743,7.743,0,0,1,9,11.743Z"
                        transform="translate(-4 10.892)"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1772"
                        data-name="Path 1772"
                        d="M16.743,5.291A6.453,6.453,0,1,0,23.2,11.743,6.452,6.452,0,0,0,16.743,5.291ZM9,11.743a7.743,7.743,0,1,1,7.743,7.743A7.743,7.743,0,0,1,9,11.743Z"
                        transform="translate(9.845 -2.389)"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1769"
                        data-name="Path 1769"
                        d="M17.912,15.2V11.014H17V9.723h.912V9h.912v.723h.912V9h.913v.776a2.014,2.014,0,0,1,1.212,3.091,2.014,2.014,0,0,1-1.212,3.622v.723h-.913v-.723h-.912v.723h-.912v-.723H17V15.2h.912Zm.912,0H20.65a.723.723,0,1,0,0-1.447H18.825Zm0-2.737V11.013h1.369a.724.724,0,0,1,0,1.447H18.825Z"
                        transform="translate(-6.838 9.118)"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1771"
                        data-name="Path 1771"
                        d="M17.912,15.2V11.014H17V9.723h.912V9h.912v.723h.912V9h.913v.776a2.014,2.014,0,0,1,1.212,3.091,2.014,2.014,0,0,1-1.212,3.622v.723h-.913v-.723h-.912v.723h-.912v-.723H17V15.2h.912Zm.912,0H20.65a.723.723,0,1,0,0-1.447H18.825Zm0-2.737V11.013h1.369a.724.724,0,0,1,0,1.447H18.825Z"
                        transform="translate(7.007 -4.163)"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1762"
                        data-name="Path 1762"
                        d="M33,31.846A1.846,1.846,0,0,1,34.846,30h5.538a1.846,1.846,0,0,1,1.846,1.846v1.846a1.846,1.846,0,0,1-1.846,1.846H34.846A1.846,1.846,0,0,1,33,33.692Z"
                        transform="translate(-2.157 -2.187)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1764"
                        data-name="Path 1764"
                        d="M30.306,20.153A10.153,10.153,0,1,1,20.153,10,10.153,10.153,0,0,1,30.306,20.153Z"
                        transform="translate(-0.385 -0.646)"
                        fill="#333"
                        opacity="0.15"
                      />
                      <path
                        id="Path_1765"
                        data-name="Path 1765"
                        d="M30.306,15.153A10.153,10.153,0,1,1,20.153,5,10.153,10.153,0,0,1,30.306,15.153Z"
                        transform="translate(-0.385 -0.261)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1766"
                        data-name="Path 1766"
                        d="M20.076,5.846a9.23,9.23,0,1,0,9.23,9.23A9.23,9.23,0,0,0,20.076,5.846ZM9,15.076A11.076,11.076,0,1,1,20.076,26.152,11.076,11.076,0,0,1,9,15.076Z"
                        transform="translate(-0.308 -0.184)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Path_1767"
                        data-name="Path 1767"
                        d="M30.306,16.7c0,4.806-4.546,8.7-10.153,8.7S10,21.509,10,16.7,14.546,8,20.153,8,30.306,11.9,30.306,16.7Z"
                        transform="translate(-0.385 -0.492)"
                        fill="#f6b33f"
                        opacity="0.2"
                      />
                      <path
                        id="Path_1768"
                        data-name="Path 1768"
                        d="M18.305,17.866V11.881H17V10.035h1.305V9H19.61v1.035h1.305V9h1.306v1.109a2.882,2.882,0,0,1,1.733,4.421,2.881,2.881,0,0,1-1.733,5.182v1.035H20.915V19.713H19.61v1.034H18.305V19.713H17V17.867h1.305Zm1.305,0h2.611a1.035,1.035,0,1,0,0-2.069H19.61Zm0-3.915V11.88h1.959a1.035,1.035,0,0,1,0,2.07H19.61Z"
                        transform="translate(-0.924 -0.569)"
                        fill="#f6b33f"
                        fill-rule="evenodd"
                      />
                    </g>
                  </svg>
                  <span> {t("home.28")} </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section-four --> */}
      <section id="section-four">
        <div class="all">
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46.407"
                height="56.802"
                viewBox="0 0 46.407 56.802"
              >
                <g id="Support" transform="translate(-156.55 -0.953)">
                  <path
                    id="Path_1813"
                    data-name="Path 1813"
                    d="M327.966,13.995a.666.666,0,0,1-.608-.394,19.107,19.107,0,0,0-34.442-.938h4.131a15.512,15.512,0,0,1,25.931.3.666.666,0,0,1-1.119.723,14.185,14.185,0,0,0-23.894,0,.667.667,0,0,1-.559.3h-5.547a.667.667,0,0,1-.6-.947,20.439,20.439,0,0,1,37.32.009.669.669,0,0,1-.607.938Z"
                    transform="translate(-130.158 0)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1814"
                    data-name="Path 1814"
                    d="M348.554,364.46c-1.738,0,.811-4.224-2.568-10.937a.666.666,0,0,1,1.19-.6c2.041,4.05,2.047,6.852,2.045,10.871A.667.667,0,0,1,348.554,364.46Zm-34.638,0a.666.666,0,0,1-.666-.666c0-4.056-.012-6.709,2.111-10.874a.666.666,0,0,1,1.187.605C313.063,360.353,315.665,364.46,313.916,364.46Z"
                    transform="translate(-151.482 -339.894)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1815"
                    data-name="Path 1815"
                    d="M1215.331,353.912h-5.548a.666.666,0,0,1,0-1.332h5.548A.666.666,0,0,1,1215.331,353.912Z"
                    transform="translate(-1017.524 -339.917)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1816"
                    data-name="Path 1816"
                    d="M324.843,1059.791a.668.668,0,0,1-.284-.064,19.846,19.846,0,0,1-11.319-17.873.666.666,0,0,1,1.332,0,18.508,18.508,0,0,0,10.556,16.669.667.667,0,0,1-.284,1.269Z"
                    transform="translate(-151.472 -1005.601)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1817"
                    data-name="Path 1817"
                    d="M642.657,1525.169a12.812,12.812,0,0,1-3.031-.911c-3.418-1.216-1.544-6.362,1.835-5.159l2.119.754A2.741,2.741,0,0,1,642.657,1525.169Zm-2.109-4.9a1.407,1.407,0,0,0-.476,2.73,16.339,16.339,0,0,0,2.585.835,1.408,1.408,0,0,0,.477-2.73,16.323,16.323,0,0,0-2.585-.834Z"
                    transform="translate(-465.236 -1467.413)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1818"
                    data-name="Path 1818"
                    d="M162.434,683.695h-2.992a2.9,2.9,0,0,1-2.892-2.893v-7.9a2.9,2.9,0,0,1,2.892-2.893h2.992a.666.666,0,0,1,.666.666v12.352a.667.667,0,0,1-.666.667Zm-2.992-12.353a1.563,1.563,0,0,0-1.561,1.561v7.9a1.562,1.562,0,0,0,1.561,1.56h2.326v-11.02Z"
                    transform="translate(0 -646.776)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1819"
                    data-name="Path 1819"
                    d="M1357.048,683.695h-2.992a.667.667,0,0,1-.666-.666V670.676a.667.667,0,0,1,.666-.666h2.992a2.9,2.9,0,0,1,2.893,2.893v7.9A2.9,2.9,0,0,1,1357.048,683.695Zm-2.325-1.333h2.325a1.562,1.562,0,0,0,1.56-1.56v-7.9a1.562,1.562,0,0,0-1.56-1.56h-2.325Z"
                    transform="translate(-1156.984 -646.776)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1820"
                    data-name="Path 1820"
                    d="M487.229,512.291a.667.667,0,0,1-.655-.548l-.932-5.134h-1.831a3.656,3.656,0,0,1-3.651-3.652V489.522a3.656,3.656,0,0,1,3.651-3.651h18.2a3.656,3.656,0,0,1,3.652,3.651v13.435a3.656,3.656,0,0,1-3.652,3.652h-9.183l-5.109,5.47a.664.664,0,0,1-.487.212ZM483.811,487.2a2.322,2.322,0,0,0-2.319,2.319v13.435a2.322,2.322,0,0,0,2.319,2.319H486.2a.666.666,0,0,1,.655.548l.794,4.376,4.4-4.712a.664.664,0,0,1,.487-.212h9.473a2.321,2.321,0,0,0,2.319-2.319V489.522a2.322,2.322,0,0,0-2.319-2.319Z"
                    transform="translate(-312.833 -468.768)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1821"
                    data-name="Path 1821"
                    d="M586.323,722.2c-3.525,0-3.525-5.356,0-5.356S589.848,722.2,586.323,722.2Zm0-4.024a1.346,1.346,0,0,0,0,2.692A1.346,1.346,0,0,0,586.323,718.172Z"
                    transform="translate(-412.906 -692.047)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1822"
                    data-name="Path 1822"
                    d="M786.293,722.2c-3.525,0-3.525-5.356,0-5.356S789.817,722.2,786.293,722.2Zm0-4.024a1.346,1.346,0,0,0,0,2.692A1.346,1.346,0,0,0,786.293,718.172Z"
                    transform="translate(-606.216 -692.047)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1823"
                    data-name="Path 1823"
                    d="M986.273,722.2c-3.525,0-3.525-5.356,0-5.356S989.8,722.2,986.273,722.2Zm0-4.024a1.346,1.346,0,0,0,0,2.692A1.346,1.346,0,0,0,986.273,718.172Z"
                    transform="translate(-799.536 -692.047)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1824"
                    data-name="Path 1824"
                    d="M543,528.209a2.322,2.322,0,0,0-2.319-2.319h-18.2a2.322,2.322,0,0,0-2.319,2.319v13.435a2.322,2.322,0,0,0,2.319,2.319h2.387a.667.667,0,0,1,.655.548l.794,4.376,4.4-4.712a.665.665,0,0,1,.487-.212h9.473A2.321,2.321,0,0,0,543,541.644Zm-11.418,9.4c-3.525,0-3.525-5.356,0-5.356S535.1,537.605,531.578,537.605Zm6.66,0c-3.525,0-3.525-5.356,0-5.356S541.762,537.605,538.238,537.605Zm-13.319,0c-3.525,0-3.525-5.356,0-5.356S528.443,537.605,524.918,537.605Z"
                    transform="translate(-351.501 -507.456)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1826"
                    data-name="Path 1826"
                    d="M376.954,51.337a19.108,19.108,0,0,0-33.994,0h4.131a15.51,15.51,0,0,1,25.729,0h4.134Z"
                    transform="translate(-180.202 -38.674)"
                    fill="#e0e0e0"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div class="title">{t("home.29")}</div>
              <p class="des">{t("home.30")}</p>
            </div>
          </div>
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46.438"
                height="52.391"
                viewBox="0 0 46.438 52.391"
              >
                <g id="Wallet" transform="translate(-5 -2)">
                  <path
                    id="Path_1797"
                    data-name="Path 1797"
                    d="M8.572,16A3.572,3.572,0,0,0,5,19.572V48.149a3.572,3.572,0,0,0,3.572,3.572H44.293a3.572,3.572,0,0,0,3.572-3.572V39.814H45.484v8.335a1.191,1.191,0,0,1-1.191,1.191H8.572a1.191,1.191,0,0,1-1.191-1.191v-25H44.293a1.191,1.191,0,0,1,1.191,1.191V32.67h2.381V24.335a3.572,3.572,0,0,0-3.572-3.572H43.1V19.572A3.572,3.572,0,0,0,39.53,16Zm32.149,4.763V19.572a1.191,1.191,0,0,0-1.191-1.191H8.572a1.191,1.191,0,0,0-1.191,1.191v1.191Z"
                    transform="translate(0 2.67)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1798"
                    data-name="Path 1798"
                    d="M32,32.572A3.572,3.572,0,0,1,35.572,29h7.144a3.572,3.572,0,0,1,3.572,3.572v2.381a3.572,3.572,0,0,1-3.572,3.572H35.572A3.572,3.572,0,0,1,32,34.954Zm3.572-1.191a1.191,1.191,0,0,0-1.191,1.191v2.381a1.191,1.191,0,0,0,1.191,1.191h7.144a1.191,1.191,0,0,0,1.191-1.191V32.572a1.191,1.191,0,0,0-1.191-1.191Z"
                    transform="translate(5.149 5.149)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1799"
                    data-name="Path 1799"
                    d="M7.191,39.381a1.191,1.191,0,0,1,0-2.381h13.1a1.191,1.191,0,0,1,0,2.381Z"
                    transform="translate(0.191 6.675)"
                    fill="#333"
                  />
                  <path
                    id="Path_1800"
                    data-name="Path 1800"
                    d="M16.526,32.056a1.191,1.191,0,0,1,0,1.684l-1.437,1.437a3.572,3.572,0,0,1-2.525,1.047H7V33.842h5.563a1.191,1.191,0,0,0,.842-.349l1.437-1.437A1.191,1.191,0,0,1,16.526,32.056Zm5.954,5.954A1.191,1.191,0,1,0,23.67,39.2,1.191,1.191,0,0,0,22.479,38.009ZM18.907,39.2a3.572,3.572,0,1,1,3.572,3.572A3.572,3.572,0,0,1,18.907,39.2Z"
                    transform="translate(0.381 5.665)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1801"
                    data-name="Path 1801"
                    d="M20.1,8H9.381A2.381,2.381,0,0,0,7,10.381V24.67H20.1Z"
                    transform="translate(0.381 1.144)"
                    fill="#333"
                    opacity="0.15"
                  />
                  <path
                    id="Path_1802"
                    data-name="Path 1802"
                    d="M38.2,23.242V5.381A2.381,2.381,0,0,0,35.814,3H14.381A2.381,2.381,0,0,0,12,5.381V23.242Z"
                    transform="translate(1.335 0.191)"
                    fill="#fff"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1803"
                    data-name="Path 1803"
                    d="M34.623,22.67V8.381A2.381,2.381,0,0,0,32.242,6H12V22.67Z"
                    transform="translate(1.335 0.763)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1804"
                    data-name="Path 1804"
                    d="M39.577,5.572A3.572,3.572,0,0,0,36,2H14.572A3.572,3.572,0,0,0,11,5.572V23.433h2.381V5.572a1.191,1.191,0,0,1,1.191-1.191H36A1.191,1.191,0,0,1,37.2,5.572V23.433h2.381Z"
                    transform="translate(1.144)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1805"
                    data-name="Path 1805"
                    d="M26.954,17.526a1.191,1.191,0,0,1-1.191-1.191V9.191a1.191,1.191,0,0,1,2.381,0v7.144A1.191,1.191,0,0,1,26.954,17.526Zm-4.763,2.381A1.191,1.191,0,0,1,21,18.716V11.572a1.191,1.191,0,0,1,2.381,0v7.144A1.191,1.191,0,0,1,22.191,19.907Z"
                    transform="translate(3.051 1.144)"
                    fill="#f6b33f"
                  />
                </g>
              </svg>
              <div class="title">{t("home.31")}</div>
              <p class="des">{t("home.32")}</p>
            </div>
          </div>
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="57.439"
                height="52.652"
                viewBox="0 0 57.439 52.652"
              >
                <g id="Order" transform="translate(0 -2)">
                  <path
                    id="Path_1789"
                    data-name="Path 1789"
                    d="M27.393,19h2.393v2.393H32.18V19h2.393v2.544a4.789,4.789,0,0,1,2.469,7.715,4.787,4.787,0,0,1-2.469,8.887V40.54H32.18V38.146H29.787V40.54H27.393V38.146H25V35.753h2.393V23.787H25V21.393h2.393Zm2.393,16.753h4.787a2.393,2.393,0,1,0,0-4.787H29.787Zm0-7.18h3.59a2.393,2.393,0,0,0,0-4.787h-3.59Z"
                    transform="translate(4.916 3.343)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1790"
                    data-name="Path 1790"
                    d="M33.54,50.686A19.146,19.146,0,1,0,14.393,31.54,19.146,19.146,0,0,0,33.54,50.686Zm0,2.393A21.54,21.54,0,1,0,12,31.54,21.54,21.54,0,0,0,33.54,53.079Z"
                    transform="translate(2.36 1.573)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1791"
                    data-name="Path 1791"
                    d="M4,8.393A2.393,2.393,0,0,1,6.393,6H30.326a2.393,2.393,0,0,1,2.393,2.393v21.54a2.393,2.393,0,0,1-2.393,2.393H6.393A2.393,2.393,0,0,1,4,29.933Z"
                    transform="translate(0.787 0.787)"
                    fill="#333"
                    opacity="0.15"
                  />
                  <path
                    id="Path_1792"
                    data-name="Path 1792"
                    d="M1,5.393A2.393,2.393,0,0,1,3.393,3H27.326a2.393,2.393,0,0,1,2.393,2.393v21.54a2.393,2.393,0,0,1-2.393,2.393H3.393A2.393,2.393,0,0,1,1,26.933Z"
                    transform="translate(0.197 0.197)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1793"
                    data-name="Path 1793"
                    d="M29.13,6H6.393A2.393,2.393,0,0,0,4,8.393V28.736H29.13Z"
                    transform="translate(0.787 0.787)"
                    fill="#f6b33f"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1794"
                    data-name="Path 1794"
                    d="M0,5.59A3.59,3.59,0,0,1,3.59,2H27.523a3.59,3.59,0,0,1,3.59,3.59V27.13a3.59,3.59,0,0,1-3.59,3.59H3.59A3.59,3.59,0,0,1,0,27.13Zm3.59-1.2a1.2,1.2,0,0,0-1.2,1.2V27.13a1.2,1.2,0,0,0,1.2,1.2H27.523a1.2,1.2,0,0,0,1.2-1.2V5.59a1.2,1.2,0,0,0-1.2-1.2Z"
                    transform="translate(0 0)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1795"
                    data-name="Path 1795"
                    d="M8.2,15.393A1.2,1.2,0,0,1,8.2,13H20.163a1.2,1.2,0,1,1,0,2.393Z"
                    transform="translate(1.377 2.163)"
                    fill="#f6b33f"
                  />
                  <path
                    id="Path_1796"
                    data-name="Path 1796"
                    d="M12.351,22.009a1.2,1.2,0,0,1,0-1.692l5.137-5.137-5.137-5.137A1.2,1.2,0,1,1,14.043,8.35l5.983,5.983a1.2,1.2,0,0,1,0,1.692l-5.983,5.983a1.2,1.2,0,0,1-1.692,0Z"
                    transform="translate(2.36 1.18)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div class="title"> {t("home.33")}</div>
              <p class="des">{t("home.34")}</p>
            </div>
          </div>
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60.548"
                height="59.75"
                viewBox="0 0 60.548 59.75"
              >
                <g id="Manage-profile" transform="translate(-1.452 -3)">
                  <path
                    id="settings_FILL0_wght400_GRAD0_opsz48"
                    d="M102.757,200.082H98.784a.9.9,0,0,1-.587-.211.885.885,0,0,1-.316-.542l-.482-3.04a8.065,8.065,0,0,1-1.2-.572,8.272,8.272,0,0,1-1.114-.753l-2.8,1.294a.9.9,0,0,1-.662.045.873.873,0,0,1-.512-.436l-1.987-3.522a.782.782,0,0,1-.09-.632,1,1,0,0,1,.361-.542l2.589-1.9a3.578,3.578,0,0,1-.075-.617q-.015-.346-.015-.617t.015-.617a3.579,3.579,0,0,1,.075-.617l-2.589-1.9a1,1,0,0,1-.361-.542.782.782,0,0,1,.09-.632l1.987-3.522a.873.873,0,0,1,.512-.436.9.9,0,0,1,.662.045l2.8,1.294a8.272,8.272,0,0,1,1.114-.753,6.23,6.23,0,0,1,1.2-.542l.482-3.07a.885.885,0,0,1,.316-.542.9.9,0,0,1,.587-.211h3.974a.9.9,0,0,1,.587.211.885.885,0,0,1,.316.542l.482,3.04a9.758,9.758,0,0,1,1.219.557,5.18,5.18,0,0,1,1.1.768l2.8-1.294a.9.9,0,0,1,.662-.045.873.873,0,0,1,.512.436l1.987,3.492a.86.86,0,0,1,.105.647.892.892,0,0,1-.376.557l-2.589,1.836a4.243,4.243,0,0,1,.075.647q.015.346.015.647t-.015.632a4.193,4.193,0,0,1-.075.632l2.589,1.866a1,1,0,0,1,.361.542.782.782,0,0,1-.09.632l-1.987,3.522a.873.873,0,0,1-.512.436.9.9,0,0,1-.662-.045l-2.8-1.294a9.986,9.986,0,0,1-1.1.768,5.245,5.245,0,0,1-1.219.557l-.482,3.04a.885.885,0,0,1-.316.542A.9.9,0,0,1,102.757,200.082Zm-1.987-8.128A3.906,3.906,0,1,0,98,190.811,3.772,3.772,0,0,0,100.77,191.955Zm0-1.806a2.107,2.107,0,1,1,1.49-.617A2.03,2.03,0,0,1,100.77,190.148ZM100.77,188.041Zm-1.325,10.235h2.649l.421-3.372a7.1,7.1,0,0,0,1.881-.753,7.715,7.715,0,0,0,1.611-1.234L109.2,194.3l1.2-2.167-2.83-2.077q.12-.512.2-1.008a6.721,6.721,0,0,0,.075-1.008,8.373,8.373,0,0,0-.06-1.008,6.228,6.228,0,0,0-.211-1.008l2.83-2.077-1.2-2.167-3.191,1.385a6.711,6.711,0,0,0-1.565-1.309,5.077,5.077,0,0,0-1.927-.677l-.421-3.372H99.446l-.421,3.372a6.379,6.379,0,0,0-1.912.722,6.64,6.64,0,0,0-1.58,1.264l-3.191-1.385-1.2,2.167,2.83,2.077q-.12.512-.2,1.008a6.794,6.794,0,0,0,0,2.017q.075.5.2,1.008l-2.83,2.077,1.2,2.167,3.191-1.385a7.715,7.715,0,0,0,1.611,1.234,7.1,7.1,0,0,0,1.881.753Z"
                    transform="translate(-87.548 -137.333)"
                  />
                  <path
                    id="Path_1787"
                    data-name="Path 1787"
                    d="M21,6A10,10,0,1,0,31,16,10,10,0,0,0,21,6ZM9,16A12,12,0,1,1,21,28,12,12,0,0,1,9,16Z"
                    transform="translate(29 -1)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1788"
                    data-name="Path 1788"
                    d="M18.414,18.606V12.121H17v-2h1.414V9h1.414v1.121h1.414V9h1.415v1.2a3.122,3.122,0,0,1,1.878,4.79,3.121,3.121,0,0,1-1.878,5.614v1.121H21.242v-1.12H19.828v1.12H18.414v-1.12H17v-2h1.414Zm1.414,0h2.829a1.121,1.121,0,1,0,0-2.242H19.828Zm0-4.242V12.12H21.95a1.122,1.122,0,1,1,0,2.243H19.828Z"
                    transform="translate(29 -1)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1779"
                    data-name="Path 1779"
                    d="M52,37A20,20,0,1,1,32,17,20,20,0,0,1,52,37Z"
                    fill="#333"
                    opacity="0.15"
                  />
                  <path
                    id="Path_1780"
                    data-name="Path 1780"
                    d="M52,32A20,20,0,1,1,32,12,20,20,0,0,1,52,32Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1781"
                    data-name="Path 1781"
                    d="M52,33c0,9.941-8.954,18-20,18S12,42.941,12,33s8.954-18,20-18S52,23.059,52,33Z"
                    fill="#f6b33f"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1782"
                    data-name="Path 1782"
                    d="M32,38.667c-6.627,0-12,4.347-12,9.71,4,3.237,14.4,7.769,24,0C44,43.014,38.627,38.667,32,38.667Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1783"
                    data-name="Path 1783"
                    d="M32,39.667c-6.283,0-11,4.087-11,8.71H19c0-6.1,6.028-10.71,13-10.71s13,4.608,13,10.71H43C43,43.754,38.283,39.667,32,39.667Z"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1784"
                    data-name="Path 1784"
                    d="M32,13A19,19,0,1,0,51,32,19,19,0,0,0,32,13ZM11,32A21,21,0,1,1,32,53,21,21,0,0,1,11,32Z"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1785"
                    data-name="Path 1785"
                    d="M39,27a7,7,0,1,1-7-7A7,7,0,0,1,39,27Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1786"
                    data-name="Path 1786"
                    d="M32,21a6,6,0,1,0,6,6A6,6,0,0,0,32,21Zm-8,6a8,8,0,1,1,8,8A8,8,0,0,1,24,27Z"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div class="title">{t("home.35")}</div>
              <p class="des">{t("home.36")}</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Section-five --> */}
      <section id="section-five">
        <div className="titles">
          <div class="t1">{t("home.37")}</div>
          <div class="t2">{t("home.38")}</div>
          <div class="t3">{t("home.39")}</div>
        </div>
        <div class="all">
          <div class="left">
            <div className="top">
              <div class="t1">{t("home.37")}</div>
              <div class="t2">{t("home.38")}</div>
              <div class="t3">{t("home.39")}</div>
            </div>
            {/* <div class="box">
              <div class="top">
                <img src={require("../image/home-page/Checked.png")} />{" "}
                {t("home.40")}
              </div>
              <p class="des">{t("home.41")}</p>
            </div> */}
            <div class="box">
              <div class="top">
                <img src={require("../image/home-page/Checked.png")} />{" "}
                {t("home.42")}
              </div>
              <p class="des">{t("home.43")}</p>
            </div>
            <div class="box">
              <div class="top">
                <img src={require("../image/home-page/Checked.png")} />
                {t("home.44")}
              </div>
              <p class="des">{t("home.45")}</p>
            </div>
          </div>
          <div class="right">
            <img src={require("../image/home-page/Image-1.png")} />
            <div className="text">{t("home.50")}</div>
          </div>
        </div>
      </section>
      {/* Section-blog */}
      <section id="section-blog">
        <div className="all">
          <div className="title">{t("home.46")}</div>
          <div className="des">{t("home.47")}</div>
          <div className="link">
            <Link to={'/blog'}>
              {t("home.48")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.863"
                height="10.256"
                viewBox="0 0 5.863 10.256"
              >
                <path
                  id="Arrow-right"
                  d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                  transform="translate(17.114 16.449) rotate(180)"
                />
              </svg>
            </Link>
          </div>
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              100: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              998: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {blog &&
              blog.map((b, i) => (
                <SwiperSlide key={i}>
                  <Link to={'/post-blog/'+b.id} target="_blank" style={{display: 'contents'}}>
                    <div className="carts">
                      <img src={b.image} />
                      
                      <div className="text">
                        <div className="title">{b.title}</div>
                        <div className="time">{b.created_at}</div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            {/* <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 34.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 3.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 3.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 34.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 3.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 34.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 34.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 34.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carts">
                <img src={require("../image/home-page/Mask Group 3.png")} />
                <div className="text">
                  <div className="title">
                    Comprehensive education of the cryptocurrency
                  </div>
                  <div className="time">12 hours ago</div>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </section>
      <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}

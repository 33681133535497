import { useContext } from "react";
import Cookies from "js-cookie";
import { MainContext } from "../../contexts/MainContexts";

const LightingMode_two = () => {

    const { lightingMode, setLightingMode } = useContext(MainContext);

    const changeStatus = () => {
        if (lightingMode === "light") {
            Cookies.set("lightingMode", "dark",{ expires: 365 });
            setLightingMode("dark");
        } else {
            Cookies.set("lightingMode", "light",{ expires: 365 });
            setLightingMode("light");
        }
    }


    return (
        <div onClick={changeStatus} className='w-[46px] h-[46] cursor-pointer overflow-hidden'
            style={{ border: "1px solid white",borderRadius:"10px" }}>
            {
                lightingMode === "light" ?
                    (
                        <span className="w-[46px] h-[46px] flexCenter rounded " style={{ border: "1px solid #707070" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.883" height="23.88" viewBox="0 0 23.883 23.88">
                                <path id="dark_mode_FILL0_wght400_GRAD0_opsz48" d="M132.072,247.747A12.022,12.022,0,0,1,120,235.675,11.49,11.49,0,0,1,122.666,228a12.111,12.111,0,0,1,6.992-4.057q1.375-.268,1.878.469a2.061,2.061,0,0,1-.034,2.012,9.8,9.8,0,0,0-.469,1.576,8.034,8.034,0,0,0-.168,1.643,7.213,7.213,0,0,0,7.243,7.243,8.63,8.63,0,0,0,1.626-.151,9.911,9.911,0,0,0,1.526-.419,2.115,2.115,0,0,1,2.146.05,1.869,1.869,0,0,1,.369,2,12,12,0,0,1-4.057,6.723A11.443,11.443,0,0,1,132.072,247.747Zm0-2.012a9.646,9.646,0,0,0,6.371-2.263,9.5,9.5,0,0,0,3.387-5.315,8.367,8.367,0,0,1-1.8.553,10.183,10.183,0,0,1-1.923.184,9.234,9.234,0,0,1-9.255-9.255,9.685,9.685,0,0,1,.168-1.727,12.6,12.6,0,0,1,.6-2.1,10.214,10.214,0,0,0-5.449,3.672,10.023,10.023,0,0,0,7.9,16.247ZM131.938,235.775Z" transform="translate(-120 -223.867)" fill="#fff" />
                            </svg>
                        </span>
                    )
                    :
                    (
                        <span className="w-[46px] h-[46px] flexCenter rounded " style={{ border: "1px solid #707070" }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="sun"
                                width="23.66"
                                height="23.66"
                                viewBox="0 0 17.66 17.66"

                            >
                                <path
                                    id="Path_8247"
                                    data-name="Path 8247"
                                    d="M13.426,16.376a2.95,2.95,0,1,0-2.95-2.95A2.95,2.95,0,0,0,13.426,16.376Zm0,1.475A4.426,4.426,0,1,0,9,13.426,4.426,4.426,0,0,0,13.426,17.851Z"
                                    transform="translate(-4.596 -4.596)"
                                    fill="#f6b33f"
                                    fill-rule="evenodd"
                                />
                                <path
                                    id="Path_8248"
                                    data-name="Path 8248"
                                    d="M8.094,0H9.566V2.989a5.945,5.945,0,0,0-1.472,0ZM5.22,4.179,3.107,2.066l-1.04,1.04L4.179,5.22a5.922,5.922,0,0,1,1.04-1.04ZM2.989,8.094H0V9.566H2.989a5.945,5.945,0,0,1,0-1.472ZM4.179,12.44,2.066,14.553l1.04,1.04L5.22,13.48A5.922,5.922,0,0,1,4.179,12.44Zm3.915,2.231V17.66H9.566V14.671a5.937,5.937,0,0,1-1.472,0ZM12.44,13.48l2.113,2.113,1.04-1.04L13.48,12.44a5.922,5.922,0,0,1-1.04,1.04Zm2.231-3.915H17.66V8.094H14.671A5.937,5.937,0,0,1,14.671,9.566ZM13.48,5.22l2.113-2.113-1.04-1.04L12.44,4.179a5.934,5.934,0,0,1,1.04,1.04Z"
                                    transform="translate(0)"
                                    fill="#f6b33f"
                                    fill-rule="evenodd"
                                />
                            </svg>
                        </span>

                    )

            }
        </div>
    )
}

export default LightingMode_two
import React from "react";
import "./aboutus.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
export default function Aboutus() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.11")}</title>
        </Helmet>
      <Header />
      {/* Aboutus-section-one */}
      <section id="aboutus-section-one">
        <div className="all">
          <img src={require("../image/about-us-page/Team.png")} />
          <div className="text1">{t("about-us.1")}</div>
          <div className="text2">
          {t("about-us.2")}
          </div>
        </div>
      </section>
      {/* <!-- Section-four --> */}
      <section id="section-four">
        <div class="all">
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46.407"
                height="56.802"
                viewBox="0 0 46.407 56.802"
              >
                <g id="Support" transform="translate(-156.55 -0.953)">
                  <path
                    id="Path_1813"
                    data-name="Path 1813"
                    d="M327.966,13.995a.666.666,0,0,1-.608-.394,19.107,19.107,0,0,0-34.442-.938h4.131a15.512,15.512,0,0,1,25.931.3.666.666,0,0,1-1.119.723,14.185,14.185,0,0,0-23.894,0,.667.667,0,0,1-.559.3h-5.547a.667.667,0,0,1-.6-.947,20.439,20.439,0,0,1,37.32.009.669.669,0,0,1-.607.938Z"
                    transform="translate(-130.158 0)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1814"
                    data-name="Path 1814"
                    d="M348.554,364.46c-1.738,0,.811-4.224-2.568-10.937a.666.666,0,0,1,1.19-.6c2.041,4.05,2.047,6.852,2.045,10.871A.667.667,0,0,1,348.554,364.46Zm-34.638,0a.666.666,0,0,1-.666-.666c0-4.056-.012-6.709,2.111-10.874a.666.666,0,0,1,1.187.605C313.063,360.353,315.665,364.46,313.916,364.46Z"
                    transform="translate(-151.482 -339.894)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1815"
                    data-name="Path 1815"
                    d="M1215.331,353.912h-5.548a.666.666,0,0,1,0-1.332h5.548A.666.666,0,0,1,1215.331,353.912Z"
                    transform="translate(-1017.524 -339.917)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1816"
                    data-name="Path 1816"
                    d="M324.843,1059.791a.668.668,0,0,1-.284-.064,19.846,19.846,0,0,1-11.319-17.873.666.666,0,0,1,1.332,0,18.508,18.508,0,0,0,10.556,16.669.667.667,0,0,1-.284,1.269Z"
                    transform="translate(-151.472 -1005.601)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1817"
                    data-name="Path 1817"
                    d="M642.657,1525.169a12.812,12.812,0,0,1-3.031-.911c-3.418-1.216-1.544-6.362,1.835-5.159l2.119.754A2.741,2.741,0,0,1,642.657,1525.169Zm-2.109-4.9a1.407,1.407,0,0,0-.476,2.73,16.339,16.339,0,0,0,2.585.835,1.408,1.408,0,0,0,.477-2.73,16.323,16.323,0,0,0-2.585-.834Z"
                    transform="translate(-465.236 -1467.413)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1818"
                    data-name="Path 1818"
                    d="M162.434,683.695h-2.992a2.9,2.9,0,0,1-2.892-2.893v-7.9a2.9,2.9,0,0,1,2.892-2.893h2.992a.666.666,0,0,1,.666.666v12.352a.667.667,0,0,1-.666.667Zm-2.992-12.353a1.563,1.563,0,0,0-1.561,1.561v7.9a1.562,1.562,0,0,0,1.561,1.56h2.326v-11.02Z"
                    transform="translate(0 -646.776)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1819"
                    data-name="Path 1819"
                    d="M1357.048,683.695h-2.992a.667.667,0,0,1-.666-.666V670.676a.667.667,0,0,1,.666-.666h2.992a2.9,2.9,0,0,1,2.893,2.893v7.9A2.9,2.9,0,0,1,1357.048,683.695Zm-2.325-1.333h2.325a1.562,1.562,0,0,0,1.56-1.56v-7.9a1.562,1.562,0,0,0-1.56-1.56h-2.325Z"
                    transform="translate(-1156.984 -646.776)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1820"
                    data-name="Path 1820"
                    d="M487.229,512.291a.667.667,0,0,1-.655-.548l-.932-5.134h-1.831a3.656,3.656,0,0,1-3.651-3.652V489.522a3.656,3.656,0,0,1,3.651-3.651h18.2a3.656,3.656,0,0,1,3.652,3.651v13.435a3.656,3.656,0,0,1-3.652,3.652h-9.183l-5.109,5.47a.664.664,0,0,1-.487.212ZM483.811,487.2a2.322,2.322,0,0,0-2.319,2.319v13.435a2.322,2.322,0,0,0,2.319,2.319H486.2a.666.666,0,0,1,.655.548l.794,4.376,4.4-4.712a.664.664,0,0,1,.487-.212h9.473a2.321,2.321,0,0,0,2.319-2.319V489.522a2.322,2.322,0,0,0-2.319-2.319Z"
                    transform="translate(-312.833 -468.768)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1821"
                    data-name="Path 1821"
                    d="M586.323,722.2c-3.525,0-3.525-5.356,0-5.356S589.848,722.2,586.323,722.2Zm0-4.024a1.346,1.346,0,0,0,0,2.692A1.346,1.346,0,0,0,586.323,718.172Z"
                    transform="translate(-412.906 -692.047)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1822"
                    data-name="Path 1822"
                    d="M786.293,722.2c-3.525,0-3.525-5.356,0-5.356S789.817,722.2,786.293,722.2Zm0-4.024a1.346,1.346,0,0,0,0,2.692A1.346,1.346,0,0,0,786.293,718.172Z"
                    transform="translate(-606.216 -692.047)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1823"
                    data-name="Path 1823"
                    d="M986.273,722.2c-3.525,0-3.525-5.356,0-5.356S989.8,722.2,986.273,722.2Zm0-4.024a1.346,1.346,0,0,0,0,2.692A1.346,1.346,0,0,0,986.273,718.172Z"
                    transform="translate(-799.536 -692.047)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1824"
                    data-name="Path 1824"
                    d="M543,528.209a2.322,2.322,0,0,0-2.319-2.319h-18.2a2.322,2.322,0,0,0-2.319,2.319v13.435a2.322,2.322,0,0,0,2.319,2.319h2.387a.667.667,0,0,1,.655.548l.794,4.376,4.4-4.712a.665.665,0,0,1,.487-.212h9.473A2.321,2.321,0,0,0,543,541.644Zm-11.418,9.4c-3.525,0-3.525-5.356,0-5.356S535.1,537.605,531.578,537.605Zm6.66,0c-3.525,0-3.525-5.356,0-5.356S541.762,537.605,538.238,537.605Zm-13.319,0c-3.525,0-3.525-5.356,0-5.356S528.443,537.605,524.918,537.605Z"
                    transform="translate(-351.501 -507.456)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1826"
                    data-name="Path 1826"
                    d="M376.954,51.337a19.108,19.108,0,0,0-33.994,0h4.131a15.51,15.51,0,0,1,25.729,0h4.134Z"
                    transform="translate(-180.202 -38.674)"
                    fill="#e0e0e0"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div class="title">{t("home.29")}</div>
              <p class="des">{t("home.30")}</p>
            </div>
          </div>
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46.438"
                height="52.391"
                viewBox="0 0 46.438 52.391"
              >
                <g id="Wallet" transform="translate(-5 -2)">
                  <path
                    id="Path_1797"
                    data-name="Path 1797"
                    d="M8.572,16A3.572,3.572,0,0,0,5,19.572V48.149a3.572,3.572,0,0,0,3.572,3.572H44.293a3.572,3.572,0,0,0,3.572-3.572V39.814H45.484v8.335a1.191,1.191,0,0,1-1.191,1.191H8.572a1.191,1.191,0,0,1-1.191-1.191v-25H44.293a1.191,1.191,0,0,1,1.191,1.191V32.67h2.381V24.335a3.572,3.572,0,0,0-3.572-3.572H43.1V19.572A3.572,3.572,0,0,0,39.53,16Zm32.149,4.763V19.572a1.191,1.191,0,0,0-1.191-1.191H8.572a1.191,1.191,0,0,0-1.191,1.191v1.191Z"
                    transform="translate(0 2.67)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1798"
                    data-name="Path 1798"
                    d="M32,32.572A3.572,3.572,0,0,1,35.572,29h7.144a3.572,3.572,0,0,1,3.572,3.572v2.381a3.572,3.572,0,0,1-3.572,3.572H35.572A3.572,3.572,0,0,1,32,34.954Zm3.572-1.191a1.191,1.191,0,0,0-1.191,1.191v2.381a1.191,1.191,0,0,0,1.191,1.191h7.144a1.191,1.191,0,0,0,1.191-1.191V32.572a1.191,1.191,0,0,0-1.191-1.191Z"
                    transform="translate(5.149 5.149)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1799"
                    data-name="Path 1799"
                    d="M7.191,39.381a1.191,1.191,0,0,1,0-2.381h13.1a1.191,1.191,0,0,1,0,2.381Z"
                    transform="translate(0.191 6.675)"
                    fill="#333"
                  />
                  <path
                    id="Path_1800"
                    data-name="Path 1800"
                    d="M16.526,32.056a1.191,1.191,0,0,1,0,1.684l-1.437,1.437a3.572,3.572,0,0,1-2.525,1.047H7V33.842h5.563a1.191,1.191,0,0,0,.842-.349l1.437-1.437A1.191,1.191,0,0,1,16.526,32.056Zm5.954,5.954A1.191,1.191,0,1,0,23.67,39.2,1.191,1.191,0,0,0,22.479,38.009ZM18.907,39.2a3.572,3.572,0,1,1,3.572,3.572A3.572,3.572,0,0,1,18.907,39.2Z"
                    transform="translate(0.381 5.665)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1801"
                    data-name="Path 1801"
                    d="M20.1,8H9.381A2.381,2.381,0,0,0,7,10.381V24.67H20.1Z"
                    transform="translate(0.381 1.144)"
                    fill="#333"
                    opacity="0.15"
                  />
                  <path
                    id="Path_1802"
                    data-name="Path 1802"
                    d="M38.2,23.242V5.381A2.381,2.381,0,0,0,35.814,3H14.381A2.381,2.381,0,0,0,12,5.381V23.242Z"
                    transform="translate(1.335 0.191)"
                    fill="#fff"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1803"
                    data-name="Path 1803"
                    d="M34.623,22.67V8.381A2.381,2.381,0,0,0,32.242,6H12V22.67Z"
                    transform="translate(1.335 0.763)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1804"
                    data-name="Path 1804"
                    d="M39.577,5.572A3.572,3.572,0,0,0,36,2H14.572A3.572,3.572,0,0,0,11,5.572V23.433h2.381V5.572a1.191,1.191,0,0,1,1.191-1.191H36A1.191,1.191,0,0,1,37.2,5.572V23.433h2.381Z"
                    transform="translate(1.144)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1805"
                    data-name="Path 1805"
                    d="M26.954,17.526a1.191,1.191,0,0,1-1.191-1.191V9.191a1.191,1.191,0,0,1,2.381,0v7.144A1.191,1.191,0,0,1,26.954,17.526Zm-4.763,2.381A1.191,1.191,0,0,1,21,18.716V11.572a1.191,1.191,0,0,1,2.381,0v7.144A1.191,1.191,0,0,1,22.191,19.907Z"
                    transform="translate(3.051 1.144)"
                    fill="#f6b33f"
                  />
                </g>
              </svg>
              <div class="title">{t("home.31")}</div>
              <p class="des">{t("home.32")}</p>
            </div>
          </div>
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="57.439"
                height="52.652"
                viewBox="0 0 57.439 52.652"
              >
                <g id="Order" transform="translate(0 -2)">
                  <path
                    id="Path_1789"
                    data-name="Path 1789"
                    d="M27.393,19h2.393v2.393H32.18V19h2.393v2.544a4.789,4.789,0,0,1,2.469,7.715,4.787,4.787,0,0,1-2.469,8.887V40.54H32.18V38.146H29.787V40.54H27.393V38.146H25V35.753h2.393V23.787H25V21.393h2.393Zm2.393,16.753h4.787a2.393,2.393,0,1,0,0-4.787H29.787Zm0-7.18h3.59a2.393,2.393,0,0,0,0-4.787h-3.59Z"
                    transform="translate(4.916 3.343)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1790"
                    data-name="Path 1790"
                    d="M33.54,50.686A19.146,19.146,0,1,0,14.393,31.54,19.146,19.146,0,0,0,33.54,50.686Zm0,2.393A21.54,21.54,0,1,0,12,31.54,21.54,21.54,0,0,0,33.54,53.079Z"
                    transform="translate(2.36 1.573)"
                    fill="#333"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1791"
                    data-name="Path 1791"
                    d="M4,8.393A2.393,2.393,0,0,1,6.393,6H30.326a2.393,2.393,0,0,1,2.393,2.393v21.54a2.393,2.393,0,0,1-2.393,2.393H6.393A2.393,2.393,0,0,1,4,29.933Z"
                    transform="translate(0.787 0.787)"
                    fill="#333"
                    opacity="0.15"
                  />
                  <path
                    id="Path_1792"
                    data-name="Path 1792"
                    d="M1,5.393A2.393,2.393,0,0,1,3.393,3H27.326a2.393,2.393,0,0,1,2.393,2.393v21.54a2.393,2.393,0,0,1-2.393,2.393H3.393A2.393,2.393,0,0,1,1,26.933Z"
                    transform="translate(0.197 0.197)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1793"
                    data-name="Path 1793"
                    d="M29.13,6H6.393A2.393,2.393,0,0,0,4,8.393V28.736H29.13Z"
                    transform="translate(0.787 0.787)"
                    fill="#f6b33f"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1794"
                    data-name="Path 1794"
                    d="M0,5.59A3.59,3.59,0,0,1,3.59,2H27.523a3.59,3.59,0,0,1,3.59,3.59V27.13a3.59,3.59,0,0,1-3.59,3.59H3.59A3.59,3.59,0,0,1,0,27.13Zm3.59-1.2a1.2,1.2,0,0,0-1.2,1.2V27.13a1.2,1.2,0,0,0,1.2,1.2H27.523a1.2,1.2,0,0,0,1.2-1.2V5.59a1.2,1.2,0,0,0-1.2-1.2Z"
                    transform="translate(0 0)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1795"
                    data-name="Path 1795"
                    d="M8.2,15.393A1.2,1.2,0,0,1,8.2,13H20.163a1.2,1.2,0,1,1,0,2.393Z"
                    transform="translate(1.377 2.163)"
                    fill="#f6b33f"
                  />
                  <path
                    id="Path_1796"
                    data-name="Path 1796"
                    d="M12.351,22.009a1.2,1.2,0,0,1,0-1.692l5.137-5.137-5.137-5.137A1.2,1.2,0,1,1,14.043,8.35l5.983,5.983a1.2,1.2,0,0,1,0,1.692l-5.983,5.983a1.2,1.2,0,0,1-1.692,0Z"
                    transform="translate(2.36 1.18)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div class="title"> {t("home.33")}</div>
              <p class="des">{t("home.34")}</p>
            </div>
          </div>
          <div class="grid-items">
            <div class="carts">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60.548"
                height="59.75"
                viewBox="0 0 60.548 59.75"
              >
                <g id="Manage-profile" transform="translate(-1.452 -3)">
                  <path
                    id="settings_FILL0_wght400_GRAD0_opsz48"
                    d="M102.757,200.082H98.784a.9.9,0,0,1-.587-.211.885.885,0,0,1-.316-.542l-.482-3.04a8.065,8.065,0,0,1-1.2-.572,8.272,8.272,0,0,1-1.114-.753l-2.8,1.294a.9.9,0,0,1-.662.045.873.873,0,0,1-.512-.436l-1.987-3.522a.782.782,0,0,1-.09-.632,1,1,0,0,1,.361-.542l2.589-1.9a3.578,3.578,0,0,1-.075-.617q-.015-.346-.015-.617t.015-.617a3.579,3.579,0,0,1,.075-.617l-2.589-1.9a1,1,0,0,1-.361-.542.782.782,0,0,1,.09-.632l1.987-3.522a.873.873,0,0,1,.512-.436.9.9,0,0,1,.662.045l2.8,1.294a8.272,8.272,0,0,1,1.114-.753,6.23,6.23,0,0,1,1.2-.542l.482-3.07a.885.885,0,0,1,.316-.542.9.9,0,0,1,.587-.211h3.974a.9.9,0,0,1,.587.211.885.885,0,0,1,.316.542l.482,3.04a9.758,9.758,0,0,1,1.219.557,5.18,5.18,0,0,1,1.1.768l2.8-1.294a.9.9,0,0,1,.662-.045.873.873,0,0,1,.512.436l1.987,3.492a.86.86,0,0,1,.105.647.892.892,0,0,1-.376.557l-2.589,1.836a4.243,4.243,0,0,1,.075.647q.015.346.015.647t-.015.632a4.193,4.193,0,0,1-.075.632l2.589,1.866a1,1,0,0,1,.361.542.782.782,0,0,1-.09.632l-1.987,3.522a.873.873,0,0,1-.512.436.9.9,0,0,1-.662-.045l-2.8-1.294a9.986,9.986,0,0,1-1.1.768,5.245,5.245,0,0,1-1.219.557l-.482,3.04a.885.885,0,0,1-.316.542A.9.9,0,0,1,102.757,200.082Zm-1.987-8.128A3.906,3.906,0,1,0,98,190.811,3.772,3.772,0,0,0,100.77,191.955Zm0-1.806a2.107,2.107,0,1,1,1.49-.617A2.03,2.03,0,0,1,100.77,190.148ZM100.77,188.041Zm-1.325,10.235h2.649l.421-3.372a7.1,7.1,0,0,0,1.881-.753,7.715,7.715,0,0,0,1.611-1.234L109.2,194.3l1.2-2.167-2.83-2.077q.12-.512.2-1.008a6.721,6.721,0,0,0,.075-1.008,8.373,8.373,0,0,0-.06-1.008,6.228,6.228,0,0,0-.211-1.008l2.83-2.077-1.2-2.167-3.191,1.385a6.711,6.711,0,0,0-1.565-1.309,5.077,5.077,0,0,0-1.927-.677l-.421-3.372H99.446l-.421,3.372a6.379,6.379,0,0,0-1.912.722,6.64,6.64,0,0,0-1.58,1.264l-3.191-1.385-1.2,2.167,2.83,2.077q-.12.512-.2,1.008a6.794,6.794,0,0,0,0,2.017q.075.5.2,1.008l-2.83,2.077,1.2,2.167,3.191-1.385a7.715,7.715,0,0,0,1.611,1.234,7.1,7.1,0,0,0,1.881.753Z"
                    transform="translate(-87.548 -137.333)"
                  />
                  <path
                    id="Path_1787"
                    data-name="Path 1787"
                    d="M21,6A10,10,0,1,0,31,16,10,10,0,0,0,21,6ZM9,16A12,12,0,1,1,21,28,12,12,0,0,1,9,16Z"
                    transform="translate(29 -1)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1788"
                    data-name="Path 1788"
                    d="M18.414,18.606V12.121H17v-2h1.414V9h1.414v1.121h1.414V9h1.415v1.2a3.122,3.122,0,0,1,1.878,4.79,3.121,3.121,0,0,1-1.878,5.614v1.121H21.242v-1.12H19.828v1.12H18.414v-1.12H17v-2h1.414Zm1.414,0h2.829a1.121,1.121,0,1,0,0-2.242H19.828Zm0-4.242V12.12H21.95a1.122,1.122,0,1,1,0,2.243H19.828Z"
                    transform="translate(29 -1)"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1779"
                    data-name="Path 1779"
                    d="M52,37A20,20,0,1,1,32,17,20,20,0,0,1,52,37Z"
                    fill="#333"
                    opacity="0.15"
                  />
                  <path
                    id="Path_1780"
                    data-name="Path 1780"
                    d="M52,32A20,20,0,1,1,32,12,20,20,0,0,1,52,32Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1781"
                    data-name="Path 1781"
                    d="M52,33c0,9.941-8.954,18-20,18S12,42.941,12,33s8.954-18,20-18S52,23.059,52,33Z"
                    fill="#f6b33f"
                    opacity="0.2"
                  />
                  <path
                    id="Path_1782"
                    data-name="Path 1782"
                    d="M32,38.667c-6.627,0-12,4.347-12,9.71,4,3.237,14.4,7.769,24,0C44,43.014,38.627,38.667,32,38.667Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1783"
                    data-name="Path 1783"
                    d="M32,39.667c-6.283,0-11,4.087-11,8.71H19c0-6.1,6.028-10.71,13-10.71s13,4.608,13,10.71H43C43,43.754,38.283,39.667,32,39.667Z"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1784"
                    data-name="Path 1784"
                    d="M32,13A19,19,0,1,0,51,32,19,19,0,0,0,32,13ZM11,32A21,21,0,1,1,32,53,21,21,0,0,1,11,32Z"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_1785"
                    data-name="Path 1785"
                    d="M39,27a7,7,0,1,1-7-7A7,7,0,0,1,39,27Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_1786"
                    data-name="Path 1786"
                    d="M32,21a6,6,0,1,0,6,6A6,6,0,0,0,32,21Zm-8,6a8,8,0,1,1,8,8A8,8,0,0,1,24,27Z"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              <div class="title">{t("home.35")}</div>
              <p class="des">{t("home.36")}</p>
            </div>
          </div>
        </div>
      </section>
      {/* Aboutus-section-two */}
      <section id="aboutus-section-two">
        <div className="all">
          <div className="about">
            <div className="title">{t("about-us.3")}</div>
            <p className="des">
            {t("about-us.4")}
            </p>
          </div>
          <div className="part2">
            <div className="title">{t("about-us.6")}</div>
            <p className="des">
              {t("about-us.8")}
            </p>
          </div>
        </div>
      </section>
      {/* Aboutus-section-three */}
      <section id="aboutus-section-three">
        <div className="all">
          <div className="grid-item">
            <div className="carts">
              <div className="number">{t("about-us.9")}</div>
              <div className="title">{t("about-us.10")}</div>
              <img
                className="opacity"
                src={require("../image/about-us-page/Group 65.png")}
              />
            </div>
          </div>
          <div className="grid-item">
            <div className="carts">
              <div className="number">{t("about-us.11")}</div>
              <div className="title">{t("about-us.12")}</div>
              <img
                src={require("../image/about-us-page/candlestick_chart_FILL0_wght400_GRAD0_opsz48.png")}
              />
            </div>
          </div>
          <div className="grid-item">
            <div className="carts">
              <div className="number">{t("about-us.13")}</div>
              <div className="title">{t("about-us.14")}</div>
              <img
                src={require("../image/about-us-page/Icon awesome-users.png")}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="team-img">
        <img src={require("../image/about-us-page/Mojbit-team.png")} />
      </div>
      <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}

//internal
import { useContext, useState, useEffect } from "react";

//external
import axios from "axios";
import Cookies from "js-cookie";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';



//Component and Style
import "./judge.css";
import "./lightingMode.css"
import Login_Left from "../login/Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode"
//Image
import canadaFlag from "../../../assests/image/flag/canada.jpg";
import usaFlag from "../../../assests/image/flag/usa.jpg";
import iranFlag from "../../../assests/image/flag/iran.jpg";

import { BASE_URL } from '../../../utils/url'



const Judge = () => {

    const baseURL = BASE_URL
    const navigate = useNavigate();
    const { t } = useTranslation()
    const { lightingMode } = useContext(MainContext);



    const [country_code, setCountry_code] = useState("98");
    const [mobile, setMobile] = useState("");






    //handleSubmitForm
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.target.disabled = true

        const _fuck = (mobile[0] === "0") ? mobile.slice(1) : mobile

        try {
            const res = await axios.post(`${baseURL}/registration/judge`,
                JSON.stringify({
                    country_code,
                    mobile: _fuck,
                    type: "mobile"
                }),
                { headers: { 'Content-Type': 'application/json' } })

            if (res.status == 200) {

                if (res.data.exist) {
                    Cookies.set("user_phone", _fuck)
                    navigate("/login")

                } else {
                    Cookies.set("user_phone", _fuck)
                    navigate("/register")
                }

                e.target.disabled = false
            }


        } catch (err) {
            // console.log(err.response.data.errors.mobile[0]);
            toast.error(err.response.data.errors.mobile[0], {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            e.target.disabled = false
        }


    }//end



    ////////////////////////////Select////////////////////
    ////////////////////////////Select////////////////////
    //style for Select
    const customStyles = {
        control: base => ({
            ...base,
            height: "46px",
            width: "100%",
            background: (lightingMode === "light") ? "white" : "black",

        }),
        option: (base) => ({
            ...base,
            backgroundColor: (lightingMode === 'light') ? '#f7f8fa' : '#000'
        }),
    };

    //لیست پرچم ها و پیش شماره
    const areaCode_List = [
        {
            src_flag: iranFlag,
            country_code: "98",
            value: "98"
        },
        {
            src_flag: usaFlag,
            country_code: "1",
            value: "1"
        },
        {
            src_flag: canadaFlag,
            country_code: "1",
            value: "1"
        }
    ]

    //option for Select
    const optionFlagList = areaCode_List.map(mm => {
        return {
            value: mm.value,
            label:
                <div className="option_style flex justify-between">
                    <img src={mm.src_flag} height="30px" width="30px" />
                    <span className="block toggleColor">+{mm.country_code}</span>
                </div>
        }
    })

    //handleSelect
    const handleSelect = (selectedOption) => {
        setCountry_code(selectedOption.value)

    }


    return (
        <HelmetProvider>
            <div className="grand-parent" id="judge">
                <Helmet>
                    <title>{t("title_judge")}</title>
                </Helmet>

                <div className="judge-parent"  >
                    {/* right */}
                    <section className="judge-right">

                        <h1>{t("judge_1")}</h1>

                        <form>
                            {/* mobile */}
                            {/* name="mobile" */}
                            <div >
                                <label for="">{t("judge_2")}</label>
                                <div className="rtl h-[46px] flex justify-between ">
                                    <input
                                        className="judge-input h-[46px] rounded-lg p-3"
                                        style={{ border: "1px solid #e3e4f5" }}
                                        name="mobile"
                                        type="text"
                                        placeholder="9123311445"
                                        value={mobile}
                                        onChange={e => setMobile(e.target.value)}
                                    />
                                    <div className="judge_select ltr  h-[46px]">
                                        <Select
                                            styles={customStyles}
                                            options={optionFlagList}
                                            placeholder={optionFlagList[0]?.label}
                                            onChange={handleSelect}
                                            isSearchable={false}
                                            isDisabled
                                        />
                                    </div>
                                </div>
                            </div>




                            {/* submit button */}
                            <div className="submit-form">
                                <button
                                    type="submit"
                                    onClick={handleSubmitForm}
                                >{t("judge_3")}</button>
                            </div>

                            {/*  */}
                        </form>
                    </section>

                    {/* left */}
                    <Login_Left />
                </div>

                {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
                {/* <LightingMode bottom="20px" left="30px" /> */}
            </div>
        </HelmetProvider>

    )
}

export default Judge


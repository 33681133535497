//internal
import { useState, useContext, useEffect } from "react";

//external
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/MainContexts";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TfiUpload, TfiDownload } from "react-icons/tfi";
import { GiCycle } from "react-icons/gi";
import QRCode from "react-qr-code";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style
import "./lightMode.css";
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links"
import LightingMode from "../../components/lighting-mode/LightingMode";
//Image
import logo from "../../image/logo/_company_logo.png";
import { BASE_URL } from '../../utils/url'






const Xchange_Deposit = () => {

    const baseURL = BASE_URL
    const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
    const { t } = useTranslation();
    const { lightingMode, primary_dir } = useContext(MainContext)
    const navigate = useNavigate();
    const selected_Coin = JSON.parse(Cookies.get("selected_Coin"))
    // console.log(selected_Coin)




    //desktop popup
    const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
    const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
    const [desktop_Notifications, setDesktop_Notifications] = useState(false);
    //mobile popup dark_screen
    const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
    const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
    //tablet popup dark_screen
    const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
    const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
    const [tablet_Notifications, setTablet_Notifications] = useState(false);



    //method
    const closeWindowForDesktop = () => {
        if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
        if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
        // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
        // if (tablet_Notifications == true) { setTablet_Notifications(false) }
        if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
    }//end of method

    //GET network
    const [coinData, setCoinData] = useState({});
    const [dynamic_NetWork, setDynamic_NetWork] = useState();

    

    /////////////////////////////////////////////////////////////////

    //mounting useEffect
    useEffect(() => {

        //اگر توکن نداشت
        if (!Cookies.get("access_token")) {
            navigate("/judge", { replace: true })
        }

        const fetchData = async () => {
            //Wallet
            //GET User Coin
            try {
                const response_1 = await axios.get(`${baseURL}/user/wallets/${selected_Coin.coin_symbol}`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get("access_token")}`,
                        'Content-Type': 'application/json'
                    }
                });
                // console.log(response_1.data.data)
                // console.log("-----------------------")
                setCoinData(response_1.data.data)
                setDynamic_NetWork(response_1.data.data.networks[0])
            } catch (err) {
                if(err.response.data.message==="Unauthenticated."){
                    Cookies.remove("access_token")
                    navigate("/judge", { replace: true })
                   }
            }
        }//end of method
        fetchData();
    }, [])



    const params = new URLSearchParams(window.location.search)
    const [userWallets, setUserWallets] = useState('')
    const location = useLocation();
    const parts = location.pathname.split('/');
    const lastPart = parts[parts.length - 1];
    const thisCoin = userWallets && userWallets.find(item => item.coin_symbol === lastPart)
    const withdrawPosibility = thisCoin && thisCoin.withdrawal_status
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response_1 = await axios.get(`${baseURL}/user/wallets?${params.toString()}`, {
              headers: {
                'Authorization': `Bearer ${Cookies.get("access_token")}`,
                'Content-Type': 'application/json'
              }
            });
            setUserWallets(response_1.data.data)
          } catch (err) {
            if (err.response.data.message === "Unauthenticated.") {
              Cookies.remove("access_token")
              navigate("/judge", { replace: true })
            }
          }
        }//end of method
        fetchData();
    }, [])


    // با تغییر کوین در url از صفحه خارج شود
    const URLLocation = useLocation()
    const URLNavigate = useNavigate()
    const [lastSegment, setLastSegment] = useState("")
    
    useEffect(() => {
        if (selected_Coin.coin_symbol && lastSegment) {
            if (selected_Coin.coin_symbol !== lastSegment) {
                Cookies.remove('selected_Coin')
                navigate("/wallet")
            }
        }
    }, [selected_Coin, lastSegment])

    useEffect(() => {
        const pathname = URLLocation.pathname
        const segments = pathname.split("/")
        setLastSegment(segments[segments.length - 1])
    }, [])



    return (
        <HelmetProvider>
            <div id="xchange" onClick={closeWindowForDesktop} className="flex flex-wrap">
                <Helmet>
                    <title>{t("title_xchangeWithdraw")}</title>
                </Helmet>



                {/* نوار فقط برای حالت موبایل فقط موبایل sm:hidden*/}
                {/* دست ساز */}
                {/* x<640 */}
                <nav className="sm:hidden w-full h-[50px] flexBetween px-4 mb-4"
                    style={lightingMode === "light" ? { background: "white" } : { background: "black" }}>
                    <span>{""}</span>
                    <Link to={"/"}><img src={logo} alt="" /></Link>
                    <Link to={"/wallet"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.064" height="25.566" viewBox="0 0 26.064 25.566">
                                <path id="arrow_back_FILL0_wght400_GRAD0_opsz48_1_" data-name="arrow_back_FILL0_wght400_GRAD0_opsz48 (1)" d="M171.911,293.192l-11.538-11.538a1.265,1.265,0,0,1-.291-.415,1.3,1.3,0,0,1,0-.913,1.265,1.265,0,0,1,.291-.415l11.579-11.579a1.126,1.126,0,0,1,.83-.332,1.233,1.233,0,0,1,.872,2.117l-9.421,9.421h20.585a1.245,1.245,0,1,1,0,2.49H164.233l9.463,9.463a1.126,1.126,0,0,1,.332.83,1.233,1.233,0,0,1-2.117.872Z" transform="translate(-160 -268)" fill={lightingMode === "light" ? "black" : "white"} />
                            </svg>

                        </span>
                    </Link>
                </nav>

                {/*  نوار برای تبلت فقط تبلت*/}
                {/* 640< x <1024*/}
                <div className="w-full hidden sm:block lg:hidden">
                    <Nav_MobileTablet
                        mobileRight_DarkScreen={mobileRight_DarkScreen}
                        mobileLeft_DarkScreen={mobileLeft_DarkScreen}
                        tablet_DarkScreen={tablet_DarkScreen}
                        tablet_ProfileMenu={tablet_ProfileMenu}
                        tablet_Notifications={tablet_Notifications}

                        setMobileRight_DarkScreen={setMobileRight_DarkScreen}
                        setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
                        setTablet_DarkScreen={setTablet_DarkScreen}
                        setTablet_ProfileMenu={setTablet_ProfileMenu}
                        setTablet_Notifications={setTablet_Notifications}
                    />
                </div>

                {/* لینک های فیکس برای موبایل sm:hidden*/}
                {/* x <640 */}
                <Fixed_Mobile_Links />



                {/* ساید بار اصلی برای دسکتاپ*/}
                {/* x > 1024 */}
                <Main_Sidebar />

                <main>

                    {/* نوار برای دسکتاپ hidden lg:flex*/}
                    {/* x > 1024 */}
                    <Nav_Desktop
                        desktop_ProfileMenu={desktop_ProfileMenu}
                        desktop_Notifications={desktop_Notifications}
                        desktop_ThreeDots={desktop_ThreeDots}

                        setDesktop_Notifications={setDesktop_Notifications}
                        setDesktop_ThreeDots={setDesktop_ThreeDots}
                        setDesktop_ProfileMenu={setDesktop_ProfileMenu}
                    />

                    {/* تبدیل-ارسال-دریافت */}
                    <section className="Change_Send_Receive">
                        {/* nth-of-type-1 */}
                        <div className="hidden sm:flex justify-between items-center mb-4">
                            <h3 className="text-lg font-bold"
                                style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{t("xchange_deposit_1")}</h3>
                            <button onClick={() => navigate("/wallet")}
                                className="w-[142px] h-12 flexCenter gap-x-2 rounded-lg"
                                style={lightingMode === "light" ? { background: "#f7f8fa" } : { background: "#141414" }}>

                                <span className="font-normal"
                                    style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{t("xchange_deposit_2")}</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.201" height="14.313" viewBox="0 0 7.201 14.313">
                                        <path id="arrow-ios-forward-fill" d="M1.022,0A1.02,1.02,0,0,0,.236,1.675l4.57,5.468L.4,12.62a1.02,1.02,0,0,0,.153,1.438,1.02,1.02,0,0,0,1.489-.153l4.927-6.12a1.02,1.02,0,0,0,0-1.3L1.869.369A1.02,1.02,0,0,0,1.022,0Z" transform="translate(7.201 14.313) rotate(180)" fill={lightingMode === "light" ? "black" : "white"} />
                                    </svg>
                                </span>
                            </button>
                        </div>


                        {/* والد برای دو دکمه*/}
                        <div className="three_button flexCenter gap-x-3 mb-5">
                            <button style={{ color: "white", background: "#f6b33f" }} >
                                <span>
                                    <TfiDownload />
                                </span>
                                <span>{t("xchange_deposit_4")}</span>
                            </button>
                            <button 
                                onClick={() => navigate(`/xchange-withdraw/${selected_Coin.coin_symbol}`)} 
                                style={{ pointerEvents: withdrawPosibility ? 'auto' : 'none', opacity: withdrawPosibility ? '1' : '.4' }}
                            >
                                <span className="font-bold">
                                    <TfiUpload />
                                </span>
                                <span>{t("xchange_deposit_5")}</span>
                            </button>
                        </div>


                        {/* باکس دریافت receive-box*/}
                        {/* deposit */}
                        {
                            (
                                <div className="flexCenter">
                                    <section className="receive-box w-[540px] h-[494px] rounded-xl">
                                        {/*  ارز دریافتی*/}
                                        <div className="h-[74px] mb-3">
                                            <label className="block mb-2" htmlFor="">{t("xchange_deposit_6")}</label>
                                            <div className="h-[46px]">
                                                <div className="option_style px-2 rounded-lg"
                                                    style={lightingMode === "light" ? { background: "#F7F8FA", color: "black" } : { background: "#141414", color: "white" }}>
                                                    <img src={`${base_url_img}__${selected_Coin.coin_symbol}.png`} height="30px" width="30px" />
                                                    <span>{selected_Coin.coin_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* نوع شبکه */}
                                        <div className="h-[75px] mb-3">
                                            <label className="block mb-2" htmlFor=""> {t("xchange_deposit_7")}</label>
                                            <div className="network_type flex justify-between h-[46px]">
                                                {
                                                 Object.keys(coinData).length>0 &&  coinData.networks.map((net, index) =>
                                                    (
                                                        <section key={index} className="w-[47%] h-full">
                                                            <span className="ballet"></span>
                                                            <span style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{net.name}</span>
                                                        </section>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        {/* آدرس در شبکه */}
                                        <div className="h-[74px] mb-5">
                                            <label htmlFor="" className="block mb-1">
                                                <span>{t("xchange_deposit_8")}</span>
                                                {" "}
                                                <span dir="ltr">{Object.keys(coinData).length>0 && coinData.networks[0].name}</span>
                                            </label>
                                            <section className="h-[46px] flexBetween rounded-lg px-4"
                                                style={{ border: "1px solid #edeef1" }}>
                                                <span style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{Object.keys(coinData).length>0 ? Object.values(coinData.addresses)[0] : ""}</span>
                                                <span
                                                 onClick={() => navigator.clipboard.writeText(Object.keys(coinData).length>0 ? Object.values(coinData.addresses)[0] : "")}
                                                    className="cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23.101" height="26.748" viewBox="0 0 23.101 26.748">
                                                        <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M20.021,1.5H5.432A2.439,2.439,0,0,0,3,3.932V20.953H5.432V3.932h14.59Zm3.647,4.863H10.295A2.439,2.439,0,0,0,7.863,8.795V25.816a2.439,2.439,0,0,0,2.432,2.432H23.669A2.439,2.439,0,0,0,26.1,25.816V8.795A2.439,2.439,0,0,0,23.669,6.363Zm0,19.453H10.295V8.795H23.669Z" transform="translate(-3 -1.5)" fill="#f6b33f" />
                                                    </svg>
                                                </span>

                                            </section>
                                        </div>
                                        {/*  */}
                                        <figure className="flexCenter mb-5">
                                            <QRCode

                                                style={{ height: "auto", maxWidth: "100%", width: "100px" }}
                                                value={Object.keys(coinData).length>0 ? Object.values(coinData.addresses)[0] : ""}

                                            />
                                        </figure>
                                        {/*  */}
                                        <p className="text-sm text-center toggleColor"

                                        >
                                            <span>ارسال</span>
                                            {" "}
                                            <span className="text-red-600">ارز غیر از {selected_Coin.coin_symbol}</span>
                                            {" "}
                                            <span>به این آدرس ممکن است منجر به از دست دادن شود.</span>
                                        </p>
                                    </section>
                                </div>
                            )
                        }


                    </section>

                </main>
                <LightingMode bottom="20px" right={primary_dir === "rtl" ? "20px" : null} left={primary_dir === "ltr" ? "20px" : null} />
            </div>
        </HelmetProvider>
    )
}


export default Xchange_Deposit;
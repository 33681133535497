//internal
import { useState, useEffect, useRef, useContext } from "react";

//external
import Cookies from "js-cookie";
import axios from "axios";
import { MainContext } from "../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import moment from "moment-jalaali";

//Component and Style 
import "./dashboard.css"
import "./lightingMode.css";
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import Convert_Box from "../../components/convert-box/Convert_Box"
import LightingMode from "../../components/lighting-mode/LightingMode";
import LoadingCircle from "../../components/loading-circle/LoadingCircle";
import NoData from "../../components/no-data/NoData";
//Image

import { BASE_URL } from '../../utils/url'
import { toast } from "react-toastify";







const Dashboard = () => {

  const baseURL = BASE_URL
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  const { t } = useTranslation();
  const { lightingMode, primary_dir } = useContext(MainContext);
  const navigate = useNavigate();


  //desktop popup
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  //mobile popup dark_screen
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  //tablet popup dark_screen
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);





  //method
  const closeWindowForDesktop = () => {
    if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
    if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
    // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
    // if (tablet_Notifications == true) { setTablet_Notifications(false) }
    if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
  }







  const [userData, setUserData] = useState({});
  const [userWallets, setUserWallets] = useState([]);
  const [business_history, setBusiness_history] = useState([]);
  const [primary_balance, setPrimary_balance] = useState("");
  const [loading, setLoading] = useState(false);






  ///////////////////////////////داستان سوکت///////////////////////////////////
  ///////////////////////////////داستان سوکت///////////////////////////////////
  const [socketData, setSocketData] = useState('');
  const [allCoins, setAllCoins] = useState([]);
  const coinsArrayRef = useRef([]);

  const createWebSocket = () => {
    const socket = new WebSocket('wss://ws.moneyex.org/featured')

    socket.onmessage = (event) => {
      const jsonData = JSON.parse(event.data)

      const allCoinsArray = Array.from(coinsArrayRef.current)
      allCoinsArray.forEach((cn) => {
        if (jsonData.base_coin === cn.name) {
          cn.name = jsonData.base_coin
          cn.price = jsonData.buy
          cn.change = jsonData.change
        }
      })

      setSocketData(allCoinsArray)
    }
  }







  //mounting--useEffect
  useEffect(() => {

    //اگر توکن نداشت
    if (!Cookies.get("access_token")) {
      navigate("/judge", { replace: true })
    }

    ////////////////////////////کد حیدر//////////////////////////////
    createWebSocket()

    fetch(BASE_URL+'/ticker/featured')
      .then((res) => res.json())
      .then((data) => {
        setAllCoins(data)

        coinsArrayRef.current = [
          ...coinsArrayRef.current,
          ...data.map((i) => ({ name: i.base_coin, price: '', change: '' })),
        ]
      })
      .catch((err) => console.log(err))


    /////////////////////////// پایان کد حیدر///////////////////////////////

    const fetchData = async () => {
      try {

        setLoading(true) //شروع لودینگ

        /////////////////////////////////////////
        const response_1 = await axios.get(`${baseURL}/user/wallets`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserWallets(response_1.data.data)
        setPrimary_balance(response_1.data.data.find(c => c.coin_symbol === "USDT").primary_balance.toFixed(3))

        //////////////////////////////////////////
        const res_profile = await axios.get(`${baseURL}/user/profile`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserData(res_profile.data);


        ///////////////////////////////
        const response_5 = await axios.get(`${baseURL}/user/orders/latest`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setBusiness_history(response_5.data.data)


        setLoading(false) //پایان لودینگ








        allCoinsInitial.current = response_5.data.data;

      } catch (err) {
        // console.log(err.response.data.message)
        setLoading(false) //پایان لودینگ
        if(err.response.data.message==="Unauthenticated."){
         Cookies.remove("access_token")
         navigate("/judge", { replace: true })
        }
      }
    }//end of method

    fetchData();
  }, [])






  ///////////////////////////////////////////
  ///////////// Sorting
  const allCoinsInitial = useRef([]);
  //  const [allCoins, setAllCoins] = useState([]);

  const sorted_by_Price =
    business_history &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.total) - parseFloat(a.total)
    );
  const sorted_by_Price_re = sorted_by_Price && [...sorted_by_Price].reverse();
  // 
  const sorted_by_Price1 =
    business_history &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.price) - parseFloat(a.price)
    );
  const sorted_by_Price1_re = sorted_by_Price1 && [...sorted_by_Price1].reverse();
  // 

  const sorted_by_Time =
    business_history &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.created_at) - parseFloat(a.created_at)
    );
  const sorted_by_Time_re = sorted_by_Time && [...sorted_by_Time].reverse();

  //  const sorted_by_Price_Alphabetical = userWallets && [...sorted_by_Price].sort((a, b) => a.txn_id.localeCompare(b.txn_id));
  const sorted_by_Price_Alphabetical =
    business_history &&
    sorted_by_Price
      .filter((item) => item.trade_pair !== null)
      .sort((a, b) => (a.trade_pair || "").localeCompare(b.trade_pair || ""));
  const sorted_by_Price_re_Alphabetical =
    sorted_by_Price_Alphabetical && [...sorted_by_Price_Alphabetical].reverse();
  //  const sorted_by_HighPrice = userWallets && [...userWallets].sort((a, b) => parseFloat(b.highPrice) - parseFloat(a.highPrice));
  //  const sorted_by_Change = userWallets && [...userWallets].sort((a, b) => parseFloat(b.change) - parseFloat(a.change));
  const sorted_by_Price_Alphabetical1 =
    business_history &&
    sorted_by_Price
      .filter((item) => item.type !== null)
      .sort((a, b) => (a.type || "").localeCompare(b.type || ""));
  const sorted_by_Price_re_Alphabetical1 =
    sorted_by_Price_Alphabetical1 &&
    [...sorted_by_Price_Alphabetical1].reverse();

  const [nameBtnStatus, setNameBtnStatus] = useState("down");
  const [priceBtnStatus, setPriceBtnStatus] = useState("off");
  const [priceBtnStatus1, setPriceBtnStatus1] = useState("off");
  const [highPriceBtnStatus, setHighPriceBtnStatus] = useState("off");
  const [changeBtnStatus, setChangeBtnStatus] = useState("off");

  function sortByName() {
    if (sorted_by_Price_Alphabetical1) {
      if (nameBtnStatus === "off" || nameBtnStatus === "up") {
        setBusiness_history(sorted_by_Price_Alphabetical1);
        setNameBtnStatus("down");
      } else {
        setBusiness_history(sorted_by_Price_re_Alphabetical1);
        setNameBtnStatus("up");
      }
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByPrice() {
    if (sorted_by_Price) {
      if (priceBtnStatus === "off" || priceBtnStatus === "up") {
        setBusiness_history(sorted_by_Price);
        setPriceBtnStatus("down");
      } else {
        setBusiness_history(sorted_by_Price_re);
        setPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setPriceBtnStatus1("off")
    }
  }
  function sortByPrice1() {
    if (sorted_by_Price1) {
      if (priceBtnStatus1 === "off" || priceBtnStatus1 === "up") {
        setBusiness_history(sorted_by_Price1);
        setPriceBtnStatus1("down");
      } else {
        setBusiness_history(sorted_by_Price1_re);
        setPriceBtnStatus1("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setPriceBtnStatus("off")
    }
  }
  function sortByHighPrice() {
    if (sorted_by_Time) {
      if (highPriceBtnStatus === "off" || highPriceBtnStatus === "up") {
        setBusiness_history(sorted_by_Time);
        setHighPriceBtnStatus("down");
      } else {
        setBusiness_history(sorted_by_Time_re);
        setHighPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setChangeBtnStatus("off");

    }
  }
  function sortByChange() {
    if (sorted_by_Price_Alphabetical) {
      if (changeBtnStatus === "off" || changeBtnStatus === "up") {
        setBusiness_history(sorted_by_Price_Alphabetical);
        setChangeBtnStatus("down");
      } else {
        setBusiness_history(sorted_by_Price_re_Alphabetical);
        setChangeBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
      setPriceBtnStatus1("off")
    }
  }


  return (
    <HelmetProvider>
      <div id="dashboard" className="flex flex-wrap" onClick={closeWindowForDesktop}>
        <Helmet>
          <title>{t("title_dashboard")}</title>
        </Helmet>



        {/* نوار برای موبایل و تبلت lg:hidden*/}
        {/* x < 1024 */}
        <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}

          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />






        {/*lg:hidden     کل دارایی برای موبایل و تبلت*/}
        {/* x < 1024 */}
        <div className="mobile-total-wealth w-full flexBetween lg:hidden mb-5">
          <section className="flex items-center gap-x-2">
            <span>
              <svg id="account_balance_wallet_black_24dp_2_" data-name="account_balance_wallet_black_24dp (2)" xmlns="http://www.w3.org/2000/svg" width="20.882" height="19.783" viewBox="0 0 20.882 19.783">
                <path id="Path_153" data-name="Path 153" d="M22.783,7.7V5.2a2.2,2.2,0,0,0-2.2-2.2H5.2A2.2,2.2,0,0,0,3,5.2V20.585a2.2,2.2,0,0,0,2.2,2.2H20.585a2.2,2.2,0,0,0,2.2-2.2V18.079a2.2,2.2,0,0,0,1.1-1.89V9.594a2.2,2.2,0,0,0-1.1-1.89Zm-1.1,1.89v6.594H13.99V9.594ZM5.2,20.585V5.2H20.585V7.4H13.99a2.2,2.2,0,0,0-2.2,2.2v6.594a2.2,2.2,0,0,0,2.2,2.2h6.594v2.2Z" transform="translate(-3 -3)" fill="#fff" />
                <circle id="Ellipse_25" data-name="Ellipse 25" cx="1.284" cy="1.284" r="1.284" transform="translate(12.153 8.607)" fill="#f9cc0b" />
              </svg>

            </span>
            <span className="text-sm">{t("dashboard_1")}</span>
            <img src={require('../../assests/image/wallet/A5.png')} width={25} />

          </section>
          <section>{primary_balance ? primary_balance : ""}</section>
        </div>



        {/* لینک های فیکس برای موبایل sm:hidden*/}
        {/* x < 640 */}
        <Fixed_Mobile_Links />

        {/* ساید بار برای حالت دسکتاپ hidden lg:block*/}
        {/* x > 1024 */}
        <Main_Sidebar />



        {/* main */}
        <main>

          {/* نوار برای دسکتاپ hidden lg:flex*/}
          {/* x > 1024 */}
          <Nav_Desktop
            desktop_ProfileMenu={desktop_ProfileMenu}
            desktop_Notifications={desktop_Notifications}
            desktop_ThreeDots={desktop_ThreeDots}

            setDesktop_Notifications={setDesktop_Notifications}
            setDesktop_ThreeDots={setDesktop_ThreeDots}
            setDesktop_ProfileMenu={setDesktop_ProfileMenu}
          />



          {/* middle میانه داشبورد*/}
          <div className="middle w-full  flex flex-wrap">
            {/* میانه راست  */}
            <section className="middle-right">
              <Convert_Box />
            </section>
            {/*end middle right */}




            {/* میانه چپ  */}
            <section className="middle-left">
              {/* 4 box-list */}
              <div className="four_box">
                <div>
                  {allCoins && allCoins.map((item, index) => {
                    const coinData = socketData && socketData.find(i => i.name === item.base_coin) || {};
                    const price = !socketData || !coinData.price ? item.latest_price : coinData.price;
                    const change = !socketData || !coinData.change ? Math.abs(item.change) : Math.abs(coinData.change);

                    return (
                      <section key={index}>
                        <img src={item.base_coin_icon} />

                        <div className="content">
                          <div className="bg-blur" style={{ '--src': `url(${item.base_coin_icon})` }} />

                          <div style={{ color: "#c2c2c2" }} className="text-sm text-left mb-1">24h</div>
                          <div className="flex justify-between mb-1">
                            <span></span>
                            <span className="ltr">
                              {change < 0 ?
                                <span className="down">{change + '%'}</span>
                                :
                                <span className="up">{change + '%'}</span>
                              }
                              {change > 0 ? <i className='p-up'></i> : change < 0 ? <i className='p-down'></i> : null}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span>{item.base_coin}</span>

                            <div className="number">
                              {price} {item.quote_coin}
                            </div>
                          </div>
                        </div>
                      </section>
                    )
                  })}
                </div>
              </div>



              {/* invite-friends لینک دعوت دوستان */}
              <div className="invite-friends  w-full flex flex-wrap rounded-2xl ">

                <section className="">
                  {/*  */}
                  <span style={lightingMode === "light" ? { color: "black" } : { color: "white" }}
                    className="block font-bold"
                  >{t("invite_friends_1")}</span>
                  {/*  */}
                  <span className="block text-sm"
                    style={lightingMode === "light" ? { color: "#707070" } : { color: "#a5a5a5" }}
                  >
                    {t("invite_friends_2")}</span>
                  {/*  */}
                </section>

                <section className="flexBetween gap-x-3 px-5 rounded-xl" style={lightingMode === "light" ? { background: "#f7f8fa" } : { background: "#141414" }} >
                  {/*  */}
                  <span className="referral_code"
                    style={lightingMode === "light" ? { color: "black" } : { color: "white" }}
                  >
                    {(userData.referral_code) ? `https://moneyex.org/ref/${userData.referral_code}` : ""}
                  </span>
                  {/*  */}
                  <span onClick={() => {
                    navigator.clipboard.writeText(`https://moneyex.org/ref/${userData.referral_code}`)
                    toast.success('کپی شد.')
                  }}
                    className="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.101" height="26.748" viewBox="0 0 23.101 26.748">
                      <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M20.021,1.5H5.432A2.439,2.439,0,0,0,3,3.932V20.953H5.432V3.932h14.59Zm3.647,4.863H10.295A2.439,2.439,0,0,0,7.863,8.795V25.816a2.439,2.439,0,0,0,2.432,2.432H23.669A2.439,2.439,0,0,0,26.1,25.816V8.795A2.439,2.439,0,0,0,23.669,6.363Zm0,19.453H10.295V8.795H23.669Z" transform="translate(-3 -1.5)" fill="#f6b33f" />
                    </svg>
                  </span>
                  {/*  */}
                </section>

              </div>

            </section>
            {/*end middle left */}

          </div>
          {/*end middle*/}



          {/* سوابق معاملات اخیر*/}
          <div className="business-history p-5">
            {/* nth-of-1 */}
            <section className="flex justify-between mb-3">
              <span
                style={
                  lightingMode === "light"
                    ? { color: "black" }
                    : { color: "white" }
                }
                className="text-lg font-bold"
              >
                {t("business-history_1")}
              </span>

              <Link
                to={"/exchange"}
                className="flex gap-x-2"
                style={{ color: "#f6b33f" }}
              >
                <span className="font-bold">{t("business-history_2")}</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.176"
                    height="27.176"
                    viewBox="0 0 27.176 27.176"
                  >
                    <path
                      id="arrow_circle_left_FILL0_wght400_GRAD0_opsz48"
                      d="M92.5,190.607h5.163a1.019,1.019,0,1,0,0-2.038H92.5l1.8-1.8a1.009,1.009,0,1,0-1.427-1.427l-3.533,3.533a.985.985,0,0,0,0,1.427l3.533,3.533a1.009,1.009,0,1,0,1.427-1.427Zm1.087,12.569a13.148,13.148,0,0,1-5.265-1.07,13.69,13.69,0,0,1-7.253-7.253,13.573,13.573,0,0,1,0-10.565,13.579,13.579,0,0,1,2.921-4.314,13.929,13.929,0,0,1,4.331-2.9,13.573,13.573,0,0,1,10.565,0,13.567,13.567,0,0,1,7.219,7.219,13.573,13.573,0,0,1,0,10.565,13.929,13.929,0,0,1-2.9,4.331,13.579,13.579,0,0,1-4.314,2.921A13.231,13.231,0,0,1,93.588,203.176Zm0-2.038a11.54,11.54,0,1,0-8.17-3.38A11.114,11.114,0,0,0,93.588,201.138ZM93.588,189.588Z"
                      transform="translate(-80 -176)"
                      fill="#f6b33f"
                    />
                  </svg>
                </span>
              </Link>
            </section>

            {
              loading ? (<LoadingCircle />) :
                (
                  <>
                    {
                      business_history.length === 0 ?
                        (<NoData />)
                        :
                        (
                          <div className="table-parent">
                            <table className="w-full">
                              <thead>
                                <tr
                                  style={
                                    primary_dir === "rtl"
                                      ? { textAlign: "right" }
                                      : { textAlign: "left" }
                                  }
                                  className="mb-3"
                                >
                                  {/* مارکت */}
                                  <th onClick={sortByChange}>
                                    <div>{t("business-history_3")}</div>
                                    {changeBtnStatus === "down" && (
                                      <img
                                        src={require("../../image/price-socket/sort-down.png")}
                                      />
                                    )}
                                    {changeBtnStatus === "up" && (
                                      <img
                                        src={require("../../image/price-socket/sort-up.png")}
                                      />
                                    )}
                                    {changeBtnStatus === "off" && (
                                      <img
                                        src={require("../../image/price-socket/sort.png")}
                                      />
                                    )}
                                  </th>
                                  {/* مقدار */}
                                  <th onClick={sortByPrice}>
                                    <div>{t("business-history_6")}</div>
                                    {priceBtnStatus === "down" && (
                                      <img
                                        src={require("../../image/price-socket/sort-down.png")}
                                      />
                                    )}
                                    {priceBtnStatus === "up" && (
                                      <img
                                        src={require("../../image/price-socket/sort-up.png")}
                                      />
                                    )}
                                    {priceBtnStatus === "off" && (
                                      <img
                                        src={require("../../image/price-socket/sort.png")}
                                      />
                                    )}
                                  </th>
                                  {/* قیمت */}
                                  <th onClick={sortByPrice1}>
                                    <div>{t("business-history_4")}</div>
                                    {priceBtnStatus1 === "down" && (
                                      <img
                                        src={require("../../image/price-socket/sort-down.png")}
                                      />
                                    )}
                                    {priceBtnStatus1 === "up" && (
                                      <img
                                        src={require("../../image/price-socket/sort-up.png")}
                                      />
                                    )}
                                    {priceBtnStatus1 === "off" && (
                                      <img
                                        src={require("../../image/price-socket/sort.png")}
                                      />
                                    )}
                                  </th>
                                  {/* کل */}
                                  <th>
                                    <div>{t("business-history_7")}</div>

                                  </th>
                                  {/* نوع */}
                                  <th onClick={sortByName}>
                                    <div>{t("business-history_5")}</div>
                                    {nameBtnStatus === "down" && (
                                      <img
                                        src={require("../../image/price-socket/sort-down.png")}
                                      />
                                    )}
                                    {nameBtnStatus === "up" && (
                                      <img
                                        src={require("../../image/price-socket/sort-up.png")}
                                      />
                                    )}
                                    {nameBtnStatus === "off" && (
                                      <img
                                        src={require("../../image/price-socket/sort.png")}
                                      />
                                    )}
                                  </th>
                                  {/* تاریخ */}
                                  <th onClick={sortByHighPrice}>
                                    <div>{t("business-history_9")}</div>
                                    {highPriceBtnStatus === "down" && (
                                      <img
                                        src={require("../../image/price-socket/sort-down.png")}
                                      />
                                    )}
                                    {highPriceBtnStatus === "up" && (
                                      <img
                                        src={require("../../image/price-socket/sort-up.png")}
                                      />
                                    )}
                                    {highPriceBtnStatus === "off" && (
                                      <img
                                        src={require("../../image/price-socket/sort.png")}
                                      />
                                    )}
                                  </th>
                                  {/* زمان */}
                                  <th>{t("business-history_10")}</th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  business_history.map((hstry, index) => {
                                    const time = hstry.created_at;
                                    const dateParts = time.split("T");
                                    const timeParts = dateParts[1].split(".");
                                    return (
                                      <tr key={index}>
                                        {/* مارکت */}
                                        <td>{hstry.trade_pair}</td>

                                        {/* مقدار */}
                                        <td>
                                          {hstry.type === "buy"
                                            ? `${hstry.total} ${hstry.quote_coin}`
                                            : `${hstry.amount} ${hstry.base_coin}`}
                                        </td>
                                        {/* قیمت */}
                                        <td>{`${hstry.price} ${hstry.quote_coin}`}</td>
                                        {/* کل */}
                                        <td>
                                          <span>{`${hstry.quote_coin} ${hstry.total}`}</span>{" "}
                                          <span>{"/"}</span>{" "}
                                          <span>{`${hstry.base_coin} ${hstry.amount}`}</span>
                                        </td>

                                        {/* نوع */}
                                        <td>
                                          <span
                                            className={
                                              hstry.type === "buy" ? "type_buy" : "type_sale"
                                            }
                                          >
                                            {hstry.type === "buy" ? "خرید" : "فروش"}
                                          </span>
                                        </td>
                                        {/* تاریخ */}
                                        <td>{moment(hstry.created_at, 'YYYY-MM-DD').format('jYYYY/jMM/jDD')}</td>
                                        {/* زمان */}
                                        <td>{new Date(hstry.created_at).toISOString().split("T")[1].split('.')[0]}</td>

                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        )
                    }
                  </>
                )
            }



          </div>

        </main>

        {/* <LightingMode bottom="20px" left={primary_dir === "rtl" ? "20px" : null} right={primary_dir === "ltr" ? "20px" : null} /> */}
        <LightingMode bottom="20px" left="30px" />
      </div>
    </HelmetProvider>
  )
}


export default Dashboard


import React, { useState, useEffect, useRef } from "react";
import Nav_MobileTablet from "../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../components/fixed-mobile-links/Fixed_Mobile_Links";
import Main_Sidebar from "../components/sidebar/Main_Sidebar";
import axios from "axios";
import Cookies from "js-cookie";
import { Box, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./records.css";
import Nav_Desktop from "../components/nav-desktop/Nav_Desktop";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingCircle from "../components/loading-circle/LoadingCircle";
import { BASE_URL } from '../utils/url'
import { Link, useNavigate } from "react-router-dom";

export default function Records() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // console.log(loading)

  const { t } = useTranslation();
  //desktop popup
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  //
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const params = new URLSearchParams(window.location.search);
  const [records, setRecords] = useState([]);
  const firstName = records.length > 0 ? records[0].coin_name : "";
  //
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);

  //
  function clickhandle2() {
    fetchData("withdrawals");
  }
  function clickhandle() {
    fetchData("deposits");
  }
  const fetchData = async (e) => {
    try {
      const response_1 = await axios.get(
        `${BASE_URL}/user/${e}/${params.get("symbol")}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRecords(response_1.data.data)
      setLoading(false);
      allCoinsInitial.current = response_1.data.data;
      console.log(response_1);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    //end of method
    fetchData("withdrawals");
  }, []);

  //

  //
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const allCoinsInitial = useRef([]);
  //  const [allCoins, setAllCoins] = useState([]);

  const sorted_by_Price =
    records &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
    );
  const sorted_by_Price_re = sorted_by_Price && [...sorted_by_Price].reverse();

  const sorted_by_Time =
    records &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.created_at) - parseFloat(a.created_at)
    );
  const sorted_by_Time_re = sorted_by_Time && [...sorted_by_Time].reverse();

  //  const sorted_by_Price_Alphabetical = userWallets && [...sorted_by_Price].sort((a, b) => a.txn_id.localeCompare(b.txn_id));
  const sorted_by_Price_Alphabetical =
    records &&
    sorted_by_Price
      .filter((item) => item.coin_name !== null)
      .sort((a, b) => (a.coin_name || "").localeCompare(b.coin_name || ""));
  const sorted_by_Price_re_Alphabetical =
    sorted_by_Price_Alphabetical && [...sorted_by_Price_Alphabetical].reverse();
  //  const sorted_by_HighPrice = userWallets && [...userWallets].sort((a, b) => parseFloat(b.highPrice) - parseFloat(a.highPrice));
  //  const sorted_by_Change = userWallets && [...userWallets].sort((a, b) => parseFloat(b.change) - parseFloat(a.change));
  const sorted_by_Price_Alphabetical1 =
    records &&
    sorted_by_Price
      .filter((item) => item.status !== null)
      .sort((a, b) => (a.status || "").localeCompare(b.status || ""));
  const sorted_by_Price_re_Alphabetical1 =
    sorted_by_Price_Alphabetical1 &&
    [...sorted_by_Price_Alphabetical1].reverse();

  const [nameBtnStatus, setNameBtnStatus] = useState("down");
  const [priceBtnStatus, setPriceBtnStatus] = useState("off");
  const [highPriceBtnStatus, setHighPriceBtnStatus] = useState("off");
  const [changeBtnStatus, setChangeBtnStatus] = useState("off");

  function sortByName() {
    if (sorted_by_Price_Alphabetical1) {
      if (nameBtnStatus === "off" || nameBtnStatus === "up") {
        setRecords(sorted_by_Price_Alphabetical1);
        setNameBtnStatus("down");
      } else {
        setRecords(sorted_by_Price_re_Alphabetical1);
        setNameBtnStatus("up");
      }
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByPrice() {
    if (sorted_by_Price) {
      if (priceBtnStatus === "off" || priceBtnStatus === "up") {
        setRecords(sorted_by_Price);
        setPriceBtnStatus("down");
      } else {
        setRecords(sorted_by_Price_re);
        setPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByHighPrice() {
    if (sorted_by_Time) {
      if (highPriceBtnStatus === "off" || highPriceBtnStatus === "up") {
        setRecords(sorted_by_Time);
        setHighPriceBtnStatus("down");
      } else {
        setRecords(sorted_by_Time_re);
        setHighPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByChange() {
    if (sorted_by_Price_Alphabetical) {
      if (changeBtnStatus === "off" || changeBtnStatus === "up") {
        setRecords(sorted_by_Price_Alphabetical);
        setChangeBtnStatus("down");
      } else {
        setRecords(sorted_by_Price_re_Alphabetical);
        setChangeBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
    }
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{t("title.5")}</title>
        </Helmet>

        <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}
          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />
        <div id="everything">
          <Main_Sidebar />

          <section id="all">
            <Nav_Desktop
              desktop_ProfileMenu={desktop_ProfileMenu}
              desktop_Notifications={desktop_Notifications}
              desktop_ThreeDots={desktop_ThreeDots}
              setDesktop_Notifications={setDesktop_Notifications}
              setDesktop_ThreeDots={setDesktop_ThreeDots}
              setDesktop_ProfileMenu={setDesktop_ProfileMenu}
            />
            <div className="top">
              <div className="tabs">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={t("dashbord.records.2")}
                      {...a11yProps(0)}
                      onClick={clickhandle2}
                    />
                    <Tab
                      label={t("dashbord.records.1")}
                      {...a11yProps(1)}
                      onClick={clickhandle}
                    />
                  </Tabs>
                </Box>
              </div>
              {/* <div className="coin-name">{firstName}</div> */}
              <button onClick={() => navigate("/wallet")} className="max-lg:hidden w-32 h-10 flexCenter gap-x-2 rounded-lg bg-white" style={{background: "rgb(247, 248, 250);"}}>
                <span className="font-normal" style={{color: "black;"}}> بازگشت</span>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="7.201" height="14.313" viewBox="0 0 7.201 14.313"><path id="arrow-ios-forward-fill" d="M1.022,0A1.02,1.02,0,0,0,.236,1.675l4.57,5.468L.4,12.62a1.02,1.02,0,0,0,.153,1.438,1.02,1.02,0,0,0,1.489-.153l4.927-6.12a1.02,1.02,0,0,0,0-1.3L1.869.369A1.02,1.02,0,0,0,1.022,0Z" transform="translate(7.201 14.313) rotate(180)" fill="black"></path></svg>
                </span>
              </button>
            </div>

            <div className="body">
              <CustomTabPanel value={value} index={0}>
                <table>
                  <tr>
                    <th>
                      <div className="list" onClick={sortByChange}>
                        <div className="text">
                          {t("dashbord.transaction.22")}
                        </div>
                        {changeBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {changeBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {changeBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                        {/* <img src={require("../../assests/image/dashbord/sort.png")}
                                style={
                                  priceBtnStatus === "down"
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                              onClick={sortByPrice}/> */}
                      </div>
                    </th>
                    <th>
                      <div className="list" onClick={sortByPrice}>
                        <div className="text">
                          {t("dashbord.transaction.16")}
                        </div>
                        {priceBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {priceBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {priceBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="list" onClick={sortByHighPrice}>
                        <div className="text">
                          {t("dashbord.transaction.17")}
                        </div>
                        {highPriceBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {highPriceBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {highPriceBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                      </div>
                    </th>

                    <th>
                      <div className="list" onClick={sortByName}>
                        <div className="text">
                          {t("dashbord.transaction.20")}
                        </div>
                        {nameBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {nameBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {nameBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                      </div>
                    </th>
                  </tr>

                  {!loading &&
                    <>
                     {records && records.length > 0 ? (
                    <tbody>
                      {records.map((index) => {
                        const time = index.created_at;
                        const dateParts = time.split("T");
                        const timeParts = dateParts[1].split(".");
                        return (
                          <tr key={index.id}>
                            <td>
                              {index.coin_symbol} | {index.coin_name}
                            </td>
                            <td>{+index.amount}</td>
                            <td>
                              <div className="time">{timeParts[0]}</div>
                            </td>
                            <td>
                              <div
                                className="background"
                                style={
                                  index.status === "completed"
                                    ? {
                                        background: "#d8f3f1",
                                        color: "#00c0af",
                                      }
                                    : index.status === "reviewing" || index.status === "pending"
                                    ? {
                                        background: "#f3f1d8",
                                        color: "#c0ad00",
                                      }
                                    : {
                                        background: "#f3d9d8",
                                        color: "#c00000",
                                      }
                                }
                              >
                                {" "}
                                {index.status === 'completed' ? 'انجام شده' : index.status === 'pending' ? 'در حال انجام' : index.status === 'reviewing' ? 'در حال بررسی' : 'ناموفق'}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="4">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              style={{ width: "200.6px" }}
                              src={require("../assests/image/dashbord/Empty.png")}
                            />
                            <div className="text">{t("nothing.1")}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                    </>
                  }
                 
                </table>
                {loading && <LoadingCircle/>}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <table>
                  <tr>
                    <th>
                      <div className="list" onClick={sortByChange}>
                        <div className="text">
                          {t("dashbord.transaction.22")}
                        </div>
                        {changeBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {changeBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {changeBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                        {/* <img src={require("../../assests/image/dashbord/sort.png")}
                                style={
                                  priceBtnStatus === "down"
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                              onClick={sortByPrice}/> */}
                      </div>
                    </th>
                    <th>
                      <div className="list" onClick={sortByPrice}>
                        <div className="text">
                          {t("dashbord.transaction.16")}
                        </div>
                        {priceBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {priceBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {priceBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                      </div>
                    </th>
                    <th>
                      <div className="list" onClick={sortByHighPrice}>
                        <div className="text">
                          {t("dashbord.transaction.17")}
                        </div>
                        {highPriceBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {highPriceBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {highPriceBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                      </div>
                    </th>

                    <th>
                      <div className="list" onClick={sortByName}>
                        <div className="text">
                          {t("dashbord.transaction.20")}
                        </div>
                        {nameBtnStatus === "down" && (
                          <img
                            src={require("../image/price-socket/sort-down.png")}
                          />
                        )}
                        {nameBtnStatus === "up" && (
                          <img
                            src={require("../image/price-socket/sort-up.png")}
                          />
                        )}
                        {nameBtnStatus === "off" && (
                          <img
                            src={require("../image/price-socket/sort.png")}
                          />
                        )}
                      </div>
                    </th>
                  </tr>
                  {records && records.length > 0 ? (
                    <tbody>
                      {records.map((index) => {
                        const time = index.created_at;
                        const dateParts = time.split("T");
                        const timeParts = dateParts[1].split(".");
                        return (
                          <tr key={index.id}>
                            <td>
                              {index.coin_symbol} | {index.coin_name}
                            </td>
                            <td>{+index.amount}</td>
                            <td>
                              <div className="time">{timeParts[0]}</div>
                            </td>

                            <td>
                              <div
                                className="background"
                                style={
                                  index.status === "completed"
                                    ? {
                                        background: "#d8f3f1",
                                        color: "#00c0af",
                                      }
                                      : index.status === "reviewing" || index.status === "pending"
                                    ? {
                                        background: "#f3f1d8",
                                        color: "#c0ad00",
                                      }
                                    : {
                                        background: "#f3d9d8",
                                        color: "#c00000",
                                      }
                                }
                              >
                                {" "}
                                {index.status === 'completed' ? 'انجام شده' : index.status === 'pending' ? 'در حال انجام' : index.status === 'reviewing' ? 'در حال بررسی' : 'ناموفق'}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="4">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              style={{ width: "200.6px" }}
                              src={require("../assests/image/dashbord/Empty.png")}
                            />
                            <div className="text">{t("nothing.1")}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </CustomTabPanel>
            </div>
          </section>
        </div>

        <Fixed_Mobile_Links />
      </HelmetProvider>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import './style.css'
import { BASE_URL } from "../../../utils/url";
import Cookies from "js-cookie";
import LoadingCircle from "../../../components/loading-circle/LoadingCircle";


export default function TransactionReturn() {
    const { t } = useTranslation();
    const { transaction_id } = useParams();
    const navigator = useNavigate()
    const [percent, setPercent] = useState(100);
    const [count, setCount] = useState(3);

    const [transaction_status, setTransaction_status] = useState();
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const interval1 = setInterval(() => {
            setPercent(prevPercent => {
                const newPercent = prevPercent - 0.3;
                if (newPercent < -2) {
                    clearInterval(interval1);
                }
                return newPercent;
            });
        }, 10);

        return () => clearInterval(interval1);
    }, []);

    useEffect(() => {
        const interval2 = setInterval(() => {
            setCount(prevCount => {
                if (prevCount === 0) {
                    clearInterval(interval2);
                    navigator('../wallet')
                    return 0;
                }
                return prevCount - 1;
            });
        }, 1000);

        return () => clearInterval(interval2);
    }, []);

    useEffect(() => {
        fetch(`${BASE_URL}/user/transactions/${transaction_id}`,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("access_token")}`,
                    "Content-Type": "application/json",
                },
            }
        )
            .then(res => res.json())
            .then(data => {
                if (data.data && data.data.status === 'success') {
                    setTransaction_status(true)
                } else {
                    setTransaction_status(false)
                }
            })
            .finally(setLoading(false))
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <title>نتیجه تراکنش</title>
                <meta name="title" content={t("metatag.1")} />
                <meta name="description" content={t("metatag.2")} />
            </Helmet>
            <Header />

            <div id='transaction-return' className='min-h-[calc(100vh-450px)] flex items-center justify-center flex-col'>
                {loading &&
                    <>
                        <LoadingCircle />
                        <p>لطفا کمی صبر کنید...</p>
                    </>
                }
                {!loading && transaction_status === true &&
                    <>
                        <div id="countdown" style={{ '--percent': `${percent}%`, '--color': '#22c55e' }}>
                            <span>{count}</span>
                        </div>
                        <h1 className="text-2xl my-5 text-green-500">تراکنش با موفقیت انجام شد</h1>
                        <p>به صفحه کیف پول منتقل می‌شوید</p>
                    </>
                }
                {!loading && transaction_status === false &&
                    <>
                        <div id="countdown" style={{ '--percent': `${percent}%`, '--color': '#ef4444' }}>
                            <span>{count}</span>
                        </div>
                        <h1 className="text-2xl my-5 text-red-500">متاسفانه تراکنش ناموفق بود</h1>
                        <p>به صفحه کیف پول منتقل می‌شوید</p>
                    </>
                }
            </div>

            <Footer />
        </HelmetProvider>
    );
}
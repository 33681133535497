import React, {useContext} from "react";
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import "./transaction.css";
import Cookies from "js-cookie";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DatePicker, { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import Warning from "../../components/allert/Warning";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Transaction1 from "./transaction-amin/Transaction1";
import Transaction2 from "./transaction-amin/Transaction2";
import Transaction3 from "./transaction-amin/Transaction3";
import Transaction4 from "./transaction-amin/Transaction4";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LightingMode from "../../components/lighting-mode/LightingMode";
import { MainContext } from "../../contexts/MainContexts";
import { BASE_URL } from '../../utils/url'



// table
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// end table

export default function Transaction() {
  const { primary_dir } = useContext(MainContext);

  const [depositsdata, setDepositsData] = useState([]);
  const [depositsdata1, setDepositsData1] = useState([]);
  const [depositsfiatdata, setDepositsFiatData] = useState([]);
  const [depositsfiatdata1, setDepositsFiatData1] = useState([]);
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [module1, setModule1] = useState(false);
  const [module2, setModule2] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function showModule1() {
    setModule1(true);
  }
  function hiddenModule1() {
    setModule1(false);
  }
  function showModule2() {
    setModule2(true);
  }
  function hiddenModule2() {
    setModule2(false);
  }

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response_deposits_data2 = await axios.get(
          BASE_URL + "/user/fiat/deposits",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const response_deposits_data = await axios.get(
          BASE_URL + "/user/crypto/deposits",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const response_deposits_data1 = await axios.get(
          BASE_URL + "/user/crypto/withdrawals",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const response_deposits_data3 = await axios.get(
          BASE_URL + "/user/fiat/withdrawals",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(response_deposits_data);
        setDepositsData(response_deposits_data.data);
        setDepositsData1(response_deposits_data1.data);

        setDepositsFiatData(response_deposits_data2.data);
        setDepositsFiatData1(response_deposits_data3.data);
      } catch (error) {
        // console.log(error.message);
      }
    };
    fetchBlog();
  }, []);
  //
  // console.log(depositsdata)
  // console.log(depositsfiatdata)
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [forceRender, setForceRender] = useState("");

  const paginate_Arr = [];
  for (let i = 0; i < totalPages; i++) {
    paginate_Arr[i] = i + 1;
  }

  const handleURL = (page_Number) => {
    setForceRender(Math.random());
    navigate(`${window.location.pathname}?page=${page_Number}`);
  };
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
    //method
    const closeWindowForDesktop = () => {
      if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
      if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
      if (desktop_Notifications == true) { setDesktop_Notifications(false) }
    }
    const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
    const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
    const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
    const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
    const [tablet_Notifications, setTablet_Notifications] = useState(false);
  
  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
          <title>{t("title.2")}</title>
        </Helmet>
      {/* <Warning /> */}
      <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}
          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />
      <div id="everything">
        <div
          className="module2"
          style={module2 == false ? { display: "none" } : { display: "block" }}
        >
          <div className="cart">
            <div className="top">
              {/* <div className="filter">
                <img
                  src={require("../assests/image/dashbord/page_info_FILL0_wght400_GRAD0_opsz48.png")}
                />
                <div className="title">{t("dashbord.transaction.1")}</div>
              </div> */}
              <div className="back" onClick={hiddenModule2}>
                <img
                  src={require("../../assests/image/dashbord/close_FILL0_wght400_GRAD0_opsz48 (4).png")}
                />
                <div className="text">{t("dashbord.transaction.2")}</div>
              </div>
            </div>
            <div className="filde1">
              <div className="first">
                <label> {t("dashbord.transaction.3")} </label>
                <select>
                  <option>{t("dashbord.transaction.4")}</option>
                </select>
              </div>
              <div className="secend">
                <label> {t("dashbord.transaction.5")} </label>
                <select>
                  <option> {t("dashbord.transaction.6")} </option>
                </select>
              </div>
            </div>
            <div className="filde2">
              <div className="first">
                <label>{t("dashbord.transaction.7")}</label>
                <DatePicker
                  calendar={persian}
                  locale={persian_fa}
                  placeholder={t("dashbord.transaction.8")}
                />
              </div>
              <div className="secend">
                <label>تا تاریخ</label>
                <DatePicker
                  calendar={persian}
                  locale={persian_fa}
                  placeholder={t("dashbord.transaction.9")}
                />
              </div>
            </div>
            <button className="btn">{t("dashbord.transaction.10")}</button>
          </div>
        </div>
        <div
          className="module1"
          style={module1 == false ? { display: "none" } : { display: "block" }}
        >
          <div className="cart">
            <div className="title">{t("dashbord.transaction.11")} </div>
            <div className="code">
              0x80eff97bf29edb0sdgfd5a2be9f582c2dgfdabf8394509f95c0d5f6f4312540
            </div>
            <button onClick={hiddenModule1}>
              {t("dashbord.transaction.12")}
            </button>
          </div>
        </div>
        {/* side bar */}
        <Main_Sidebar />
        {/* all */}
        <section id="all">
        <Nav_Desktop
            desktop_ProfileMenu={desktop_ProfileMenu}
            desktop_Notifications={desktop_Notifications}
            desktop_ThreeDots={desktop_ThreeDots}
            setDesktop_Notifications={setDesktop_Notifications}
            setDesktop_ThreeDots={setDesktop_ThreeDots}
            setDesktop_ProfileMenu={setDesktop_ProfileMenu}
          />
          <div className="top">
            <div className="tabs">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label={t("dashbord.transaction.13")} {...a11yProps(0)} />
                  <Tab label={t("dashbord.transaction.14")} {...a11yProps(1)} />
                  <Tab label={t("dashbord.transaction.29")} {...a11yProps(2)} />
                  <Tab label={t("dashbord.transaction.30")} {...a11yProps(3)} />
                </Tabs>
              </Box>
            </div>
            {/* <div className="filter" onClick={showModule2}>
              <img
                style={{ width: "21.6px", height: "21px" }}
                src={require("../assests/image/dashbord/page_info_FILL0_wght400_GRAD0_opsz48.png")}
              />
              <div className="text">{t("dashbord.transaction.15")}</div>
            </div> */}
          </div>
          <div className="body">
            <CustomTabPanel value={value} index={0}>
              <Transaction1 />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
             <Transaction2 />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Transaction3 />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Transaction4 />
            </CustomTabPanel>
          </div>
        </section>
        <Fixed_Mobile_Links />
        <LightingMode bottom="20px" left="30px" />
      </div>
      </HelmetProvider>
    </React.Fragment>
  );
}

import React from "react";
import "./contact.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
export default function Contact() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.10")}</title>
        </Helmet>
      <Header />
      {/* Contact-section-one */}
      <section id="contact-section-one">
        <div className="all">
          <div className="title">{t("contact.1")}</div>
        </div>
      </section>
      {/* Contact-section-two */}
      <section id="contact-section-two">
        <div className="all">
          <div className="grid-item">
            <div className="carts">
              <div className="titles">
                <img src={require("../image/contact-page/03_Location.png")} />
                <div className="title">{t("contact.3")}</div>
              </div>
              <p className="des">
              {t("contact.4")}
              </p>
            </div>
          </div>
          <div className="grid-item">
            <div className="carts">
              <div className="titles">
                <img src={require("../image/contact-page/02_email.png")} />
                <div className="title">{t("contact.5")}</div>
              </div>
              <p className="des">
                <a href="mailto:support@moneyex.org">support@moneyex.org</a>
              </p>
            </div>
          </div>
          <div className="grid-item">
            <div className="carts">
              <div className="titles">
                <img src={require("../image/contact-page/01_Call.png")} />
                <div className="title">{t("contact.6")}</div>
              </div>
              <p className="des">
                <a href="tel:09056915681">09056915681</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Contact-section-three */}
      {/* <section id="contact-section-three">
        <form>
          <div className="title">
            <img src={require("../image/contact-page/04_Message.png")} />
            <div className="text">{t("contact.9")}</div>
          </div>
          <div className="inputs">
            <div className="name">
              <label>{t("contact.10")}</label>
              <input type="text" />
            </div>
            <div className="email">
              <label>{t("contact.11")}</label>
              <input type="email" />
            </div>
            <div className="subject">
              <label>{t("contact.12")}</label>
              <input type="text" />
            </div>
          </div>
          <div className="message">
            <label>{t("contact.13")}</label>
            <textarea></textarea>
          </div>
          <div className="end">
            <button>{t("contact.14")}</button>
            <div className="file">
                <img src={require("../image/contact-page/attach.png")}/>
              <div className="text">{t("contact.15")}</div>
            </div>
          </div>
        </form>
      </section> */}
      <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}

import { createContext } from "react";

export const MainContext=createContext({
    user_phone:"",
    user_lang: "",
    reverse_dir: "",
    primary_dir: "",
    lightingMode:"",
    authorization_status:"",
    user_name:"",
    user_USDT_balance:"",
    setUser_phone:()=>{},
    setUser_lang:()=>{},
    setReverse_dir:()=>{},
    setLightingMode:()=>{},
    setPrimary_dir:()=>{},
    setAuthorization_status:()=>{},
    setUser_name:()=>{},
    setUser_USDT_balance:()=>{}
})
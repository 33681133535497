import React, { useState, useContext, useEffect } from "react";
import "./Header.css";
import Select from "react-select";
import { MainContext } from "../contexts/MainContexts";
import { Link } from "react-router-dom";
import eneimage from "../image/header/En.png";
import faeimage from "../image/header/images.png";
import i18next from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Change_Language from "./change-language/Change_Language";
import ScrollToTopOnMount from "./scroll-to-top/ScrollToTopOnMount";
import { BASE_URL } from '../utils/url'
const languageSelect = [
  {
    name: "English",
    image: eneimage,
    valuee: "en",
  },
  {
    name: "فارسی",
    image: faeimage,
    valuee: "ar",
  },
];
export default function Header() {
  const { t } = useTranslation();
  const {
    lang,
    setLang,
    setUser_lang,
    setLightingMode,
    user_lang,
    setReverse_dir,
    setPrimary_dir,
  } = useContext(MainContext);
  const context = useContext(MainContext);

  const [show, setShow] = useState(false);
  function showMenu() {
    setShow(true);
  }
  function hiddenMenu() {
    setShow(false);
  }
  function changetheme() {
    if (Cookies.get("lightingMode") === "light") {
      // console.log("me");
      Cookies.set("lightingMode", "dark");
      setLightingMode("dark");
    } else {
      // console.log("mes");
      Cookies.set("lightingMode", "light");
      setLightingMode("light");
    }
  }

  const country = languageSelect.map((c) => {
    return {
      value: c.valuee,
      label: (
        <div className="option_style">
          <img src={c.image} height="16px" width="26px" />
          <span>{c.name}</span>
        </div>
      ),
    };
  });

  function changeLn(selectedOption) {
    i18next.changeLanguage(selectedOption.value);
    Cookies.set("user_lang", selectedOption.value);
    setUser_lang(selectedOption.value);
    Cookies.set("primary_dir", selectedOption.value === "ar" ? "rtl" : "ltr");
    setPrimary_dir(Cookies.get("primary_dir"));
    //3
    Cookies.set("reverse_dir", selectedOption.value === "ar" ? "ltr" : "rtl");
    setReverse_dir(Cookies.get("primary_dir"));
  }
  let placeholder = "";
  if (Cookies.get("i18next") === "en") {
    placeholder = country[0]?.label;
  } else {
    placeholder = country[1]?.label;
  }

  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          `${BASE_URL}/user/profile`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserProfile(response_1);
        // console.log("------------------------------------------");
        // console.log(response_1.data.meta.last_page)
      } catch (error) {
        // console.log(error.message);
      }
    }; //end of method
    fetchData();
  }, []);

  return (
    <React.Fragment>
     <ScrollToTopOnMount />
      <header>
        <div class="all">
          <div class="menu">
            <Link to="/" class="logo">
              <img src={require("../image/logo/_company_logo.png")} />
            </Link>
            <ul>
              <li>
                <Link to="/markets">{t("header.1")}</Link>
              </li>
              <li>
                <Link to="/exchange">{t("header.2")}</Link>
              </li>
              {/* <li>
                <Link to="/helpcenter">{t("header.3")}</Link>
              </li> */}
              <li>
                <Link to="/swap">{t("header.4")}</Link>
              </li>
              <li>
                <Link to="/blog">{t("header.5")}</Link>
              </li>
            </ul>
          </div>
          <div class="profile">
            <Link to="/dashboard" class="account">
              <div class="img-profile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.159"
                  height="20.531"
                  viewBox="0 0 21.159 20.531"
                >
                  <path
                    id="person"
                    d="M170.58,284.918a4.756,4.756,0,1,1,3.571-1.389A4.833,4.833,0,0,1,170.58,284.918Zm-8.6,10.613A1.975,1.975,0,0,1,160,293.547v-1.124a3.638,3.638,0,0,1,.628-2.149,4.033,4.033,0,0,1,1.62-1.355,24.85,24.85,0,0,1,4.248-1.488,17.173,17.173,0,0,1,4.083-.5,16.451,16.451,0,0,1,4.067.512,28.161,28.161,0,0,1,4.232,1.471,3.986,3.986,0,0,1,1.653,1.355,3.638,3.638,0,0,1,.628,2.149v1.124a1.975,1.975,0,0,1-1.984,1.984Z"
                    transform="translate(-160 -275)"
                    fill="#f9cc0b"
                  />
                </svg>
              </div>
              <div class="text">
                {/* {userProfile ? (
                  <div>
                    s
                  </div>
                ) : (
                  <p key={userProfile.data.profile.id}>
                  {userProfile.data.profile.first_name} {userProfile.data.profile.last_name}
                </p>
                )} */}
                {/* {userProfile && <div>2222222222</div>}
                {!userProfile && <p>{userProfile.data.profile.first_name}</p>} */}
                {userProfile && userProfile.data && userProfile.data.profile ? (
                  <p key={userProfile.data.profile.id}>
                    {userProfile.data.profile.first_name}{' '}
                    {userProfile.data.profile.last_name}
                  </p>
                ) : (
                  <p>{t("header.6")}</p>
                )}
              </div>
            </Link>
            {/* <div class="language"> */}
              {/* <Select
                options={country}
                onChange={changeLn}
                // styles={customStyles}
                placeholder={placeholder}
              /> */}
              {/* <Change_Language />
            </div> */}
            <div class="theme" onClick={changetheme}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 23.883 23.88"
                style={
                  context.lightingMode === "dark"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <path
                  id="Moon"
                  d="M132.072,247.747A12.022,12.022,0,0,1,120,235.675,11.49,11.49,0,0,1,122.666,228a12.111,12.111,0,0,1,6.992-4.057q1.375-.268,1.878.469a2.061,2.061,0,0,1-.034,2.012,9.8,9.8,0,0,0-.469,1.576,8.034,8.034,0,0,0-.168,1.643,7.213,7.213,0,0,0,7.243,7.243,8.63,8.63,0,0,0,1.626-.151,9.911,9.911,0,0,0,1.526-.419,2.115,2.115,0,0,1,2.146.05,1.869,1.869,0,0,1,.369,2,12,12,0,0,1-4.057,6.723A11.443,11.443,0,0,1,132.072,247.747Zm0-2.012a9.646,9.646,0,0,0,6.371-2.263,9.5,9.5,0,0,0,3.387-5.315,8.367,8.367,0,0,1-1.8.553,10.183,10.183,0,0,1-1.923.184,9.234,9.234,0,0,1-9.255-9.255,9.685,9.685,0,0,1,.168-1.727,12.6,12.6,0,0,1,.6-2.1,10.214,10.214,0,0,0-5.449,3.672,10.023,10.023,0,0,0,7.9,16.247ZM131.938,235.775Z"
                  transform="translate(-120 -223.867)"
                  fill="#141414"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="sun"
                width="20"
                height="20"
                viewBox="0 0 17.66 17.66"
                style={
                  context.lightingMode === "dark"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <path
                  id="Path_8247"
                  data-name="Path 8247"
                  d="M13.426,16.376a2.95,2.95,0,1,0-2.95-2.95A2.95,2.95,0,0,0,13.426,16.376Zm0,1.475A4.426,4.426,0,1,0,9,13.426,4.426,4.426,0,0,0,13.426,17.851Z"
                  transform="translate(-4.596 -4.596)"
                  fill="#f6b33f"
                  fill-rule="evenodd"
                />
                <path
                  id="Path_8248"
                  data-name="Path 8248"
                  d="M8.094,0H9.566V2.989a5.945,5.945,0,0,0-1.472,0ZM5.22,4.179,3.107,2.066l-1.04,1.04L4.179,5.22a5.922,5.922,0,0,1,1.04-1.04ZM2.989,8.094H0V9.566H2.989a5.945,5.945,0,0,1,0-1.472ZM4.179,12.44,2.066,14.553l1.04,1.04L5.22,13.48A5.922,5.922,0,0,1,4.179,12.44Zm3.915,2.231V17.66H9.566V14.671a5.937,5.937,0,0,1-1.472,0ZM12.44,13.48l2.113,2.113,1.04-1.04L13.48,12.44a5.922,5.922,0,0,1-1.04,1.04Zm2.231-3.915H17.66V8.094H14.671A5.937,5.937,0,0,1,14.671,9.566ZM13.48,5.22l2.113-2.113-1.04-1.04L12.44,4.179a5.934,5.934,0,0,1,1.04,1.04Z"
                  transform="translate(0)"
                  fill="#f6b33f"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="m-all">
          <div className="m-profile">
            <Link to={"/dashboard"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.159"
              height="20.531"
              viewBox="0 0 21.159 20.531"
            >
              <path
                id="person"
                d="M170.58,284.918a4.756,4.756,0,1,1,3.571-1.389A4.833,4.833,0,0,1,170.58,284.918Zm-8.6,10.613A1.975,1.975,0,0,1,160,293.547v-1.124a3.638,3.638,0,0,1,.628-2.149,4.033,4.033,0,0,1,1.62-1.355,24.85,24.85,0,0,1,4.248-1.488,17.173,17.173,0,0,1,4.083-.5,16.451,16.451,0,0,1,4.067.512,28.161,28.161,0,0,1,4.232,1.471,3.986,3.986,0,0,1,1.653,1.355,3.638,3.638,0,0,1,.628,2.149v1.124a1.975,1.975,0,0,1-1.984,1.984Z"
                transform="translate(-160 -275)"
              />
            </svg>
            </Link>
          </div>
          <Link to="/" className="logo">
            <img src={require("../image/logo/_company_logo.png")} />
          </Link>
          <div className="m-menu" onClick={showMenu}>
            <img
              src={require("../image/header/menu_FILL0_wght400_GRAD0_opsz48.png")}
            />
          </div>
        </div>
      </header>
      <div
        className="menu-blur"
        style={show ? { display: "block" } : { display: "none" }}
      >
        <div className="top">
          <div className="left">
            <div className="back-btn" onClick={hiddenMenu}>
              <img
                src={require("./../image/arrow/arrow_back_FILL0_wght400_GRAD0_opsz48.png")}
                className="tablet"
              />
              <img
                src={require("./../image/arrow/arrow_back_FILL0_wght400_GRAD0_opsz48 (1).png")}
                className="mobile"
              />
              <div className="text">Back</div>
            </div>
          </div>
          <div className="right">
            <div className="theme" onClick={changetheme}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.883"
                height="23.88"
                viewBox="0 0 23.883 23.88"
                style={
                  context.lightingMode === "dark"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <path
                  id="Moon"
                  d="M132.072,247.747A12.022,12.022,0,0,1,120,235.675,11.49,11.49,0,0,1,122.666,228a12.111,12.111,0,0,1,6.992-4.057q1.375-.268,1.878.469a2.061,2.061,0,0,1-.034,2.012,9.8,9.8,0,0,0-.469,1.576,8.034,8.034,0,0,0-.168,1.643,7.213,7.213,0,0,0,7.243,7.243,8.63,8.63,0,0,0,1.626-.151,9.911,9.911,0,0,0,1.526-.419,2.115,2.115,0,0,1,2.146.05,1.869,1.869,0,0,1,.369,2,12,12,0,0,1-4.057,6.723A11.443,11.443,0,0,1,132.072,247.747Zm0-2.012a9.646,9.646,0,0,0,6.371-2.263,9.5,9.5,0,0,0,3.387-5.315,8.367,8.367,0,0,1-1.8.553,10.183,10.183,0,0,1-1.923.184,9.234,9.234,0,0,1-9.255-9.255,9.685,9.685,0,0,1,.168-1.727,12.6,12.6,0,0,1,.6-2.1,10.214,10.214,0,0,0-5.449,3.672,10.023,10.023,0,0,0,7.9,16.247ZM131.938,235.775Z"
                  transform="translate(-120 -223.867)"
                  fill="#fff"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="sun"
                width="23.66"
                height="23.66"
                viewBox="0 0 17.66 17.66"
                style={
                  context.lightingMode === "dark"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <path
                  id="Path_8247"
                  data-name="Path 8247"
                  d="M13.426,16.376a2.95,2.95,0,1,0-2.95-2.95A2.95,2.95,0,0,0,13.426,16.376Zm0,1.475A4.426,4.426,0,1,0,9,13.426,4.426,4.426,0,0,0,13.426,17.851Z"
                  transform="translate(-4.596 -4.596)"
                  fill="#f6b33f"
                  fill-rule="evenodd"
                />
                <path
                  id="Path_8248"
                  data-name="Path 8248"
                  d="M8.094,0H9.566V2.989a5.945,5.945,0,0,0-1.472,0ZM5.22,4.179,3.107,2.066l-1.04,1.04L4.179,5.22a5.922,5.922,0,0,1,1.04-1.04ZM2.989,8.094H0V9.566H2.989a5.945,5.945,0,0,1,0-1.472ZM4.179,12.44,2.066,14.553l1.04,1.04L5.22,13.48A5.922,5.922,0,0,1,4.179,12.44Zm3.915,2.231V17.66H9.566V14.671a5.937,5.937,0,0,1-1.472,0ZM12.44,13.48l2.113,2.113,1.04-1.04L13.48,12.44a5.922,5.922,0,0,1-1.04,1.04Zm2.231-3.915H17.66V8.094H14.671A5.937,5.937,0,0,1,14.671,9.566ZM13.48,5.22l2.113-2.113-1.04-1.04L12.44,4.179a5.934,5.934,0,0,1,1.04,1.04Z"
                  transform="translate(0)"
                  fill="#f6b33f"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
            {/* <div className="language" style={{border:"0"}}> */}
              {/* <Select
                options={country}
                onChange={changeLn}
                // styles={customStyles}
                placeholder={placeholder}
              /> */}
               {/* <Change_Language />
            </div> */}
            <div className="profile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.159"
                height="20.531"
                viewBox="0 0 21.159 20.531"
              >
                <path
                  id="person"
                  d="M170.58,284.918a4.756,4.756,0,1,1,3.571-1.389A4.833,4.833,0,0,1,170.58,284.918Zm-8.6,10.613A1.975,1.975,0,0,1,160,293.547v-1.124a3.638,3.638,0,0,1,.628-2.149,4.033,4.033,0,0,1,1.62-1.355,24.85,24.85,0,0,1,4.248-1.488,17.173,17.173,0,0,1,4.083-.5,16.451,16.451,0,0,1,4.067.512,28.161,28.161,0,0,1,4.232,1.471,3.986,3.986,0,0,1,1.653,1.355,3.638,3.638,0,0,1,.628,2.149v1.124a1.975,1.975,0,0,1-1.984,1.984Z"
                  transform="translate(-160 -275)"
                  fill="#f9cc0b"
                ></path>
              </svg>
              <div className="text">User profile</div>
            </div>
          </div>
        </div>
        <div className="menu-list">
          <ul>
            <li
            //  className="active"
            >
              <Link to="/markets" onClick={hiddenMenu}>
                <div className="text">{t("header.1")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.863"
                  height="10.256"
                  viewBox="0 0 5.863 10.256"
                >
                  <path
                    id="Arrow-right"
                    d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                    transform="translate(17.114 16.449) rotate(180)"
                  />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="/exchange" onClick={hiddenMenu}>
                <div className="text">{t("header.2")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.863"
                  height="10.256"
                  viewBox="0 0 5.863 10.256"
                >
                  <path
                    id="Arrow-right"
                    d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                    transform="translate(17.114 16.449) rotate(180)"
                  />
                </svg>
              </Link>
            </li>
            {/* <li>
              <Link to="/helpcenter" onClick={hiddenMenu}>
                <div className="text">{t("header.3")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.863"
                  height="10.256"
                  viewBox="0 0 5.863 10.256"
                >
                  <path
                    id="Arrow-right"
                    d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                    transform="translate(17.114 16.449) rotate(180)"
                  />
                </svg>
              </Link>
            </li> */}
            <li>
              <Link to="/swap" onClick={hiddenMenu}>
                <div className="text">{t("header.4")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.863"
                  height="10.256"
                  viewBox="0 0 5.863 10.256"
                >
                  <path
                    id="Arrow-right"
                    d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                    transform="translate(17.114 16.449) rotate(180)"
                  />
                </svg>
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={hiddenMenu}>
                <div className="text">{t("header.5")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.863"
                  height="10.256"
                  viewBox="0 0 5.863 10.256"
                >
                  <path
                    id="Arrow-right"
                    d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                    transform="translate(17.114 16.449) rotate(180)"
                  />
                </svg>
              </Link>
            </li>
          
            <li>
              <Link to="/dashboard" onClick={hiddenMenu}>
                <div className="text">{t("header.6")}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5.863"
                  height="10.256"
                  viewBox="0 0 5.863 10.256"
                >
                  <path
                    id="Arrow-right"
                    d="M13.018,11.32,16.9,7.443A.733.733,0,0,0,15.86,6.408l-4.4,4.393a.731.731,0,0,0-.021,1.011l4.415,4.424A.733.733,0,1,0,16.9,15.2Z"
                    transform="translate(17.114 16.449) rotate(180)"
                  />
                </svg>
              </Link>
            </li>
            
          </ul>
        </div>
        <div className="info">
          <div className="gmail">
            <a href="mailto:support@moneyex.org" className="text">support@moneyex.org</a>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
            >
              <g id="Email" transform="translate(6.559 8.597)">
                <rect
                  id="Rectangle_24"
                  data-name="Rectangle 24"
                  width="32"
                  height="33"
                  rx="10"
                  transform="translate(-5.559 -8.597)"
                  fill="#3d3b3b"
                  opacity="0.2"
                />
                <rect
                  id="Rectangle_23"
                  data-name="Rectangle 23"
                  width="31"
                  height="31"
                  rx="10"
                  transform="translate(-6.559 -8.597)"
                  fill="#00c0af"
                />
                <path
                  id="mail_FILL0_wght400_GRAD0_opsz48_3_"
                  data-name="mail_FILL0_wght400_GRAD0_opsz48 (3)"
                  d="M5.279,21.644A1.312,1.312,0,0,1,4,20.365V9.279A1.312,1.312,0,0,1,5.279,8h14.5a1.312,1.312,0,0,1,1.279,1.279V20.365a1.312,1.312,0,0,1-1.279,1.279Zm7.249-6.438L5.279,10.452v9.914h14.5V10.452Zm0-1.279,7.163-4.648H5.386ZM5.279,10.452v0Z"
                  transform="translate(-4 -8)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <div className="number">
            <a href="tel:09056915681" className="text">09056915681</a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
            >
              <g id="phone_in_talk" transform="translate(7 7.5)">
                <rect
                  id="Rectangle_19"
                  data-name="Rectangle 19"
                  width="32"
                  height="32"
                  rx="10"
                  transform="translate(-6 -6.5)"
                  fill="#3d3b3b"
                  opacity="0.2"
                />
                <rect
                  id="Rectangle_20"
                  data-name="Rectangle 20"
                  width="31"
                  height="32"
                  rx="10"
                  transform="translate(-7 -7.5)"
                  fill="#00c0af"
                />
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M13.806,11.1h1.8a4.5,4.5,0,0,0-4.5-4.5V8.4A2.7,2.7,0,0,1,13.806,11.1Zm3.6,0h1.8A8.105,8.105,0,0,0,11.1,3V4.8A6.3,6.3,0,0,1,17.408,11.1Zm.9,3.152a10.23,10.23,0,0,1-3.215-.513,1,1,0,0,0-.279-.045.922.922,0,0,0-.639.261L12.194,15.94A13.548,13.548,0,0,1,6.26,10.006l1.981-1.99a.864.864,0,0,0,.225-.9A10.23,10.23,0,0,1,7.953,3.9a.9.9,0,0,0-.9-.9H3.9a.9.9,0,0,0-.9.9A15.307,15.307,0,0,0,18.308,19.209a.9.9,0,0,0,.9-.9V15.157A.9.9,0,0,0,18.308,14.256ZM4.828,4.8H6.179a12.414,12.414,0,0,0,.405,2.323L5.5,8.214A13.82,13.82,0,0,1,4.828,4.8Zm12.58,12.58a13.419,13.419,0,0,1-3.422-.684l1.081-1.081a11.484,11.484,0,0,0,2.341.405Z"
                  transform="translate(-3 -3)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

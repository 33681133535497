//internal
import { useState, useEffect, useContext } from "react";
//external
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContexts"
import Cookies from "js-cookie";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style and Image
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Convert_Box from "../../components/convert-box/Convert_Box";
import "../../components/convert-box/convert-box.css"
import "./swap.css"

import { BASE_URL } from '../../utils/url'



//متد دست ساز
function reverse_String(str) {
  let array = str.split("_");
  let reversedArray = array.reverse();
  let reversedString = reversedArray.join("_");

  return reversedString;
}





const Swap = () => {


  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  // console.log(params.toString())
  ///////////////////////////////////////////////////////////

  const baseURL = BASE_URL
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  const { t } = useTranslation();
  const { lightingMode } = useContext(MainContext);

  ////////////////داستان تبدیل سکه ها///////////////////////////////
  ///////////////////////////////////////////////////////////////////

  const [profileData, setProfileData] = useState({});
  const [pair_balance, setPair_balance] = useState({});
  // const [forceRender, setForceRender] = useState();
  const [coinList, setCoinList] = useState([]);
  const [paired_CoinList, setPaired_CoinList] = useState([]);
  // const [featured_List, setFeatured_List] = useState([]);
  const [swap, setSwap] = useState({});
  const [order_type, setOrder_type] = useState(params.get("type"));
  const [loading, setLoading] = useState(false);




  const [input_First, setInput_First] = useState("");
  const [input_Second, setInput_Second] = useState("");
  const [placeHolder_First, setPlaceHolder_First] = useState(0);
  // const [help_input_First, setHelp_Input_First] = useState("");
  // const [help_input_Second, setHelp_Input_Second] = useState("");

  const [base_Coin, setBase_Coin] = useState("");
  const [quote_Coin, setQuote_Coin] = useState("");

  const myCheck = (base_Coin && quote_Coin) ? `${base_Coin}_${quote_Coin}` : `AAVE_USDT`
  const min_base = paired_CoinList.find(z => z.name === params.get("pair"))?.base_min_size
  const max_base = paired_CoinList.find(z => z.name === params.get("pair"))?.base_max_size
  const checkList = paired_CoinList.map(x => x.name);
  const myIndex = checkList.findIndex(item => item === myCheck);


  let src_1 = ""
  let src_2 = ""
  if (params.toString()) {
    src_1 = base_url_img + coinList.find(b => b.symbol === params.get("pair").split("_")[0])?.icon
    src_2 = base_url_img + coinList.find(b => b.symbol === params.get("pair").split("_")[1])?.icon
  }





  // console.log("base: " + base_Coin)
  // console.log("quote: " + quote_Coin)
  // console.log(myCheck)
  // console.log(myIndex)

  // console.log(checkList)
  // console.log("///////////////////////")




  const handle_BTN = (arg) => {

    if (order_type === "sell" && arg === "buy") {
      setOrder_type("buy")
      setInput_First(0)



    } else if (order_type === "buy" && arg === "sell") {
      setOrder_type("sell")
      setInput_First(0)


    }

  }//end





  /////////useEffect////////////////
  /////////useEffect////////////////

  //mounting--useEffect
  useEffect(() => {

    //اگر توکن نبود
    if (!Cookies.get("access_token")) {
      navigate("/judge", { replace: true })
    }



    const fetchData = async () => {
      try {

        //تمامی ارزها
        const response_1 = await axios.get(`${baseURL}/coins`);
        setCoinList(response_1.data)

        //تمامی جفت شد ها
        const res_pair = await axios.get(`${baseURL}/coin-pairs`);
        setPaired_CoinList(res_pair.data)


        //توکن میخواهد
        //باید بدانیم کابر از آن ارز ،چقدر در کیف پول خود دارد
        if (params.toString()) {

          const res_balance = await axios.get(`${baseURL}/user/coin-pairs/${params.get("pair")}/balance`, {
            headers: {
              'Authorization': `Bearer ${Cookies.get("access_token")}`,
              'Content-Type': 'application/json'
            }
          });
          setPair_balance(res_balance.data)
          setPlaceHolder_First(res_balance.data.base_coin_balance)
        }
         
        if (params.toString()) {
          //برای بدست آوردن آخرین قیمت
          const res_swap = await axios.get(`${baseURL}/swap/pair-ticker-info/${params.get("pair")}`);
          setSwap(res_swap.data)
        }


        //برای ضریب fee
        const res_profile = await axios.get(`${baseURL}/user/profile`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setProfileData(res_profile.data)

      } catch (err) {
        
        if(err.response.data.message==="Unauthenticated."){
          Cookies.remove("access_token")
          navigate("/judge", { replace: true })
         }

      }
    }//end of method

    fetchData();
  }, [])



  //updating از بالا به پایین  
  //تغییر اینپوت اول 
  useEffect(() => {

    //حالت فروش
    if (myIndex !== -1 && order_type === "sell") {
      if (input_First) {
        //ضریب فرمول
        const x = parseFloat(swap?.latest_price) - parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100)
        const xx = input_First * x
        setInput_Second(xx.toFixed(4))
      } else {
        setInput_Second("")
      }



      //حالت خرید 
    } else if (order_type === "buy") {
      if (input_First && input_First !== "0") {
        //ضریب فرمول
        const y = parseFloat(swap?.latest_price) + parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100)
        const yy = input_First / y
        setInput_Second(yy.toFixed(6))
      }

    } else if (myIndex == -1) {
      setInput_Second("")
    }


  }, [input_First])



  /////////////////////////////////////////////////////////////////////////

  //ارسال فرم
  const handleSubmitForm = async (e) => {

    e.preventDefault();



    try {
      const res = await axios.post(`${baseURL}/user/orders/market`,
        JSON.stringify({
          order_type,
          trade_pair: (order_type === "sell") ? myCheck : reverse_String(myCheck),
          amount: input_First
        }),
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        })

      if (res.data.status === "success") {
        setInput_First(0)
        setInput_Second(0)
        toast.success(res.data.status, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

        });
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

        });
      }


    } catch (error) {

    }
  }


  return (
    <HelmetProvider>
      <div className='min-h-[100vh] flex flex-col justify-between gap-[7rem]'>
        <Helmet>
          <title>{t("title_swap")}</title>
        </Helmet>

        <Header />
        {
          params.toString() ?
            (
              <div className='w-[600px] max-w-[95vw] m-auto shadow-xl p-10 rounded-xl border'>

                <div className="convert-box">
                  {/* دکمه */}
                  <section className="two_btn">
                    {/* فروش */}
                    <button onClick={() => handle_BTN("sell")}
                      style={(order_type === "sell") ? { background: "#f63f3f", color: "white" } : {}}
                    >{t("convertBox_1")}</button>

                    {/*خرید*/}
                    <button onClick={() => handle_BTN("buy")}
                      style={(order_type === "buy") ? { background: "#00C0AF", color: "white" } : {}}
                    >{t("convertBox_2")}</button>
                  </section>


                  <form onSubmit={handleSubmitForm}>


                    {/* input-first*/}
                    <div className="h-[74px] mb-3 relative">
                      <label htmlFor="" className="h-[19px] block mb-2">{t("convertBox_3")}</label>

                      <div style={{ borderRadius: "10px", border: "1px solid #edeef1" }} className="h-[46px] flex justify-between">
                        <input
                          type="text"
                          className="block h-full p-3 w-[calc(100%-160px)]"
                          value={isNaN(input_First) ? "" : input_First}
                          onChange={(e) => {
                            setInput_First(e.target.value)
                          }}

                          placeholder={` دارایی شما ${placeHolder_First}`}
                          style={{ borderRadius: "10px" }}

                        />
                        {/* جایگزین سلکت */}
                        <div className="ltr h-full w-[150px] ">
                          <div className="option_style_2">
                            {
                              order_type === "sell" ?
                                (
                                  <>
                                    <img src={src_1} height="30px" width="30px" />
                                    <span className="toggleColor">{params.get("pair").split("_")[0]}</span>
                                  </>
                                ) :
                                (
                                  <>
                                    <img src={src_2} height="30px" width="30px" />
                                    <span className="toggleColor">{params.get("pair").split("_")[1]}</span>
                                  </>
                                )
                            }
                          </div>
                        </div>
                        {/*  */}
                        {(
                          <span className="show-min-max">
                            <span>Min: {min_base},</span>
                            {" "}
                            <span>Max: {max_base}</span>
                          </span>)}
                      </div>

                    </div>
                    {/* rotate logo */}
                    <div class="flexCenter">
                      <span class="flexCenter w-[43px] h-[43px] rounded-md"
                        style={{ background: "#f9cc0b" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.414" height="32.578" viewBox="0 0 24.414 32.578">
                          <path id="autorenew_FILL1_wght400_GRAD0_opsz48" d="M160.687,170.409a9.493,9.493,0,0,1-.534-2.022,14.038,14.038,0,0,1-.153-2.06,12.309,12.309,0,0,1,12.207-12.207h1.64l-2.327-2.327a.95.95,0,0,1-.286-.744,1.083,1.083,0,0,1,.324-.744,1.059,1.059,0,0,1,1.488,0l4.12,4.158a1.162,1.162,0,0,1,.267.381,1.192,1.192,0,0,1,0,.839,1.162,1.162,0,0,1-.267.381l-4.12,4.12a1.079,1.079,0,0,1-1.526-1.526l2.251-2.251h-1.564a9.964,9.964,0,0,0-9.918,9.918,9.8,9.8,0,0,0,.153,1.755,14.765,14.765,0,0,0,.381,1.6,1.091,1.091,0,0,1,0,.572,1.041,1.041,0,0,1-.267.5,1.19,1.19,0,0,1-1.068.4A1.012,1.012,0,0,1,160.687,170.409Zm10.643,11.864-4.158-4.12a1.163,1.163,0,0,1-.267-.381,1.192,1.192,0,0,1,0-.839,1.163,1.163,0,0,1,.267-.381l4.158-4.158a1.052,1.052,0,0,1,1.488,1.488l-2.327,2.327h1.717a9.964,9.964,0,0,0,9.918-9.918,11.528,11.528,0,0,0-.134-1.774,9.161,9.161,0,0,0-.4-1.621,1.159,1.159,0,0,1-.019-.553.909.909,0,0,1,.248-.477,1.19,1.19,0,0,1,1.068-.4,1.012,1.012,0,0,1,.8.744,10.366,10.366,0,0,1,.553,2.022,12.51,12.51,0,0,1,.172,2.06A12.309,12.309,0,0,1,172.207,178.5H170.49l2.327,2.327a1.013,1.013,0,0,1,0,1.45,1.059,1.059,0,0,1-1.488,0Z" transform="translate(-160 -150)" fill="#fff" />
                        </svg>

                      </span>
                    </div>
                    {/*input-second */}

                    <div className="h-[74px] mb-3">
                      <label htmlFor="" className="h-[19px] block mb-2">{t("convertBox_4")}</label>

                      <div style={{ borderRadius: "10px", border: "1px solid #edeef1" }}
                        className="h-[46px] flex relative justify-between">
                        <input
                          type="text"
                          className="block h-full p-3 w-[calc(100%-160px)]"
                          value={isNaN(input_Second) ? "" : input_Second}
                          disabled

                          style={{ borderRadius: "10px" }}
                          placeholder="0.00"
                        />
                        {/* جایگزین سلکت */}
                        <div className="ltr h-full w-[150px] ">
                          <div className="option_style_2">
                            {
                              order_type === "sell" ?
                                (
                                  <>
                                    <img src={src_2} height="30px" width="30px" />
                                    <span className="toggleColor">{params.get("pair").split("_")[1]}</span>
                                  </>
                                ) :
                                (
                                  <>
                                    <img src={src_1} height="30px" width="30px" />
                                    <span className="toggleColor">{params.get("pair").split("_")[0]}</span>
                                  </>
                                )
                            }
                          </div>
                        </div>
                        {(myIndex == -1) && (<span className="show-error">قابلیت تبدیل وجود ندارد</span>)}
                      </div>

                    </div>


                    {/* نرخ تبدیل */}
                    <div className="text-center text-sm mb-5">
                      <span className="toggleColor">
                        <span>{t("convertBox_5")}</span>
                        <span>:</span>
                        <span>
                          {
                            order_type === "sell" ? <span>{parseFloat(swap?.latest_price) - parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100)}</span>
                              :
                              <span>{parseFloat(swap?.latest_price) + parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100)}</span>
                          }
                        </span>
                        <span>~</span>
                        <span>1</span>
                      </span>
                    </div>
                    {/* submit button */}
                    <button
                      className="h-[46px] w-full text-white"
                      type="submit"
                      style={order_type === "sell" ? { background: "#f63f3f", borderRadius: "10px" } : { background: "#00C0AF", borderRadius: "10px" }} >{order_type === "sell" ? "فروش" : "خرید"}</button>
                    {/*  */}
                  </form>

                </div>


              </div>
            )
            :
            (
              <div className="flexCenter">
                <div className="mm w-[600px]">
                  <Convert_Box />

                </div>
              </div>

            )
        }

        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default Swap











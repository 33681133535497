import React from "react";
import "./helpcenter.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
export default function Helpcenter() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.8")}</title>
        </Helmet>
      <Header />
      {/* <!-- helpcenter-Section-one --> */}
      <section id="helpcenter-section-one">
        <div className="all">
          <div className="title">{t("helpcenter.1")}</div>
          <p className="des">{t("helpcenter.2")}</p>
          <div className="design-img-1">
            <img src={require("../image/helpcenter-page/Path 11203.png")} />
          </div>
          <div className="design-img-2">
            <img src={require("../image/helpcenter-page/Path 11203.png")} />
          </div>
          <form>
            <input type="text" placeholder={t("helpcenter.3")}/>
            <div className="search-icon">
              <img
                src={require("../image/helpcenter-page/search_FILL0_wght400_GRAD0_opsz48.png")}
              />
            </div>
          </form>
        </div>
      </section>
      {/* help-senter-section-two */}
      <section id="helpcenter-section-two">
        <div className="all">
          <div className="grid-item">
            <div className="carts">
              <img src={require("../image/helpcenter-page/Rectangle.png")} />
              <div className="title">{t("helpcenter.4")}</div>
              <p className="des">
              {t("helpcenter.5")}
              </p>
            </div>
          </div>
          <div className="grid-item">
            <div className="carts">
              <img src={require("../image/helpcenter-page/Rectangle.png")} />
              <div className="title">{t("helpcenter.6")}</div>
              <p className="des">
              {t("helpcenter.7")}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* help-senter-section-two */}
      <section id="helpcenter-section-three">
        <div className="all">
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/04_Security.png")}/>
                    <div className="title">
                    {t("helpcenter.8")}
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/03_Withdraw.png")}/>
                    <div className="title">
                    {t("helpcenter.9")}
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/02_Deposit.png")}/>
                    <div className="title">
                    {t("helpcenter.10")}
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/01_Submit.png")}/>
                    <div className="title">
                    {t("helpcenter.11")}
                    </div>
                </div>
            </div>

            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/08_Referals.png")}/>
                    <div className="title">
                    {t("helpcenter.12")}
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/07_List.png")}/>
                    <div className="title">
                    {t("helpcenter.13")}
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/06_Cryptowallet.png")}/>
                    <div className="title">
                    {t("helpcenter.14")}
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="carts">
                    <img src={require("../image/helpcenter-page/05_Fee.png")}/>
                    <div className="title">
                    {t("helpcenter.15")}
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}

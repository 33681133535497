import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// console.log("Initializing i18next...");

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "ar",
    detection: {
      order: ["cookie", "path", "htmlTag"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: process.env.PUBLIC_URL + "/language/{{lng}}/translation.json",
    },
    // debug: true,  // این گزینه برای فعال کردن لاگ‌های i18next است
  }, (err, t) => {
    // if (err) {
    //   console.error("i18next initialization error:", err);
    // } else {
    //   console.log("i18next initialized successfully.");
    // }
  });

const root = ReactDOM.createRoot(document.getElementById("mojbit"));
root.render(
  <React.StrictMode>
     <Suspense>
       <BrowserRouter>
         <App />
       </BrowserRouter>
     </Suspense>
  </React.StrictMode>
);
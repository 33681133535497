import React from 'react'

const LoadingCircle = () => {
    return (
        <div className="w-full h-full min-h-40 min-w-40 flex items-center justify-center">
            <img src={require('./loading-spiner.gif')} alt="Loading..." width={40} />
        </div>
    )
}

export default LoadingCircle;
//internal
import { useContext, useState, useEffect } from "react";

//external
import axios from "axios";
import Select from 'react-select';
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style
import "./register.css";
import "./lightingMode.css";
import Login_Left from "../login/Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode";
//Image
import canadaFlag from "../../../assests/image/flag/canada.jpg";
import usaFlag from "../../../assests/image/flag/usa.jpg";
import iranFlag from "../../../assests/image/flag/iran.jpg";

import { BASE_URL } from '../../../utils/url'




const Register = () => {

  const baseURL = BASE_URL
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { primary_dir, setUser_phone, lightingMode } = useContext(MainContext);

  const [type, setType] = useState("password")
  const [type_repeat, setType_repeat] = useState("password")

  const [country_code, setCountry_code] = useState("98")
  const [mobile, setMobile] = useState("")
  const [password, setPassword] = useState("")
  const [password_confirmation, setPassword_confirmation] = useState("")
  const [referral_code, setReferral_code] = useState("")
  const [check_agreement, setCheck_agreement] = useState(false)






  //Mounting
  useEffect(() => {
    //اگر توکن داشت اینجا نیا
    if (Cookies.get("access_token")) {
      navigate("/dashboard", { replace: true })
    }
    if (Cookies.get("user_phone")) {
      setMobile(Cookies.get("user_phone"))
    }
    if (Cookies.get("referral_code")) {
      setReferral_code(Cookies.get("referral_code"))
    }
  }, [])



  ///////////////////////////////Select///////////////////////////////
  const customStyles = {
    control: base => ({
      ...base,
      height: "46px",
      width: "100%",
      background: (lightingMode === "light") ? "#f7f8fa" : "black"
    }),
    option: (base) => ({
      ...base,
      backgroundColor: (lightingMode === 'light') ? '#f7f8fa' : '#000'
    }),

  };

  //لیست پرچم ها و پیش شماره
  const areaCode_List = [
    {
      src_flag: iranFlag,
      country_code: "98",
      value: "98"
    },
    {
      src_flag: usaFlag,
      country_code: "1",
      value: "1"
    },
    {
      src_flag: canadaFlag,
      country_code: "1",
      value: "1"
    }
  ]

  //for Select
  const optionFlagList = areaCode_List.map(mm => {
    return {
      value: mm.value,
      label:
        <div className="option_style flex justify-between">
          <img src={mm.src_flag} height="30px" width="30px" />
          <span className="block">+{mm.country_code}</span>
        </div>
    }
  })

  //handleSelect
  const handleSelect = (selectedOption) => {
    setCountry_code(selectedOption.value)

  }

  //ارسال فرم
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    e.target.disabled = true

    const _fuck = (mobile[0] === "0") ? mobile.slice(1) : mobile

    const send_Json = {
      country_code,
      mobile: _fuck,
      password,
      password_confirmation,
      referral_code,
      check_agreement,
      type: "mobile"
    }

    if (password === password_confirmation) {
      try {
        const res = await axios.post(`${baseURL}/registration`, JSON.stringify(send_Json), { headers: { 'Content-Type': 'application/json' } })

        if (res.status == 200) {

          Cookies.set("pre_registration_id", res.data.data.pre_registration_id)

          navigate("/verification-one")
        }

      e.target.disabled = false
      } catch (err) {
        // console.log(err.response.data.message);
        toast.error(err.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

        });
      e.target.disabled = false
      }
    } else {
      toast.error('گذر واژه و تکرار آن با یکدیگر مطابقت ندارند', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      e.target.disabled = false
    }




  }//end of method



  return (
    <HelmetProvider>
      <div className="grand-parent" id="register">
        <Helmet>
          <title>{t("title_register")}</title>
        </Helmet>

        <div className="register-parent" >

          {/* right */}
          <section className="register-right">
            <h1>{t("register_1")}</h1>

            <form>

              {/* name="mobile" */}
              <div className="h-[72px] mb-4">
                <label for="">{t("register_2")}</label>

                <div className="rtl h-[46px] flex justify-between">
                  <input
                    className="judge-input h-[46px] rounded-lg p-3"
                    style={{ border: "1px solid #e3e4f5" }}
                    name="mobile"
                    disabled
                    type="text"
                    placeholder="9123311445"
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                  />
                  <div className="judge_select ltr h-[46px]">
                    <Select
                      styles={customStyles}
                      options={optionFlagList}
                      placeholder={optionFlagList[0]?.label}
                      onChange={handleSelect}
                      isSearchable={false}
                      isDisabled
                    />
                  </div>
                </div>

              </div>

              {/* password */}
              {/* name="password" */}
              <div className="mini-parent">
                <label for="">{t("register_3")}</label>
                <input
                  dir="ltr"
                  className="w-full ltr"
                  name="password"
                  type={type}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />

                <span className="toggleEye"
                  onClick={(type === "password") ? () => setType("text") : () => setType("password")}
                  style={{ right: "7px" }}
                >
                  {
                    (type === "password") ? <IoEyeOutline /> : <FaRegEyeSlash />
                  }
                </span>
              </div>


              {/* name="password_confirmation" */}
              <div className="mini-parent">
                <label for="">{t("register_4")}</label>
                <input
                  dir="ltr"
                  className="w-full ltr"
                  name="password_confirmation"
                  type={type_repeat}
                  value={password_confirmation}
                  onChange={e => setPassword_confirmation(e.target.value)}
                />

                <span className="toggleEye"
                  onClick={(type_repeat === "password") ? () => setType_repeat("text") : () => setType_repeat("password")}
                  style={{ right: "7px" }}
                >
                  {
                    (type_repeat === "password") ? <IoEyeOutline /> : <FaRegEyeSlash />
                  }
                </span>
              </div>


              {/* section */}
              <section>
                <input type="checkbox" onChange={(e) => setCheck_agreement(e.target.checked)} />
                <span className={(primary_dir === "ltr" ? "ml-3" : "mr-3")} >{t("register_5")}</span>
              </section>
              {/* submit button*/}
              <div className="submit-form">
                <button
                  type="submit"
                  onClick={handleSubmitForm}
                  disabled={!check_agreement}
                  // style={(check_agreement) ? { background: "#f9cc0b" } : { background: "#bfbaa6" }}
                >{t("register_6")}</button>
              </div>
              {/* recently */}
              {/* <div className="recently">
              <span>
                {t("register_recently")}
                {" "}
                <Link to={"/login"}>{t("register_to_login")}</Link>
              </span>
            </div> */}
              {/*  */}
            </form>


          </section>

          {/* left */}
          <Login_Left />
        </div>

        {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
        {/* <LightingMode bottom="20px" left="30px" /> */}
      </div>
    </HelmetProvider>
  )
}

export default Register
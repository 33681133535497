import { useTranslation } from "react-i18next";
import "./login.css";

const Login_Left = () => {

  const {t}=useTranslation();


  return (
    <section className="login-left">
      <div className="fake">
            
      </div>
      <h2>
        <span>{t("login_left_1")}</span>
        <span>{t("login_left_2")}</span>
      </h2>
      <p>{t("login_left_3")}</p>
    </section>
  )
}

export default Login_Left
import React, { useEffect, useState, useRef } from 'react';
import './PriceSocket.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

import { BASE_URL } from '../../utils/url'


const PriceSocket = () => {
  const [socketData, setSocketData] = useState('');
  const [allCoins, setAllCoins] = useState([]);
  const coinsArrayRef = useRef([]);

  const createWebSocket = () => {
    const socket = new WebSocket('wss://ws.moneyex.org/featured')

    socket.onmessage = (event) => {
      const jsonData = JSON.parse(event.data)

      const allCoinsArray = Array.from(coinsArrayRef.current)
      allCoinsArray.forEach((cn) => {
        if (jsonData.base_coin === cn.name) {
          cn.name = jsonData.base_coin
          cn.price = jsonData.latest_price
          cn.change = jsonData.change
        }
      })

      setSocketData(allCoinsArray)
    }
  }

  useEffect(() => {
    createWebSocket()

    fetch(BASE_URL+'/ticker/featured')
      .then((res) => res.json())
      .then((data) => {
        setAllCoins(data)

        coinsArrayRef.current = [
          ...coinsArrayRef.current,
          ...data.map((i) => ({ name: i.base_coin, price: '', change: '' })),
        ]
      })
  }, [])


  return (
    <section id="socket">
      <div className="all">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={40}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          breakpoints={{
            1200: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 1,
            },
          }}
        >
          {allCoins && allCoins.map((item, index) => {
            const coinData = socketData && socketData.find(i => i.name === item.base_coin) || {};
            const price = !socketData || !coinData.price ? item.latest_price : coinData.price;
            const change = !socketData || !coinData.change ? Math.abs(item.change) : Math.abs(coinData.change);

            return (
              <SwiperSlide key={index}>
                <div className="grid-item">
                  <img
                    src={item.base_coin_icon}
                    className="logo"
                  />
                  <div className="carts">
                    <div className="image" style={{ '--src': `url(${item.base_coin_icon})` }}></div>
                    <div className="time">24h</div>
                    <div className="details dt1">
                      <div className="text"></div>
                      <div>
                        {change < 0 ?
                          <span className="down">{change + '%'}</span>
                          :
                          <span className="up">{change + '%'}</span>
                        }
                        {change > 0 ? <i className='p-up'></i> : change < 0 ? <i className='p-down'></i> : null}
                      </div>
                    </div>
                    <div className="details dt2">
                      <div className="text">{item.base_coin}</div>
                      <div className="number">
                        <div className="number">{price} {item.quote_coin}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default PriceSocket;
import React, { useContext } from "react";
import "./terms.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { MainContext } from "../contexts/MainContexts";

export default function Terms() {
  const { t } = useTranslation();
  const { user_lang } = useContext(MainContext)

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{t("title.3")}</title>
        </Helmet>
        <Header />
        {/* Terms-section-one */}
        <section id="terms-section-one">
          {/* <img className="design1" src={require("../image/terms-page/BG.png")} /> */}
          <div className="all">
            <img src={require("../image/helpcenter-page/Rectangle.png")} />
            <div className="title">{t("terms.1")}</div>
            <div className="des">
              {t("terms.2")}
            </div>
          </div>
        </section>
        {/* Terms-section-two */}
        {user_lang === 'ar' &&
          <section id="terms-section-two">
            <div className="all">
              <div className="title">شرایط و قوانین استفاده کاربران از خدمات پلتفورم مانی‌اکســ و حریم خصوصی</div>
              <p className="des">
                موافقتنامه ی ارایه شده در این صفحه شرایط و قوانین و همچنین حریم خصوصی کاربران متقاضی استفاده از خدمات وبسایت moneyex.org که از این پس " سایت " نامیده می شود ، می باشد.
                <br /><br />
                هر گونه تغییر در مفاد این موافقتنامه که از طرف سایت صورت پذیرد از طریق کانال های رسمی ارتباطی مانی‌اکســ، باطلاع کاربران خواهد رسید .<br />
                کاربران موظفند قبل از ثبت نام و شروع فعالیت در سایت مانی‌اکســ مفاد این توافقنامه را مطالعه و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند .
              </p>
              <div className="title">درباره مانی‌اکســ:</div>
              <p className="des">
                مانی‌اکســ یک اکسچنج (صرافی آنلاین ) رمز ارز و دیگر دارایی های دیجیتال می باشد که کاربران می توانند با عضویت در آن اقدام به تبادل رمز ارزها و دارایی های دیجیتال نمایند .
              </p>
              <p className="des">
                مانی‌اکســ تابع قوانین جمهوری اسلامی ایران بوده به هیچ عنوان هیچگونه فعالیت ارزی، شامل خرید، فروش، تسهیل خرید و فروش، یا خدمات مبتنی بر ارزهای فیات (مانند دلار ، یورو ، درهم و ... ) نداشته و ندارد.
              </p>
              <div className="title">سیاست نامه حریم خصوصی:</div>
              <p className="des">
                سایت مانی‌اکســ برای ارایه هر چه بهتر خدمات خود ناچار به جمع آوری برخی از اطلاعات شخصی کاربران خود می باشد . لذا به منظور آگاهی کاربران از سیاست مانی‌اکســ در این زمینه موارد ذیل به اطلاع می رسد :
              </p>
              <p className="des">
                الف ) کدام اطلاعات توسط مانی‌اکســ جمع آوری می شوند :
              </p>
              <p className="des">
                از آنجاییکه خدمات مانی‌اکســ در ارتباط با دارایی ها دیجیتال کاربران می باشد و به منظور حفاظت و جلوگیری از سواستفاده از آنها اقدام به احراز هویت کاربران می نماید .
              </p>
              <p className="des">
                اطلاعاتی که در هنگام احراز هویت از کاربران اخذ می شود :
              </p>
              <p className="des">
                کد ملی ، تاریخ تولد ، شماره تلفن همراه ، شماره کارت بانکی<br />
                نوع دستگاه ، آدرس آی پی و سیستم عاملی که کاربر با آن وارد مانی‌اکســ می شود .
              </p>
              <p className="des">
                ب ) مانی‌اکســ از اطلاعات کاربران چه استفاده ای می کند :
              </p>
              <p className="des">
                مانی‌اکســ اطلاعات هویتی کاربران را به منظور انجام مراحل احراز هویت در جهت حصول اطمینان از این واقعیت که شخص و یا اشخاص دیگری به حساب کاربری و در نهایت دارایی های او دسترسی نداشته باشد ، جمع آوری و نگه داری می کند .
              </p>
              <p className="des">
                ج ) مانی‌اکســ اطلاعات کاربران را در اختیار چه اشخاصی قرار می دهد ؟
              </p>
              <p className="des">
                اطلاعات شخصی کاربران تنها برای موضوع توافقنامه و در چارچوب آن مورد استفاده قرار خواهد گرفت تا خدمات مانی‌اکســ به طور منظم به کاربران ارائه گردد. اطلاعات شخصی کاربران جز با رضایت جداگانه از طرف شما به اشخاص ثالث ارائه نخواهد شد.
              </p>
              <p className="des">
                نسبت به وقوع احتمالی خسارت ناشی از کلاهبرداری مالی باید خود توجه کافی داشته باشید و اگر کلاهبردار مالی یا الکترونیکی موجب تحقیق از ناحیه‌ی مراجع تحقیقاتی، قضایی، اداری یا نهاد عمومی دیگری شود، می‌توانیم نسبت به تعلیق حساب شما و کیف پول رمزارز به طور همزمان اقدام کنیم.
              </p>
              <p className="des">
                مانی‌اکســ اطلاعات جمع آوری شده از کاربران خود را خارج از قوانین جاری بر کشور در اختیار هیچ شخص حقیقی و یا حقوقی و نهاد دیگری قرار نمی دهد .
              </p>
              <p className="des">
                د) مسئولیت امنیت اطلاعات حساب کاربری :
              </p>
              <p className="des">
                یکی از اصلی ترین اولویت های مانی‌اکســ محافظت از حساب و دارایی های کاربران می باشد لذا برای جلوگیری از دسترسی غیر مجاز به این اطلاعات ، مانی‌اکســ تمهیدات امنیتی لازم را به خدمت گرفته است .
              </p>
              <p className="des">
                حساب کاربری نزد مانی‌اکســ تنها باید توسط همان کاربر مورد استفاده قرار گیرد و نباید تحت هیچ شرایطی به دیگران اجازه داده شود از آن استفاده کنند. لذا در خصوص خسارات ناشی احتمالی از عدم ارایه اطلاعات صحیح و یا صدمه‌ ناشی از استفاده‌ غیر مجاز از حساب توسط افراد شخص ثالث که رمز عبور شما را به سرقت برده است، مانی‌اکســ مسئولیتی ندارد .
              </p>
              <p className="des">
                در این حالت کاربر موظف به جبران خسارت وارده به اشخاص ثالث در اثر کلاهبرداری مالی خواهد بود. علاوه بر آن، در خصوص جبران خسارت شخص ثالث از سوی شما هیچگونه مسئولیتی بر عهده‌ی مانی‌اکســ نخواهد بود.
              </p>
              <p className="des">
                شرایط و قوانین استفاده از خدمات مانی‌اکســ:
              </p>
              <p className="des">
                تمامی فعالیت های صورت گرفته توسط کاربر اعم از ثبت نام ، واریز وجه ، انجام تبادلات صحیح و واقعی تلقی می‌گردد. از این رو حفاظت از حساب کاربری، رمز عبور و دیگر اطلاعات برعهده ایشان بوده و لازم است به صدمات ناشی از انجام تخلفات یا جرایم مالی توجه داشته باشد.<br />
                در مورد رمزارزهایی مانند بیت کوین، صادره کننده‌ای وجود ندارد و لذا هیچ شخص ثالثی از جمله مانی‌اکســ در داخل یا خارج از ایران، ارزش و یا نقد کردن آن را تضمین نمی‌کند. لذا رمز ارزهایی مانند بیت کوین می‌تواند در کشورهای مختلف دارای ارزش های متفاوتی باشد. بنابراین، توجه داشته باشید رمز ارزها، عموماً و برخلاف واحدهای پولی سنتی (فیات)، دارای ریسک ذاتی هستند.<br />
                مانی‌اکســ یک پلتفورم بر خط می باشد و تلاش میکند خدمات خود را به صورت ۲۴ ساعته و در 7 روز هفته و در طول 365 روز سال ارایه دهد. البته این امر را تضمین نمی‌کنیم.<br />
                لیکن در صورت وقوع حادثه‌ای از قبیل تعلیق خدمات به علت اقدام غیر قانونی کاربران یا اشخاص ثالث، یا وقوع خطا در خدمات، مشکل را مرتفع و ارائه‌ی خدمات را از سر می‌گیریم. در این زمان می‌توانید درخواست بازیابی سوابق فعالیت های خود را به ما ارائه کنید. اما مانی‌اکســ نمی تواند زیان احتمالی ناشی از این موضوع را پوشش دهد .<br />
              </p>
              <p className="des">
                در شرایط زیر مانی‌اکســ می تواند در خواست ایجاد حساب کاربری را رد نماید :
              </p>
              <p className="des">
                چنانچه متقاضی به سن قانونی نرسیده (۱۸ سال کامل شمسی) یا از اهلیت قانونی کافی برخوردار نباشد.<br />
                تلاش برای ایجاد حساب کاربری از طریق ارائه‌ی اطلاعات شخصی دیگران توسط شما از قبیل کد ملی ، شماره تلفن همراه ، تاریخ تولد و دیگر اطلاعات هویتی اشخاص دیگر<br />
                عدم وارد کردن اطلاعات لازم یا وارد کردن اطلاعات نادرست در زمان ایجاد حساب از سوی کاربر<br />
                در صورت عدم رعایت سایر قوانین و مقررات از سوی کاربر یا انجام اقداماتی برخلاف استانداردهای که توسط مانی‌اکســ اعلام می‌گردد.<br />
                چنانچه مشخص شود که با نقض هر یک از مفاد این موافقتنامه نسبت به ایجاد حساب اقدام نموده اید، مانی‌اکســ می تواند بلافاصله ممنوعیت‌های مناسب از قبیل قطع امکان استفاده از خدمات برای کاربر و یا حذف حساب کاربران را به مورد اجرا بگذارد.<br />
              </p>
              <p className="des">
                کاربر مانی‌اکســ می پذیرد که :
              </p>
              <p className="des">
                منبع و مقصد کلیه رمزارزها و وجوه نقد ارائه شده توسط وی در تراکنش های سایت کاملا قانونی و مطابق با قوانین بین المللی و مقررات جمهوری اسلامی ایران باشند.<br />
                مالک قانونی وجوه و حساب هایی است که وی در هر سفارش به هر نحو چه برای پرداخت و چه برای دریافت از آنها استفاده کرده است.<br />
                اطلاعاتی که کاربر در خلال سفارش یا ثبت نام در سایت وارد کرده است کاملا محفوظ خواهد ماند و به هیچ شخص ثالثی ارائه نخواهد شد مگر با نامه قضایی و یا درخواست کتبی پلیس محترم فتای جمهوری اسلامی ایران.<br />
                کاربر می پذیرد که اطلاعات لازم در طی انجام معاملات و یا واریز و برداشت رمز ارزها را با دقت وارد کرده و کلیه مسئولیت مربوط به اشتباه وارد کردن اطلاعات با خود وی است و مانی‌اکســ هیچ مسئولیتی راجع به آن ندارد .<br />
                کارمزد مبادلات مانی‌اکســ، در هر سفارش با نرخ مشخص به کاربر و پیش از انجام معامله نشان داده می شود و بعد از انجام موفق معامله از کاربر دریافت می گردد.<br />
                مانی‌اکســ هیچ گونه مسئولیتی راجع به تاخیر یا تراکنش ناموفق ایجاد شده به علت نقص یا مشکل یا بروز رسانی شبکه رمز ارزها و یا بانک پذیرنده را نمی پذیرد.<br />
                هر گونه پیامدهای مالیاتی ناشی از تراکنش های کاربران به عهده خود آنان خواهد بود و مانی‌اکســ هیچ گونه مسئولیتی نمی پذیرد.<br />
              </p>
              <p className="des">
                اعلامیه ها :
              </p>
              <p className="des">
                تمامی اطلاع رسانی های رسمی مانی‌اکســ فقط از طریق کانال های ارتباطی رسمی آن که همگی در وبسایت moneyex.org قید گردیده است انجام خواهد شد و مانی‌اکســ مسئولیتی در قبال اطلاعاتی که از مجاری دیگر ارایه می شود ندارد .
              </p>
              <p className="des">
                سلب مسئولیت :
              </p>
              <p className="des">
                مانی‌اکســ یک بستر آنلاین مبادله رمز ارزها و ارزهای دیجیتال می باشد که هیچگونه مسئولیتی در خصوص نوسانات ارزش رمز ارزها و ارزهای دیجیتال موجود بر روی بازارهای مانی‌اکســ را ندارد. همچنین مانی‌اکســ به هیچ عنوان مسئولیتی در قبال بازخرید رمز ارزها و ارزهای دیجیتال مزبور نداشته و قیمت های مندرج در بازارهای مانی‌اکســ همگی توسط کاربران مانی‌اکســ ثبت و مدیریت می شوند و مانی‌اکســ نقشی در این خصوص ایفا نمی نماید.
              </p>
            </div>
          </section>
        }
        {user_lang === 'en' &&
          <section id="terms-section-two">
            <div className="all">
              <div className="title">{t("terms.3")}</div>
              <p className="des">{t("terms.4")}</p>
            </div>
          </section>
        }
        <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}

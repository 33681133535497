import mySrc from "../../image/price-socket/Empty@2x.png"
import { useTranslation } from "react-i18next";
import './no-data.css'

const NoData = () => {

    const {t}=useTranslation();
    return (
        <div id="no-data" className="text-center py-5">
            <figure className="flexCenter">
                <img src={mySrc} alt="" width={200} />
            </figure>
            <p className="text-center">
               {
                t("noData_1")
               }
            </p>

        </div>
    )
}

export default NoData
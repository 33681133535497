import React, { useEffect, useState } from "react";
import { BASE_URL } from '../../utils/url'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './welcome.css'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


export default function WelcomeCampain() {
    const { t } = useTranslation();
    const [pepePrice, setPepePrice] = useState('-')

    const convertToPersian = (num) => {
        const persianDigits = '۰۱۲۳۴۵۶۷۸۹';
        return num.toString().replace(/\d/g, (d) => persianDigits[d]);
    };

    const calculateTimeLeft = () => {
        const totalSeconds = Math.floor((new Date(2024, 5, 22) - Date.now()) / 1000);

        const days = Math.floor(totalSeconds / (3600 * 24));
        const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return {
            days: convertToPersian(days < 10 ? '0' + days : days),
            hours: convertToPersian(hours < 10 ? '0' + hours : hours),
            minutes: convertToPersian(minutes < 10 ? '0' + minutes : minutes),
            seconds: convertToPersian(seconds < 10 ? '0' + seconds : seconds),
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: '۱. چگونه می‌توانیم موجودی PEPE خودمان را برداشت کنیم؟',
            answer: 'شما با رسیدن موجودی کیف پولتان به دو میلیون تومان، می‌توانید از طریق داشبورد خود در سایت موجودی خود را برداشت کنید.',
        },
        {
            question: '۲. چگونه می‌توانیم دوستان خودمان را دعوت کنیم؟',
            answer: 'با مراجعه به قسمت «دعوت دوستان» در داشبوردتان در سایت، کد معرفی خود را به دوستانتان دهید تا با آن ثبت نام کنند.',
        },
        {
            question: '۳. رمزارز PEPE چیست؟',
            answer: 'پپه با نماد اختصاری PEPE یک ارز دیجیتال یا شکلی از دارایی دیجیتال است که با ارزش بازار حدود ۳.۳۶ میلیارد دلار، در رتبه ۳۸ بازار قرار دارد. هر واحد از پپه در این لحظه با قیمت ' + pepePrice + ' دلار معامله می‌شود.',
        },
    ];

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        fetch(BASE_URL + '/swap/pair-ticker-info/PEPE_USDT')
            .then(res => res.json())
            .then(data => setPepePrice(data.latest_price))
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <title>به مانی‌اکســ خوش آمدید!</title>
            </Helmet>

            <div
                id="welcome"
                className="overflow-x-hidden min-h-screen bg-[#061123] pb-[450px] max-lg:pb-[600px] relative"
            >
                <nav
                    style={{ backdropFilter: "blur(5px)", borderBottom: "1px solid #fff1" }}
                    className="fixed top-0 left-0 flex justify-between items-center p-3 w-full px-32 max-lg:px-2 z-50"
                >
                    <a
                        id="start_btn2"
                        href="https://moneyex.org/ref/F1rlu3yZNcTv7gVl"
                        className="flex flex-row-reverse w-fit bg-[#f6b33f] items-center px-4 rounded-full group text-black transition h-[43px]"
                        style={{ boxShadow: "0 3px 0 #f6b33f7c" }}
                    >
                        <span className="max-lg:hidden">از اینجا شروع کنید</span>
                        <span className="lg:hidden">شروع</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                            width={20}
                            fill="#000"
                            className="mr-1 group-hover:-translate-x-1 group-hover:scale-x-110 transition"
                        >
                            <path d="M520-200 80-480l440-280-137 240h497v80H383l137 240Z" />
                        </svg>
                    </a>
                    <div
                        className="flex items-center gap-[.3rem] bg-[#020914] w-fit px-5 rounded-full h-[43px]"
                        style={{ direction: "rtl", boxShadow: "0 3px 0 #0004" }}
                    >
                        <span>قیمت</span>
                        <span className="text-[#4c9641] max-lg:hidden">PEPE</span>
                        <img
                            src={require("./images/pepe.png")}
                            width={30}
                            style={{ border: "1px solid gold", borderRadius: "50%" }}
                        />
                        :
                        <span id="price" className="max-lg:text-sm" />
                        {pepePrice}{" "}
                        دلار
                    </div>
                </nav>
                <div className="pt-52 w-fit mx-auto relative max-w-[calc(100vw-75px)]">
                    <h1 className="text-center text-4xl max-lg:text-3xl font-bold relative px-5">
                        جشنواره <span className="max-lg:hidden">آغاز به کار</span>{" "}
                        <span id="mojbit" className="text-[#f6b33f]">
                            مانی‌اکســ
                        </span>
                        <img
                            src={require("./images/2.gif")}
                            width={100}
                            className="absolute left-[-5.6rem] bottom-0 -rotate-90 max-lg:w-[80px] max-lg:left-[-4rem]"
                        />
                        <img
                            src={require("./images/2.gif")}
                            width={100}
                            className="absolute right-[-5.2rem] bottom-0 max-lg:w-[80px] max-lg:right-[-3.7rem]"
                        />
                    </h1>
                </div>
                <div className="relative mt-20 w-fit mx-auto max-w-[90vw]">
                    <h2
                        style={{ direction: "rtl" }}
                        className="text-5xl max-lg:text-3xl font-bold relative z-10"
                    >
                        <span>
                            با ثبت نام در مانی‌اکســ{" "}
                            <span id="five" className="text-[#4c9641] font-bold">
                                ۴۰۰,۰۰۰
                            </span>{" "}
                            عدد
                            {" "}
                        </span>
                        <img
                            src={require("./images/pepe.png")}
                            width={60}
                            title="PEPE"
                            style={{
                                border: "1px solid gold",
                                borderRadius: "50%",
                                display: "inline"
                            }}
                        />
                        {" "}
                        <span>دریافت می‌کنید</span>!
                    </h2>
                    <h2
                        style={{ direction: "rtl" }}
                        className="text-5xl max-lg:text-3xl font-bold absolute left-0 top-1 max-lg:top-[.1rem] opacity-20"
                    >
                        <span>
                            با ثبت نام در مانی‌اکســ{" "}
                            <span id="five" className="text-[#4c9641] font-bold">
                                ۴۰۰,۰۰۰
                            </span>{" "}
                            عدد
                            {" "}
                        </span>
                        <img
                            src={require("./images/pepe.png")}
                            width={60}
                            title="PEPE"
                            style={{
                                border: "1px solid gold",
                                borderRadius: "50%",
                                display: "inline"
                            }}
                        />
                        {" "}
                        <span>دریافت می‌کنید</span>!
                    </h2>
                </div>
                <div className="relative mt-14 w-fit mx-auto max-w-[90vw]">
                    <h2
                        style={{ direction: "rtl" }}
                        className="text-2xl font-bold relative z-10"
                    >
                        <span>
                            و با دعوت از دوستانتان به مانی‌اکســ، شما{" "}
                            <span id="five" className="text-[#4c9641] font-bold">
                                ۶۰,۰۰۰
                            </span>
                            {" "}
                        </span>
                        <img
                            src={require("./images/pepe.png")}
                            width={40}
                            title="PEPE"
                            style={{
                                border: "1px solid gold",
                                borderRadius: "50%",
                                display: "inline"
                            }}
                        />
                        {" "}
                        و دوستتان
                        {" "}
                        <span id="five" className="text-[#4c9641] font-bold">
                            ۴۰۰,۰۰۰
                        </span>
                        {" "}
                        <img
                            src={require("./images/pepe.png")}
                            width={40}
                            title="PEPE"
                            style={{
                                border: "1px solid gold",
                                borderRadius: "50%",
                                display: "inline"
                            }}
                        />
                        {" "}
                        <span>دریافت خواهید کرد</span>!
                    </h2>
                    <h2
                        style={{ direction: "rtl" }}
                        className="text-2xl font-bold absolute left-0 top-[.2rem] max-lg:top-[.1rem] opacity-20"
                    >
                        <span>
                            و با دعوت از دوستانتان به مانی‌اکســ، شما{" "}
                            <span id="five" className="text-[#4c9641] font-bold">
                                ۶۰,۰۰۰
                            </span>
                            {" "}
                        </span>
                        <img
                            src={require("./images/pepe.png")}
                            width={40}
                            title="PEPE"
                            style={{
                                border: "1px solid gold",
                                borderRadius: "50%",
                                display: "inline"
                            }}
                        />
                        {" "}
                        و دوستتان
                        {" "}
                        <span id="five" className="text-[#4c9641] font-bold">
                            ۴۰۰,۰۰۰
                        </span>
                        {" "}
                        <img
                            src={require("./images/pepe.png")}
                            width={40}
                            title="PEPE"
                            style={{
                                border: "1px solid gold",
                                borderRadius: "50%",
                                display: "inline"
                            }}
                        />
                        {" "}
                        <span>دریافت خواهید کرد</span>!
                    </h2>
                    <a
                        id="start_btn"
                        href="https://moneyex.org/ref/F1rlu3yZNcTv7gVl"
                        className="flex flex-row-reverse mx-auto w-fit bg-[#f6b33f] p-3 px-6 rounded-full mt-20 group text-black"
                        style={{ boxShadow: "0 4px 0 #f6b33f7c" }}
                    >
                        از اینجا شروع کنید
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                            width={20}
                            fill="#000"
                            className="mr-1 group-hover:-translate-x-1 group-hover:scale-x-110 transition"
                        >
                            <path d="M520-200 80-480l440-280-137 240h497v80H383l137 240Z" />
                        </svg>
                    </a>
                    <p
                        style={{ direction: "rtl" }}
                        className="mt-24 !font-normal p-4 bg-red-950 rounded-lg w-fit mx-auto relative z-10"
                    >
                        با رسیدن موجودی کیف پول به{" "}
                        <span className="!font-bold">۲ میلیون تومان</span> می‌توانید اقدام به
                        برداشت آن کنید.
                    </p>
                </div>
                <div
                    id="calendar"
                    className="w-fit mx-auto mt-20 p-7 pb-5 rounded-[1.5rem] relative pt-[4.7rem] max-w-[90vw]"
                    style={{ boxShadow: "0 10px 0 #0005" }}
                >
                    <p className="text-xl mb-6">تا پایان کمپین</p>
                    <div className="flex gap-2 flex-wrap">
                        <div className="p-5 flex flex-col gap-1 bg-[#091324] w-20 rounded-lg">
                            <div>{timeLeft.days}</div>
                            <span>روز</span>
                        </div>
                        <div className="p-5 flex flex-col gap-1 bg-[#091324] w-20 rounded-lg">
                            <div>{timeLeft.hours}</div>
                            <span>ساعت</span>
                        </div>
                        <div className="p-5 flex flex-col gap-1 bg-[#091324] w-20 rounded-lg">
                            <div>{timeLeft.minutes}</div>
                            <span>دقیقه</span>
                        </div>
                        <div className="p-5 flex flex-col gap-1 bg-[#091324] w-20 rounded-lg max-lg:hidden">
                            <div>{timeLeft.seconds}</div>
                            <span>ثانیه</span>
                        </div>
                    </div>
                    <img
                        src={require("./images/blur_circle.png")}
                        width={600}
                        className="absolute -left-[130%] -top-[120%] max-w-none max-lg:-top-[210%]"
                    />
                    <img
                        src={require("./images/blur_circle.png")}
                        width={600}
                        className="absolute -right-[130%] top-[20%] max-w-none max-lg:top-[100%]"
                    />
                </div>
                <div className="flex flex-col gap-7 mt-40 mb-10 relative max-w-[90vw] mx-auto">
                    <h3 className="text-3xl">سوالات متداول</h3>
                    <div
                        style={{ direction: "rtl" }}
                        className="flex flex-col gap-2 mx-auto w-[600px] max-w-[90vw]"
                    >
                        {faqs.map((faq, index) => (
                            <div
                                key={index}
                                className="row bg-[#020914] rounded-2xl p-5 py-6 cursor-pointer"
                                onClick={() => toggleAnswer(index)}
                            >
                                <div className="flex items-center gap-4 max-lg:gap-3">
                                    <i className={openIndex !== index ? 'minusAnim' : 'plusAnim'}></i>
                                    <p>{faq.question}</p>
                                </div>
                                <p className={`!font-normal text-justify mt-7 ${openIndex === index ? '' : 'hidden'}`}>
                                    {faq.answer}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div
                    id="team"
                    className="absolute bottom-0 left-1/2 -translate-x-1/2 overflow-hidden"
                >
                    <img src={require("./images/Mojbit-team.png")} className="max-lg:w-[600px]" />
                    <img src={require("./images/8.gif")} width={390} className="absolute right-0 top-0" />
                    <img src={require("./images/8.gif")} width={390} className="absolute left-0 top-0" />
                    <img
                        src={require("./images/8.gif")}
                        width={390}
                        className="absolute left-[300px] top-0"
                    />
                </div> */}
                <footer className="absolute bottom-0 left-1/2 -translate-x-1/2 overflow-hidden bg-black" style={{direction: 'rtl'}}>
                    <div class="all">
                        <Link class="logo" style={{top:'-65px'}}>
                            <img src={require("../../image/logo/_company_logo.png")} />
                        </Link>

                        <div class="all-grid" style={{marginBottom: '20px'}}>
                            <div class="grid-items l1">
                                <div class="list ">
                                    <div class="title" style={{textAlign: 'right'}}>{t("footer.1")}</div>
                                    <p class="des" style={{width:'500px',maxWidth:'88vw'}}>{t("footer.2")}</p>
                                </div>
                            </div>
                            <div class="grid-items">
                                <div class="list">
                                    <div class="title" style={{textAlign: 'right'}}>{t("footer.13")}</div>
                                    <ul class="df">
                                        <li>
                                            <a href="tel:02177884862" className="!text-white">02177884862</a>
                                        </li>
                                        <li>
                                            <a href="mailto:support@moneyex.org" className="!text-white">{t("footer.15")}</a>
                                        </li>
                                        <li style={{textAlign:'right'}}>{t("footer.16")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="details" className="p-3 px-4 rounded-full" style={{background: '#111'}}>
                            <div class="left">
                                <svg fill="#fff"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="copyright"
                                    width="35.25"
                                    height="35.25"
                                    viewBox="0 0 20.25 20.25"
                                >
                                    <path
                                        id="Path_436"
                                        data-name="Path 436"
                                        d="M18.12,14.741l1.6-1.6a4.5,4.5,0,1,0,.2,6.523l-1.478-1.477H18.2a2.25,2.25,0,1,1-.082-3.451Z"
                                        transform="translate(-6.429 -6.375)"
                                    />
                                    <path
                                        id="Path_437"
                                        data-name="Path 437"
                                        d="M14.625,4.5A10.125,10.125,0,1,1,4.5,14.625,10.125,10.125,0,0,1,14.625,4.5Zm0,2.25A7.875,7.875,0,1,1,6.75,14.625,7.875,7.875,0,0,1,14.625,6.75Z"
                                        transform="translate(-4.5 -4.5)"
                                        fill-rule="evenodd"
                                    />
                                </svg>
                                <div class="text">{t("footer.17")}</div>
                            </div>

                            <div class="right">
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </HelmetProvider>
    )
}
//internal
import { useContext, useEffect, useState } from "react";
//external
import axios from "axios";
import Cookies from "js-cookie";
import { MainContext } from "../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation, useFetcher } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronDown, FaChevronLeft,FaChevronRight } from "react-icons/fa";
import NoData from '../../components/no-data/NoData'

//Component and Style and Image
import SingleNotification from "../single-notification/SingleNotification";
import "./nav-desktop.css";
import "./lightMode.css"

import { BASE_URL } from '../../utils/url'

const Nav_Desktop = ({ desktop_ProfileMenu,
                          desktop_Notifications,
                          desktop_ThreeDots,
                          setDesktop_Notifications,
                          setDesktop_ThreeDots,
                          setDesktop_ProfileMenu }) => {




  const navigate = useNavigate();
  const location = useLocation();
  const baseURL = BASE_URL
  const { t } = useTranslation();
  const { lightingMode, primary_dir, user_lang, authorization_status, user_name, setAuthorization_status, setUser_name } = useContext(MainContext);


  // اینجا نام کاربری کاربر را در کانتکست ذخیره میکنیم تا از روی آن بخوانیم، تا با هر بار فچ دیتا پرش نداشته باشیم در ابتدای لود صفحه
  const [userData, setUserData] = useState({});
  const userName = userData && userData.profile ? (userData.profile?.first_name + " " + userData.profile?.last_name) : userData.mobile
  if (userName) setUser_name(userName)


  //اعلان های خوانده نشده
  const [unread_notif, setUnread_notif] = useState([]);


  //mounting
  useEffect(() => {
    if (!Cookies.get("access_token")) {
      navigate("/judge", { replace: true })
    }


    const fetchData = async () => {
      try {
        const res_profile = await axios.get(`${baseURL}/user/profile`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserData(res_profile.data);

        const res_notifications = await axios.get(`${baseURL}/user/notifications/latest-unread`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUnread_notif(res_notifications.data.data)




        



      } catch (error) {
        
      }
    }//end of method
    fetchData();


    // برای بررسی وضعیت احراز هویت
    fetch(BASE_URL + '/user/kyc/latest', {
      headers: {
          'Authorization': `Bearer ${Cookies.get('access_token')}`,
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.code && data.code === 1001) navigate("/verification", { replace: true })
        
        if (data.data) setAuthorization_status(data.data.status)
      })

  }, [])


  //updating
  useEffect(() => {
    const fetchData = async () => {
      try {



        const res_notifications = await axios.get(`${baseURL}/user/notifications/latest-unread`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUnread_notif(res_notifications.data.data)


      } catch (error) {
        // console.log(error.message)
      }
    }//end of method
    fetchData();
  }, [desktop_Notifications])


  const log_Out = () => {
    Cookies.remove("access_token")
    navigate("/")
  }


  return (
    <nav dir={primary_dir} className="desktop-nav h-[50px]  justify-between mb-5 hidden lg:flex">
      {/* سمت راست  */}
      <div className="flex gap-x-3">
        {/* پشتیبانی */}
        <section dir={primary_dir}
          className="flex items-center justify-center gap-x-2 h-[42px] w-[197px]"
          style={{ borderRadius: "10px" }}>
          {/* svg */}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.114" height="18.103" viewBox="0 0 20.114 18.103">
              <path id="support_agent_FILL0_wght400_GRAD0_opsz48" d="M89.806,234.1a.728.728,0,0,1-.537-.218.736.736,0,0,1-.217-.541.722.722,0,0,1,.217-.536.736.736,0,0,1,.537-.214H97.6v-7.643a6.547,6.547,0,0,0-.629-2.8,8.062,8.062,0,0,0-1.685-2.376,8.389,8.389,0,0,0-2.414-1.647,6.744,6.744,0,0,0-5.632,0,8.39,8.39,0,0,0-2.414,1.647,8.062,8.062,0,0,0-1.685,2.376,6.547,6.547,0,0,0-.629,2.8v5.632a.494.494,0,0,1-.5.5A2.017,2.017,0,0,1,80,229.074v-2.011a1.873,1.873,0,0,1,.277-1.018,2.208,2.208,0,0,1,.729-.717L81.081,224a9.122,9.122,0,0,1,1.043-3.319,8.835,8.835,0,0,1,2.037-2.514,9.152,9.152,0,0,1,2.741-1.6,9.153,9.153,0,0,1,6.336,0,8.853,8.853,0,0,1,2.728,1.609,9.183,9.183,0,0,1,2.024,2.514,8.98,8.98,0,0,1,1.043,3.281l.075,1.307a1.829,1.829,0,0,1,1.006,1.634v2.313a1.765,1.765,0,0,1-.277.981,1.828,1.828,0,0,1-.729.654v1.735A1.513,1.513,0,0,1,97.6,234.1Zm-2.766-7.292a.724.724,0,0,1-.528-.226.738.738,0,0,1-.226-.541.7.7,0,0,1,.226-.528.76.76,0,0,1,.541-.214.712.712,0,0,1,.528.217.767.767,0,0,1,0,1.065A.715.715,0,0,1,87.04,226.812Zm6.034,0a.724.724,0,0,1-.528-.226.738.738,0,0,1-.226-.541.7.7,0,0,1,.226-.528.76.76,0,0,1,.541-.214.712.712,0,0,1,.528.217.767.767,0,0,1,0,1.065A.715.715,0,0,1,93.074,226.812ZM84.048,225.5a5.767,5.767,0,0,1,.415-2.69,6.223,6.223,0,0,1,1.383-2.049,6.249,6.249,0,0,1,4.262-1.747,5.444,5.444,0,0,1,3.847,1.446,6.12,6.12,0,0,1,1.911,3.608A7.271,7.271,0,0,1,91.7,222.8a8.368,8.368,0,0,1-2.778-3.231,8.03,8.03,0,0,1-1.7,3.608A7.824,7.824,0,0,1,84.048,225.5Z" transform="translate(-80 -216)" fill={lightingMode === "light" ? "black" : "white"} />
            </svg>
          </span>

          <div dir={primary_dir} className="text-sm toggleColor">
            <span>{t("nav_Desktop_10")}</span>
            {" "}
            <span>:</span>
            {" "}
            <span className="ltr" dir="ltr" style={{direction:"ltr"}}>
              <a href="callto:09056915681">09056915681</a>
            </span>
          </div>
        </section>

        {/* زنگوله اعلانات */}
        <section
          className="notifications h-[42px] w-[42px] rounded-xl flexCenter">
          <span onClick={() => setDesktop_Notifications(prev => !prev)}
            className="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="17.553" height="19.744" viewBox="0 0 17.553 19.744">
              <path id="notifications_active_FILL0_wght400_GRAD0_opsz48_3_" data-name="notifications_active_FILL0_wght400_GRAD0_opsz48 (3)" d="M125.613,183.725a.7.7,0,0,1-.531-.222.647.647,0,0,1-.185-.518,8.667,8.667,0,0,1,.8-3.048,8.535,8.535,0,0,1,1.839-2.554.779.779,0,0,1,1.086.074.709.709,0,0,1,.185.518.758.758,0,0,1-.234.518,7.3,7.3,0,0,0-1.493,2.061,7.1,7.1,0,0,0-.679,2.431.894.894,0,0,1-.271.531A.731.731,0,0,1,125.613,183.725Zm16.116,0a.69.69,0,0,1-.506-.21.928.928,0,0,1-.259-.531,7.292,7.292,0,0,0-2.122-4.492.757.757,0,0,1-.234-.518.709.709,0,0,1,.185-.518.732.732,0,0,1,.531-.259.683.683,0,0,1,.531.21,9,9,0,0,1,1.8,2.554,8.6,8.6,0,0,1,.79,3.023.647.647,0,0,1-.185.518A.7.7,0,0,1,141.729,183.725ZM126.5,192.782a.741.741,0,1,1,0-1.481h1.333v-7.552a5.99,5.99,0,0,1,1.222-3.665,5.229,5.229,0,0,1,3.221-2.061v-.716a1.212,1.212,0,0,1,.407-.938,1.469,1.469,0,0,1,1.95,0,1.212,1.212,0,0,1,.407.938v.716a5.279,5.279,0,0,1,3.233,2.061,5.951,5.951,0,0,1,1.234,3.665V191.3h1.308a.741.741,0,1,1,0,1.481ZM133.659,185.5Zm0,10.242a1.914,1.914,0,0,1-1.382-.58,1.882,1.882,0,0,1-.592-1.394h3.949a1.98,1.98,0,0,1-1.974,1.974Zm-4.344-4.442h8.712v-7.552a4.266,4.266,0,0,0-1.259-3.122,4.409,4.409,0,0,0-6.195,0,4.266,4.266,0,0,0-1.259,3.122Z" transform="translate(-124.895 -176)" fill={lightingMode === "light" ? "black" : "white"} />
            </svg>
          </span>
          {
            desktop_Notifications &&
            (
              <ul className="notification-list">

                {
                  unread_notif && unread_notif.map(notif =>
                  (
                    <SingleNotification key={notif.id} notif={notif} />
                  ))
                }

                {
                  unread_notif.length === 0 && <NoData />
                }



              </ul>
            )
          }
        </section>

        {/* threeDots سه نقطه*/}
        <section
          onClick={() => setDesktop_ThreeDots(prev => !prev)}
          className="three_dots h-[42px] w-[42px] rounded-xl flexCenter bg-white ">
          <span>
            <BsThreeDotsVertical />
          </span>
          {
            desktop_ThreeDots &&
            (
              <ul className="list_three_dots">

                <li>
                  <Link to="/">
                    <span>{t("nav_Desktop_1")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/markets">
                    <span>{t("nav_Desktop_2")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/exchange">
                    <span>{t("nav_Desktop_3")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/helpcenter">
                    <span>{t("nav_Desktop_4")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/swap">
                    <span>{t("nav_Desktop_5")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                <li>
                  <Link href="/blog">
                    <span>{t("nav_Desktop_6")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus">
                    <span>{t("nav_Desktop_7")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <span>{t("nav_Desktop_8")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/terms">
                    <span>{t("nav_Desktop_9")}</span>
                    <span>{primary_dir==="rtl" ? <FaChevronLeft /> :<FaChevronRight />}</span>
                  </Link>
                </li>
              </ul>
            )
          }
        </section>

      </div>
      {/* nav left */}
      <div className="flex gap-x-3 items-center">

        {/* احراز هویت */}
        {user_lang === 'ar' && location.pathname !== '/authorization' &&
          <>
            {authorization_status === '' && <button onClick={() => navigate('/authorization')} className="bg-red-500 text-white rounded p-1 px-2 hover:bg-red-600">
              احراز هویت
            </button>}
            {authorization_status === 'declined' && <button title="برای مشاهده توضیحات کلیک کنید" onClick={() => navigate('/authorization')} className="bg-red-500 text-white rounded p-1 px-2 hover:bg-red-600 flex gap-[2px] items-center">
              احراز هویت رد شده
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="20" fill="#fff"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
            </button>}
            {(authorization_status === 'reviewing' || authorization_status === 'pending') && <button className="text-white bg-orange-500 cursor-default rounded p-1 px-2">
              احراز هویت در حال بررسی
            </button>}
            {authorization_status === 'expired' && <button onClick={() => navigate('/authorization')} className="bg-red-500 text-white rounded p-1 px-2 hover:bg-red-600">
              احراز هویت مجدد
            </button>}
            {authorization_status === 'verified' && lightingMode === 'light' && <button className="text-[var(--maincolor3)] border border-dashed border-[var(--maincolor3)] rounded p-1 px-2 bg-white cursor-default flex gap-[2px] items-center">
              احراز هویت شده
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="22" fill="var(--maincolor3)"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>
            </button>}
            {authorization_status === 'verified' && lightingMode === 'dark' && <button className="text-[var(--maincolor3)] border border-dashed border-[var(--maincolor3)] rounded p-1 px-2 bg-[#1d1d1d] cursor-default flex gap-[2px] items-center">
              احراز هویت شده
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="22" fill="var(--maincolor3)"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>
            </button>}
          </>
        }

        <span onClick={() => setDesktop_ProfileMenu(prev => !prev)}
         className=" w-[42px] h-[42px] flexCenter rounded-xl cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="17.813" height="17.285" viewBox="0 0 17.813 17.285">
            <path id="person_FILL1_wght400_GRAD0_opsz48" d="M168.907,283.35a4,4,0,1,1,3.006-1.169A4.069,4.069,0,0,1,168.907,283.35Zm-7.237,8.935a1.663,1.663,0,0,1-1.67-1.67v-.946a3.063,3.063,0,0,1,.529-1.809,3.4,3.4,0,0,1,1.364-1.141,20.923,20.923,0,0,1,3.577-1.253,14.459,14.459,0,0,1,3.437-.417,13.849,13.849,0,0,1,3.424.431,23.7,23.7,0,0,1,3.563,1.239,3.356,3.356,0,0,1,1.392,1.141,3.063,3.063,0,0,1,.529,1.809v.946a1.663,1.663,0,0,1-1.67,1.67Z" transform="translate(-160 -275)" fill="#00c0af" />
          </svg>
        </span>

        <span onClick={() => setDesktop_ProfileMenu(prev => !prev)}
         className="text-sm ltr cursor-pointer">{user_name}</span>
        {/* arrow_bottom */}
        <span onClick={() => setDesktop_ProfileMenu(prev => !prev)}
          className="block cursor-pointer arrow_bottom">
          <FaChevronDown />
          {
            desktop_ProfileMenu &&
            (
              <ul style={primary_dir === "rtl" ? { left: "0" } : { right: "0" }}
                className="profile_menu flexCenter flex-wrap px-6 ">
                <li onClick={() => navigate("/setting")}
                  className="w-full flexCenter gap-x-2 mb-3 cursor-pointer">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.537" height="17.987" viewBox="0 0 18.537 17.987">
                      <path id="person_FILL0_wght400_GRAD0_opsz48_2_" data-name="person_FILL0_wght400_GRAD0_opsz48 (2)" d="M169.269,283.689a4.167,4.167,0,1,1,3.128-1.217A4.234,4.234,0,0,1,169.269,283.689Zm7.531,9.3H161.738A1.743,1.743,0,0,1,160,291.249v-.985a3.188,3.188,0,0,1,.55-1.883,3.533,3.533,0,0,1,1.419-1.188,21.768,21.768,0,0,1,3.722-1.3,15.044,15.044,0,0,1,3.577-.434,14.412,14.412,0,0,1,3.563.449,24.237,24.237,0,0,1,3.705,1.295,3.5,3.5,0,0,1,1.454,1.187,3.18,3.18,0,0,1,.547,1.878v.985a1.743,1.743,0,0,1-1.738,1.738Zm-15.061-1.738H176.8v-.985a1.587,1.587,0,0,0-.275-.883,1.744,1.744,0,0,0-.681-.623,15.381,15.381,0,0,0-3.389-1.231,14.984,14.984,0,0,0-3.186-.333,15.392,15.392,0,0,0-3.215.333,14.711,14.711,0,0,0-3.389,1.231,1.743,1.743,0,0,0-.927,1.506Zm7.531-9.3a2.526,2.526,0,1,0-1.868-.739A2.532,2.532,0,0,0,169.269,281.951ZM169.269,279.345ZM169.269,291.249Z" transform="translate(-160 -275)" fill={lightingMode === "light" ? "black" : "white"} />
                    </svg>
                  </span>

                  <span>{t("nav_Desktop_a1")}</span>
                </li>
                <li onClick={log_Out}
                  className="w-full flexCenter gap-x-2 cursor-pointer">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.801" height="19.119" viewBox="0 0 18.801 19.119">
                      <path id="logout_FILL0_wght400_GRAD0_opsz48_1_" data-name="logout_FILL0_wght400_GRAD0_opsz48 (1)" d="M133.941,229.623a.787.787,0,0,1-.239-.578.744.744,0,0,1,.239-.564l2.124-2.124h-8.5a.768.768,0,0,1-.568-.23.778.778,0,0,1-.229-.571.762.762,0,0,1,.229-.566.777.777,0,0,1,.568-.226h8.444l-2.151-2.151a.735.735,0,0,1-.212-.543.773.773,0,0,1,.245-.571.735.735,0,0,1,.561-.24.784.784,0,0,1,.575.239l3.532,3.532a.74.74,0,0,1,.239.561.77.77,0,0,1-.053.289.809.809,0,0,1-.186.266l-3.505,3.505a.737.737,0,0,1-.544.212A.779.779,0,0,1,133.941,229.623Zm-12.348,5.5A1.634,1.634,0,0,1,120,233.526V217.593A1.634,1.634,0,0,1,121.593,216h6.931a.769.769,0,0,1,.568.23.778.778,0,0,1,.229.571.762.762,0,0,1-.229.566.777.777,0,0,1-.568.226h-6.931v15.933h6.931a.769.769,0,0,1,.568.23.778.778,0,0,1,.229.571.762.762,0,0,1-.229.566.777.777,0,0,1-.568.226Z" transform="translate(-120 -216)" fill="#f63f3f" />
                    </svg>

                  </span>
                  <span style={{ color: "#f63f3f" }}>{t("nav_Desktop_a2")}</span>
                </li>
              </ul>
            )
          }
        </span>

      </div>
    </nav>
  )
}

export default Nav_Desktop;
//internal
import { useState,useEffect } from "react";
import { Routes, Route } from "react-router-dom";
//external
import Cookies from "js-cookie";
import { MainContext } from "./contexts/MainContexts";
import { ToastContainer } from "react-toastify";
import i18next from "i18next";



//Component and Style
import "./App.css";

import Home from "./pages/Home";
import Helpcenter from "./pages/Helpcenter";
import HelpcenterB from "./pages/HelpcenterB";
import Aboutus from "./pages/Aboutus";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Page404 from "./pages/Page404";
import Page504 from "./pages/Page504";

import Transaction from "./pages/transactions/Transaction";
import TransactionReturn from "./pages/transactions/transaction-return/TransactionReturn";
import Agree from "./pages/Agree";
import Setting from "./pages/Setting";
import Markets from "./pages/Markets";
import PriceSocket from './components/price-socket/PriceSocket.jsx'
import Exchange from "./pages/Exchange.jsx";
import Records from "./pages/Records.js"
import Order from "./pages/Order"
import Authorization from "./pages/Authorization.jsx"

import ApiDoc from "./pages/api-doc/ApiDoc";


//صفحات مهدی
//صفحات مهدی
import Judge from "./pages/auth/worod/Judge.jsx";
import Login from './pages/auth/login/Login.jsx';
import Register from './pages/auth/register/Register.jsx';
import Forget from './pages/auth/forget/Forget';
import NewPassword from './pages/auth/new-password/NewPassword';
import Verification_one from "./pages/auth/verification-one/Verification_one.jsx";
import Verification_two from "./pages/auth/verification-two/Verification_two.jsx";
import Verification_Login from "./pages/auth/verification-for-login/Verification_Login";

import Dashboard from './pages/dashboard/Dashboard';
import Wallet from './pages/wallet/Wallet.jsx';
import Xchange_Deposit from "./pages/xchange-deposit/Xchange_Deposit.jsx";
import Xchange_Withdraw from "./pages/xchange-withdraw/Xchange_Withdraw.jsx";
import Wallet_Rial_Deposit from "./pages/wallet-rial-deposit/Wallet_Rial_Deposit.jsx";
import Wallet_Rial_Withdraw from "./pages/wallet-rial-withdraw/Wallet_Rial_Withdraw.jsx";
import Swap from "./pages/swap/Swap.jsx"
import Invite_Friends from "./pages/invite-friends/Invite_Friends.jsx";
import Reports from "./pages/reports/Reports.jsx";
//پایان


import Blog from "./pages/blog/Blog.jsx";
import SingleBlog from "./pages/single-blog/SingleBlog.jsx";
import Ref from "./pages/ref/Ref.jsx"


import WelcomeCampaign from './pages/welcome-campain/welcomeCampain.jsx'







function App() {


  const [lightingMode, setLightingMode] = useState(Cookies.get("lightingMode"));
  const [user_lang, setUser_lang] = useState(Cookies.get("user_lang"));
  const [user_phone, setUser_phone] = useState("");
  const [primary_dir, setPrimary_dir] = useState(Cookies.get("primary_dir"));
  const [reverse_dir, setReverse_dir] = useState(Cookies.get("reverse_dir"));
  const [authorization_status, setAuthorization_status] = useState('')
  const [user_name, setUser_name] = useState('')
  const [user_USDT_balance, setUser_USDT_balance] = useState('')

// //////////////////////////////////////////////
  // useEffect mounting
  useEffect(() => {
    if (Cookies.get('user_lang') === 'en') {
      i18next.changeLanguage("ar");
      Cookies.set("user_lang","ar",{ expires: 365 });
      setUser_lang("ar");
    }
    
    //اگر کوکی زبان کاربر نداشتیم
    if(!Cookies.get("user_lang")){
         //1.one  بسیار مهم
         i18next.changeLanguage("ar");
         
         //2.two
         Cookies.set("user_lang","ar",{ expires: 365 });
         setUser_lang("ar");
         //3.three
         Cookies.set("primary_dir","rtl",{ expires: 365 })
         setPrimary_dir("rtl")
         //4.four
         Cookies.set("reverse_dir","ltr",{ expires: 365 }) 
         setReverse_dir("ltr")
         //5.five
         Cookies.set("lightingMode","light",{ expires: 365 }) 
         setLightingMode("light")

         
    }else{
      
    }

    
  }, [])

  return (
    
    <MainContext.Provider
      value={{
        user_phone,
        user_lang,
        primary_dir,
        reverse_dir,
        lightingMode,
        authorization_status,
        user_name,
        user_USDT_balance,

        setUser_phone,
        setUser_lang,
        setPrimary_dir,
        setReverse_dir,
        setLightingMode,
        setAuthorization_status,
        setUser_name,
        setUser_USDT_balance
      }}
    >
      <div id={lightingMode} dir={primary_dir} className={user_lang}
        // style={{minWidth:'100%',minHeight:'100%'}}
      >
        
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/helpcenter" element={<Helpcenter />} />
          <Route path="/helpcenter2" element={<HelpcenterB />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/504" element={<Page504 />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/transactions/:transaction_id" element={<TransactionReturn />} />
          <Route path="/agree" element={<Agree />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/markets" element={<Markets />} />
          {/* صفحات مهدی */}
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forget' element={<Forget />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/verification-one' element={<Verification_one />} />
          <Route path='/verification-two' element={<Verification_two />} />
          <Route path='/verification' element={<Verification_Login />} />
          <Route path='/judge' element={<Judge />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/wallet' element={<Wallet />} />
          <Route path='/xchange-deposit/:selectedCoin' element={<Xchange_Deposit />} />
          <Route path='/xchange-withdraw/:selectedCoin' element={<Xchange_Withdraw />} />
          <Route path='/wallet-rial-withdraw/:selectedCoin' element={<Wallet_Rial_Withdraw />} />
          <Route path='/wallet-rial-deposit/:selectedCoin' element={<Wallet_Rial_Deposit />} />
          <Route path='/swap' element={<Swap />} />
          <Route path='/invite-friends' element={<Invite_Friends />} />
          <Route path='/reports' element={<Reports />} />
          {/* پایان مهدی */}
          
          <Route path='/blog' element={<Blog />} />
          <Route path='/post-blog/:blogId' element={<SingleBlog />} />

          <Route path='/ref/:referral_code' element={<Ref />} />
          

          
          <Route path='/socket' element={<PriceSocket />} />
          <Route path='/exchange' element={<Exchange />} />       
          <Route path='/records' element={<Records />} />
          <Route path='/order' element={<Order />} />
          <Route path='/authorization' element={<Authorization />} />

          <Route path='/api-doc' element={<ApiDoc />} />


          <Route path='/welcome-campaign' element={<WelcomeCampaign />} />
        </Routes>
       
        <ToastContainer rtl/>
      </div>
    </MainContext.Provider>
   
  );
}

export default App;

import React, { useEffect, useState, useRef, useContext } from "react";
import "./Markets.css";
import PriceSocket from "../components/price-socket/PriceSocket";
import Select from "react-select";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loading from "../components/loading-circle/LoadingCircle";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContexts";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BASE_URL } from '../utils/url'


const Markets = () => {
    const { t } = useTranslation()
    const { lightingMode, user_lang } = useContext(MainContext)

    const options = [
        { value: "market", label: "Market" },
        { value: "price", label: "Price" },
        { value: "highest-price", label: "Highest price (24h)" },
        { value: "changes", label: "Changes (24h)" },
    ];

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: lightingMode === 'light' ? 'unset' : '#000'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: lightingMode === 'light' ? 'unset' : '#000'
        }),
    };

    //////////////////// Socket
    const allCoinsInitial = useRef([]);
    const [allCoins, setAllCoins] = useState([]);
    const coinsArrayRef = useRef([]);
    const [socketData, setSocketData] = useState('');

    const createWebSocket = () => {
        const socket = new WebSocket("wss://ws.moneyex.org");

        socket.onopen = () => {
            const request = { type: 'marketTicker', symbol: '*' }
            socket.send(JSON.stringify(request))

            setSelectIsDisabled(false);
        }

        socket.onmessage = (event) => {
            const jsonData = JSON.parse(event.data);

            const allCoinsArray = Array.from(coinsArrayRef.current);
            allCoinsArray.forEach((cn) => {
                if (jsonData.base_coin === cn.name) {
                    cn.name = jsonData.base_coin;
                    cn.price = jsonData.latest_price;
                    cn.change = jsonData.change;
                    cn.highPrice = jsonData.high_price;
                }
            });

            setSocketData(allCoinsArray);
        };
    };

    ///////////// Sorting
    const sorted_by_Price = socketData && [...socketData].sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    const sorted_by_HighPrice = socketData && [...socketData].sort((a, b) => parseFloat(b.highPrice) - parseFloat(a.highPrice));
    const sorted_by_Change = socketData && [...socketData].sort((a, b) => parseFloat(b.change) - parseFloat(a.change));

    const allCoinsInitialRe = [...allCoinsInitial.current].reverse();
    const allCoins_sorted_by_price = sorted_by_Price && sorted_by_Price.map((item2) => allCoins.find((item1) => item1.base_coin === item2.name) || null).filter(Boolean);
    const allCoins_sorted_by_price_re = allCoins_sorted_by_price && [...allCoins_sorted_by_price].reverse();
    const allCoins_sorted_by_highPrice = sorted_by_HighPrice && sorted_by_HighPrice.map((item2) => allCoins.find((item1) => item1.base_coin === item2.name) || null).filter(Boolean);
    const allCoins_sorted_by_highPrice_re = allCoins_sorted_by_highPrice && [...allCoins_sorted_by_highPrice].reverse();
    const allCoins_sorted_by_change = sorted_by_Change && sorted_by_Change.map((item2) => allCoins.find((item1) => item1.base_coin === item2.name) || null).filter(Boolean);
    const allCoins_sorted_by_change_re = allCoins_sorted_by_change && [...allCoins_sorted_by_change].reverse();

    const [nameBtnStatus, setNameBtnStatus] = useState("down");
    const [priceBtnStatus, setPriceBtnStatus] = useState("off");
    const [highPriceBtnStatus, setHighPriceBtnStatus] = useState("off");
    const [changeBtnStatus, setChangeBtnStatus] = useState("off");

    function sortByName() {
        if (allCoinsInitial.current) {
            if (nameBtnStatus === "off" || nameBtnStatus === "up") {
                setAllCoins(allCoinsInitial.current);
                setNameBtnStatus("down");
            } else {
                setAllCoins(allCoinsInitialRe);
                setNameBtnStatus("up");
            }
            setPriceBtnStatus("off");
            setHighPriceBtnStatus("off");
            setChangeBtnStatus("off");
        }
    }
    function sortByPrice() {
        if (allCoins_sorted_by_price) {
            if (priceBtnStatus === "off" || priceBtnStatus === "up") {
                setAllCoins(allCoins_sorted_by_price);
                setPriceBtnStatus("down");
            } else {
                setAllCoins(allCoins_sorted_by_price_re);
                setPriceBtnStatus("up");
            }
            setNameBtnStatus("off");
            setHighPriceBtnStatus("off");
            setChangeBtnStatus("off");
        }
    }
    function sortByHighPrice() {
        if (allCoins_sorted_by_highPrice) {
            if (highPriceBtnStatus === "off" || highPriceBtnStatus === "up") {
                setAllCoins(allCoins_sorted_by_highPrice);
                setHighPriceBtnStatus("down");
            } else {
                setAllCoins(allCoins_sorted_by_highPrice_re);
                setHighPriceBtnStatus("up");
            }
            setNameBtnStatus("off");
            setPriceBtnStatus("off");
            setChangeBtnStatus("off");
        }
    }
    function sortByChange() {
        if (allCoins_sorted_by_change) {
            if (changeBtnStatus === "off" || changeBtnStatus === "up") {
                setAllCoins(allCoins_sorted_by_change);
                setChangeBtnStatus("down");
            } else {
                setAllCoins(allCoins_sorted_by_change_re);
                setChangeBtnStatus("up");
            }
            setNameBtnStatus("off");
            setPriceBtnStatus("off");
            setHighPriceBtnStatus("off");
        }
    }
    /////////////

    ///////////// React select
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [selectIsDisabled, setSelectIsDisabled] = useState(true);

    function selectOnChange(option) {
        setSelectedOption(option);

        if (option.value === "market") {
            if (allCoinsInitial.current) setAllCoins(allCoinsInitial.current);
        }
        if (option.value === "price") {
            if (allCoins_sorted_by_price) setAllCoins(allCoins_sorted_by_price);
        }
        if (option.value === "highest-price") {
            if (allCoins_sorted_by_highPrice)
                setAllCoins(allCoins_sorted_by_highPrice);
        }
        if (option.value === "changes") {
            if (allCoins_sorted_by_change) setAllCoins(allCoins_sorted_by_change);
        }

        setNameBtnStatus("off");
        setPriceBtnStatus("off");
        setHighPriceBtnStatus("off");
        setChangeBtnStatus("off");
    }
    /////////////

    useEffect(() => {
        createWebSocket();

        //////////////////////////////////////////////////////////////////////////////////////////// این پیج پجینیشن هم دارد که در حال حاضر استفاده نشده است!
        fetch(BASE_URL + "/ticker?page=1")
            .then((res) => res.json())
            .then((data) => {
                allCoinsInitial.current = data.items;
                setAllCoins(data.items);

                coinsArrayRef.current = [
                    ...coinsArrayRef.current,
                    ...data.items.map((i) => ({
                        name: i.base_coin,
                        price: i.latest_price,
                        change: i.change,
                        highPrice: i.high_price,
                    })),
                ];
            })
        // .catch((err) => console.log(err));
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{user_lang === 'ar' ? 'بازارها' : 'Markets'}</title>
            </Helmet>
            <Header />
            <div className="bg-container">
                <div className="m-title-box">
                    <h1 className="m-title">{t('markets.1')}</h1>
                    <div className="m-time-box">
                        {t('markets.2')}
                        <i></i>
                    </div>
                </div>

                <PriceSocket />

                <div className="m-container">
                    <div className="m-top">
                        <div className="m-top-left">
                            <button className="active">{t('markets.3')}</button>
                            {/* <button>{t('markets.4')}</button>
                            <button>{t('markets.5')}</button>
                            <button>{t('markets.6')}</button> */}
                        </div>
                        <div className="m-top-right">
                            {/* <button>IRR</button>
                            <button className="active">USD</button>
                            <button>USDT</button>
                            <button>BTC</button> */}
                        </div>
                    </div>
                    <div className="m-top-mobile">
                        <span>{t('markets.7')}</span>
                        <div>
                            <Select
                                defaultValue={selectedOption}
                                onChange={selectOnChange}
                                options={options}
                                isDisabled={selectIsDisabled}
                                styles={selectStyles}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                    <div className="m-bottom">
                        {!socketData && <Loading />}
                        {socketData &&
                            <>
                                <div className="m-bottom-top">
                                    <button onClick={sortByName}>
                                        {t('markets.8')}
                                        {nameBtnStatus === "down" && (
                                            <img src={require("../image/price-socket/sort-down.png")} />
                                        )}
                                        {nameBtnStatus === "up" && (
                                            <img src={require("../image/price-socket/sort-up.png")} />
                                        )}
                                        {nameBtnStatus === "off" && (
                                            <img src={require("../image/price-socket/sort.png")} />
                                        )}
                                    </button>
                                    <button onClick={sortByPrice}>
                                        {t('markets.9')}
                                        {priceBtnStatus === "down" && (
                                            <img src={require("../image/price-socket/sort-down.png")} />
                                        )}
                                        {priceBtnStatus === "up" && (
                                            <img src={require("../image/price-socket/sort-up.png")} />
                                        )}
                                        {priceBtnStatus === "off" && (
                                            <img src={require("../image/price-socket/sort.png")} />
                                        )}
                                    </button>
                                    <button className="highprice" onClick={sortByHighPrice}>
                                        {t('markets.11')}
                                        {highPriceBtnStatus === "down" && (
                                            <img src={require("../image/price-socket/sort-down.png")} />
                                        )}
                                        {highPriceBtnStatus === "up" && (
                                            <img src={require("../image/price-socket/sort-up.png")} />
                                        )}
                                        {highPriceBtnStatus === "off" && (
                                            <img src={require("../image/price-socket/sort.png")} />
                                        )}
                                    </button>
                                    <button onClick={sortByChange}>
                                        {t('markets.12')}
                                        {changeBtnStatus === "down" && (
                                            <img src={require("../image/price-socket/sort-down.png")} />
                                        )}
                                        {changeBtnStatus === "up" && (
                                            <img src={require("../image/price-socket/sort-up.png")} />
                                        )}
                                        {changeBtnStatus === "off" && (
                                            <img src={require("../image/price-socket/sort.png")} />
                                        )}
                                    </button>
                                    <span>{t('markets.13')}</span>
                                </div>
                                {allCoins &&
                                    allCoins.map((item, index) => {
                                        const coinData = socketData && socketData.find((i) => i.name === item.base_coin) || {};
                                        const price = !socketData || !coinData.price ? item.latest_price : coinData.price;
                                        const change = !socketData || !coinData.change ? item.change : coinData.change;
                                        const highPrice = !socketData || !coinData.highPrice ? item.high_price : coinData.highPrice;

                                        return (
                                            <div className="m-row" key={index}>
                                                <div className="m-row-first">
                                                    <img
                                                        src={item.base_coin_icon}
                                                        className={item.base_coin === "WLD" ? "wld-img" : null}
                                                    />
                                                    <span>{item.symbol} </span>
                                                </div>
                                                <div>
                                                    <p className="m-mobile-title">{t('markets.9')}</p>
                                                    {price !== "null" ? price : "..."}
                                                    <span className="m-price-kind"> ({item.quote_coin})</span>
                                                </div>
                                                <div className="highprice">
                                                    <p className="m-mobile-title">{t('markets.11')}</p>
                                                    {highPrice !== "null" ? highPrice : "..."}
                                                    <span className="m-price-kind"> ({item.quote_coin})</span>
                                                </div>
                                                <div className="m-up">
                                                    <p className="m-mobile-title">{t('markets.12')}</p>
                                                    <div>
                                                        {change < 0 ?
                                                            <span className="m-down">{change + '%'}</span>
                                                            :
                                                            <span className="m-up">+{change + '%'}</span>
                                                        }
                                                        {change > 0 ? <i className='p-up'></i> : change < 0 ? <i className='p-down'></i> : null}
                                                    </div>
                                                </div>
                                                <div className="m-row-last">
                                                    <p className="m-mobile-title">{t('markets.13')}</p>
                                                    <div className="m-link">
                                                        <Link
                                                            to={{
                                                                pathname: '../swap/',
                                                                search: new URLSearchParams(`pair=${item.symbol}&type=buy`).toString(),
                                                            }}
                                                            className="green"
                                                        >
                                                            {t('markets.14')}
                                                        </Link>
                                                        <Link
                                                            to={{
                                                                pathname: '../swap/',
                                                                search: new URLSearchParams(`pair=${item.symbol}&type=sell`).toString(),
                                                            }}
                                                            className="red"
                                                        >
                                                            {t('markets.15')}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </HelmetProvider>
    );
};

export default Markets;
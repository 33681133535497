import React, { useState } from "react";
import "./HelpcenterB.css";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function HelpcenterB() {
  const { t } = useTranslation();
  const accardeon = [
    {
      question: t("helpcenter2.4"),
      answer:
      t("helpcenter2.5"),
    },
    {
      question: t("helpcenter2.6"),
      answer:
      t("helpcenter2.7"),
    },
    {
      question: t("helpcenter2.8"),
      answer:
      t("helpcenter2.9"),
    },
    {
      question: t("helpcenter2.10"),
      answer:
      t("helpcenter2.11"),
    },
  ];
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }

    setSelected(i);
  };
  return (
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.9")}</title>
        </Helmet>
      <Header />
      {/* <!-- helpcenter-Section-one --> */}
      <section id="helpcenter-section-one">
        <div className="all">
          <div className="title">{t("helpcenter2.1")}</div>
          <p className="des">{t("helpcenter2.2")}</p>
          <div className="design-img-1">
            <img src={require("../image/helpcenter-page/Path 11203.png")} />
          </div>
          <div className="design-img-2">
            <img src={require("../image/helpcenter-page/Path 11203.png")} />
          </div>
          <form>
            <input type="text" placeholder={t("helpcenter2.3")} />
            <div className="search-icon">
              <img
                src={require("../image/helpcenter-page/search_FILL0_wght400_GRAD0_opsz48.png")}
              />
            </div>
          </form>
        </div>
      </section>
      {/* helpcenter2-Section-two */}
      <section id="helpcenter2-section-two">
        <div className="all">
          {accardeon.map((item, i) => (
            <div className="item">
              <div className="title" onClick={() => toggle(i)}>
                <div className="text">{item.question}</div>
                <img
                  className="plus" style={(selected == i) ? {display:"none"}:{displa:"block"}}
                  src={require("../image/helpcenter-page/add_FILL0_wght400_GRAD0_opsz48.png")}
                />
                <img
                  className="negetive"
                  style={(selected == i) ? {display:"block"}:{displa:"none"}}
                  src={require("../image/helpcenter-page/add_FILL0_wght400_GRAD0_opsz48 (1).png")}
                />
              </div>
              <div className={selected == i ? "content show" : "content"}>{item.answer}</div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}


const accardeonar = [
  {
    question: "What should be done to change email, mobile phone or landline?",
    answer:
      "Is it possible to visit in person to follow up on problems or perform authentication? Is it possible to visit in person to follow up on problems or perform authentication?",
  },
  {
    question: "What should be done to recover the password?",
    answer:
      "Is it possible to visit in person to follow up on problems or perform authentication? lroem",
  },
  {
    question: "Do we need authentication?",
    answer:
      "lorem Is it possible to visit in person to follow up on problems or perform authentication?",
  },
  {
    question: "How to start?",
    answer:
      "lIs it possible to visit in person to follow up on problems or perform authentication?",
  },
];


//internal
import { useState, useEffect, useContext } from "react";

//external
import Cookies from "js-cookie";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/MainContexts";
import { Link, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style and Image
import "./wallet.css";
import "./lightMode.css"
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links"
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import LightingMode from "../../components/lighting-mode/LightingMode";
import LoadingCircle from "../../components/loading-circle/LoadingCircle";

import { BASE_URL } from '../../utils/url'


const Wallet = () => {

  const baseURL = BASE_URL
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  const { t } = useTranslation();
  const { lightingMode, primary_dir } = useContext(MainContext);
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search)
  // console.log(params.toString())



  //desktop popup
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  //mobile popup dark_screen
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  //tablet popup dark_screen
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);
  //desktop popup filter
  const [filterPopup, setFilterPopup] = useState(false);




  //method
  const closeWindowForDesktop = () => {
    if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
    if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
    // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
    // if (tablet_Notifications == true) { setTablet_Notifications(false) }
    if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
  }//end of method


  const [userWallets, setUserWallets] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [forceRender, setForceRender] = useState("");
  const [loading, setLoading] = useState(false);

  const paginate_Arr = [];
  for (let i = 0; i < totalPages; i++) {
    paginate_Arr[i] = i + 1;

  }



  const handleURL = (page_Number) => {
    setForceRender(Math.random())
    navigate(`${window.location.pathname}?page=${page_Number}`)
  }


  //Mounting useEffect
  useEffect(() => {

    //اگر توکن نداشت
    if (!Cookies.get("access_token")) {
      navigate("/judge", { replace: true })
    }

    const fetchData = async () => {
      try {
        //Wallet
        //GET User

        setLoading(true) //شروع لودینگ


        const response_1 = await axios.get(`${baseURL}/user/wallets?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserWallets(response_1.data.data)
        setTotalPages(response_1.data.meta.last_page)
        setCurrentPage(response_1.data.meta.current_page)





        setLoading(false) //پایان لودینگ

      } catch (err) {
        setLoading(false) //پایان لودینگ

        if (err.response.data.message === "Unauthenticated.") {
          Cookies.remove("access_token")
          navigate("/judge", { replace: true })
        }

      }
    }//end of method
    fetchData();
  }, [])


  //Updating useEffect for paginate

  useEffect(() => {

    const fetchData = async () => {
      try {

        setLoading(true) //شروع لودینگ


        const response_1 = await axios.get(`${baseURL}/user/wallets?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserWallets(response_1.data.data)
        setTotalPages(response_1.data.meta.last_page)
        setCurrentPage(response_1.data.meta.current_page)




        setLoading(false) //پایان لودینگ

      } catch (error) {
        setLoading(false) //پایان لودینگ

      }
    }//end of method
    fetchData();
  }, [forceRender])


  const usdt = userWallets?.find(w => w.coin_symbol === "USDT")



  return (
    <HelmetProvider>
      <Helmet>
        <title>{t("title_wallet")}</title>
      </Helmet>

      <div id="wallet" onClick={closeWindowForDesktop} className="flex flex-wrap">

        {/* نوار برای موبایل و تبلت lg:hidden*/}
        {/* x < 1024 */}
        <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}

          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />

        {/* لینک های فیکس برای موبایل sm:hidden*/}
        {/* x < 640 */}
        <Fixed_Mobile_Links />

        {/* ساید بار برای دسکتاپ hidden lg:block*/}
        {/* x > 1024 */}
        <Main_Sidebar />

        <main>

          {/* نوار برای دسکتاپ hidden lg:flex*/}
          {/* x > 1024 */}
          <Nav_Desktop
            desktop_ProfileMenu={desktop_ProfileMenu}
            desktop_Notifications={desktop_Notifications}
            desktop_ThreeDots={desktop_ThreeDots}

            setDesktop_Notifications={setDesktop_Notifications}
            setDesktop_ThreeDots={setDesktop_ThreeDots}
            setDesktop_ProfileMenu={setDesktop_ProfileMenu}

          />





          {/* digital-wallet */}
          <section className="digital-wallet mb-4">
            {/*nth-of-type(1)  */}
            <div className="flex flex-wrap mb-4">
              <h3 style={lightingMode === "light" ? { color: "black" } : { color: "white" }} className="font-bold text-lg">{t("wallet_b1")}</h3>
              {/* ارسال-دریافت-تبدیل-فیلتر */}
              <div className="flex gap-x-3">
                {/* دکمه ارسال  */}
                {/* <button onClick={() => {

                navigate("/xchange-withdraw")
              }}>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.102" height="21.101" viewBox="0 0 18.102 21.101">
                    <path id="vertical_align_bottom_FILL0_wght400_GRAD0_opsz48" d="M160.849,239.761a.819.819,0,0,0-.6.245.828.828,0,0,0-.244.608.812.812,0,0,0,.244.6.827.827,0,0,0,.6.24h16.4a.819.819,0,0,0,.6-.245.828.828,0,0,0,.244-.608.812.812,0,0,0-.244-.6.827.827,0,0,0-.6-.24Zm8.2-19.4a.82.82,0,0,0-.307.057.862.862,0,0,0-.283.2l-4.3,4.3a.8.8,0,0,0-.226.588.878.878,0,0,0,.266.634.8.8,0,0,0,.6.249.83.83,0,0,0,.608-.255l2.8-2.828v12.841a.819.819,0,0,0,.245.6.828.828,0,0,0,.608.244.812.812,0,0,0,.6-.244.827.827,0,0,0,.24-.6V223.3l2.715,2.715a.855.855,0,0,0,1.188-.028.854.854,0,0,0,0-1.216l-4.158-4.158a.788.788,0,0,0-.6-.255Z" transform="translate(-160 -220.357)" fill={lightingMode === "light" ? "#333333" : "#cfcfcf"} />
                  </svg>

                </span>
                <span>{t("wallet_b2")}</span>
              </button> */}
                {/*  دکمه دریافت*/}
                {/* <button onClick={() => {

                navigate("/xchange-deposit")
              }}>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.736" height="19.953" viewBox="0 0 17.736 19.953">
                    <path id="vertical_align_bottom_FILL0_wght400_GRAD0_opsz48" d="M160.831,235.953a.8.8,0,0,1-.592-.24.812.812,0,0,1-.239-.6.8.8,0,0,1,.239-.591.811.811,0,0,1,.592-.236H176.9a.8.8,0,0,1,.592.24.812.812,0,0,1,.239.6.8.8,0,0,1-.239.591.811.811,0,0,1-.592.236Zm8.033-3.658a.8.8,0,0,1-.3-.055.845.845,0,0,1-.277-.194l-4.212-4.212a.78.78,0,0,1-.222-.576.86.86,0,0,1,.26-.621.787.787,0,0,1,.585-.244.813.813,0,0,1,.6.249l2.744,2.771V216.831a.8.8,0,0,1,.24-.592.812.812,0,0,1,.6-.239.8.8,0,0,1,.591.239.811.811,0,0,1,.236.592v12.582l2.66-2.66a.837.837,0,0,1,1.164.028.836.836,0,0,1,0,1.192l-4.074,4.074a.772.772,0,0,1-.586.249Z" transform="translate(-160 -216)" fill={lightingMode === "light" ? "#333333" : "#cfcfcf"} />
                  </svg>

                </span>
                <span>{t("wallet_b3")}</span>
              </button> */}
                {/* دکمه تبدیل */}
                {/* <button onClick={() => {
                navigate("/xchange-convert")
              }}>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.903" height="19.036" viewBox="0 0 18.903 19.036">
                    <path id="published_with_changes_FILL0_wght400_GRAD0_opsz48" d="M101.536,183.268a9.09,9.09,0,0,1,5.283,3.308,9.609,9.609,0,0,1,2.084,6.192,8.725,8.725,0,0,1-1.006,4.071,11.88,11.88,0,0,1-2.7,3.441h2.23a.727.727,0,1,1,0,1.454h-4.1a.7.7,0,0,1-.727-.727v-4.1a.727.727,0,1,1,1.454,0v2.4a9.825,9.825,0,0,0,2.472-2.957,7.41,7.41,0,0,0,.921-3.587,8.132,8.132,0,0,0-1.757-5.247,7.739,7.739,0,0,0-4.471-2.8.652.652,0,0,1-.4-.254.723.723,0,0,1-.158-.448.74.74,0,0,1,.267-.606A.685.685,0,0,1,101.536,183.268Zm-4.168,19a9.09,9.09,0,0,1-5.283-3.308A9.609,9.609,0,0,1,90,192.768a8.725,8.725,0,0,1,1.006-4.071,11.88,11.88,0,0,1,2.7-3.441h-2.23a.727.727,0,1,1,0-1.454h4.1a.7.7,0,0,1,.727.727v4.1a.727.727,0,1,1-1.454,0v-2.4a9.5,9.5,0,0,0-2.472,2.945,7.479,7.479,0,0,0-.921,3.6,8.132,8.132,0,0,0,1.757,5.247,7.739,7.739,0,0,0,4.471,2.8.652.652,0,0,1,.4.254.723.723,0,0,1,.158.448.74.74,0,0,1-.267.606A.685.685,0,0,1,97.367,202.268Z" transform="translate(-90 -183.25)" fill={lightingMode === "light" ? "#333333" : "#cfcfcf"} />
                  </svg>

                </span>
                <span>{t("wallet_b4")}</span>
              </button> */}
                {/* دکمه فیلتر */}
                {/* <button onClick={() => setFilterPopup(true)} >
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.6" height="21" viewBox="0 0 21.6 21">
                      <path id="page_info_FILL0_wght400_GRAD0_opsz48" d="M137.4,247a4.2,4.2,0,1,1,2.97-1.23A4.047,4.047,0,0,1,137.4,247Zm-.007-1.8a2.331,2.331,0,0,0,1.7-.7,2.3,2.3,0,0,0,.705-1.7,2.331,2.331,0,0,0-.7-1.7,2.3,2.3,0,0,0-1.695-.7,2.331,2.331,0,0,0-1.7.7,2.3,2.3,0,0,0-.705,1.7,2.331,2.331,0,0,0,.7,1.7A2.3,2.3,0,0,0,137.393,245.2Zm-7.493-1.5h-9a.868.868,0,0,1-.641-.26.879.879,0,0,1-.259-.645.861.861,0,0,1,.259-.64.877.877,0,0,1,.641-.255h9a.868.868,0,0,1,.641.26.879.879,0,0,1,.259.645.861.861,0,0,1-.259.64A.878.878,0,0,1,129.9,243.7Zm-5.7-9.3a4.2,4.2,0,1,1,2.97-1.23A4.047,4.047,0,0,1,124.2,234.4Zm-.007-1.8a2.331,2.331,0,0,0,1.7-.7,2.3,2.3,0,0,0,.705-1.7,2.331,2.331,0,0,0-.7-1.7,2.3,2.3,0,0,0-1.695-.7,2.331,2.331,0,0,0-1.7.7,2.3,2.3,0,0,0-.705,1.7,2.331,2.331,0,0,0,.7,1.7A2.3,2.3,0,0,0,124.193,232.6Zm16.507-1.5h-9a.868.868,0,0,1-.641-.26.879.879,0,0,1-.259-.645.861.861,0,0,1,.259-.64.877.877,0,0,1,.641-.255h9a.868.868,0,0,1,.641.26.879.879,0,0,1,.259.645.861.861,0,0,1-.259.64A.877.877,0,0,1,140.7,231.1Z" transform="translate(-120 -226)" fill={lightingMode === "light" ? "black" : "white"} />
                    </svg>
                  </span>
                  <span>{t("wallet_b5")}</span>
                </button> */}
                {/*  */}

              </div>
            </div>
            {/* table جدول*/}



            {
              loading ? <LoadingCircle /> :
                (
                  <div className="table-parent">
                    <table className="w-full text-right">
                      <thead>
                        <tr>
                          <th>{t("wallet_b6")}</th>
                          <th>{t("wallet_b7")}</th>
                          {/* <th>{t("wallet_b8")}</th> */}
                          {/* <th>{t("wallet_b9")}</th> */}
                          <th>{t("wallet_b10")}</th>
                          <th></th>
                          <th></th>

                        </tr>
                      </thead>
                      <tbody>

                        {/*item in wallet */}
                        {
                          userWallets?.map(item =>
                          (
                            <tr key={item.id}>
                              {/*نام ارز  به همراه عکس*/}
                              <td>
                                <div className="flex items-center gap-x-2">
                                  <img src={`${base_url_img}__${item.coin_symbol}.png`} width={43} height={43} />
                                  <span>{item.coin_symbol} | {item.coin_name}</span>
                                </div>
                              </td>
                              {/* مقدار */}
                              <td>{item.primary_balance}</td>
                              {/* موجودی کل */}
                              {/* <td>
                           
                          </td> */}
                              {/* سود ضرر */}
                              {/* <td>
                          </td> */}



                              {/* ارسال_withdraw */}
                              {/* دارای لینک */}
                              <td>
                                {
                                  (item.deposit_status) ?
                                    <span onClick={() => {
                                      Cookies.set("selected_Coin", JSON.stringify(item))

                                      navigate(item.coin_type !== "fiat" ? `/xchange-deposit/${item.coin_symbol}` : `/wallet-rial-deposit/${item.coin_symbol}`)
                                    }}
                                      style={{ color: "#f6b33f", cursor: "pointer" }}>{t("wallet_b11")}</span>
                                    :
                                    <span className="text-gray-300">{t("wallet_b11")}</span>
                                }
                              </td>

                              {/* دریافت_deposit */}
                              {/* دارای لینک */}
                              <td>
                                {
                                  (item.withdrawal_status) ?
                                    <span onClick={() => {
                                      Cookies.set("selected_Coin", JSON.stringify(item))

                                      navigate(item.coin_type !== "fiat" ? `/xchange-withdraw/${item.coin_symbol}` : `/wallet-rial-withdraw/${item.coin_symbol}`)
                                    }}
                                      style={{ color: "#f6b33f", cursor: "pointer" }}>{t("wallet_b12")}</span>
                                    :
                                    <span className="text-gray-300">{t("wallet_b12")}</span>
                                }
                              </td>
                              {/* سوابق */}
                              <td>
                                <span
                                  onClick={() => navigate(`/records?symbol=${item.coin_symbol}`)}
                                  style={{ color: "#f6b33f", cursor: "pointer" }}>
                                  {t("wallet_b13")}
                                </span>
                              </td>

                            </tr>)
                          )}


                      </tbody>
                    </table>
                  </div>
                )
            }



            {/*end table */}
          </section>
          {/* end digital-wallet*/}

          {/* صفحه بندی */}

          {
            !loading && (
              <div className="flexCenter w-full">
                <ul className="paginate" >
                  {
                    paginate_Arr.map((pageNumber) => (
                      <li onClick={() => handleURL(pageNumber)} key={pageNumber}
                        className={pageNumber === currentPage ? "active-paginate " : ""}>

                        {pageNumber}


                      </li>
                    ))
                  }

                </ul>
              </div>
            )
          }

        </main>


        {/*position-fixed*/}
        {
          // filterPopup && (
          //   <div className="filter_parent flexCenter">
          //     <section className="">
          //       {/* nth-of-1 */}
          //       <div className="flexBetween mb-2">
          //         <span className="flex gap-x-2">
          //           <span>
          //             <svg xmlns="http://www.w3.org/2000/svg" width="21.6" height="21" viewBox="0 0 21.6 21">
          //               <path id="page_info_FILL0_wght400_GRAD0_opsz48" d="M137.4,247a4.2,4.2,0,1,1,2.97-1.23A4.047,4.047,0,0,1,137.4,247Zm-.007-1.8a2.331,2.331,0,0,0,1.7-.7,2.3,2.3,0,0,0,.705-1.7,2.331,2.331,0,0,0-.7-1.7,2.3,2.3,0,0,0-1.695-.7,2.331,2.331,0,0,0-1.7.7,2.3,2.3,0,0,0-.705,1.7,2.331,2.331,0,0,0,.7,1.7A2.3,2.3,0,0,0,137.393,245.2Zm-7.493-1.5h-9a.868.868,0,0,1-.641-.26.879.879,0,0,1-.259-.645.861.861,0,0,1,.259-.64.877.877,0,0,1,.641-.255h9a.868.868,0,0,1,.641.26.879.879,0,0,1,.259.645.861.861,0,0,1-.259.64A.878.878,0,0,1,129.9,243.7Zm-5.7-9.3a4.2,4.2,0,1,1,2.97-1.23A4.047,4.047,0,0,1,124.2,234.4Zm-.007-1.8a2.331,2.331,0,0,0,1.7-.7,2.3,2.3,0,0,0,.705-1.7,2.331,2.331,0,0,0-.7-1.7,2.3,2.3,0,0,0-1.695-.7,2.331,2.331,0,0,0-1.7.7,2.3,2.3,0,0,0-.705,1.7,2.331,2.331,0,0,0,.7,1.7A2.3,2.3,0,0,0,124.193,232.6Zm16.507-1.5h-9a.868.868,0,0,1-.641-.26.879.879,0,0,1-.259-.645.861.861,0,0,1,.259-.64.877.877,0,0,1,.641-.255h9a.868.868,0,0,1,.641.26.879.879,0,0,1,.259.645.861.861,0,0,1-.259.64A.877.877,0,0,1,140.7,231.1Z" transform="translate(-120 -226)" fill={lightingMode === "light" ? "black" : "white"} />
          //             </svg>

          //           </span>
          //           <span style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{t("wallet_filter_1")}</span>
          //         </span>

          //         <button onClick={() => setFilterPopup(false)}
          //           className="flexCenter w-[116px] h-[46px] gap-x-2 rounded-md"
          //           style={{ border: "1px solid #edeef1" }}>
          //           <span>
          //             <svg xmlns="http://www.w3.org/2000/svg" width="12.387" height="12.387" viewBox="0 0 12.387 12.387">
          //               <path id="close_FILL0_wght400_GRAD0_opsz48_4_" data-name="close_FILL0_wght400_GRAD0_opsz48 (4)" d="M225.193,322.19l-4.983,4.983a.7.7,0,1,1-1-1l4.983-4.983-4.983-4.983a.7.7,0,1,1,1-1l4.983,4.983,4.983-4.983a.7.7,0,1,1,1,1l-4.983,4.983,4.983,4.983a.7.7,0,1,1-1,1Z" transform="translate(-219 -315)" fill={lightingMode === "light" ? "black" : "white"} />
          //             </svg>

          //           </span>
          //           <span>{t("wallet_filter_2")}</span>
          //         </button>
          //       </div>
          //       {/* four_box */}
          //       <div className="filter-box flex flex-wrap justify-between gap-y-2 mb-5">
          //         {/*box1  */}
          //         <section
          //           className="w-[47%] h-[74px]"
          //         >
          //           <label className="text-sm block mb-1" >{t("wallet_filter_3")}</label>
          //           <select className="w-full h-12 px-2">
          //             <option value="">{t("wallet_filter_4")}</option>
          //           </select>
          //         </section>
          //         {/*box2  */}
          //         <section
          //           className="w-[47%] h-[74px]"
          //         >
          //           <label className="text-sm block mb-1" >{t("wallet_filter_5")}</label>
          //           <select className="w-full h-12 px-2">
          //             <option value="">{t("wallet_filter_6")}</option>
          //           </select>
          //         </section>
          //         {/*box3  */}
          //         <section
          //           className="w-[47%] h-[74px]"
          //         >
          //           <label className="text-sm block mb-1" >{t("wallet_filter_7")}</label>
          //           <select className="w-full h-12 px-2" >
          //             <option value="">{t("wallet_filter_8")}</option>
          //           </select>
          //         </section>
          //         {/*box4  */}
          //         <section
          //           className="w-[47%] h-[74px]"
          //         >
          //           <span>{t("wallet_filter_9")}</span>
          //         </section>
          //         {/*  */}
          //       </div>

          //       {/* button-parent */}
          //       <div className="flex justify-end">
          //         <button
          //           className="apply-filter w-[282px] h-12 text-white rounded-lg"
          //           style={{ background: "#f9cc0b" }}
          //         >
          //           {t("wallet_filter_10")}
          //         </button>
          //       </div>
          //       {/*  */}
          //     </section>
          //   </div>


          // )
        }
        {/* absolute */}
        <LightingMode bottom="20px" left="30px" />
      </div>
    </HelmetProvider>
  )
}

export default Wallet
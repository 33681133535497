import React, { useEffect } from 'react'
import styled from 'styled-components';


const ApiDoc = () => {
    const StyledContainer = styled.div`
    body {
        background-color: #fdfdfd !important;
        /* font-family: yekan !important; */
        font-family: IRANSans !important;
        position: relative;
        min-height: 100vh;
    }
    
    :root {
        --color-primary: #3640f5;
        --color-primary-hover: #2a33ee;
        --color-primary-2: #131cbc;
        --color-primary-light: #f7f9ff;
        --color-icon: #0a0092;
        --color-icon-2: #06005a;
    }
    
    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }
    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #bbb;
    }
    
    .accordion-toggle {
        gap: 1.5rem;
    }
    
    p {
        margin: 0 !important;
    }
    
    .btn-2 {
        background-color: darkred;
        color: #fff;
        border-radius: .25rem;
    }
    
    .btn-2:hover {
        background-color: rgb(109, 0, 0);
    }
    
    .accordion-body {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
    
    .accordion-body img {
        margin: auto;
    }
    
    .accordion-body ul {
        list-style: disc;
        list-style-position: inside;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
    
    pre {
        overflow-x: auto;
    }

.big-container {
    display: flex;
    gap: 2rem;
}

.big-container>* {
    overflow-x: auto;
}

#sidebar ul {
    padding: .5rem !important;
    gap: 0;
}

#sidebar li {
    list-style: none;
    border-radius: 4px;
}

#sidebar a {
    color: #444;
    font-size: .94rem;
    text-decoration: none;
    display: block;
    padding: 2px 6px;
    margin: 2px 0;
}

#sidebar>li>a {
    color: black;
    font-weight: 600;
    font-size: 1.05rem;
}

#sidebar li.active {
    background-color: white;
    border-left: 3px solid var(--color-primary);
    padding-left: 7px;
}

section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

section:last-child {
    margin-bottom: 0;
}

code {
    padding: 4px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: black !important;
}



ul li {
    list-style-type: disc;
}

ol li {
    list-style-type: decimal;
}

.content-container a {
    color: var(--color-primary) !important;
}

.content-container a:hover {
    color: var(--color-primary) !important;
}

blockquote {
    color: #444;
    padding-left: 1rem;
    margin-left: 2rem !important;
    border-left: 4px solid #ddd;
}

pre {
    background-color: #f1f1f1;
    border-radius: 5px;
}


th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    line-height: 1.4;
}

tr:nth-of-type(even) {
    background-color: #f5f5f5;
}

.code-example ul {
    padding: 0 !important;
    flex-direction: row;
    gap: 7px;
    margin-bottom: 7px;
    flex-wrap: wrap;
}

.code-example ul li {
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;
}

.code-example ul li.active {
    background-color: #000;
    cursor: default;
}

.code-example ul li a {
    display: flex;
    gap: 10px;
    color: #555 !important;
    pointer-events: none;
}

.code-example ul li a:hover {
    color: #555 !important;
}

.code-example ul li.active a {
    color: #fff !important;
}

h5,
h6 {
    font-weight: 700 !important;
    margin: 1.5rem 0 1rem !important;
}

h5 {
    font-size: 1.3rem !important;
}

h6 {
    font-size: 1.1rem !important;
}

b {
    margin-bottom: 1rem !important;
    display: inline-block;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
li{
    list-style-type: none !important;
}
#card-inquiry{
    
}
 .url{
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: center !important;
    justify-content: flex-end;
    padding: 0 20px;
   border: 2px solid #5a8dee;
   margin-top: 1rem;
}
 .url button{
    width: 80px;
    height: 50px;
    background: #1753c4;
    color: white;font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
    pointer-events: none;
}
.url p{
    margin: 0;
    direction: ltr;
}
 table {
    text-align: left;
    direction: ltr;
    width: 100% !important;
    
}
 .dont{
    display: flex;
    /* border: 1px solid red; */
    /* flex-direction: column; */
    /* align-items: center; */
    gap: 10px;
}
 .dont>section{
    width: 50%;
}
.dont>section ul{
    margin-bottom: 0;
}
 .dont>section ul li{
    direction: ltr;
    display: flex;
    justify-content: space-between;
   /* border: 1px solid black; */
   min-height: 40px;
}
.dont>section ul li span{
    display: flex;
    
    align-items: center;
    border: 1px solid #ddd;
    padding: 8px;
}
.dont>section ul li span:nth-of-type(1){
    width: 60%;
}
    
.dont>section ul li span:nth-of-type(2){
  width: 40%;
}
/* ////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////// */
#sheba-inquiry{

}
#sheba-inquiry .url{
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: center !important;
    justify-content: flex-end;
    padding: 0 20px;
   border: 2px solid #5a8dee;
}
#sheba-inquiry .url button{
    width: 80px;
    height: 50px;
    background: #1753c4;
    color: white;font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 10px;
}
#sheba-inquiry .url p{
    margin-bottom: 0;
}
#sheba-inquiry table {
    text-align: left;
    direction: ltr;
    width: 100% !important;
    
}
#sheba-inquiry .dont{
    display: flex;
    /* border: 1px solid red; */
}
#sheba-inquiry .dont>section{
    width: 50%;
}
#sheba-inquiry .dont>section ul li{
    direction: ltr;
    display: flex;
    justify-content: space-between;
   /* border: 1px solid black; */
   min-height: 40px;
}
.dont>section ul li span{
    display: flex;
    
    align-items: center;
    border: 1px solid #ddd;
}
.dont>section ul li span:nth-of-type(1){
    width: 60%;
}
    
.dont>section ul li span:nth-of-type(2){
  width: 40%;
}
td,th {
    direction: rtl !important;
    text-align: center !important;
}
li span {
    direction: rtl;
    text-align: center;
}
h5 {
    text-align: center;
    padding-top: 1rem;
}
`


    useEffect(() => {
        document.querySelectorAll('#sidebar li').forEach(li => {
            li.addEventListener('click', e => {
                document.querySelectorAll('#sidebar li').forEach(l => l.classList.remove('active'))
                e.target.closest('li').classList.add('active')
            })
        });

        window.addEventListener('scroll', () => {
            document.querySelectorAll('.content-container>section').forEach(sec => {
                if (sec.getBoundingClientRect().top < 100 && sec.getBoundingClientRect().top > -100) {
                    document.querySelectorAll('#sidebar a').forEach(a => {
                        if (a.href.includes(sec.id)) {
                            document.querySelectorAll('#sidebar li').forEach(l => l.classList.remove('active'))
                            a.parentElement.classList.add('active')
                        }
                    })
                }
            })
        });

        document.querySelector('#sidebar-btn').addEventListener('click', () => {
            const sidebar = document.querySelector('#sidebar');
            if (sidebar.classList.contains('max-lg:right-[-35rem]')) {
                sidebar.classList.remove('max-lg:right-[-35rem]');
                setTimeout(() => {
                    sidebar.classList.add('max-lg:right-5');
                }, 10);
                setTimeout(() => {
                    document.querySelectorAll('#sidebar-btn svg')[0].classList.add('hidden')
                    document.querySelectorAll('#sidebar-btn svg')[1].classList.remove('hidden')
                }, 100)
            } else {
                sidebar.classList.remove('max-lg:right-5');
                setTimeout(() => {
                    sidebar.classList.add('max-lg:right-[-35rem]');
                }, 10);
                setTimeout(() => {
                    document.querySelectorAll('#sidebar-btn svg')[0].classList.remove('hidden')
                    document.querySelectorAll('#sidebar-btn svg')[1].classList.add('hidden')
                }, 100)
            }

            document.querySelectorAll('#sidebar li').forEach(li => {
                li.addEventListener('click', e => {
                    setTimeout(() => {
                        sidebar.classList.remove('max-lg:right-5')
                        setTimeout(() => {
                            sidebar.classList.add('max-lg:right-[-35rem]')
                        }, 10)
                    }, 300)
                    setTimeout(() => {
                        document.querySelectorAll('#sidebar-btn svg')[0].classList.remove('hidden')
                        document.querySelectorAll('#sidebar-btn svg')[1].classList.add('hidden')
                    }, 400)
                })
            })
        })

        document.querySelectorAll('.code-example ul li').forEach(li => {
            li.addEventListener('click', e => {
                const ul = e.target.closest('ul')
                ul.querySelectorAll('li').forEach(ll => ll.classList.remove('active'))
                e.target.closest('li').classList.add('active')

                let href = li.firstElementChild.href.match(/#([^#]+)$/)[1]
                e.target.closest('.code-example').querySelectorAll('div').forEach(div => div.hidden = true)
                e.target.closest('.code-example').querySelector('div#' + href).hidden = false
            })
        })
    }, [])


    return (
        <StyledContainer>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n        blockquote {\n            color: #444;\n            padding-left: unset;\n            margin-left: unset;\n            border-left: unset;\n            padding-right: 1rem;\n            margin-right: 2rem !important;\n            border-right: 4px solid #ddd;\n        }\n\n        #sidebar li.active {\n            border-left: unset;\n            padding-left: unset;\n            border-right: 3px solid var(--color-primary);\n            padding-right: 7px;\n        }\n\n        pre {\n            text-align: left;\n            direction: ltr;\n            padding: 5px;\n            margin: 1rem 0;\n        }\n\n        h2 {\n            font-size: 26px;\n            margin-top: 5rem;\n        }\n\n        h3 {\n            margin: 1rem 0;\n            margin-top: 2rem;\n            font-size: 22px;\n        }\n\n        h4 {\n            font-size: 19px;\n            margin: 1rem 0;\n        }\n\n        section {\n            margin-bottom: 3rem;\n        }\n\n        img {\n            margin-bottom: 1rem;\n        }\n    "
                }}
            />
            <div id="container" className="container-box !mx-auto w-4/5 relative">
                <header id="header" className="flex justify-center py-10">
                    <a href="/">
                        <img src={require("./assets/_company_logo.png")} width={150} />
                    </a>
                </header>
                <div className="big-container">
                    <ul
                        id="sidebar"
                        className="overflow-y-auto sticky right-0 max-h-[calc(100vh-2.5rem)] top-5  bg-[#f7f7f7] !py-8 !px-4 rounded-[8px] gap-0 max-lg:fixed max-lg:top-[4.3rem] max-lg:right-[-35rem] max-lg:w-[17rem] max-lg:border max-lg:max-h-[85vh] max-lg:shadow-lg"
                        style={{
                            transition: ".35s",
                            height: "fit-content",
                            paddingLeft: "1rem",
                            maxWidth: "65vw",
                            overflowX: "hidden",
                            minWidth: "min-content"
                        }}
                    >
                        <ul style={{ display: "flex", gap: 8, flexDirection: "column" }}>
                            {/* <li class="active"><a href="#Aboutus">Aboutus</a></li> */}
                            {/* <li><a href="#Agree">Agree</a></li> */}
                            <li>
                                <a href="#Authorization">Authorization</a>
                            </li>
                            {/* <li><a href="#Blog">Blog</a></li> */}
                            {/* <li><a href="#Contact">Contact</a></li> */}
                            <li>
                                <a href="#Dashboard">Dashboard</a>
                            </li>
                            <li>
                                <a href="#Exchange">Exchange</a>
                            </li>
                            <li>
                                <a href="#Forget">Forget</a>
                            </li>
                            {/* <li><a href="#Helpcenter">Helpcenter</a></li> */}
                            {/* <li><a href="#HelpcenterB">HelpcenterB</a></li> */}
                            <li>
                                <a href="#Home">Home</a>
                            </li>
                            <li>
                                <a href="#Login">Login</a>
                            </li>
                            <li>
                                <a href="#Markets">Markets</a>
                            </li>
                            <li>
                                <a href="#New-Password">New Password</a>
                            </li>
                            <li>
                                <a href="#Order">Order</a>
                            </li>
                            <li>
                                <a href="#Records">Records</a>
                            </li>
                            <li>
                                <a href="#Register">Register</a>
                            </li>
                            <li>
                                <a href="#Setting">Setting</a>
                            </li>
                            <li>
                                <a href="#Swap">Swap</a>
                            </li>
                            {/* <li><a href="#Terms">Terms</a></li> */}
                            <li>
                                <a href="#Transaction">Transaction</a>
                            </li>
                            <li>
                                <a href="#Verification-one">Verification-one</a>
                            </li>
                            <li>
                                <a href="#Verfication-two">Verfication-two</a>
                            </li>
                            <li>
                                <a href="#Wallet">Wallet</a>
                            </li>
                            <li>
                                <a href="#Wallet_Rial_Deposit">Wallet_Rial_Deposit</a>
                            </li>
                            <li>
                                <a href="#Wallet_Rial_Withdraw">Wallet_Rial_Withdraw</a>
                            </li>
                            <li>
                                <a href="#Worod">Worod</a>
                            </li>
                            <li>
                                <a href="#Xchange_Deposit">Xchange_Deposit</a>
                            </li>
                            <li>
                                <a href="#Xchange_Withdraw">Xchange_Withdraw</a>
                            </li>
                        </ul>
                    </ul>
                    <button
                        type="button"
                        id="sidebar-btn"
                        className="lg:hidden fixed top-5 right-5 p-2 rounded-full bg-gray-200"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                            width={24}
                            fill="#444"
                        >
                            <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 -960 960 960"
                            width={24}
                            fill="#444"
                            className="hidden"
                        >
                            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                        </svg>
                    </button>
                    {/* Start Page content */}
                    <div className="content-container pr-1">
                        <blockquote>
                            راهنمای داکیومنت نویسی
                            <br />
                            <br />
                            از این ساختار استفاده شود:
                            <br />
                            تگ section
                            <br />
                            داخلش:
                            <br />
                            تگ h2 (اسم پیج)
                            <br />
                            تگ h3 (اگر نیاز به سابتایتل بود)
                            <br />
                            تگ p (برای توضیحات)
                            <br />
                            تگ pre (برای نمایش ریسپان apiها)
                            <br />
                            <br />
                            نکته: ترتیب چینش پیج‌ها بر اساس الفبا است. این را رعایت کنید.
                        </blockquote>
                        <section id="Authorization">
                            <h2>Authorization</h2>
                            <p>
                                از https://api.moneyex.org/user/kyc/latest با توکن آخرین درخواست
                                کاربر گرفته میشود:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"status": "success",{"\n"}
                                {"    "}"data": {"{"}
                                {"\n"}
                                {"        "}"id": "e0f160c2-ce12-4d56-ad99-308c3b08a43c",{"\n"}
                                {"        "}"user_id": "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"        "}"type": "id_card",{"\n"}
                                {"        "}"reference": null,{"\n"}
                                {"        "}"card_image": {"{"}
                                {"\n"}
                                {"            "}"identity_document_image":
                                "id_identity_document_image_af6c8330-313e-4ebf-8a7b-6b27f9fe7e03-65754932.png",
                                {"\n"}
                                {"            "}"client_image":
                                "id_client_image_af6c8330-313e-4ebf-8a7b-6b27f9fe7e03-940265893.png",
                                {"\n"}
                                {"            "}"bank_card_image":
                                "id_bank_card_image_af6c8330-313e-4ebf-8a7b-6b27f9fe7e03-869409251.png"
                                {"\n"}
                                {"        "}
                                {"}"},{"\n"}
                                {"        "}"face_image": null,{"\n"}
                                {"        "}"data": null,{"\n"}
                                {"        "}"reason": null,{"\n"}
                                {"        "}"status": "reviewing",{"\n"}
                                {"        "}"created_at": "2024-03-06T15:06:04.000000Z",{"\n"}
                                {"        "}"updated_at": "2024-03-06T15:06:04.000000Z",{"\n"}
                                {"        "}"profile_data": {"{"}
                                {"\n"}
                                {"            "}"address": null,{"\n"}
                                {"            "}"last_name": "درانی",{"\n"}
                                {"            "}"first_name": "حیدر",{"\n"}
                                {"            "}"national_id": "2222222222",{"\n"}
                                {"            "}"bank_card_number": "3232342444343434"{"\n"}
                                {"        "}
                                {"}"},{"\n"}
                                {"        "}"token": null{"\n"}
                                {"    "}
                                {"}"}
                                {"\n"}
                                {"}"}
                            </pre>
                            <p>
                                از data.status برای مشخص شدن وضعیت احراز هویت کاربر استفاده میشود. شش
                                وضعیت میتواند داشته باشد:
                            </p>
                            <p style={{ direction: "ltr" }}>
                                "", "reviewing", "pending", "declined", "verified", "expired"
                            </p>
                            <h3>احراز هویت سریع</h3>
                            <p>
                                به https://api.moneyex.org/user/kyc/start-fast با توکن درخواست
                                POST ارسال میشود و پاسخ:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"status": "success",{"\n"}
                                {"    "}"data": {"{"}
                                {"\n"}
                                {"        "}"redirect_to":
                                "https://v2.hooshsaman.com/services/authenticate/client/36R9cBbAa6"
                                {"\n"}
                                {"    "}
                                {"}"}
                                {"\n"}
                                {"}"}
                            </pre>
                            <p>آدرس صفحه احراز هویت سریع: redirect_to</p>
                            <h3>احراز هویت معمولی</h3>
                            <p>
                                به https://api.moneyex.org/user/kyc/start-normal با توکن درخواست
                                POST ارسال میشود با این مقادیر:
                            </p>
                            <br />
                            <div style={{ direction: "ltr" }}>
                                <span>first_name:</span>
                                <span>نام</span>
                                <br />
                                <span>last_name:</span>
                                <span>نام خانوادگی</span>
                                <br />
                                <span>national_id:</span>
                                <span>کد ملی</span>
                                <br />
                                <span>id_type:</span>
                                <span>"id_card"</span>
                                <br />
                                <span>identity_document_image:</span>
                                <span>عکس کارت ملی</span>
                                <br />
                                <span>client_image:</span>
                                <span>عکس تعهدنامه</span>
                                <br />
                                <span>bank_card_number:</span>
                                <span>شماره بانکی</span>
                                <br />
                                <span>bank_card_image:</span>
                                <span>عکس کارت بانکی</span>
                            </div>
                        </section>
                        {/* داشبورد */}
                        <section id="Dashboard">
                            <h2>
                                <span>Dashboard</span>
                                <span>(داشبورد)</span>
                            </h2>
                            <br />
                            {/*  */}
                            <p>
                                از
                                <span>https://api.moneyex.org/user/wallets</span>
                                با توکن، اطلاعات کیف پول کاربر گرفته میشود
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "d697b884-6644-479d-8a23-1fcef3356af8",{"\n"}
                                {"                            "}"coin_symbol": "USDT",{"\n"}
                                {"                            "}"coin_name": "Tether",{"\n"}
                                {"                            "}"coin_type": "trc20",{"\n"}
                                {"                            "}
                                <span style={{ color: "red" }}>"primary_balance": 776229.458,</span>
                                {"\n"}
                                {"                            "}"on_order_balance": 0,{"\n"}
                                {"                            "}"usdt_amount": 0,{"\n"}
                                {"                            "}"is_active": true,{"\n"}
                                {"                            "}"deposit_status": true,{"\n"}
                                {"                            "}"withdrawal_status": true{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>برای اینکه دارایی تتر کاربر را در ساید بار نمایش دهیم</p>
                            <p>
                                <span>ارزش کل دارایی ها</span>
                                <span>=</span>
                                <span className="red">primary_balance</span>
                            </p>
                            <br />
                            <br />
                            {/*  */}
                            <p>
                                از
                                <span>https://api.moneyex.org/user/profile</span>
                                با توکن، اطلاعات پروفایل کاربر گرفته میشود
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"                            "}"assigned_role": "user",{"\n"}
                                {"                            "}"referrer_id": null,{"\n"}
                                {"                            "}
                                <span className="red">"referral_code": "strZrJUu8N9nAMQM",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"username": "+989193263689",</span>
                                {"\n"}
                                {"                            "}"email": null,{"\n"}
                                {"                            "}
                                <span className="red">"mobile": "+989193263689",</span>
                                {"\n"}
                                {"                            "}"avatar": null,{"\n"}
                                {"                            "}"google2fa_secret": null,{"\n"}
                                {"                            "}"verify_code": null,{"\n"}
                                {"                            "}"is_id_verified": true,{"\n"}
                                {"                            "}"is_email_verified": 1,{"\n"}
                                {"                            "}"is_mobile_verified": true,{"\n"}
                                {"                            "}"is_financial_active": 1,{"\n"}
                                {"                            "}"is_accessible_under_maintenance": 0,
                                {"\n"}
                                {"                            "}"is_super_admin": 0,{"\n"}
                                {"                            "}"status": "active",{"\n"}
                                {"                            "}"created_at":
                                "2024-02-12T06:43:12.000000Z",{"\n"}
                                {"                            "}"updated_at":
                                "2024-03-10T10:29:44.000000Z",{"\n"}
                                {"                            "}"preference": {"{"}
                                {"\n"}
                                {"                                "}"id":
                                "a8c01665-9392-44bd-af2a-251fe6118903",{"\n"}
                                {"                                "}"user_id":
                                "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"                                "}"default_language": null,{"\n"}
                                {"                                "}"default_coin_pair": "TRX_USDT"
                                {"\n"}
                                {"                            "}
                                {"}"},{"\n"}
                                {"                            "}"profile": {"{"}
                                {"\n"}
                                {"                                "}"id":
                                "59555abe-ca77-11ee-830a-960002fc74ab",{"\n"}
                                {"                                "}"user_id":
                                "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"                                "}
                                <span className="red">"first_name": "امیر",</span>
                                {"\n"}
                                {"                                "}
                                <span className="red">"last_name": "رضایی",</span>
                                {"\n"}
                                {"                                "}"address": "تهران",{"\n"}
                                {"                                "}"phone": null,{"\n"}
                                {"                                "}"birthday": "2014-02-13 17:23:18",
                                {"\n"}
                                {"                                "}"created_at": null,{"\n"}
                                {"                                "}"updated_at": null,{"\n"}
                                {"                                "}"national_id": "0012345678"{"\n"}
                                {"                            "}
                                {"}"},{"\n"}
                                {"                            "}"usdt_amount": 0,{"\n"}
                                {"                            "}"taker_fee_percent": "0.3"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                <span>لینک معرفی به دوستان</span>
                                <span>=</span>
                                <span className="red">referral_code</span>
                            </p>
                            <br />
                            <br />
                            {/*  */}
                            <p>
                                از
                                <span>https://api.moneyex.org/user/orders/latest</span>
                                با توکن، سوابق معاملات اخیر کاربر گرفته میشود
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "cb2b36c7-e413-408e-aa39-42b1bb50d2a0",{"\n"}
                                {"                            "}
                                <span className="red">"base_coin": "BTC",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"quote_coin": "USDT",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"trade_pair": "BTC_USDT",</span>
                                {"\n"}
                                {"                            "}"category": "market",{"\n"}
                                {"                            "}
                                <span className="red">"type": "buy",</span>
                                {"\n"}
                                {"                            "}"status":
                                "error_get_balance_in_exchange",{"\n"}
                                {"                            "}
                                <span className="red">"price": "27288.9",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"amount": "0.00036",</span>
                                {"\n"}
                                {"                            "}"exchanged": "0.00000000",{"\n"}
                                {"                            "}
                                <span className="red">"total": "10",</span>
                                {"\n"}
                                {"                            "}"canceled": "0.00000000",{"\n"}
                                {"                            "}"stop_limit": null,{"\n"}
                                {"                            "}"maker_fee_in_percent": "0.30",{"\n"}
                                {"                            "}"taker_fee_in_percent": "0.30",{"\n"}
                                {"                            "}
                                <span className="red">
                                    "created_at": "2024-03-13T11:18:55.000000Z"
                                </span>
                                {"\n"}
                                {"                        "}
                                {"}"},{"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                <span>مارکت </span>
                                <span>=</span>
                                <span>trade_pair</span>
                            </p>
                            <p>
                                <span>مقدار </span>
                                <span>=</span>
                                <span>اگر خرید بود</span>
                                <span>total + quote_coin</span>
                            </p>
                            <p>
                                <span>مقدار </span>
                                <span>=</span>
                                <span>اگر فروش بود</span>
                                <span>amount + bace_coin</span>
                            </p>
                            <p>
                                <span>قیمت </span>
                                <span>=</span>
                                <span>price + quote_coin</span>
                            </p>
                            <p>
                                <span>نوع</span>
                                <span>=</span>
                                <span>type</span>
                            </p>
                            <p>
                                <span>تاریخ و زمان</span>
                                <span>=</span>
                                <span>created_at</span>
                            </p>
                            <h3>Convert box</h3>
                            <p>به swap مراجعه شود.</p>
                        </section>
                        <section id="Exchange">
                            <h2>Exchange</h2>
                            <h3>نوار بالایی</h3>
                            <img src={require("./assets/1.png")} />
                            از https://api.moneyex.org/coins لیست کوین‌ها گرفته میشود:
                            <pre>
                                [{"\n"}
                                {"    "}
                                {"{"}
                                {"\n"}
                                {"        "}"symbol": "AAVE",{"\n"}
                                {"        "}"name": "Aave",{"\n"}
                                {"        "}"type": "erc20",{"\n"}
                                {"        "}"icon": "__AAVE.png",{"\n"}
                                {"        "}"decimal_place": 2,{"\n"}
                                {"        "}"floating_point": 3{"\n"}
                                {"    "}
                                {"}"},{"\n"}
                                {"    "}...24+{"\n"}]
                            </pre>
                            <p>تایتل کوین: symbol + /USDT</p>
                            <p>سابتایتل کوین: name</p>
                            <p>
                                عکس کوین: `https://api.moneyex.org/storage/images/coin-icons/${"{"}icon
                                {"}"}`
                            </p>
                            <br />
                            <p>نکته: این دیتا شامل TOMAN و USDT هم هست که استفاده نمیشوند.</p>
                            <br />
                            <p>
                                به سوکت wss://ws.moneyex.org یک پیام با محتوای type:marketTicker و
                                symbol:* ارسال میشود:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"symbolName": "BTC-USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"buy": "73247.7",{"\n"}
                                {"    "}"sell": "73247.8",{"\n"}
                                {"    "}"change": "0.0193",{"\n"}
                                {"    "}"change_price": "1392.1",{"\n"}
                                {"    "}"high_price": "73634.9",{"\n"}
                                {"    "}"low_price": "68653.1",{"\n"}
                                {"    "}"base_coin_volume": "8438.07631053",{"\n"}
                                {"    "}"quote_coin_volume": "602977089.963872956",{"\n"}
                                {"    "}"latest_price": "73247.8",{"\n"}
                                {"    "}"average_price": "72039.77825475",{"\n"}
                                {"    "}"taker_fee_rate": "0.001",{"\n"}
                                {"    "}"maker_fee_rate": "0.001",{"\n"}
                                {"    "}"taker_coefficient": "1",{"\n"}
                                {"    "}"maker_coefficient": "1"{"\n"}
                                {"}"}
                            </pre>
                            <p>قیمت: latest_price</p>
                            <p>تغییر در ۲۴ ساعت: change</p>
                            <p>بالاترین قیمت در ۲۴ ساعت: high_price</p>
                            <p>پایین‌ترین قیمت در ۲۴ ساعت: low_price</p>
                            <p>مقدار در ۲۴ ساعت: base_coin_volume</p>
                            <p>حجم در ۲۴ ساعت: quote_coin_volume</p>
                            <h3>تبدیل ارز</h3>
                            <p>
                                مثلا برای BTC با توکن به
                                https://api.moneyex.org/user/coin-pairs/BTC_USDT/balance درخواست
                                ارسال میشود:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"simple_symbol": "BTCUSDT",{"\n"}
                                {"    "}"pair_name": "BTC_USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"base_coin_balance": 0,{"\n"}
                                {"    "}"base_coin_on_order": 0,{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"quote_coin_balance": 9996229.45849782,{"\n"}
                                {"    "}"quote_coin_on_order": 0{"\n"}
                                {"}"}
                            </pre>
                            <p>موجودی (در قسمت خرید): quote_coin_balance</p>
                            <p>موجودی (در قسمت فروش): base_coin_balance</p>
                            <h4>در خرید:</h4>
                            <p>در نوع Market:</p>
                            <p>مجموع: تعداد * قیمت کوین</p>
                            <p>درصد اینپوت رنج: موجودی تتر کاربر / (تعداد * قیمت کوین)</p>
                            <br />
                            <p>در نوع Limit و Stop Limit:</p>
                            <p>مجموع: تعداد * قیمت سفارش</p>
                            <p>درصد اینپوت رنج: موجودی تتر کاربر / (تعداد * قیمت سفارش)</p>
                            <h4>در فروش:</h4>
                            <p>در نوع Market:</p>
                            <p>مجموع: تعداد * قیمت کوین</p>
                            <p>درصد اینپوت رنج: (موجودی کوین کاربر / 100) * تعداد</p>
                            <br />
                            <p>در نوع Limit و Stop Limit:</p>
                            <p>مجموع: تعداد * قیمت سفارش</p>
                            <p>درصد اینپوت رنج: (موجودی کوین کاربر / 100) * تعداد</p>
                            <br />
                            <h4>POST</h4>
                            <p>
                                برای انجام عملیات خرید یا فروش، با توکن به
                                https://api.moneyex.org/user/orders/market درخواست POST با مثل
                                این مقادیر ارسال میشود:
                            </p>
                            <br />
                            <p>مثلا برای خرید Market:</p>
                            <p>order_type: buy</p>
                            <p>trade_pair: BTC_USDT</p>
                            <p>تعداد :amount</p>
                            <br />
                            <p>مثلا برای فروش Stop Limit:</p>
                            <p>order_type: sell</p>
                            <p>category: stop_limit</p>
                            <p>trade_pair: BTC_USDT</p>
                            <p>تعداد :amount</p>
                            <p>قیمت سفارش :price</p>
                            <p>حد ضرر :stop</p>
                            <h3>سفارشات باز</h3>
                            <p>
                                به https://api.moneyex.org/user/orders/open با توکن درخواست
                                ارسال میشود:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"data": [{"\n"}
                                {"        "}
                                {"{"}
                                {"\n"}
                                {"            "}"id": "a654d399-e7dd-4f4a-b3dc-5b058584dc12",{"\n"}
                                {"            "}"base_coin": "BTC",{"\n"}
                                {"            "}"quote_coin": "USDT",{"\n"}
                                {"            "}"trade_pair": "BTC_USDT",{"\n"}
                                {"            "}"category": "market",{"\n"}
                                {"            "}"type": "buy",{"\n"}
                                {"            "}"status": "pending",{"\n"}
                                {"            "}"price": "27288.9",{"\n"}
                                {"            "}"amount": "0.00036",{"\n"}
                                {"            "}"exchanged": "0.00000000",{"\n"}
                                {"            "}"total": "9.824004",{"\n"}
                                {"            "}"canceled": "0.00000000",{"\n"}
                                {"            "}"stop_limit": null,{"\n"}
                                {"            "}"maker_fee_in_percent": "0.30",{"\n"}
                                {"            "}"taker_fee_in_percent": "0.30",{"\n"}
                                {"            "}"open_amount": "0.00036000",{"\n"}
                                {"            "}"created_at": "2024-03-13T11:17:39.000000Z"{"\n"}
                                {"        "}
                                {"}"}...{"\n"}
                                {"    "}]{"\n"}
                                {"}"}
                            </pre>
                            <p>نماد: base_coin</p>
                            <p>قیمت: price</p>
                            <p>مقدار: amount</p>
                            <p>انجام شده: exchanged</p>
                            <p>سفارشات باز: open_amount</p>
                            <p>مجموع: total</p>
                            <p>نوع: type</p>
                            <p>تاریخ: created_at</p>
                            <br />
                            <p>
                                برای قسمت لغوکردن، id اردر به
                                `https://api.moneyex.org/user/orders/${"{"}id{"}"}` با متود
                                DELETE ارسال میشود.
                            </p>
                            <h3>معاملات</h3>
                            <p>به سوکت wss://ws.moneyex.org یک پیام با این مقادیر ارسال میشود:</p>
                            <p>type: level2</p>
                            <p>symbol: [BTC_USDT]</p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"asks": [{"\n"}
                                {"        "}[{"\n"}
                                {"            "}"73197.3",{"\n"}
                                {"            "}"1.69201478"{"\n"}
                                {"        "}],...4+{"\n"}
                                {"    "}],{"\n"}
                                {"    "}"bids": [{"\n"}
                                {"        "}[{"\n"}
                                {"            "}"73197.2",{"\n"}
                                {"            "}"0.49009142"{"\n"}
                                {"        "}],...4+{"\n"}
                                {"    "}],{"\n"}
                                {"    "}"timestamp": 1710330577751,{"\n"}
                                {"    "}"symbol": "BTC_USDT"{"\n"}
                                {"}"}
                            </pre>
                            <img src={require("./assets/2.png")} width={500} />
                            <p>1. asks.item[0]</p>
                            <p>2. asks.item[1]</p>
                            <p>3. asks.item[0] * asks.item[1]</p>
                            <p>4. bids.item[0]</p>
                            <p>5. bids.item[1]</p>
                            <p>6. bids.item[0] * bids.item[1]</p>
                            <p>7. میانگین ده قیمت asks و bids (مقادیر اول هر فرد asks و bids)</p>
                        </section>
                        {/* فراموشی رمز عبور */}
                        <section id="Forget">
                            <h2>
                                <span>Forget</span>
                                <span>(فراموشی رمز عبور)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>https://api.moneyex.org/forget-password-by-mobile</span>
                                آبجکتی به صورت زیر ارسال میکنیم
                                <br />
                            </p>
                            <pre>
                                {"                    "}
                                {"{"}
                                {"\n"}
                                {"                        "}"mobile": "+989379616251"{"\n"}
                                {"                    "}
                                {"}"}
                                {"\n"}
                                {"                  "}
                            </pre>
                            <p />
                            <p>
                                بعد کاربر را به صفحه
                                <span>verification-two</span>
                                منتقل میکنیم
                            </p>
                        </section>
                        <section id="Home">
                            <h2>Home</h2>
                            <h3>قسمت خرید آسان</h3>
                            <p>به swap مراجعه شود.</p>
                            <h3>سوکت</h3>
                            <p>
                                از https://api.moneyex.org/ticker/featured لیست کوین ها را
                                میگیریم:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"name": "BTC_USDT",{"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"price_floating_point": 2,{"\n"}
                                {"    "}"buy": 0,{"\n"}
                                {"    "}"sell": 0,{"\n"}
                                {"    "}"latest_price": "0",{"\n"}
                                {"    "}"low_price": 0,{"\n"}
                                {"    "}"high_price": 0,{"\n"}
                                {"    "}"change": 0,{"\n"}
                                {"    "}"base_coin_volume": 0,{"\n"}
                                {"    "}"quote_coin_volume": 0,{"\n"}
                                {"    "}"base_coin_icon":
                                "https://api.moneyex.org/storage/images/coin-icons/__BTC.png",{"\n"}
                                {"    "}"marketLink": "https://api.moneyex.org/swap/BTC_USDT"{"\n"}
                                {"}"},{"\n"}...8+
                            </pre>
                            <p>اسم کوین: base_coin</p>
                            <p>قیمت: latest_price</p>
                            <p>تغییر: change</p>
                            <br />
                            <p>بعد از سوکت wss://ws.moneyex.org تغذیه میشود:</p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"symbolName": "BTC-USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"buy": "72018.7",{"\n"}
                                {"    "}"sell": "72018.8",{"\n"}
                                {"    "}"change": "0.0324",{"\n"}
                                {"    "}"change_price": "2262.8",{"\n"}
                                {"    "}"high_price": "72228.6",{"\n"}
                                {"    "}"low_price": "67092.3",{"\n"}
                                {"    "}"base_coin_volume": "8909.15322516",{"\n"}
                                {"    "}"quote_coin_volume": "620134099.827227286",{"\n"}
                                {"    "}"latest_price": "72018.8",{"\n"}
                                {"    "}"average_price": "69214.66308541",{"\n"}
                                {"    "}"taker_fee_rate": "0.001",{"\n"}
                                {"    "}"maker_fee_rate": "0.001",{"\n"}
                                {"    "}"taker_coefficient": "1",{"\n"}
                                {"    "}"maker_coefficient": "1"{"\n"}
                                {"}"}
                            </pre>
                            <p>قیمت: latest_price</p>
                            <p>تغییر: change</p>
                            <h3>جدول پیج</h3>
                            <p>
                                از https://api.moneyex.org/ticker?page=1 لیست کوین ها گرفته
                                میشود. (این api محتوای pagination هم دارد که در حال حاضر از آن استفاده
                                نشده است.)
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"items": [{"\n"}
                                {"        "}
                                {"{"}
                                {"\n"}
                                {"            "}"name": "AAVE_USDT",{"\n"}
                                {"            "}"symbol": "AAVE_USDT",{"\n"}
                                {"            "}"base_coin": "AAVE",{"\n"}
                                {"            "}"quote_coin": "USDT",{"\n"}
                                {"            "}"price_floating_point": 2,{"\n"}
                                {"            "}"buy": 0,{"\n"}
                                {"            "}"sell": 0,{"\n"}
                                {"            "}"latest_price": "0",{"\n"}
                                {"            "}"low_price": 0,{"\n"}
                                {"            "}"high_price": 0,{"\n"}
                                {"            "}"change": 0,{"\n"}
                                {"            "}"base_coin_volume": 0,{"\n"}
                                {"            "}"quote_coin_volume": 0,{"\n"}
                                {"            "}"base_coin_icon":
                                "https://api.moneyex.org/storage/images/coin-icons/__AAVE.png",
                                {"\n"}
                                {"            "}"marketLink":
                                "https://api.moneyex.org/swap/AAVE_USDT"{"\n"}
                                {"        "}
                                {"}"},{"\n"}
                                {"        "}...23+{"\n"}
                                {"    "}],{"\n"}
                                {"    "}"per_page": 50,{"\n"}
                                {"    "}"current_page": 1,{"\n"}
                                {"    "}"total": 24,{"\n"}
                                {"    "}"last_page": 1{"\n"}
                                {"}"}
                            </pre>
                            <p>نماد: symbol</p>
                            <p>قیمت: latest_price</p>
                            <p>بالاترین قیمت (۲۴ ساعت): high_price</p>
                            <p>تغییرات (۲۴ ساعت): change</p>
                            <br />
                            <p>بعد از سوکت wss://ws.moneyex.org تغذیه میشود:</p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"symbolName": "BTC-USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"buy": "72018.7",{"\n"}
                                {"    "}"sell": "72018.8",{"\n"}
                                {"    "}"change": "0.0324",{"\n"}
                                {"    "}"change_price": "2262.8",{"\n"}
                                {"    "}"high_price": "72228.6",{"\n"}
                                {"    "}"low_price": "67092.3",{"\n"}
                                {"    "}"base_coin_volume": "8909.15322516",{"\n"}
                                {"    "}"quote_coin_volume": "620134099.827227286",{"\n"}
                                {"    "}"latest_price": "72018.8",{"\n"}
                                {"    "}"average_price": "69214.66308541",{"\n"}
                                {"    "}"taker_fee_rate": "0.001",{"\n"}
                                {"    "}"maker_fee_rate": "0.001",{"\n"}
                                {"    "}"taker_coefficient": "1",{"\n"}
                                {"    "}"maker_coefficient": "1"{"\n"}
                                {"}"}
                            </pre>
                            <p>قیمت: latest_price</p>
                            <p>بالاترین قیمت (۲۴ ساعت): high_price</p>
                            <p>تغییرات (۲۴ ساعت): change</p>
                            <h3>بلاگ</h3>
                            <p>
                                https://api.moneyex.org/blog/featured-posts بریا دریافت پست ها
                            </p>
                            <pre>
                                {"                        "}[{"\n"}
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"title": "گزارش اخیر می‌گوید حاشیه سود
                                دارندگان کوتاه‌مدت بیت‌کوین به صفر رسیده است",{"\n"}
                                {"                            "}"slug":
                                "gzarsh-akhyr-mygoyd-hashyh-sod-darndgan-kotahmdt-bytkoyn-bh-sfr-rsydh-ast",
                                {"\n"}
                                {"                            "}"image":
                                "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgwIiBoZWlnaHQ9Ijc4NiIgdmlld0JveD0iMCAwIDEyODAgNzg2Ij4NCiAgICAgICAgICAgIDxyZWN0IGZpbGw9IiNlZWUiIHdpZHRoPSIxMjgwIiBoZWlnaHQ9Ijc4NiIvPg0KICAgICAgICAgICAgPHRleHQgZmlsbD0icmdiYSgwLDAsMCwwLjUpIiBmb250LWZhbWlseT0ic2Fucy1zZXJpZiIgZm9udC1zaXplPSIxMDAiIGR5PSIxMC41IiBmb250LXdlaWdodD0iYm9sZCIgeD0iNTAlIiB5PSI1MCUiIHRleHQtYW5jaG9yPSJtaWRkbGUiPg0KICAgICAgICAgICAgICAgIDEyODDDlzc4Ng0KICAgICAgICAgICAgPC90ZXh0Pg0KICAgICAgICA8L3N2Zz4=",
                                {"\n"}
                                {"                            "}"summary": "گزارش اخیر می‌گوید حاشیه
                                سود دارندگان کوتاه‌مدت بیت‌کوین به صفر رسیده است",{"\n"}
                                {"                            "}"comments_count": null,{"\n"}
                                {"                            "}"created_at": "1 ماه پیش"{"\n"}
                                {"                        "}
                                {"}"}, ...{"\n"}
                                {"                        "}]{"\n"}
                                {"                    "}
                            </pre>
                        </section>
                        {/* ورود */}
                        <section id="Login">
                            <h2>
                                <span>Login</span>
                                <span>(ورود)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>https://api.moneyex.org/login</span>
                                آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"username": "+989193263689",{"\n"}
                                {"                            "}"password": "11223344",{"\n"}
                                {"                            "}"type": "mobile"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس جواب بازگشتی به صورت زیر خواهد بود</p>
                            <pre>
                                {"                    "}
                                {"{"}
                                {"\n"}
                                {"                        "}"success": true,{"\n"}
                                {"                        "}"message": "وارد شدید.",{"\n"}
                                {"                        "}"data": {"{"}
                                {"\n"}
                                {"                            "}
                                <span className="red">
                                    "access_token": "378|hDp9uFmEktjYq6jwUlwr17WBI2LvFuQoeJ2un8uN"
                                </span>
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                                {"}"}
                                {"\n"}
                                {"                  "}
                            </pre>
                            <span>توکن را ذخیره</span>،
                            <span>سپس کاربر را به صفحه dashboard منتقل میکنیم</span>
                        </section>
                        <section id="Markets">
                            <h2>Markets</h2>
                            <h3>سوکت</h3>
                            <p>
                                از https://api.moneyex.org/ticker/featured لیست کوین ها را
                                میگیریم:
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"name": "BTC_USDT",{"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"price_floating_point": 2,{"\n"}
                                {"    "}"buy": 0,{"\n"}
                                {"    "}"sell": 0,{"\n"}
                                {"    "}"latest_price": "0",{"\n"}
                                {"    "}"low_price": 0,{"\n"}
                                {"    "}"high_price": 0,{"\n"}
                                {"    "}"change": 0,{"\n"}
                                {"    "}"base_coin_volume": 0,{"\n"}
                                {"    "}"quote_coin_volume": 0,{"\n"}
                                {"    "}"base_coin_icon":
                                "https://api.moneyex.org/storage/images/coin-icons/__BTC.png",{"\n"}
                                {"    "}"marketLink": "https://api.moneyex.org/swap/BTC_USDT"{"\n"}
                                {"}"},{"\n"}...8+
                            </pre>
                            <p>اسم کوین: base_coin</p>
                            <p>قیمت: latest_price</p>
                            <p>تغییر: change</p>
                            <br />
                            <p>بعد از سوکت wss://ws.moneyex.org تغذیه میشود:</p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"symbolName": "BTC-USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"buy": "72018.7",{"\n"}
                                {"    "}"sell": "72018.8",{"\n"}
                                {"    "}"change": "0.0324",{"\n"}
                                {"    "}"change_price": "2262.8",{"\n"}
                                {"    "}"high_price": "72228.6",{"\n"}
                                {"    "}"low_price": "67092.3",{"\n"}
                                {"    "}"base_coin_volume": "8909.15322516",{"\n"}
                                {"    "}"quote_coin_volume": "620134099.827227286",{"\n"}
                                {"    "}"latest_price": "72018.8",{"\n"}
                                {"    "}"average_price": "69214.66308541",{"\n"}
                                {"    "}"taker_fee_rate": "0.001",{"\n"}
                                {"    "}"maker_fee_rate": "0.001",{"\n"}
                                {"    "}"taker_coefficient": "1",{"\n"}
                                {"    "}"maker_coefficient": "1"{"\n"}
                                {"}"}
                            </pre>
                            <p>قیمت: latest_price</p>
                            <p>تغییر: change</p>
                            <h3>جدول پیج</h3>
                            <p>
                                از https://api.moneyex.org/ticker?page=1 لیست کوین ها گرفته
                                میشود. (این api محتوای pagination هم دارد که در حال حاضر از آن استفاده
                                نشده است.)
                            </p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"items": [{"\n"}
                                {"        "}
                                {"{"}
                                {"\n"}
                                {"            "}"name": "AAVE_USDT",{"\n"}
                                {"            "}"symbol": "AAVE_USDT",{"\n"}
                                {"            "}"base_coin": "AAVE",{"\n"}
                                {"            "}"quote_coin": "USDT",{"\n"}
                                {"            "}"price_floating_point": 2,{"\n"}
                                {"            "}"buy": 0,{"\n"}
                                {"            "}"sell": 0,{"\n"}
                                {"            "}"latest_price": "0",{"\n"}
                                {"            "}"low_price": 0,{"\n"}
                                {"            "}"high_price": 0,{"\n"}
                                {"            "}"change": 0,{"\n"}
                                {"            "}"base_coin_volume": 0,{"\n"}
                                {"            "}"quote_coin_volume": 0,{"\n"}
                                {"            "}"base_coin_icon":
                                "https://api.moneyex.org/storage/images/coin-icons/__AAVE.png",
                                {"\n"}
                                {"            "}"marketLink":
                                "https://api.moneyex.org/swap/AAVE_USDT"{"\n"}
                                {"        "}
                                {"}"},{"\n"}
                                {"        "}...23+{"\n"}
                                {"    "}],{"\n"}
                                {"    "}"per_page": 50,{"\n"}
                                {"    "}"current_page": 1,{"\n"}
                                {"    "}"total": 24,{"\n"}
                                {"    "}"last_page": 1{"\n"}
                                {"}"}
                            </pre>
                            <p>نماد: symbol</p>
                            <p>قیمت: latest_price</p>
                            <p>بالاترین قیمت (۲۴ ساعت): high_price</p>
                            <p>تغییرات (۲۴ ساعت): change</p>
                            <br />
                            <p>بعد از سوکت wss://ws.moneyex.org تغذیه میشود:</p>
                            <pre>
                                {"{"}
                                {"\n"}
                                {"    "}"symbol": "BTC_USDT",{"\n"}
                                {"    "}"symbolName": "BTC-USDT",{"\n"}
                                {"    "}"base_coin": "BTC",{"\n"}
                                {"    "}"quote_coin": "USDT",{"\n"}
                                {"    "}"buy": "72018.7",{"\n"}
                                {"    "}"sell": "72018.8",{"\n"}
                                {"    "}"change": "0.0324",{"\n"}
                                {"    "}"change_price": "2262.8",{"\n"}
                                {"    "}"high_price": "72228.6",{"\n"}
                                {"    "}"low_price": "67092.3",{"\n"}
                                {"    "}"base_coin_volume": "8909.15322516",{"\n"}
                                {"    "}"quote_coin_volume": "620134099.827227286",{"\n"}
                                {"    "}"latest_price": "72018.8",{"\n"}
                                {"    "}"average_price": "69214.66308541",{"\n"}
                                {"    "}"taker_fee_rate": "0.001",{"\n"}
                                {"    "}"maker_fee_rate": "0.001",{"\n"}
                                {"    "}"taker_coefficient": "1",{"\n"}
                                {"    "}"maker_coefficient": "1"{"\n"}
                                {"}"}
                            </pre>
                            <p>قیمت: latest_price</p>
                            <p>بالاترین قیمت (۲۴ ساعت): high_price</p>
                            <p>تغییرات (۲۴ ساعت): change</p>
                        </section>
                        {/* گذر واژه جدید */}
                        <section id="New-Password">
                            <h2>
                                <span>New-Password</span>
                                <span>(گذر واژه جدید)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>
                                    https://api.moneyex.org/forget-password/set-password
                                </span>
                                آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"type": "mobile",{"\n"}
                                {"                            "}"email": null,{"\n"}
                                {"                            "}"mobile": "+989904406922",{"\n"}
                                {"                            "}"verification_code": "703286",{"\n"}
                                {"                            "}"new_password": "!Q@W#E$R1q2w3e4r",
                                {"\n"}
                                {"                            "}"password_confirmation":
                                "!Q@W#E$R1q2w3e4r"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس کاربر را به صفحه login منتقل میکنیم</p>
                        </section>
                        <section id="Order">
                            <h2>Order</h2>
                            <p>
                                یه ریکوست به یو ار ال https://api.moneyex.org/user/orders/open
                            </p>
                            <pre>
                                {"\n"}
                                {"                    "}
                            </pre>
                        </section>
                        <section id="Records">
                            <h2>Records</h2>
                            <h3>
                                برای این بخش ما باید path رو چک کنیم . از path اسم coin کلیک شده رو
                                برداریم و نسبت به اون درخواست بزنیم
                            </h3>
                            <p>
                                به طور مثال روی TRX کلیک شده
                                <br />
                                https://api.moneyex.org/user/withdrawals/TRX
                                <br />
                                Get (برای خرید)
                            </p>
                            <pre>
                                {"    "}"data": [{"\n"}
                                {"        "}
                                {"{"}
                                {"\n"}
                                {"            "}"id": "b5c4d932-b626-4524-9218-78f7c87ab4cd",{"\n"}
                                {"            "}"wallet_id": "ec3bc64f-0472-4118-a723-a263ce6bc0ff",
                                {"\n"}
                                {"            "}"coin_symbol": "TRX",{"\n"}
                                {"            "}"coin_name": "Tron",{"\n"}
                                {"            "}"coin_type": "crypto",{"\n"}
                                {"            "}"amount": "3.00000000",{"\n"}
                                {"            "}"system_fee": "0.00000000",{"\n"}
                                {"            "}"address": "TQhdP2Fk5QbatC3iRUisT5VXrTfGtHbwci",{"\n"}
                                {"            "}"txn_id": null,{"\n"}
                                {"            "}"status": "reviewing",{"\n"}
                                {"            "}"created_at": "2024-02-20T13:36:07.000000Z"{"\n"}
                                {"        "}
                                {"}"},{"\n"}
                            </pre>
                            <p>
                                {"{"}index.coin_symbol{"}"} | {"{"}index.coin_name{"}"} برای اسم ارز
                                <br />
                                {"{"}+index.amount{"}"} برای مبلغ(مثبت اولش برای اینه که فقط number
                                نمایش بده)
                                <br />
                                {"{"}index.created_at{"}"} برای زمان
                                <br />
                                {"{"}index.status{"}"} برای وضعیت
                                <br />
                                <br />
                            </p>
                            <h3>
                                به طور مثال روی TRX کلیک شده
                                <br />
                                https://api.moneyex.org/user/deposit/TRX
                                <br />
                                Get (برای فروش)
                            </h3>
                            <pre>
                                {"    "}"data": [{"\n"}
                                {"        "}
                                {"{"}
                                {"\n"}
                                {"            "}"id": "b5c4d932-b626-4524-9218-78f7c87ab4cd",{"\n"}
                                {"            "}"wallet_id": "ec3bc64f-0472-4118-a723-a263ce6bc0ff",
                                {"\n"}
                                {"            "}"coin_symbol": "TRX",{"\n"}
                                {"            "}"coin_name": "Tron",{"\n"}
                                {"            "}"coin_type": "crypto",{"\n"}
                                {"            "}"amount": "3.00000000",{"\n"}
                                {"            "}"system_fee": "0.00000000",{"\n"}
                                {"            "}"address": "TQhdP2Fk5QbatC3iRUisT5VXrTfGtHbwci",{"\n"}
                                {"            "}"txn_id": null,{"\n"}
                                {"            "}"status": "reviewing",{"\n"}
                                {"            "}"created_at": "2024-02-20T13:36:07.000000Z"{"\n"}
                                {"        "}
                                {"}"},{"\n"}
                            </pre>
                            <p>
                                {"{"}index.coin_symbol{"}"} | {"{"}index.coin_name{"}"} برای اسم ارز
                                <br />
                                {"{"}+index.amount{"}"} برای مبلغ(مثبت اولش برای اینه که فقط number
                                نمایش بده)
                                <br />
                                {"{"}index.created_at{"}"} برای زمان
                                <br />
                                {"{"}index.status{"}"} برای وضعیت
                                <br />
                                <br />
                            </p>
                        </section>
                        {/* ثبت نام */}
                        <section id="Register">
                            <h2>
                                <span>Register</span>
                                <span>(ثبت نام)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>https://api.moneyex.org/registration</span>
                                آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"country_code": "98",{"\n"}
                                {"                            "}"mobile": "9125876922",{"\n"}
                                {"                            "}"password": "12345678",{"\n"}
                                {"                            "}"password_confirmation": "12345678",
                                {"\n"}
                                {"                            "}"referral_code": "",{"\n"}
                                {"                            "}"check_agreement": true,{"\n"}
                                {"                            "}"type": "mobile"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس کاربر را به صفحه verification-one منتقل میکنیم</p>
                        </section>
                        <section id="Setting">
                            <h2>Setting</h2>
                            <h3>حساب بانکی</h3>
                            <p>
                                {" "}
                                برای گرفتن تمام کارت ها
                                https://api.moneyex.org/user/bank-accounts
                            </p>
                            <pre>
                                {"                        "}"data": [{"\n"}
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "130cf781-7d3a-454e-8d25-5cbfb1724d33",{"\n"}
                                {"                            "}"bank_name": "ssss",{"\n"}
                                {"                            "}"card_number": "1234569874563215",
                                {"\n"}
                                {"                            "}"iban": null,{"\n"}
                                {"                            "}"is_verified": false,{"\n"}
                                {"                            "}"is_active": true,{"\n"}
                                {"                            "}"created_at":
                                "2024-03-13T11:38:29.000000Z"{"\n"}
                                {"                        "}
                                {"}"}, ...{"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                bank_name : نام بانک
                                <br />
                                card_number : شماره کارت
                                <br />
                                iban : شماره شبا
                                <br />
                                is_active : وضعیت
                                <br />
                                <br />
                            </p>
                            <h3> برای اد کردن https://api.moneyex.org/user/bank-accounts</h3>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"bank_name": "رسالت جدیدم",{"\n"}
                                {"                            "}"card_number": "5041721065953241"
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                bank_name : نام بانک
                                <br />
                                card_number : شماره کارت
                                <br />
                                <br />
                            </p>
                            <h3>تنظیمات</h3>
                            <p>
                                تعویض گزر واژه
                                <br />
                                https://api.moneyex.org/user/password/update
                                <br />
                                با put
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"password": "!Q@W#E$R1q2w3e4r",{"\n"}
                                {"                            "}"new_password": "!Q@W#E$R1q2w3e4r",
                                {"\n"}
                                {"                            "}"new_password_confirmation":
                                "!Q@W#E$R1q2w3e4r"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                password : رمز قدیمی
                                <br />
                                new_password : زمر جدید
                                <br />
                                new_password_confirmation : تکرار رمز جدید
                                <br />
                            </p>
                        </section>
                        {/* خرید آسان */}
                        <section id="Swap">
                            <h2>
                                <span>Swap</span>
                                <span>(خرید آسان)</span>
                            </h2>
                            <br />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>https://api.moneyex.org/coins</span>
                                لیست تمامی ارز ها میگیریم
                            </p>
                            <span>توجه: با تغییر سلکت ها دوباره این دیتا باید فچ شود</span>
                            <hr />
                            <br />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>https://api.moneyex.org/coin-pairs</span>
                                لیست تمامی ارز هایی که قابلیت تبدیل شدن به یکدیگر را دارند را میگیریم
                            </p>
                            <span>توجه: با تغییر سلکت ها دوباره این دیتا باید فچ شود</span>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"base_coin": "AAVE",{"\n"}
                                {"                            "}"quote_coin": "USDT",{"\n"}
                                {"                            "}
                                <span className="red">"name": "AAVE_USDT",</span>
                                {"\n"}
                                {"                            "}"price_floating_point": 2,{"\n"}
                                {"                            "}
                                <span className="red">"base_min_size": "0.033",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"base_max_size": "32",</span>
                                {"\n"}
                                {"                            "}"quote_min_size": "2",{"\n"}
                                {"                            "}"quote_max_size": "2000"{"\n"}
                                {"                        "}
                                {"}"},{"\n"}
                                {"                    "}
                            </pre>
                            <p>مانند AAVE_USDT</p>
                            <p>همچنین min و max را</p>
                            <hr />
                            <br />
                            <br />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>
                                    https://api.moneyex.org/user/coin-pairs/
                                    <span className="red">TRX_USDT</span>/balance
                                </span>
                                <span>به همراه توکن،</span>
                                دارایی کاربر برای آن ارزی که میخواهد تبدیل شود را میگیریم
                            </p>
                            <span>توجه: url داینامیک است</span>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"simple_symbol": "TRXUSDT",{"\n"}
                                {"                            "}"pair_name": "TRX_USDT",{"\n"}
                                {"                            "}"base_coin": "TRX",{"\n"}
                                {"                            "}
                                <span className="red">"base_coin_balance": 9996,</span>
                                {"\n"}
                                {"                            "}"base_coin_on_order": 0,{"\n"}
                                {"                            "}"quote_coin": "USDT",{"\n"}
                                {"                            "}
                                <span className="red">"quote_coin_balance": 9996229.45849782,</span>
                                {"\n"}
                                {"                            "}"quote_coin_on_order": 0{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <hr />
                            <br />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>
                                    https://api.moneyex.org/swap/pair-ticker-info/
                                    <span className="red">BTC_USDT</span>
                                </span>
                                آخرین قیمت را بدست می آوریم
                            </p>
                            <span>توجه: url داینامیک است</span>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"name": "BTC_USDT",{"\n"}
                                {"                            "}"symbol": "BTC_USDT",{"\n"}
                                {"                            "}"base_coin": "BTC",{"\n"}
                                {"                            "}"quote_coin": "USDT",{"\n"}
                                {"                            "}"price_floating_point": 2,{"\n"}
                                {"                            "}"buy": 0,{"\n"}
                                {"                            "}"sell": 0,{"\n"}
                                {"                            "}
                                <span className="red">"latest_price": "27288.9",</span>
                                {"\n"}
                                {"                            "}"low_price": 0,{"\n"}
                                {"                            "}"high_price": 0,{"\n"}
                                {"                            "}"change": 0,{"\n"}
                                {"                            "}"base_coin_volume": 0,{"\n"}
                                {"                            "}"quote_coin_volume": 0,{"\n"}
                                {"                            "}"base_coin_icon":
                                "https://api.moneyex.org/storage/images/coin-icons/__BTC.png",{"\n"}
                                {"                            "}"marketLink":
                                "https://api.moneyex.org/swap/BTC_USDT",{"\n"}
                                {"                            "}"base_min_size": "0.0004",{"\n"}
                                {"                            "}"base_max_size": "1",{"\n"}
                                {"                            "}"quote_min_size": "10",{"\n"}
                                {"                            "}"quote_max_size": "30000"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <br />
                            <hr />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>https://api.moneyex.org/user/profile</span>
                                به همراه توکن، ضریب fee را میگیریم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"                            "}"assigned_role": "user",{"\n"}
                                {"                            "}"referrer_id": null,{"\n"}
                                {"                            "}"referral_code": "strZrJUu8N9nAMQM",
                                {"\n"}
                                {"                            "}"username": "+989193263689",{"\n"}
                                {"                            "}"email": null,{"\n"}
                                {"                            "}"mobile": "+989193263689",{"\n"}
                                {"                            "}"avatar": null,{"\n"}
                                {"                            "}"google2fa_secret": null,{"\n"}
                                {"                            "}"verify_code": null,{"\n"}
                                {"                            "}"is_id_verified": true,{"\n"}
                                {"                            "}"is_email_verified": 1,{"\n"}
                                {"                            "}"is_mobile_verified": true,{"\n"}
                                {"                            "}"is_financial_active": 1,{"\n"}
                                {"                            "}"is_accessible_under_maintenance": 0,
                                {"\n"}
                                {"                            "}"is_super_admin": 0,{"\n"}
                                {"                            "}"status": "active",{"\n"}
                                {"                            "}"created_at":
                                "2024-02-12T06:43:12.000000Z",{"\n"}
                                {"                            "}"updated_at":
                                "2024-03-10T10:29:44.000000Z",{"\n"}
                                {"                            "}"preference": {"{"}
                                {"\n"}
                                {"                                "}"id":
                                "a8c01665-9392-44bd-af2a-251fe6118903",{"\n"}
                                {"                                "}"user_id":
                                "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"                                "}"default_language": null,{"\n"}
                                {"                                "}"default_coin_pair": "TRX_USDT"
                                {"\n"}
                                {"                            "}
                                {"}"},{"\n"}
                                {"                            "}"profile": {"{"}
                                {"\n"}
                                {"                                "}"id":
                                "59555abe-ca77-11ee-830a-960002fc74ab",{"\n"}
                                {"                                "}"user_id":
                                "af6c8330-313e-4ebf-8a7b-6b27f9fe7e03",{"\n"}
                                {"                                "}"first_name": "امیر",{"\n"}
                                {"                                "}"last_name": "رضایی",{"\n"}
                                {"                                "}"address": "تهران",{"\n"}
                                {"                                "}"phone": null,{"\n"}
                                {"                                "}"birthday": "2014-02-13 17:23:18",
                                {"\n"}
                                {"                                "}"created_at": null,{"\n"}
                                {"                                "}"updated_at": null,{"\n"}
                                {"                                "}"national_id": "0012345678"{"\n"}
                                {"                            "}
                                {"}"},{"\n"}
                                {"                            "}"usdt_amount": 0,{"\n"}
                                {"                            "}
                                <span className="red">"taker_fee_percent": "0.3"</span>
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                        </section>
                        <section id="Transaction">
                            <h2>Transaction</h2>
                            <h3>
                                توی api ها و توی نوشته ها جدا شده / اما در کل یه ساختار کلی داره واسه
                                هر کدوم از خرید یا فروش
                            </h3>
                            <p>
                                https://api.moneyex.org/user/"deposits"/crypto برای ارز ها
                                <br />
                                توجه "متغیره نسبت به فروش یا خرید"
                            </p>
                            <pre>
                                {"                        "}"data": [{"\n"}
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "1642374e-cdfb-4275-8798-70d89d0951fd",{"\n"}
                                {"                            "}"wallet_id":
                                "ec3bc64f-0472-4118-a723-a263ce6bc0ff",{"\n"}
                                {"                            "}"coin_symbol": "TRX",{"\n"}
                                {"                            "}"coin_name": "Tron",{"\n"}
                                {"                            "}"coin_type": "crypto",{"\n"}
                                {"                            "}"amount": "10000.00000000",{"\n"}
                                {"                            "}"system_fee": "0.00000000",{"\n"}
                                {"                            "}"address": null,{"\n"}
                                {"                            "}"txn_id":
                                "a9154744-f8a8-46ac-a326-f2432201fe26",{"\n"}
                                {"                            "}"status": "completed",{"\n"}
                                {"                            "}"created_at":
                                "2024-02-12T07:23:40.000000Z"{"\n"}
                                {"                        "}
                                {"}"},{"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                coin_name | coin_symbol : نام ارز
                                <br />
                                created_at : زمان
                                <br />
                                amount : مقدار
                                <br />
                                id : شناسه
                                <br />
                                status : وضعیت
                                <br />
                                txn_id : هش تراکنش
                                <br />
                                <br />
                            </p>
                            <h3>
                                https://api.moneyex.org/user/"withdrawals"/fiat برای ریال ها
                                <br />
                                توجه "متغیره نسبت به فروش یا خرید"
                            </h3>
                            <pre>
                                {"                        "}
                                {"\n"}
                                {"                            "}"data": [{"\n"}
                                {"                                "}
                                {"{"}
                                {"\n"}
                                {"                                    "}"id":
                                "0716157f-03eb-4069-ad32-eac65c64ce20",{"\n"}
                                {"                                    "}"wallet_id":
                                "1050c161-9ff2-49fa-bba6-9e5240bec46a",{"\n"}
                                {"                                    "}"coin_symbol": "TOMAN",{"\n"}
                                {"                                    "}"coin_name": "TOMAN",{"\n"}
                                {"                                    "}"coin_type": "fiat",{"\n"}
                                {"                                    "}"amount": "100000.00000000",
                                {"\n"}
                                {"                                    "}"system_fee": "2000.00000000",
                                {"\n"}
                                {"                                    "}"address": "sss",{"\n"}
                                {"                                    "}"txn_id": null,{"\n"}
                                {"                                    "}"status": "reviewing",{"\n"}
                                {"                                    "}"created_at":
                                "2024-02-19T11:19:48.000000Z"{"\n"}
                                {"                                "}
                                {"}"},{"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                amount : مبلغ
                                <br />
                                created_at : زمان
                                <br />
                                id : شناسه
                                <br />
                                status : وضعیت
                                <br />
                                <br />
                            </p>
                        </section>
                        {/* کد تایید برای ثبت نام */}
                        <section id="Verification-one">
                            <h2>
                                <span>Verification-one</span>
                                <span>(کد تایید برای ثبت نام)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>https://api.moneyex.org/registration/verify</span>
                                آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"pre_registration_id":
                                "46422535-57f2-4772-990a-4b7719e19b32",{"\n"}
                                {"                            "}"key": "660667"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس کابر را به صفحه dashboard منتقل میکنیم</p>
                        </section>
                        {/* کد تایید برای فراموشی رمز عبور */}
                        <section id="Verfication-two">
                            <h2>
                                <span>Verfication-two</span>
                                <span>(کد تایید برای فراموشی رمز عبور)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>
                                    https://api.moneyex.org/forget-password-check-verification
                                </span>
                                آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"type": "mobile",{"\n"}
                                {"                            "}"email": null,{"\n"}
                                {"                            "}"mobile": "+989904406922",{"\n"}
                                {"                            "}"verification_code": "703286"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس کاربر را به صفحه new password منتقل میکنیم</p>
                        </section>
                        {/* کیف پول */}
                        <section id="Wallet">
                            <h2>
                                <span>Wallet</span>
                                <span>(کیف پول)</span>
                            </h2>
                            <p>
                                از آدرس
                                <span>https://api.moneyex.org/user/wallets</span>
                                به همراه توکن ، اطلاعات کیف پول کاربر و همچنین صفحه بندی را میگیریم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"id":
                                "d697b884-6644-479d-8a23-1fcef3356af8",{"\n"}
                                {"                            "}
                                <span className="red">"coin_symbol": "USDT",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"coin_name": "Tether",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"coin_type": "trc20",</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"primary_balance": 9996229.45849782,</span>
                                {"\n"}
                                {"                            "}"on_order_balance": 0,{"\n"}
                                {"                            "}"usdt_amount": 0,{"\n"}
                                {"                            "}"is_active": true,{"\n"}
                                {"                            "}
                                <span className="red">"deposit_status": true,</span>
                                {"\n"}
                                {"                            "}
                                <span className="red">"withdrawal_status": true</span>
                                {"\n"}
                                {"                        "}
                                {"}"},{"\n"}
                                {"                        "}"links": {"{"}
                                {"\n"}
                                {"                            "}
                                <span className="red">
                                    "first": "https://api.moneyex.org/user/wallets?page=1",
                                </span>
                                {"\n"}
                                {"                            "}
                                <span className="red">
                                    "last": "https://api.moneyex.org/user/wallets?page=2",
                                </span>
                                {"\n"}
                                {"                            "}"prev": null,{"\n"}
                                {"                            "}"next":
                                "https://api.moneyex.org/user/wallets?page=2"{"\n"}
                                {"                        "}
                                {"}"},{"\n"}
                                {"                        "}"meta": {"{"}
                                {"\n"}
                                {"                            "}"current_page": 1,{"\n"}
                                {"                            "}"from": 1,{"\n"}
                                {"                            "}"last_page": 2,{"\n"}
                                {"                            "}"links": [{"\n"}
                                {"                                "}
                                {"{"}
                                {"\n"}
                                {"                                    "}"url": null,{"\n"}
                                {"                                    "}"label": "« Previous",{"\n"}
                                {"                                    "}"active": false{"\n"}
                                {"                                "}
                                {"}"},{"\n"}
                                {"                                "}
                                {"{"}
                                {"\n"}
                                {"                                    "}"url":
                                "https://api.moneyex.org/user/wallets?page=1",{"\n"}
                                {"                                    "}"label": "1",{"\n"}
                                {"                                    "}"active": true{"\n"}
                                {"                                "}
                                {"}"},{"\n"}
                                {"                                "}
                                {"{"}
                                {"\n"}
                                {"                                    "}"url":
                                "https://api.moneyex.org/user/wallets?page=2",{"\n"}
                                {"                                    "}"label": "2",{"\n"}
                                {"                                    "}"active": false{"\n"}
                                {"                                "}
                                {"}"},{"\n"}
                                {"                                "}
                                {"{"}
                                {"\n"}
                                {"                                    "}"url":
                                "https://api.moneyex.org/user/wallets?page=2",{"\n"}
                                {"                                    "}"label": "Next »",{"\n"}
                                {"                                    "}"active": false{"\n"}
                                {"                                "}
                                {"}"}
                                {"\n"}
                                {"                            "}],{"\n"}
                                {"                            "}"path":
                                "https://api.moneyex.org/user/wallets",{"\n"}
                                {"                            "}
                                <span className="red">"per_page": 15,</span>
                                {"\n"}
                                {"                            "}"to": 15,{"\n"}
                                {"                            "}
                                <span className="red">"total": 25</span>
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                <span>نماد ارز</span>
                                <span>=</span>
                                <span>coin_symbol</span>
                            </p>
                            <p>
                                <span>نام ارز</span>
                                <span>=</span>
                                <span>coin_name</span>
                            </p>
                            <p>
                                <span>دسته بندی ارز</span>
                                <span>=</span>
                                <span>coin_type</span>
                            </p>
                            <p>
                                <span>مقدارارز</span>
                                <span>=</span>
                                <span>primary_balance</span>
                            </p>
                            <p>
                                <span>آیا قابلیت برداشت وجود دارد</span>
                                <span>=</span>
                                <span>primary_balance</span>
                            </p>
                            <p>
                                <span>مقدارارز</span>
                                <span>=</span>
                                <span>primary_balance</span>
                            </p>
                            <p>
                                <span>تعداد نمایش برای هر صفحه</span>
                                <span>=</span>
                                <span>per_page</span>
                            </p>
                            <p>
                                <span> تعداد کل </span>
                                <span>=</span>
                                <span>total</span>
                            </p>
                        </section>
                        {/* واریز ریالی */}
                        <section id="Wallet_Rial_Deposit">
                            <h2>
                                <span>Wallet_Rial_Deposit</span>
                                <span>(واریز ریالی)</span>
                            </h2>
                            <br />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>
                                    https://api.moneyex.org/user/wallets/
                                    <span className="red">TOMAN</span>
                                </span>
                                به همراه توکن، اطلاعات ارز مورد نظر را دریافت میکنیم.
                            </p>
                            <span>توجه:url داینامیک است</span>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"data": {"{"}
                                {"\n"}
                                {"                                "}
                                <span className="red">
                                    "id": "1050c161-9ff2-49fa-bba6-9e5240bec46a",
                                </span>
                                {"\n"}
                                {"                                "}"coin_symbol": "TOMAN",{"\n"}
                                {"                                "}"coin_name": "TOMAN",{"\n"}
                                {"                                "}
                                <span className="red">"coin_type": "fiat",</span>
                                {"\n"}
                                {"                                "}"primary_balance": 7036880,{"\n"}
                                {"                                "}"on_order_balance": 0,{"\n"}
                                {"                                "}"usdt_amount": 0,{"\n"}
                                {"                                "}"is_active": true,{"\n"}
                                {"                                "}"deposit_status": true,{"\n"}
                                {"                                "}"withdrawal_status": true,{"\n"}
                                {"                                "}"deposit_fee": 0,{"\n"}
                                {"                                "}"deposit_fee_type": "fixed",{"\n"}
                                {"                                "}"minimum_deposit_amount": 1000,
                                {"\n"}
                                {"                                "}"withdrawal_fee": 2000,{"\n"}
                                {"                                "}"withdrawal_fee_type": "fixed",
                                {"\n"}
                                {"                                "}"minimum_withdrawal_amount":
                                100000,{"\n"}
                                {"                               "}
                                <span className="red">
                                    {"\n"}
                                    {"                                "}"networks": [{"\n"}
                                    {"                                "}"ZarinPalApi",{"\n"}
                                    {"                                "}"PayPingApi",{"\n"}
                                    {"                                "}"VandarApi"{"\n"}
                                    {"                            "}],{"\n"}
                                    {"                               "}
                                </span>
                                {"\n"}
                                {"                                "}"addresses": []{"\n"}
                                {"                            "}
                                {"}"}
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                <span>نوع ارز</span>
                                <span>=</span>
                                <span>coin_type</span>
                            </p>
                            <p>
                                <span>درگاه پرداخت</span>
                                <span>=</span>
                                <span>networks</span>
                            </p>
                            <br />
                            <hr />
                            {/*  */}
                            <p>
                                به آدرس
                                <span>
                                    https://api.moneyex.org/user/wallets/
                                    <span className="red">coin.id</span>
                                    /deposits
                                </span>
                                به همراه توکن آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"amount": 1000,{"\n"}
                                {"                            "}"gateway": "ZarinPalApi",{"\n"}
                                {"                            "}"accepted": true{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <span>
                                سپس با توجه جواب دریافتی کاربر را به درگاه پرداخت منتقل میکنیم
                            </span>
                        </section>
                        {/* برداشت ریالی */}
                        <section id="Wallet_Rial_Withdraw">
                            <h2>
                                <span>Wallet_Rial_Withdraw</span>
                                <span>(برداشت ریالی)</span>
                            </h2>
                            <br />
                            <p>
                                از آدرس
                                <span>https://api.moneyex.org/user/bank-accounts/actives</span>
                                به همراه توکن، لیست بانک های فعال کاربر را دریافت میکنیم
                            </p>
                            <br />
                            <hr />
                            <p>
                                اطلاعات فرم را به آدرس
                                <span>
                                    https://api.moneyex.org/user/wallets/
                                    <span className="red">coin.id</span>/withdrawals
                                </span>
                                به همراه توکن، مانند آبجکت زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"amount": 100000,{"\n"}
                                {"                            "}"bank_account_id":
                                "dc2e81d9-4753-45a8-849f-a0522bd62c33"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس کاربر را به صفحه wallet منتقل میکنیم</p>
                        </section>
                        {/* تشخیص اولیه */}
                        <section id="Worod">
                            <h2>
                                <span>Worod</span>
                                <span>(تشخیص اولیه)</span>
                            </h2>
                            <p>
                                به آدرس
                                <span>https://api.moneyex.org/registration/judge</span>
                                آبجکتی مانند زیر ارسال میکنیم
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"country_code": "98",{"\n"}
                                {"                            "}"mobile": "9913067077",{"\n"}
                                {"                            "}"type": "mobile"{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس جواب بازگشتی به صورت زیر خواهد بود</p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"exist": true{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>اگر جواب true بود کاربر را به صفحه login منتقل میکنیم</p>
                            <p>در غیر اینصورت به صفحه register</p>
                        </section>
                        {/* برداشت */}
                        <section id="Xchange_Deposit">
                            <h2>
                                <span>Xchange_Deposit</span>
                                <span />
                            </h2>
                            <br />
                            <p>
                                از آدرس
                                <span>
                                    https://api.moneyex.org/user/wallets/
                                    <span className="red">TRX</span>
                                </span>
                                به همراه توکن، اطلاعات ارز مورد نظر را دریافت میکنیم.
                            </p>
                            <span>توجه:url داینامیک است</span>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"data": {"{"}
                                {"\n"}
                                {"                                "}
                                <span className="red">
                                    "id": "ec3bc64f-0472-4118-a723-a263ce6bc0ff",
                                </span>
                                {"\n"}
                                {"                                "}"coin_symbol": "TRX",{"\n"}
                                {"                                "}"coin_name": "Tron",{"\n"}
                                {"                                "}"coin_type": "crypto",{"\n"}
                                {"                                "}"primary_balance": 9996,{"\n"}
                                {"                                "}"on_order_balance": 0,{"\n"}
                                {"                                "}"usdt_amount": 0,{"\n"}
                                {"                                "}"is_active": true,{"\n"}
                                {"                                "}"deposit_status": true,{"\n"}
                                {"                                "}"withdrawal_status": true,{"\n"}
                                {"                                "}"deposit_fee": 0,{"\n"}
                                {"                                "}"deposit_fee_type": "fixed",{"\n"}
                                {"                                "}"minimum_deposit_amount": 0,{"\n"}
                                {"                                "}
                                <span className="red">"withdrawal_fee": 0,</span>
                                {"\n"}
                                {"                                "}"withdrawal_fee_type": "fixed",
                                {"\n"}
                                {"                                "}"minimum_withdrawal_amount": 1,
                                {"\n"}
                                {"                               "}
                                <span className="red">
                                    {"\n"}
                                    {"                                "}"networks": [{"\n"}
                                    {"                                "}"TronApi"{"\n"}
                                    {"                                 "}],{"\n"}
                                    {"                               "}
                                </span>
                                {"\n"}
                                {"                               "}
                                <span className="red">
                                    {"\n"}
                                    {"                                "}"addresses": {"{"}
                                    {"\n"}
                                    {"                                    "}"TronApi":
                                    "TC7sRBaG4M8GDcmiHgRYLtiFBevKJRdwnm"{"\n"}
                                    {"                                "}
                                    {"}"}
                                    {"\n"}
                                    {"                               "}
                                </span>
                                {"\n"}
                                {"                            "}
                                {"}"}
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                <span>آدرس در شبکه</span>
                                <span>=</span>
                                <span>addresses</span>
                            </p>
                            <p>
                                <span>برای</span>
                                <span>qrcode</span>
                                <span>از آدرس در شبکه استفاده میشود</span>
                            </p>
                            <br />
                            <hr />
                        </section>
                        {/* ارسال */}
                        <section id="Xchange_Withdraw">
                            <h2>
                                <span>Xchange_Withdraw</span>
                            </h2>
                            <br />
                            {/*  */}
                            <p>
                                از آدرس
                                <span>
                                    https://api.moneyex.org/user/wallets/
                                    <span className="red">TRX</span>
                                </span>
                                به همراه توکن، اطلاعات ارز مورد نظر را دریافت میکنیم.
                            </p>
                            <span>توجه:url داینامیک است</span>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"data": {"{"}
                                {"\n"}
                                {"                                "}
                                <span className="red">
                                    "id": "ec3bc64f-0472-4118-a723-a263ce6bc0ff",
                                </span>
                                {"\n"}
                                {"                                "}"coin_symbol": "TRX",{"\n"}
                                {"                                "}"coin_name": "Tron",{"\n"}
                                {"                                "}"coin_type": "crypto",{"\n"}
                                {"                                "}"primary_balance": 9996,{"\n"}
                                {"                                "}"on_order_balance": 0,{"\n"}
                                {"                                "}"usdt_amount": 0,{"\n"}
                                {"                                "}"is_active": true,{"\n"}
                                {"                                "}"deposit_status": true,{"\n"}
                                {"                                "}"withdrawal_status": true,{"\n"}
                                {"                                "}"deposit_fee": 0,{"\n"}
                                {"                                "}"deposit_fee_type": "fixed",{"\n"}
                                {"                                "}"minimum_deposit_amount": 0,{"\n"}
                                {"                                "}
                                <span className="red">"withdrawal_fee": 0,</span>
                                {"\n"}
                                {"                                "}"withdrawal_fee_type": "fixed",
                                {"\n"}
                                {"                                "}"minimum_withdrawal_amount": 1,
                                {"\n"}
                                {"                               "}
                                <span className="red">
                                    {"\n"}
                                    {"                                "}"networks": [{"\n"}
                                    {"                                "}"TronApi"{"\n"}
                                    {"                                 "}],{"\n"}
                                    {"                               "}
                                </span>
                                {"\n"}
                                {"                                "}"addresses": {"{"}
                                {"\n"}
                                {"                                    "}"TronApi":
                                "TC7sRBaG4M8GDcmiHgRYLtiFBevKJRdwnm"{"\n"}
                                {"                                "}
                                {"}"}
                                {"\n"}
                                {"                            "}
                                {"}"}
                                {"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>
                                <span>کارمزد انتقال</span>
                                <span>=</span>
                                <span>withdrawal_fee</span>
                            </p>
                            <p>مقدار خاص دریافتی = مقدار وارد شده و کسر شدن کارمزد انتقال</p>
                            <br />
                            <hr />
                            {/*  */}
                            <p>
                                اطلاعات فرم را به آدرس
                                <span>
                                    https://api.moneyex.org/user/wallets/
                                    <span className="red">coin.id</span>/withdrawals
                                </span>
                                ارسال میکنیم به همراه توکن مانند آبجکت زیر
                            </p>
                            <pre>
                                {"                        "}
                                {"{"}
                                {"\n"}
                                {"                            "}"address":
                                "TQhdP2Fk5QbatC3iRUisT5VXrTfGtHbwci",{"\n"}
                                {"                            "}"amount": 220{"\n"}
                                {"                        "}
                                {"}"}
                                {"\n"}
                                {"                    "}
                            </pre>
                            <p>سپس کاربر را به صفحه wallet منتقل میکنیم</p>
                        </section>
                    </div>
                </div>
            </div>
        </StyledContainer>
    )
}

export default ApiDoc;
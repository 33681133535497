//internal
import { useState, useContext, useEffect } from "react";
//external
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../contexts/MainContexts";
import Cookies from "js-cookie";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';


//Component and Style and Image
import "./new-password.css";
import "./lightingMode.css";
import Login_Left from "../login/Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode"

import { BASE_URL } from '../../../utils/url'




const NewPassword = () => {

    const baseURL = BASE_URL
    const navigate = useNavigate();
    const { t } = useTranslation()
    const { primary_dir, lightingMode } = useContext(MainContext);

    const [new_password, setNew_password] = useState("");
    const [password_confirmation, setPassword_confirmation] = useState("");

    const [passType, setPassType] = useState("password");
    const [repeatType, setRepeatType] = useState("password");

    //method
    const toggle_PassType = () => {
        if (passType === "password") {
            setPassType("text")
        } else {
            setPassType("password")
        }
    }
    //method
    const toggle_RepeatType = () => {
        if (repeatType === "password") {
            setRepeatType("text")
        } else {
            setRepeatType("password")
        }
    }
    //Mounting 
    useEffect(() => {
        if (Cookies.get("access_token")) {
            navigate("/dashboard", { replace: true })
        }
    }, [])




    //ارسال فرم
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.target.disabled = true

        if(new_password===password_confirmation){
            try {
                const res = await axios.post(`${baseURL}/forget-password/set-password`
                    , JSON.stringify({
                        type: "mobile",
                        email: null,
                        mobile: Cookies.get("country_code") + Cookies.get("user_phone"),
                        verification_code: Cookies.get("verification_code"),
                        new_password,
                        password_confirmation
                    })
                    , { headers: { 'Content-Type': 'application/json' } })
    
    
                if (res.status == 200) {
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    Cookies.remove("verification_code")
                    navigate("/login")

                    e.target.disabled = false
                }
    
    
                //  console.log(res)
            } catch (error) {
                //  console.log(error.response.data.message)

                e.target.disabled = false
            }
        }else{
            toast.error("پسورد و تکرار آن با یکدیگر مطابقت ندارند.", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            
            e.target.disabled = false
        }
    }
    return (
        <HelmetProvider>
            <div className="grand-parent" id="newPassword">
                <Helmet>
                    <title>{t("title-newpass")}</title>
                </Helmet>
                <div className="new-password-parent" >

                    {/* right */}
                    <section className="new-password-right">
                        <form>
                            <h1 className="flexCenter">{t("newPassword_1")}</h1>
                            <p>{t("newPassword_2")}</p>


                            {/*name=new_password */}
                            <div>
                                <label for="">{t("newPassword_3")}</label>
                                <input
                                    dir="ltr"
                                    className="p-2 ltr"
                                    type={passType}
                                    name="new_password"
                                    value={new_password}
                                    onChange={e => setNew_password(e.target.value)}
                                />
                                <span onClick={toggle_PassType}
                                    className="eye cursor-pointer text-2xl"
                                    style={{ right: "7px" }}>
                                    {
                                        (passType === "password") ? <IoEyeOutline /> : <FaRegEyeSlash />
                                    }
                                </span>
                            </div>


                            {/*name=password_confirmation */}
                            <div>
                                <label for="">{t("newPassword_4")}</label>
                                <input
                                    dir="ltr"
                                    className="p-2 ltr"
                                    type={repeatType}
                                    name="password_confirmation"
                                    value={password_confirmation}
                                    onChange={e => setPassword_confirmation(e.target.value)}
                                />
                                <span onClick={toggle_RepeatType}
                                    className="eye cursor-pointer text-2xl"
                                    style={{ right: "7px" }}>
                                    {
                                        (repeatType === "password") ? <IoEyeOutline /> : <FaRegEyeSlash />
                                    }
                                </span>
                            </div>

                            <div className="submit-form">
                                <button
                                    type="submit"
                                    onClick={handleSubmitForm}
                                >{t("newPassword_5")}</button>
                            </div>
                            {/* <div className="recently">
                            <span>
                                <Link to={"/login"}>{t("newPassword_to_login")}</Link>
                            </span>
                        </div> */}
                        </form>
                    </section>

                    {/* left */}
                    <Login_Left />

                </div>

                {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
                {/* <LightingMode bottom="20px" left="30px" /> */}
            </div>
        </HelmetProvider>
    )
}

export default NewPassword
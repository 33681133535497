//internal
import {useState, useContext,useEffect } from "react";
//external
import axios from "axios";
import Cookies from "js-cookie";
import { MainContext } from "../../contexts/MainContexts";
import { Link,useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
//Component and Style 
import "./main-sidebar.css";
import "./lightingMode.css";
import LightingMode from "../lighting-mode/LightingMode"
//Image
import main_logo from "../../assests/image/logo/main_logo.png";
import irr from "../../assests/image/dashbord/IRR.png";

import { BASE_URL } from '../../utils/url'

const Main_Sidebar = () => {


    const baseURL = BASE_URL
    const { t } = useTranslation();
    const {lightingMode, user_USDT_balance, setUser_USDT_balance}=useContext(MainContext);
    const {pathname}=useLocation()
    
    const [usdt, setUsdt] = useState("");
    // برای ست کردن موجودی در کانتکست که دیگر با تغییر پیچ، پرش در دریافت موجودی نداشته باشیم
    if (usdt) setUser_USDT_balance(usdt)
    



    useEffect(() => {
   
        const fetchData = async () => {
          try {
            //Wallet
            //GET User's Wallets
            const response_1 = await axios.get(`${baseURL}/user/wallets`, {
              headers: {
                'Authorization': `Bearer ${Cookies.get("access_token")}`,
                'Content-Type': 'application/json'
              }
            });
              
             setUsdt(response_1.data.data.find(c => c.coin_symbol==="USDT").primary_balance.toFixed(3))     
            
            
           
          } catch (error) {
            // console.log(error.message)
          }
        }//end of method
        fetchData();
      }, [])

    //  const primary_balance = userWallets?.find(w=> w.coin_symbol==="USDT").primary_balance || ""
     

    return (

        <aside className="main-sidebar hidden lg:block">

            {/* logo */}
            <div className="flex justify-end mb-5">
                <section className="flexCenter">
                   <Link to={"/"}>
                   <img
                        src={main_logo}
                        className="h-[33px]" />
                   </Link>
                </section>
            </div>

              


            {/* ارزش کل دارایی ها */}
            <div className="total-wealth mb-6">
                <div className="h-[22px]">
                    <span className="w-8 h-8 flexCenter">
                        <svg id="account_balance_wallet_black_24dp_2_" data-name="account_balance_wallet_black_24dp (2)" xmlns="http://www.w3.org/2000/svg" width="14.99" height="14.201" viewBox="0 0 14.99 14.201">
                            <path id="Path_153" data-name="Path 153" d="M17.2,6.377v-1.8A1.582,1.582,0,0,0,15.623,3H4.578A1.577,1.577,0,0,0,3,4.578V15.623A1.577,1.577,0,0,0,4.578,17.2H15.623A1.582,1.582,0,0,0,17.2,15.623v-1.8a1.578,1.578,0,0,0,.789-1.357V7.734A1.578,1.578,0,0,0,17.2,6.377Zm-.789,1.357v4.734H10.889V7.734ZM4.578,15.623V4.578H15.623V6.156H10.889A1.582,1.582,0,0,0,9.311,7.734v4.734a1.582,1.582,0,0,0,1.578,1.578h4.734v1.578Z" transform="translate(-3 -3)" fill="#fff" />
                            <circle id="Ellipse_25" data-name="Ellipse 25" cx="0.922" cy="0.922" r="0.922" transform="translate(8.724 6.179)" fill="#f9cc0b" />
                        </svg>
                    </span>
                </div>

                <div className="h-[70px] flex flex-wrap content-center mt-[6px]">
                    <span className="w-full flex justify-center font-bold">{user_USDT_balance ? user_USDT_balance : '...' }</span>

                    <span className="w-full flex justify-center gap-x-1 items-center">
                        <span style={{ fontSize: "12px" }}>{t("main_sidebar_1")}</span>
                        <img src={require('../../assests/image/wallet/A5.png')} width={20} className="object-cover" />
                    </span>
                </div>

            </div>

            {/* لیست */}
            <ul id="list_sidebar">

                {/* داشبورد */}
                <li>
                    <Link to={"/dashboard"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.276" height="20.276" viewBox="0 0 20.276 20.276">
                                <path id="grid_view_FILL0_wght400_GRAD0_opsz48" d="M121.69,225.293A1.695,1.695,0,0,1,120,223.6V217.69a1.695,1.695,0,0,1,1.69-1.69H127.6a1.695,1.695,0,0,1,1.69,1.69V223.6a1.695,1.695,0,0,1-1.69,1.69Zm0,10.983a1.695,1.695,0,0,1-1.69-1.69v-5.914a1.695,1.695,0,0,1,1.69-1.69H127.6a1.695,1.695,0,0,1,1.69,1.69v5.914a1.695,1.695,0,0,1-1.69,1.69Zm10.983-10.983a1.695,1.695,0,0,1-1.69-1.69V217.69a1.695,1.695,0,0,1,1.69-1.69h5.914a1.695,1.695,0,0,1,1.69,1.69V223.6a1.695,1.695,0,0,1-1.69,1.69Zm0,10.983a1.695,1.695,0,0,1-1.69-1.69v-5.914a1.695,1.695,0,0,1,1.69-1.69h5.914a1.695,1.695,0,0,1,1.69,1.69v5.914a1.695,1.695,0,0,1-1.69,1.69ZM121.69,223.6H127.6V217.69H121.69Zm10.983,0h5.914V217.69h-5.914Zm0,10.983h5.914v-5.914h-5.914Zm-10.983,0H127.6v-5.914H121.69ZM132.673,223.6ZM132.673,228.673ZM127.6,228.673ZM127.6,223.6Z" transform="translate(-120 -216)" className={pathname==="/dashboard" ? "active":""} />
                            </svg>

                        </span>
                        <span style={pathname==="/dashboard" ? {color:"#f6b33f"}: {}} >{t("main_sidebar_2")}</span>
                    </Link>
                </li>
                {/* کیف پول */}
                <li>
                    <Link to={"/wallet"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.173" height="16.227" viewBox="0 0 17.173 16.227">
                                <path id="account_balance_wallet_FILL0_wght400_GRAD0_opsz48" d="M131.99,225.556a1.5,1.5,0,0,0,1.442-1.454,1.357,1.357,0,0,0-.439-1,1.388,1.388,0,0,0-2.006,0,1.357,1.357,0,0,0-.439,1,1.5,1.5,0,0,0,1.442,1.454Zm-10.637,4.124v0Zm0,2.547a1.31,1.31,0,0,1-.935-.406,1.281,1.281,0,0,1-.417-.947V217.352A1.43,1.43,0,0,1,121.352,216h13.522a1.281,1.281,0,0,1,.947.417,1.31,1.31,0,0,1,.406.935v3.02h-1.352v-3.02H121.352v13.522h13.522v-3h1.352v3a1.386,1.386,0,0,1-1.352,1.352Zm8.068-3.9a1.644,1.644,0,0,1-1.217-.451,1.614,1.614,0,0,1-.451-1.194v-5.116a1.606,1.606,0,0,1,.451-1.206,1.668,1.668,0,0,1,1.217-.439h6.085a1.668,1.668,0,0,1,1.217.439,1.606,1.606,0,0,1,.451,1.206v5.116a1.614,1.614,0,0,1-.451,1.194,1.644,1.644,0,0,1-1.217.451Z" transform="translate(-120 -216)" className={pathname==="/wallet" ? "active":""} />
                            </svg>

                        </span>
                        <span style={pathname==="/wallet" ? {color:"#f6b33f"}: {}}>{t("main_sidebar_3")}</span>
                    </Link>
                </li>
                {/* سفارشات */}
                <li>
                    <Link to={"/order"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.529" height="18.529" viewBox="0 0 18.529 18.529">
                                <path id="add_chart_FILL0_wght400_GRAD0_opsz48" d="M121.544,234.529A1.583,1.583,0,0,1,120,232.985V217.544A1.583,1.583,0,0,1,121.544,216h9.393a.745.745,0,0,1,.55.223.754.754,0,0,1,.222.553.739.739,0,0,1-.222.549.753.753,0,0,1-.55.219h-9.393v15.441h15.441v-9.393a.745.745,0,0,1,.223-.55.754.754,0,0,1,.553-.222.739.739,0,0,1,.549.222.753.753,0,0,1,.219.55v9.393a1.583,1.583,0,0,1-1.544,1.544Zm3.444-11.118a.739.739,0,0,0-.549.222.753.753,0,0,0-.219.55v5.533a.745.745,0,0,0,.223.55.754.754,0,0,0,.553.222.739.739,0,0,0,.549-.222.753.753,0,0,0,.219-.55v-5.533a.745.745,0,0,0-.223-.55A.754.754,0,0,0,124.988,223.412Zm4.272-3.371a.739.739,0,0,0-.549.222.753.753,0,0,0-.219.55v8.9a.745.745,0,0,0,.223.55.754.754,0,0,0,.553.222.739.739,0,0,0,.549-.222.753.753,0,0,0,.219-.55v-8.9a.745.745,0,0,0-.223-.55A.754.754,0,0,0,129.26,220.04Zm4.272,6.64a.739.739,0,0,0-.549.222.753.753,0,0,0-.219.55v2.265a.745.745,0,0,0,.223.55.754.754,0,0,0,.553.222.739.739,0,0,0,.549-.222.753.753,0,0,0,.219-.55v-2.265a.745.745,0,0,0-.223-.55A.754.754,0,0,0,133.532,226.68Zm1.214-6.871h-1.493a.745.745,0,0,1-.55-.223.754.754,0,0,1-.222-.553.739.739,0,0,1,.222-.549.753.753,0,0,1,.55-.219h1.493v-1.493a.745.745,0,0,1,.223-.55.754.754,0,0,1,.553-.222.739.739,0,0,1,.549.222.753.753,0,0,1,.219.55v1.493h1.467a.745.745,0,0,1,.55.223.754.754,0,0,1,.222.553.739.739,0,0,1-.222.549.753.753,0,0,1-.55.219H136.29v1.467a.745.745,0,0,1-.223.55.754.754,0,0,1-.553.222.739.739,0,0,1-.549-.222.753.753,0,0,1-.219-.55ZM129.265,225.265Z" transform="translate(-120 -216)" className={pathname==="/order" ? "active":""} />
                            </svg>

                        </span>
                        <span style={pathname==="/order" ? {color:"#f6b33f"}: {}}>{t("main_sidebar_4")}</span>
                    </Link>
                </li>
                {/* تراکنش ها */}
                <li>
                    <Link to={"/transaction"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.892" height="18.475" viewBox="0 0 16.892 18.475">
                                <path id="receipt_long_FILL0_wght400_GRAD0_opsz48_4_" data-name="receipt_long_FILL0_wght400_GRAD0_opsz48 (4)" d="M119.443,200.475A2.433,2.433,0,0,1,117,198.031v-2.21a.686.686,0,0,1,.7-.7h2.257V182.465q0-.233.186-.233a.536.536,0,0,1,.372.186l.838.838,1.14-1.14a.333.333,0,0,1,.252-.116.36.36,0,0,1,.26.116l1.117,1.14,1.14-1.14a.333.333,0,0,1,.252-.116.36.36,0,0,1,.26.116l1.14,1.14,1.14-1.14A.333.333,0,0,1,128.3,182a.36.36,0,0,1,.26.116l1.14,1.14,1.14-1.14A.333.333,0,0,1,131.1,182a.36.36,0,0,1,.26.116l1.14,1.14.838-.838a.536.536,0,0,1,.372-.186q.186,0,.186.239v15.56a2.433,2.433,0,0,1-2.443,2.443Zm12.006-1.4a1,1,0,0,0,1.047-1.047V184.187H121.351v10.936H129.7a.686.686,0,0,1,.7.7v2.207a1,1,0,0,0,1.047,1.051Zm-8.19-11.215a.632.632,0,0,1-.477-.209.7.7,0,0,1-.2-.5.659.659,0,0,1,.2-.489.681.681,0,0,1,.5-.2h4.188a.673.673,0,0,1,.5.2.681.681,0,0,1,.2.5.668.668,0,0,1-.2.5.681.681,0,0,1-.5.2Zm0,3.118a.632.632,0,0,1-.477-.209.7.7,0,0,1-.2-.5.659.659,0,0,1,.2-.489.681.681,0,0,1,.5-.2h4.188a.673.673,0,0,1,.5.2.682.682,0,0,1,.2.5.668.668,0,0,1-.2.5.681.681,0,0,1-.5.2Zm7.073-3.118a.716.716,0,1,1,.489-.209A.67.67,0,0,1,130.332,187.863Zm0,3a.716.716,0,1,1,.489-.209A.67.67,0,0,1,130.332,190.865Zm-10.913,8.214h9.586v-2.559H118.4v1.512a1.016,1.016,0,0,0,.294.756A1,1,0,0,0,119.42,199.079Zm-1.024,0v0Z" transform="translate(-117 -182)" className={pathname==="/transaction" ? "active":""} />
                            </svg>

                        </span>
                        <span style={pathname==="/transaction" ? {color:"#f6b33f"}: {}} >{t("main_sidebar_5")}</span>
                    </Link>
                </li>
                {/* تنظیمات */}
                <li>
                    <Link to={"/setting"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.827" height="20.827" viewBox="0 0 20.827 20.827">
                                <path id="tune_FILL0_wght400_GRAD0_opsz48_2_" data-name="tune_FILL0_wght400_GRAD0_opsz48 (2)" d="M120.868,234.455a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h5.409a.837.837,0,0,1,.618.251.847.847,0,0,1,.249.622.83.83,0,0,1-.249.617.846.846,0,0,1-.618.246Zm0-14.348a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h10.211a.837.837,0,0,1,.618.251.847.847,0,0,1,.249.622.83.83,0,0,1-.249.617.846.846,0,0,1-.618.246Zm8.875,16.72a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-4.773a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v1.533h9.343a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246h-9.343v1.5a.837.837,0,0,1-.251.618A.847.847,0,0,1,129.743,236.827Zm-3.471-7.174a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-1.5h-4.541a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h4.541v-1.562a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v4.8a.837.837,0,0,1-.251.618A.847.847,0,0,1,126.272,229.653Zm3.476-2.372a.837.837,0,0,1-.618-.251.847.847,0,0,1-.25-.622.83.83,0,0,1,.25-.617.846.846,0,0,1,.618-.246h10.211a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246Zm4.8-4.773a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-4.773a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v1.5h4.541a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246h-4.541v1.533a.837.837,0,0,1-.251.618A.847.847,0,0,1,134.545,222.508Z" transform="translate(-120 -216)" className={pathname==="/setting" ? "active":""} />
                            </svg>

                        </span>
                        <span style={pathname==="/setting" ? {color:"#f6b33f"}: {}}>{t("main_sidebar_6")}</span>
                    </Link>
                </li>
                {/* دعوت دوستان*/}
                <li>
                    <Link to={"/invite-friends"}>
                        <span className={(pathname==="/invite-friends" || pathname==='/reports') ? 'invite-span-active': ''}>
                        <FaUserFriends />  
                         
                        </span>
                        <span style={(pathname==="/invite-friends" || pathname==='/reports') ? {color:"#f6b33f"}: {}}>{t("main_sidebar_7")}</span>
                    </Link>
                </li>
               
            </ul>
            
        </aside>
    )
}

export default Main_Sidebar
//internal
import { useState, useEffect, useContext } from "react";

//external
import Cookies from "js-cookie";
import axios from "axios";
import { MainContext } from "../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { Link,NavLink, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaUserFriends } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";


//Component and Style 
import "./invite-friends.css";
import "./lightMode.css";
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";

import LightingMode from "../../components/lighting-mode/LightingMode";
//Image

import { BASE_URL } from '../../utils/url'


import { toast } from "react-toastify";



const Invite_Friends = () => {


  const baseURL = BASE_URL
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  const { t } = useTranslation();
  const { lightingMode, primary_dir } = useContext(MainContext);
  const navigate = useNavigate();

  const mm = (lightingMode==="light" ) ? "black" : "white"


  //desktop popup
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  //mobile popup dark_screen
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  //tablet popup dark_screen
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);

  //method
  const closeWindowForDesktop = () => {
    if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
    if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
    // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
    // if (tablet_Notifications == true) { setTablet_Notifications(false) }
    if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
  }

  const [profileData, setProfileData] = useState({});
  const [referrals_user, setReferrals_user] = useState();
  const authorized_users = referrals_user && referrals_user.filter(user => user.is_id_verified === true) || []
  

  
  //Mounting useEffect
  useEffect(() => {

     //اگر توکن نداشت
     if (!Cookies.get("access_token")) {
      navigate("/judge", { replace: true })
    }

    const fetchData = async () => {

         //User
         //GET User Profile
      try {
        const res_profile = await axios.get(`${baseURL}/user/profile`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setProfileData(res_profile.data);

        const res_referrals = await axios.get(`${baseURL}/user/referrals`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setReferrals_user(res_referrals.data.data)
        



      } catch (err) {
        if(err.response.data.message==="Unauthenticated."){
          Cookies.remove("access_token")
          navigate("/judge", { replace: true })
         }
      }
    }//end of method
    fetchData();
   
  }, [])
  

  return (
    <HelmetProvider>
      <div id="invite" className="flex flex-wrap" onClick={closeWindowForDesktop}>
        <Helmet>
          <title>{t("title_inviteFriends")}</title>
        </Helmet>

        {/* نوار برای موبایل و تبلت lg:hidden*/}
        {/* x < 1024 */}
        <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}

          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />

        {/* لینک های فیکس برای موبایل sm:hidden*/}
        {/* x < 640 */}
        <Fixed_Mobile_Links />

        {/* ساید بار برای حالت دسکتاپ hidden lg:block*/}
        {/* x > 1024 */}
        <Main_Sidebar />


        <main>

          {/* نوار برای دسکتاپ hidden lg:flex*/}
          {/* x > 1024 */}
          <Nav_Desktop
            desktop_ProfileMenu={desktop_ProfileMenu}
            desktop_Notifications={desktop_Notifications}
            desktop_ThreeDots={desktop_ThreeDots}

            setDesktop_Notifications={setDesktop_Notifications}
            setDesktop_ThreeDots={setDesktop_ThreeDots}
            setDesktop_ProfileMenu={setDesktop_ProfileMenu}
          />

          <div className="invite-container bg-white rounded-[20px] p-5 py-6">
            {/* دکمه */}
             <section className="w-fit flex items-center gap-x-4 mb-5 pb-3 px-2"
             style={{borderBottom:".5px solid #e6e8f2"}}>

                <NavLink to={"/invite-friends"} 
                 style={({ isActive}) => {
                  return {
                    background: isActive ? "var(--maincolor2)" : "",
                    color: isActive ? "white" : mm,
                  }}}
                  className="p-1 rounded"
                > {t("inviteFriends_1")}</NavLink>

                <NavLink to={"/reports"}
                style={({ isActive}) => {
                  return {
                    background: isActive ? "var(--maincolor2)" : "",
                    color: isActive ? "white" : mm,
                  }}}
                  className="p-1 rounded"
                  >{t("inviteFriends_2")}</NavLink>

             </section>

             {/*  */}
            <div className="flex items-center gap-x-8 mb-5">
              <span> <FaUserFriends size={50} color="#5a8dee" /> </span>
              <span className="text-2xl toggleColor">{t("inviteFriends_3")}</span>
            </div>
            
            {/* لینک معرف */}
            <div className="flex items-center gap-x-4 mb-5 flex-wrap">
               <span className="toggleColor">{t("inviteFriends_4")}</span>
               <span className="toggleColor">:</span>

               <section className="h-[46px] flexBetween gap-x-3 px-5 rounded-xl"
                style={lightingMode === "light" ? { background: "#f7f8fa" } : { background: "#141414" }} >
                  {/*  */}
                  <span className="referral_code"
                    style={lightingMode === "light" ? { color: "black" } : { color: "white" }}
                  >
                    {(profileData.referral_code) ? `https://moneyex.org/ref/${profileData.referral_code}` : ""}
                  </span>
                  {/*  */}
                  <span onClick={() => {
                    navigator.clipboard.writeText(`https://moneyex.org/ref/${profileData.referral_code}`)
                    toast.success('کپی شد.')
                  }}
                    className="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.101" height="26.748" viewBox="0 0 23.101 26.748">
                      <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M20.021,1.5H5.432A2.439,2.439,0,0,0,3,3.932V20.953H5.432V3.932h14.59Zm3.647,4.863H10.295A2.439,2.439,0,0,0,7.863,8.795V25.816a2.439,2.439,0,0,0,2.432,2.432H23.669A2.439,2.439,0,0,0,26.1,25.816V8.795A2.439,2.439,0,0,0,23.669,6.363Zm0,19.453H10.295V8.795H23.669Z" transform="translate(-3 -1.5)" fill="#f6b33f" />
                    </svg>
                  </span>
                  {/*  */}
                </section>
               
            </div>
            {/* اشتراک گذاری */}
            <div className="flex items-center gap-x-3 mb-8 toggleColor">
              <span>{t("inviteFriends_5")}</span>
              <span>:</span>
              <a target="_blank" href={`https://telegram.me/share/?url=https://moneyex.org/ref/${profileData.referral_code}`} ><FaTelegramPlane size={30} /></a>
              <a target="_blank" href={`https://twitter.com/share/?url=https://moneyex.org/ref/${profileData.referral_code}`}><FaTwitter size={30} /></a>
            </div>
            {/* تعداد */}
            <section 
             className="flex gap-x-6 bg-[#F5F6F9] w-fit p-5 rounded-lg flex-wrap gap-y-4">
               
                {/*  */}
                <div>
                  <span>{t("inviteFriends_6")}</span>
                  <span>: </span>
                  <span>{referrals_user ? referrals_user.length : 0} </span>
                  <span>نفر</span>
                </div>
                {/*  */}
                <div>
                  <span>{t("inviteFriends_7")}</span>
                  <span>: </span>
                  <span>{authorized_users ? authorized_users.length : 0} </span>
                  <span>نفر</span>
                </div>
                {/*  */}
               

            </section>

          </div>
        </main>
        <LightingMode bottom="20px" left="30px" />
      </div>
    </HelmetProvider>
  )
}

export default Invite_Friends
// export default Invite_Friends
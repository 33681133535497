import React, { useState, useEffect } from "react";
import "./Footer.css";
import { useContext } from "react";
import { MainContext } from "./../contexts/MainContexts";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
export default function Footer() {
  const {
    setLightingMode,
  } = useContext(MainContext);

  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const context = useContext(MainContext);
  function changetheme() {
    // if (context.lightingMode === "light") {
    //   // console.log("me");
    //   context.setLightingMode("dark");
    // } else {
    //   // console.log("mes");
    //   context.setLightingMode("light");
    // }
    if (Cookies.get("lightingMode") === "light") {
      // console.log("me");
      Cookies.set("lightingMode", "dark");
      setLightingMode("dark");
    } else {
      // console.log("mes");
      Cookies.set("lightingMode", "light");
      setLightingMode("light");
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // اضافه کردن وابستگی آرایه‌ای خالی برای اجرای useEffect یک‌بار در ابتدا

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <React.Fragment>
      {/* <!-- Footer --> */}
      <footer>
        {isVisible && (
          <div
            class="up"
            style={{ cursor: "pointer", transition: ".5s all ease" }}
            onClick={scrollToTop}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39.032"
              height="39.032"
              viewBox="0 0 39.032 39.032"
            >
              <path
                id="Scroll-top"
                d="M4.879,0A4.879,4.879,0,0,0,0,4.879V34.153a4.879,4.879,0,0,0,4.879,4.879H34.153a4.879,4.879,0,0,0,4.879-4.879V4.879A4.879,4.879,0,0,0,34.153,0ZM13.06,20.379a1.221,1.221,0,0,1-1.727-1.727l7.318-7.318a1.22,1.22,0,0,1,1.727,0L27.7,18.652a1.221,1.221,0,1,1-1.727,1.727l-5.235-5.237V26.834a1.22,1.22,0,0,1-2.439,0V15.142Z"
                fill="#f6b33f"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        )}
        <div class="all">
          <Link to="/" class="logo">
            <img src={require("../image/logo/_company_logo.png")} />
          </Link>

          <div class="all-grid">
            <div class="grid-items l1">
              <div class="list ">
                <div class="title">{t("footer.1")}</div>
                <p class="des">{t("footer.2")}</p>
              </div>
            </div>
            <div class="grid-items">
              <div class="list">
                <div class="title">{t("footer.3")}</div>
                <ul>
                  <li>
                    <Link to="/aboutus">{t("footer.4")}</Link>
                  </li>
                  <li>
                    <Link to="/contact">{t("footer.5")}</Link>
                  </li>
                  <li>
                    <Link to="/dashboard">{t("footer.6")}</Link>
                  </li>
                  <li>
                    <Link to="/terms"  >
                      {t("footer.7")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/exchange">{t("footer.9")}</Link>
                  </li>

                  <li>
                    <Link to="/blog">{t("footer.12")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="grid-items">
              <div class="list">
                <div class="title">{t("footer.13")}</div>
                <ul class="df">
                  <li>
                    <a href="tel:09056915681">{t("footer.14")}</a>
                  </li>
                  <li>
                    <a href="mailto:support@moneyex.org">{t("footer.15")}</a>
                  </li>
                  <li>{t("footer.16")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="details">
            <div class="left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="copyright"
                width="35.25"
                height="35.25"
                viewBox="0 0 20.25 20.25"
              >
                <path
                  id="Path_436"
                  data-name="Path 436"
                  d="M18.12,14.741l1.6-1.6a4.5,4.5,0,1,0,.2,6.523l-1.478-1.477H18.2a2.25,2.25,0,1,1-.082-3.451Z"
                  transform="translate(-6.429 -6.375)"
                />
                <path
                  id="Path_437"
                  data-name="Path 437"
                  d="M14.625,4.5A10.125,10.125,0,1,1,4.5,14.625,10.125,10.125,0,0,1,14.625,4.5Zm0,2.25A7.875,7.875,0,1,1,6.75,14.625,7.875,7.875,0,0,1,14.625,6.75Z"
                  transform="translate(-4.5 -4.5)"
                  fill-rule="evenodd"
                />
              </svg>
              <div class="text">{t("footer.17")}</div>
            </div>

            <div class="right">
              <a target="_blank" href="https://www.instagram.com/moneyex_org/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.527"
                  height="16.526"
                  viewBox="0 0 16.527 16.526"
                >
                  <path
                    id="instagram"
                    d="M11.263,3c-2.244,0-2.525.01-3.407.05a6.081,6.081,0,0,0-2.006.384A4.223,4.223,0,0,0,3.434,5.851a6.092,6.092,0,0,0-.384,2.006C3.009,8.738,3,9.019,3,11.263s.009,2.525.05,3.407a6.081,6.081,0,0,0,.384,2.006,4.222,4.222,0,0,0,2.417,2.417,6.093,6.093,0,0,0,2.006.384c.881.04,1.163.05,3.407.05s2.525-.01,3.407-.05a6.081,6.081,0,0,0,2.006-.384,4.222,4.222,0,0,0,2.417-2.417,6.092,6.092,0,0,0,.385-2.006c.04-.881.05-1.163.05-3.407s-.01-2.525-.05-3.407a6.081,6.081,0,0,0-.385-2.006,4.222,4.222,0,0,0-2.417-2.417,6.092,6.092,0,0,0-2.006-.384C13.788,3.01,13.507,3,11.263,3m0,1.489c2.206,0,2.467.008,3.338.048a4.591,4.591,0,0,1,1.535.284A2.735,2.735,0,0,1,17.7,6.389a4.586,4.586,0,0,1,.284,1.534c.04.872.048,1.132.048,3.339s-.008,2.467-.048,3.338a4.62,4.62,0,0,1-.284,1.535A2.735,2.735,0,0,1,16.136,17.7a4.586,4.586,0,0,1-1.534.284c-.871.04-1.132.048-3.339.048s-2.468-.008-3.338-.048A4.62,4.62,0,0,1,6.389,17.7a2.735,2.735,0,0,1-1.568-1.568A4.557,4.557,0,0,1,4.537,14.6c-.04-.872-.048-1.132-.048-3.339S4.5,8.8,4.537,7.925a4.591,4.591,0,0,1,.284-1.535A2.735,2.735,0,0,1,6.389,4.821a4.557,4.557,0,0,1,1.534-.284c.872-.04,1.132-.048,3.339-.048m0,9.528a2.754,2.754,0,1,1,2.754-2.754,2.754,2.754,0,0,1-2.754,2.754m0-7a4.243,4.243,0,1,0,4.243,4.243A4.243,4.243,0,0,0,11.263,7.02m5.4-.167a.992.992,0,1,1-.992-.992.992.992,0,0,1,.992.992"
                    transform="translate(-3 -3)"
                    fill-rule="evenodd"
                  />
                </svg>
              </a>
              <div className="theme">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.883"
                  height="23.88"
                  viewBox="0 0 23.883 23.88"
                  id="moon"
                  onClick={changetheme}
                >
                  <path
                    id="Moon"
                    d="M132.072,247.747A12.022,12.022,0,0,1,120,235.675,11.49,11.49,0,0,1,122.666,228a12.111,12.111,0,0,1,6.992-4.057q1.375-.268,1.878.469a2.061,2.061,0,0,1-.034,2.012,9.8,9.8,0,0,0-.469,1.576,8.034,8.034,0,0,0-.168,1.643,7.213,7.213,0,0,0,7.243,7.243,8.63,8.63,0,0,0,1.626-.151,9.911,9.911,0,0,0,1.526-.419,2.115,2.115,0,0,1,2.146.05,1.869,1.869,0,0,1,.369,2,12,12,0,0,1-4.057,6.723A11.443,11.443,0,0,1,132.072,247.747Zm0-2.012a9.646,9.646,0,0,0,6.371-2.263,9.5,9.5,0,0,0,3.387-5.315,8.367,8.367,0,0,1-1.8.553,10.183,10.183,0,0,1-1.923.184,9.234,9.234,0,0,1-9.255-9.255,9.685,9.685,0,0,1,.168-1.727,12.6,12.6,0,0,1,.6-2.1,10.214,10.214,0,0,0-5.449,3.672,10.023,10.023,0,0,0,7.9,16.247ZM131.938,235.775Z"
                    transform="translate(-120 -223.867)"
                    fill="#fff"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="sun"
                  width="17.66"
                  height="17.66"
                  viewBox="0 0 17.66 17.66"
                  onClick={changetheme}
                >
                  <path
                    id="Path_8247"
                    data-name="Path 8247"
                    d="M13.426,16.376a2.95,2.95,0,1,0-2.95-2.95A2.95,2.95,0,0,0,13.426,16.376Zm0,1.475A4.426,4.426,0,1,0,9,13.426,4.426,4.426,0,0,0,13.426,17.851Z"
                    transform="translate(-4.596 -4.596)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                  <path
                    id="Path_8248"
                    data-name="Path 8248"
                    d="M8.094,0H9.566V2.989a5.945,5.945,0,0,0-1.472,0ZM5.22,4.179,3.107,2.066l-1.04,1.04L4.179,5.22a5.922,5.922,0,0,1,1.04-1.04ZM2.989,8.094H0V9.566H2.989a5.945,5.945,0,0,1,0-1.472ZM4.179,12.44,2.066,14.553l1.04,1.04L5.22,13.48A5.922,5.922,0,0,1,4.179,12.44Zm3.915,2.231V17.66H9.566V14.671a5.937,5.937,0,0,1-1.472,0ZM12.44,13.48l2.113,2.113,1.04-1.04L13.48,12.44a5.922,5.922,0,0,1-1.04,1.04Zm2.231-3.915H17.66V8.094H14.671A5.937,5.937,0,0,1,14.671,9.566ZM13.48,5.22l2.113-2.113-1.04-1.04L12.44,4.179a5.934,5.934,0,0,1,1.04,1.04Z"
                    transform="translate(0)"
                    fill="#f6b33f"
                    fill-rule="evenodd"
                  />
                </svg>
                <div
                  className="circle"
                  style={
                    context.lightingMode === "dark"
                      ? { left: "2px" }
                      : { left: "42px" }
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>

        <i>
          <span></span>
          <span></span>
        </i>
      </footer>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from 'react'
import './Authorization.css'
import Main_Sidebar from "../components/sidebar/Main_Sidebar";
import Fixed_Mobile_Links from "../components/fixed-mobile-links/Fixed_Mobile_Links";
import Nav_Desktop from "../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../components/nav-mobile-tablet/Nav_MobileTablet";
import Cookies from 'js-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../utils/url'


const Authorization = () => {
    const navigate = useNavigate()


    ////////////////////////////////////////////////////////////////////////// Header & Nav 
    //desktop popup
    const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
    const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
    const [desktop_Notifications, setDesktop_Notifications] = useState(false);
    //mobile popup dark_screen
    const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
    const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
    //tablet popup dark_screen
    const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
    const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
    const [tablet_Notifications, setTablet_Notifications] = useState(false);
    const closeWindowForDesktop = () => {
        if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
        if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
        // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
        // if (tablet_Notifications == true) { setTablet_Notifications(false) }
        if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
    }
    ///////////////////////////////////////////////////////////////////////////


    const [level, setLevel] = useState(0)


    //////////////////////////////////////////// Fast Auth
    const [shoroo, setShoroo] = useState(true)

    function goToFastAuth() {
        setShoroo(false)

        fetch(BASE_URL+'/user/kyc/start-fast', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data.data) window.location.replace(data.data.redirect_to)
            })
            // .catch(err => console.error(err))
    }


    ///////////////////////////////////////////// Regular Auth
    // Inputs
    const [charFaError, setCharFaError] = useState(false)
    const [charFaError2, setCharFaError2] = useState(false)

    const [first_name, setFirst_name] = useState()
    const [last_name, setLast_name] = useState()

    function nameInputHandler(e) {
        const persianRegex = /^[\u0600-\u06FF\s]+$/
        if (!persianRegex.test(e.target.value)) {
            setCharFaError(true)
        } else {
            setCharFaError(false)
        }
        setFirst_name(e.target.value)
    }

    function nameInputHandler2(e) {
        const persianRegex = /^[\u0600-\u06FF\s]+$/
        if (!persianRegex.test(e.target.value)) {
            setCharFaError2(true)
        } else {
            setCharFaError2(false)
        }
        setLast_name(e.target.value)
    }


    const [national_id, setNational_id] = useState()
    const [idNumError, setIdNumError] = useState(false)

    function idInputHandler(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')
        setNational_id(e.target.value)
    }

    function checkIdNumber() {
        if (national_id && national_id.length < 10) setIdNumError(true)
        else setIdNumError(false)
    }


    const [bank_card_number, setBank_card_number] = useState()
    const [bankCardError, setBankCardError] = useState(false)

    function bankCardInputHandler(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')
        setBank_card_number(e.target.value)
    }

    function bankCardCheck() {
        if (bank_card_number && bank_card_number.length < 16) setBankCardError(true)
        else setBankCardError(false)
    }


    function goToNextLevel(level) {
        setLevel(level)
    }

    // Making Image File
    const [imageSrcs, setImageSrcs] = useState({})

    const [identityDocumentImageFile, setIdentityDocumentImageFile] = useState()
    const [clientImageFile, setClientImageFile] = useState()
    const [bankCardImageFile, setBankCardImageFile] = useState()

    function handleImageUpload(e, imageKey, setImageFile) {
        const file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                setImageSrcs(prevImageSrcs => ({
                    ...prevImageSrcs,
                    [imageKey]: URL.createObjectURL(file)
                }))
                setImageFile(file)
            }
            reader.readAsDataURL(file)
        }
    }

    // Regular Auth POST
    const [waiting, setWaiting] = useState(false)

    function handleFormSubmit(e) {
        setWaiting(true)

        e.preventDefault()
        const formData = new FormData()

        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('national_id', national_id)
        formData.append('id_type', 'id_card')
        formData.append('identity_document_image', identityDocumentImageFile)
        formData.append('client_image', clientImageFile)
        formData.append('bank_card_number', bank_card_number)
        formData.append('bank_card_image', bankCardImageFile)

        fetch(BASE_URL+'/user/kyc/start-normal', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization'
            },
            body: formData,
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 'success') setLevel(4)
                // console.log('API Response:', data)
            })
            .catch(error => {
                setWaiting(false)
                // console.error('API Error:', error)
            })
    }


    const [declinedMassageShow, setDeclinedMassageShow] = useState(false)
    const [declinedMassage, setDeclinedMassage] = useState('')

    useEffect(() => {
        if (!Cookies.get("access_token")) navigate("/judge")

        fetch(BASE_URL+'/user/kyc/latest', {
            headers: {
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.data && (data.data.status === 'verified' || data.data.status === 'reviewing' || data.data.status === 'pending')) navigate('/')
                if (data.data.reason) {
                    setDeclinedMassageShow(true)
                    setDeclinedMassage(data.data.reason)
                }
            })
    }, [])



    return (
        <HelmetProvider>
            <Helmet>
                <title>احراز هویت</title>
            </Helmet>
            <div className="auth" onClick={closeWindowForDesktop}>
                <Nav_MobileTablet
                    mobileRight_DarkScreen={mobileRight_DarkScreen}
                    mobileLeft_DarkScreen={mobileLeft_DarkScreen}
                    tablet_DarkScreen={tablet_DarkScreen}
                    tablet_ProfileMenu={tablet_ProfileMenu}
                    tablet_Notifications={tablet_Notifications}
                    setMobileRight_DarkScreen={setMobileRight_DarkScreen}
                    setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
                    setTablet_DarkScreen={setTablet_DarkScreen}
                    setTablet_ProfileMenu={setTablet_ProfileMenu}
                    setTablet_Notifications={setTablet_Notifications}
                />
                <Fixed_Mobile_Links />
                <Main_Sidebar />
                <main>
                    <Nav_Desktop
                        desktop_ProfileMenu={desktop_ProfileMenu}
                        desktop_Notifications={desktop_Notifications}
                        desktop_ThreeDots={desktop_ThreeDots}
                        setDesktop_Notifications={setDesktop_Notifications}
                        setDesktop_ThreeDots={setDesktop_ThreeDots}
                        setDesktop_ProfileMenu={setDesktop_ProfileMenu}
                    />

                    {level === 0 &&
                        <>
                            {declinedMassageShow &&
                                <div className="status begin-status">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="40" fill="#f00"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                        <p>درخواست احراز هویت شما به این علت رد شده است:</p><span className='declined-text'>{declinedMassage}</span>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="40" fill='#aaa'><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                        <p>شما میتوانید دوباره درخواست دهید.</p>
                                    </div>
                                </div>
                            }
                            <div className="begin">
                                <h1>احراز هویت</h1>
                                <p>احراز هویت علاوه بر افزایش امنیت حساب کاربری شما، برای انجام هرگونه عملیات مالی لازم است.</p>
                                <div className="container">
                                    <div className="right">
                                        <img src={require('../image/authorization/authorization.webp')} alt="authorization" />
                                    </div>
                                    <div className='left-big'>
                                        <div className="middle">
                                            <span>پیشنهادی</span>
                                            <div>
                                                <h2>احراز هویت سریع</h2>
                                                <ul>
                                                    <li>تایید حساب طی ۵ دقیقه</li>
                                                    <li>بدون نیاز به سلفی با تعهدنامه</li>
                                                    <li>مدارک مورد نیاز: کارت بانکی و کارت ملی هوشمند</li>
                                                </ul>
                                            </div>
                                            <button onClick={goToFastAuth} disabled={!shoroo}>{shoroo ? 'شروع' : 'لطفا صبر کنید...'}</button>
                                        </div>
                                        <div className="left">
                                            <div>
                                                <h2>احراز هویت معمولی</h2>
                                                <ul>
                                                    <li>تایید حساب طی 30 دقیقه</li>
                                                    <li>بارگذاری عکس سلفی با تعهدنامه</li>
                                                    <li>بارگذاری عکس از کارت شناسایی</li>
                                                    <li>بارگذاری عکس سلفی با کارت بانکی</li>
                                                </ul>
                                            </div>
                                            <button onClick={() => goToNextLevel(1)}>شروع</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {level === 1 &&
                        <>
                            <div className='status level-1'>
                                <div>
                                    <div className='level1'>
                                        <svg width="41" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.3352 16.9036C23.0418 16.9036 25.2458 14.7189 25.2458 11.9929C25.2458 9.2669 23.0418 7.10156 20.3352 7.10156C17.6285 7.10156 15.4245 9.28623 15.4245 12.0122C15.4245 14.7382 17.6092 16.9229 20.3352 16.9229V16.9036Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M29.2274 29.9992H11.4408C12.2528 25.8232 15.9454 22.6719 20.3341 22.6719C24.7228 22.6719 28.4348 25.8232 29.2274 29.9992Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M33.8667 1H6.8C3.59675 1 1 3.59675 1 6.8V24.2C1 27.4033 3.59675 30 6.8 30H33.8667C37.0699 30 39.6667 27.4033 39.6667 24.2V6.8C39.6667 3.59675 37.0699 1 33.8667 1Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>اطلاعات هویتی</span>
                                    </div>
                                    <i className='stroke stroke1'></i>
                                    <div className='level2'>
                                        <svg width="34" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31 5.57518V4.00012C31 2.35005 29.6499 1 27.9999 1H26.4248" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M31 26.4297V28.0047C31 29.6548 29.6499 31.0049 27.9999 31.0049H26.4248" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 5.57518V4.00012C1 2.35005 2.35005 1 4.00012 1H5.57518" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 26.4297V28.0047C1 29.6548 2.35005 31.0049 4.00012 31.0049H5.57518" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0008 13.6759C17.9359 13.6759 19.511 12.1008 19.511 10.1508C19.511 8.20069 17.9359 6.64062 16.0008 6.64062C14.0657 6.64062 12.4907 8.21569 12.4907 10.1658C12.4907 12.1158 14.0657 13.6909 16.0008 13.6909V13.6759Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.7341 25.3665C8.03394 25.3665 6.63889 22.7714 8.55897 21.2413C10.464 19.7263 13.0891 18.7812 15.9993 18.7812C18.9094 18.7812 21.5495 19.7113 23.4395 21.2413C25.3446 22.7564 23.9646 25.3665 21.2645 25.3665H10.7341Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>عکس با تعهدنامه</span>
                                    </div>
                                    <i className='stroke stroke2'></i>
                                    <div className='level3'>
                                        <svg width="44" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M36.2143 1H7.21429C3.78223 1 1 3.78223 1 7.21429V23.7857C1 27.2178 3.78223 30 7.21429 30H36.2143C39.6463 30 42.4286 27.2178 42.4286 23.7857V7.21429C42.4286 3.78223 39.6463 1 36.2143 1Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 10.6797H42.4286" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.18053 20.3203H14.4522" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>اطلاعات بانکی</span>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={() => goToNextLevel(2)}>
                                <h1>اطلاعات هویتی</h1>
                                <div className='container'>
                                    <div className="right">
                                        <div className='input-container'>
                                            <label htmlFor="code-melli">کد ملی</label>
                                            <input type="text" id='code-melli' required maxLength='10' onChange={idInputHandler} onBlur={checkIdNumber} value={national_id} />
                                            {idNumError && <span>لطفا شماره کارت ملی را کامل وارد نمایید.</span>}
                                        </div>
                                        <div className='input-container'>
                                            <label htmlFor="name">نام</label>
                                            <input type="text" id='name' required onChange={nameInputHandler} value={first_name} />
                                            {charFaError && <span>فقط از کاراکترهای فارسی استفاده کنید.</span>}
                                        </div>
                                        <div className='input-container'>
                                            <label htmlFor="family">نام خانوادگی</label>
                                            <input type="text" id='family' required onChange={nameInputHandler2} value={last_name} />
                                            {charFaError2 && <span>فقط از کاراکترهای فارسی استفاده کنید.</span>}
                                        </div>
                                    </div>
                                    <div className="left">
                                        <div className="img-container">
                                            <img src={imageSrcs['identity_document'] || require('../image/authorization/identical_information.webp')} alt="Uploaded Image" />
                                            <label htmlFor="image_upload">{imageSrcs['identity_document'] ? 'تغییر عکس' : 'بارگذاری تصویر'}</label>
                                            <input
                                                type="file"
                                                accept=".jpg, .jpeg, .png"
                                                id="image_upload"
                                                onChange={(e) => handleImageUpload(e, 'identity_document', setIdentityDocumentImageFile)}
                                                required
                                            />
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>تصویر واضح و خوانا باشد.</li>
                                                <li>تصویر به صورت کامل و بدون ادیت باشد.</li>
                                                <li>حجم تصویر بارگذاری شده نباید بیشتر از 10 مگابایت باشد.</li>
                                                <li>فرمت تصویر بارگذاری شده باید PNG یا JPEG باشد.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <button type='submit' disabled={idNumError || charFaError || charFaError2}>مرحله بعدی</button>
                            </form>
                        </>
                    }
                    {level === 2 &&
                        <div className='level-2'>
                            <div className='status level-2'>
                                <div>
                                    <div className='level1'>
                                        <svg width="41" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.3352 16.9036C23.0418 16.9036 25.2458 14.7189 25.2458 11.9929C25.2458 9.2669 23.0418 7.10156 20.3352 7.10156C17.6285 7.10156 15.4245 9.28623 15.4245 12.0122C15.4245 14.7382 17.6092 16.9229 20.3352 16.9229V16.9036Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M29.2274 29.9992H11.4408C12.2528 25.8232 15.9454 22.6719 20.3341 22.6719C24.7228 22.6719 28.4348 25.8232 29.2274 29.9992Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M33.8667 1H6.8C3.59675 1 1 3.59675 1 6.8V24.2C1 27.4033 3.59675 30 6.8 30H33.8667C37.0699 30 39.6667 27.4033 39.6667 24.2V6.8C39.6667 3.59675 37.0699 1 33.8667 1Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>اطلاعات هویتی</span>
                                    </div>
                                    <i className='stroke stroke1'></i>
                                    <div className='level2'>
                                        <svg width="34" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31 5.57518V4.00012C31 2.35005 29.6499 1 27.9999 1H26.4248" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M31 26.4297V28.0047C31 29.6548 29.6499 31.0049 27.9999 31.0049H26.4248" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 5.57518V4.00012C1 2.35005 2.35005 1 4.00012 1H5.57518" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 26.4297V28.0047C1 29.6548 2.35005 31.0049 4.00012 31.0049H5.57518" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0008 13.6759C17.9359 13.6759 19.511 12.1008 19.511 10.1508C19.511 8.20069 17.9359 6.64062 16.0008 6.64062C14.0657 6.64062 12.4907 8.21569 12.4907 10.1658C12.4907 12.1158 14.0657 13.6909 16.0008 13.6909V13.6759Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.7341 25.3665C8.03394 25.3665 6.63889 22.7714 8.55897 21.2413C10.464 19.7263 13.0891 18.7812 15.9993 18.7812C18.9094 18.7812 21.5495 19.7113 23.4395 21.2413C25.3446 22.7564 23.9646 25.3665 21.2645 25.3665H10.7341Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>عکس با تعهدنامه</span>
                                    </div>
                                    <i className='stroke stroke2'></i>
                                    <div className='level3'>
                                        <svg width="44" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M36.2143 1H7.21429C3.78223 1 1 3.78223 1 7.21429V23.7857C1 27.2178 3.78223 30 7.21429 30H36.2143C39.6463 30 42.4286 27.2178 42.4286 23.7857V7.21429C42.4286 3.78223 39.6463 1 36.2143 1Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 10.6797H42.4286" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.18053 20.3203H14.4522" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>اطلاعات بانکی</span>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={() => goToNextLevel(3)}>
                                <div className='title'>
                                    <h1>عکس با تعهدنامه</h1>
                                    <p>لطفا متن احراز هویت را بر روی یک کاغذ نوشته و به همراه کارت شناسایی خود در دست گرفته و مطابق نمونه عکس بگیرید و بارگذاری نمایید.</p>
                                </div>
                                <div className='container'>
                                    <div className="right">
                                        <div className="info">
                                            <div>
                                                <span>متن تعهدنامه:</span>
                                            </div>
                                            <p>اینجانب .......... قصد معامله با سایت آبان‌تتر به آدرس abantether.com را دارم و مدارک لازم جهت احراز هویت در این سایت ارسال گردیده است. ضمنا متعهد میشوم که حساب بنده تحت اختیار خودم می‌باشد و حساب به شخصی اجاره داده نشده است و خرید را برای خودم انجام می‌دهم. (امضای شما)</p>
                                        </div>
                                        <div className="info">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="26"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                <span>نمونه مورد تایید:</span>
                                            </div>
                                            <ul>
                                                <li>تمام رخ با نور کافی</li>
                                                <li>بدون عینک و ماسک</li>
                                                <li>نوشته های کارت واضح و خوانا</li>
                                                <li>حجم تصویر بارگذاری شده نباید بیشتر از 10 مگابایت باشد.</li>
                                                <li>فرمت تصویر بارگذاری شده باید PNG یا JPEG باشد.</li>
                                                <li>تصویر با دوربین اصلی و توسط فرد دیگری گرفته شود تا نوشته ها برعکس نشود.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="left">
                                        <div className="img-container">
                                            <img src={imageSrcs['client'] || require('../image/authorization/agreement.webp')} alt="Uploaded Image" />
                                            <label htmlFor="image_upload">{imageSrcs['client'] ? 'تغییر عکس' : 'بارگذاری تصویر'}</label>
                                            <input
                                                type="file"
                                                accept=".jpg, .jpeg, .png"
                                                id="image_upload"
                                                onChange={(e) => handleImageUpload(e, 'client', setClientImageFile)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type='submit'>مرحله بعدی</button>
                            </form>
                        </div>
                    }
                    {level === 3 &&
                        <div className='level-3'>
                            <div className='status level-3'>
                                <div>
                                    <div className='level1'>
                                        <svg width="41" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.3352 16.9036C23.0418 16.9036 25.2458 14.7189 25.2458 11.9929C25.2458 9.2669 23.0418 7.10156 20.3352 7.10156C17.6285 7.10156 15.4245 9.28623 15.4245 12.0122C15.4245 14.7382 17.6092 16.9229 20.3352 16.9229V16.9036Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M29.2274 29.9992H11.4408C12.2528 25.8232 15.9454 22.6719 20.3341 22.6719C24.7228 22.6719 28.4348 25.8232 29.2274 29.9992Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M33.8667 1H6.8C3.59675 1 1 3.59675 1 6.8V24.2C1 27.4033 3.59675 30 6.8 30H33.8667C37.0699 30 39.6667 27.4033 39.6667 24.2V6.8C39.6667 3.59675 37.0699 1 33.8667 1Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>اطلاعات هویتی</span>
                                    </div>
                                    <i className='stroke stroke1'></i>
                                    <div className='level2'>
                                        <svg width="34" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31 5.57518V4.00012C31 2.35005 29.6499 1 27.9999 1H26.4248" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M31 26.4297V28.0047C31 29.6548 29.6499 31.0049 27.9999 31.0049H26.4248" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 5.57518V4.00012C1 2.35005 2.35005 1 4.00012 1H5.57518" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 26.4297V28.0047C1 29.6548 2.35005 31.0049 4.00012 31.0049H5.57518" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0008 13.6759C17.9359 13.6759 19.511 12.1008 19.511 10.1508C19.511 8.20069 17.9359 6.64062 16.0008 6.64062C14.0657 6.64062 12.4907 8.21569 12.4907 10.1658C12.4907 12.1158 14.0657 13.6909 16.0008 13.6909V13.6759Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.7341 25.3665C8.03394 25.3665 6.63889 22.7714 8.55897 21.2413C10.464 19.7263 13.0891 18.7812 15.9993 18.7812C18.9094 18.7812 21.5495 19.7113 23.4395 21.2413C25.3446 22.7564 23.9646 25.3665 21.2645 25.3665H10.7341Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>عکس با تعهدنامه</span>
                                    </div>
                                    <i className='stroke stroke2'></i>
                                    <div className='level3'>
                                        <svg width="44" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M36.2143 1H7.21429C3.78223 1 1 3.78223 1 7.21429V23.7857C1 27.2178 3.78223 30 7.21429 30H36.2143C39.6463 30 42.4286 27.2178 42.4286 23.7857V7.21429C42.4286 3.78223 39.6463 1 36.2143 1Z" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M1 10.6797H42.4286" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.18053 20.3203H14.4522" stroke="#434343" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <span>اطلاعات بانکی</span>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleFormSubmit}>
                                <div className='title'>
                                    <h1>اطلاعات بانکی</h1>
                                    <p>لطفا پس از وارد کردن شماره کارت، کارت بانکی را در دست گرفته و مطابق نمونه عکس بگیرید و بارگذاری نمایید.</p>
                                </div>
                                <div className='container'>
                                    <div className="right">
                                        <div className='input-container'>
                                            <label htmlFor="code-melli">شماره کارت (باید به نام صاحب حساب کاربری باشد)</label>
                                            <input type="text" id='code-melli' maxLength='16' required onChange={bankCardInputHandler} onBlur={bankCardCheck} value={bank_card_number} />
                                            {bankCardError && <span>فقط شماره کارت را کامل وارد نمایید.</span>}
                                        </div>
                                        {/* <label htmlFor="name">شماره شبا</label>
                                        <div className="sheba">
                                            <input type="text" id='name' maxLength='24' minLength='24' required onChange={inputHandler} />
                                        </div> */}
                                    </div>
                                    <div className="left">
                                        <div className="img-container">
                                            <img src={imageSrcs['bank_card'] || require('../image/authorization/bank_info.webp')} alt="Uploaded Image" />
                                            <label htmlFor="image_upload">{imageSrcs['bank_card'] ? 'تغییر عکس' : 'بارگذاری تصویر'}</label>
                                            <input
                                                type="file"
                                                accept=".jpg, .jpeg, .png"
                                                id="image_upload"
                                                onChange={(e) => handleImageUpload(e, 'bank_card', setBankCardImageFile)}
                                                required
                                            />
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>تصویر (شامل نوشته‌های کارت) واضح و خوانا باشد.</li>
                                                <li>تصویر به صورت کامل و بدون ادیت باشد.</li>
                                                <li>تصویر شخص بدون عینک و ماسک باشد.</li>
                                                <li>حجم تصویر بارگذاری شده نباید بیشتر از 10 مگابایت باشد.</li>
                                                <li>فرمت تصویر بارگذاری شده باید PNG یا JPEG باشد.</li>
                                                <li>تصویر با دوربین پشت موبایل و توسط فرد دیگری گرفته شود تا نوشته ها برعکس نشود.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <button type='submit' disabled={bankCardError || waiting}>{waiting ? 'لطفا صبر کنید...' : 'ثبت درخواست'}</button>
                            </form>
                        </div>
                    }
                    {level === 4 &&
                        <div className="level-success">
                            <form>
                                <img src={require('../image/authorization/check.png')} />
                                <h1>ثبت مدارک مربوط به احراز هویت با موفقیت انجام شد</h1>
                                <p>مدارک شما توسط کارشناسان احراز هویت ما دریافت و در نوبت بررسی قرار گرفت و حداکثر در مدت یک ساعت بررسی‌ها انجام شده و در صورت صحت مدارک حساب کاربری شما احراز هویت خواهد شد. همچنین در صورتی که ایرادی در مدارک ارسالی وجود داشته باشد از طریق پیامک به شما اطلاع رسانی خواهد شد.</p>
                                <button onClick={() => navigate('/')}>بازگشت به صفحه اصلی</button>
                            </form>
                        </div>
                    }
                </main>
            </div>
        </HelmetProvider>
    )
}

export default Authorization;
//internal
import { useContext, useEffect, useState } from "react";
//external
import axios from "axios";
import Cookies from "js-cookie";
import { MainContext } from "../../contexts/MainContexts"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { FaUserFriends } from "react-icons/fa";
//Component and Style and Image
import "./nav-mobile-tablet.css";
import "./lightMode.css"
import logo from "../../assests/image/logo/main_logo.png";
import iran from "../../assests/image/dashbord/iran.png"
import Change_Language from "../change-language/Change_Language";
import SingleNotification from "../single-notification/SingleNotification";
import LightingMode_two from "../lighting-mode-two/LightingMode_two";
import NoData from '../../components/no-data/NoData'

import { BASE_URL } from '../../utils/url'


const Nav_MobileTablet = ({
  mobileRight_DarkScreen,
  mobileLeft_DarkScreen,
  tablet_DarkScreen,
  tablet_ProfileMenu,
  tablet_Notifications,

  setMobileRight_DarkScreen,
  setMobileLeft_DarkScreen,
  setTablet_DarkScreen,
  setTablet_ProfileMenu,
  setTablet_Notifications
}) => {



  const baseURL = BASE_URL
  const { t } = useTranslation()
  const { lightingMode, primary_dir, user_lang, authorization_status } = useContext(MainContext);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  //اعلان های خوانده نشده
  const [unread_notif, setUnread_notif] = useState([]);


  const mm = (lightingMode==="light") ? "black" : "white";

  //همگی برای لیست اعلانات
  let right=""
  let left=""
  let top=""

  if(window.innerWidth < 640){
   top="48px"
   right="0"
   left="0"
  }else if(window.innerWidth < 640 && primary_dir==="rtl"){
    top= "70px";
     right= "50px";
  }else if(window.innerWidth < 640 && primary_dir==="ltr"){
    top= "70px";
    left= "50px";
  }
 

  //mounting
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res_profile = await axios.get(`${baseURL}/user/profile`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUserData(res_profile.data);

        const res_notifications = await axios.get(`${baseURL}/user/notifications/latest-unread`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUnread_notif(res_notifications.data.data)

      } catch (error) {
        // console.log(error.message)
      }
    }//end of method
    fetchData();


  }, [])

  //updating
  useEffect(() => {
    const fetchData = async () => {
      try {



        const res_notifications = await axios.get(`${baseURL}/user/notifications/latest-unread`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setUnread_notif(res_notifications.data.data)








      } catch (error) {
        // console.log(error.message)
      }
    }//end of method
    fetchData();
  }, [tablet_Notifications])


  //مدیریت آیکون سه خط در موبایل و تبلت
  const handleBars_InMobileAndTablet = () => {
    //اگر در حالت موبایل کلیک شد
    if (window.innerWidth < 768) {
      setMobileRight_DarkScreen(true);

    }
    //اگر در حالت تبلت کلیک شد
    if (window.innerWidth > 768)
      setTablet_DarkScreen(true)
  }//end method


  //مدیریت آیکون پروفایل در موبایل و تبلت
  const handleProfile_InMobileAndTablet = () => {
    //اگر در حالت موبایل کلیک شد
    if (window.innerWidth < 768) {
      setMobileLeft_DarkScreen(true)
    }
    //اگر در حالت تبلت کلیک شد
    if (window.innerWidth > 768) {
      setTablet_ProfileMenu(prev => !prev)
    }
  }//end method


  //متد خروج
  const log_Out = () => {
    Cookies.remove("access_token")
    navigate("/")
  }


  return (

    <nav className="mobile_nav w-full lg:hidden flexBetween px-4" dir={primary_dir}>
      {/* سمت راست */}
      <section className="flex gap-x-3 ">
        {/* bars سه خط*/}
        <span onClick={handleBars_InMobileAndTablet}
          className="bars flexCenter  cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="22.131" height="14.754" viewBox="0 0 22.131 14.754">
            <path id="menu_FILL0_wght400_GRAD0_opsz48" d="M120.922,350.754a.89.89,0,0,1-.657-.267.9.9,0,0,1-.265-.661.882.882,0,0,1,.265-.655.9.9,0,0,1,.657-.261h20.287a.89.89,0,0,1,.657.267.9.9,0,0,1,.265.661.882.882,0,0,1-.265.655.9.9,0,0,1-.657.261Zm4.858-6.455a.89.89,0,0,1-.657-.267.9.9,0,0,1-.265-.661.882.882,0,0,1,.265-.655.9.9,0,0,1,.657-.261h15.429a.89.89,0,0,1,.657.267.9.9,0,0,1,.265.661.882.882,0,0,1-.265.656.9.9,0,0,1-.657.261Zm-4.858-6.455a.889.889,0,0,1-.657-.267.9.9,0,0,1-.265-.661.882.882,0,0,1,.265-.655.9.9,0,0,1,.657-.261h20.287a.89.89,0,0,1,.657.267.9.9,0,0,1,.265.661.882.882,0,0,1-.265.655.9.9,0,0,1-.657.261Z" transform="translate(-120 -336)" fill={lightingMode === "light" ? "black" : "white"} />
          </svg>
        </span>

        {/* زنگوله اعلانات */}
        <section className="bell">
          <span onClick={() => setTablet_Notifications(prev => !prev)}
            className="flexCenter w-full h-full cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.224" height="21.624" viewBox="0 0 19.224 21.624">
              <path id="notifications_active_FILL0_wght400_GRAD0_opsz48_3_" data-name="notifications_active_FILL0_wght400_GRAD0_opsz48 (3)" d="M125.681,184.46a.765.765,0,0,1-.581-.243.709.709,0,0,1-.2-.568,9.493,9.493,0,0,1,.878-3.338,9.348,9.348,0,0,1,2.014-2.8.853.853,0,0,1,1.189.081.776.776,0,0,1,.2.568.83.83,0,0,1-.257.568,7.989,7.989,0,0,0-1.635,2.257,7.771,7.771,0,0,0-.743,2.662.98.98,0,0,1-.3.581A.8.8,0,0,1,125.681,184.46Zm17.651,0a.755.755,0,0,1-.554-.23,1.016,1.016,0,0,1-.284-.581,7.987,7.987,0,0,0-2.325-4.92.83.83,0,0,1-.257-.568.776.776,0,0,1,.2-.568.8.8,0,0,1,.581-.284.748.748,0,0,1,.581.23,9.861,9.861,0,0,1,1.973,2.8,9.424,9.424,0,0,1,.865,3.311.709.709,0,0,1-.2.568A.765.765,0,0,1,143.332,184.46Zm-16.678,9.92a.811.811,0,1,1,0-1.622h1.46v-8.271a6.561,6.561,0,0,1,1.338-4.014,5.727,5.727,0,0,1,3.527-2.257v-.784a1.328,1.328,0,0,1,.446-1.027,1.608,1.608,0,0,1,2.135,0,1.328,1.328,0,0,1,.446,1.027v.784a5.782,5.782,0,0,1,3.541,2.257,6.518,6.518,0,0,1,1.352,4.014v8.271h1.433a.811.811,0,1,1,0,1.622ZM134.493,186.407Zm0,11.218a2.1,2.1,0,0,1-1.514-.635,2.061,2.061,0,0,1-.649-1.527h4.325a2.169,2.169,0,0,1-2.162,2.162Zm-4.757-4.865h9.542v-8.271a4.672,4.672,0,0,0-1.379-3.419,4.829,4.829,0,0,0-6.785,0,4.672,4.672,0,0,0-1.379,3.419Z" transform="translate(-124.895 -176)" fill={tablet_Notifications  ? "#f6b33f" : mm} />
            </svg>
          </span>
          {
            tablet_Notifications &&
            (
              <ul className="notification-list"
              style={{top,left,right}}>
                {
                  unread_notif && unread_notif.map(notif =>
                  (
                    <SingleNotification key={notif.id} notif={notif} />
                  ))
                }

                {
                  unread_notif.length === 0 && <NoData />
                }
              </ul>



            )
          }

        </section>


      </section>



      {/* logo */}
      
        <Link to="/">
          <img className="logo
          " src={logo} alt="" />
        </Link>
      
      {/* سمت چپ */}
      <section className="flexCenter gap-2 cursor-pointer relative ">
        
        {/* احراز هویت (فقط تبلت) */}
        {user_lang === 'ar' &&
          <div id="authorization_box-tablet">
            {authorization_status === '' && <button onClick={() => navigate('/authorization')} className="h-[46px] rounded-lg bg-red-500 text-white p-1 px-2 hover:bg-red-600">
              احراز هویت
            </button>}
            {authorization_status === 'declined' && <button title="برای مشاهده توضیحات کلیک کنید" onClick={() => navigate('/authorization')} className="justify-center h-[46px] rounded-lg bg-red-500 text-white p-1 px-2 hover:bg-red-600 flex gap-[2px] items-center">
              احراز هویت رد شده
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="20" fill="#fff"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
            </button>}
            {authorization_status === 'expired' && <button onClick={() => navigate('/authorization')} className="bg-red-500 text-white rounded p-1 px-2 hover:bg-red-600">
              احراز هویت مجدد
            </button>}
            {(authorization_status === 'reviewing' || authorization_status === 'pending') && <button className="h-[46px] rounded-lg text-white bg-orange-500 cursor-default p-1 px-2">
              احراز هویت در حال بررسی
            </button>}
            {authorization_status === 'verified' && <button className="justify-center h-[46px] rounded-lg text-[var(--maincolor3)] p-1 px-2 cursor-default flex gap-[3px] items-center border border-[var(--maincolor3)] border-dashed">
              احراز هویت شده
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="22" fill="var(--maincolor3)"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>
            </button>}
          </div>
        }

        <span onClick={handleProfile_InMobileAndTablet}
          className="profile flexCenter">
          <svg xmlns="http://www.w3.org/2000/svg" width="17.379" height="16.863" viewBox="0 0 17.379 16.863">
            <path id="person_FILL1_wght400_GRAD0_opsz48" d="M168.689,283.146a3.907,3.907,0,1,1,2.933-1.141A3.97,3.97,0,0,1,168.689,283.146Zm-7.06,8.717A1.623,1.623,0,0,1,160,290.234v-.923a2.988,2.988,0,0,1,.516-1.765,3.312,3.312,0,0,1,1.331-1.113,20.408,20.408,0,0,1,3.489-1.222,14.1,14.1,0,0,1,3.354-.407,13.512,13.512,0,0,1,3.34.421,23.129,23.129,0,0,1,3.476,1.208,3.274,3.274,0,0,1,1.358,1.113,2.988,2.988,0,0,1,.516,1.765v.923a1.622,1.622,0,0,1-1.629,1.629Z" transform="translate(-160 -275)" fill={lightingMode === "light" ? "black" : "white"} />
          </svg>
        </span>
        
        {
          tablet_ProfileMenu &&
          (
            // 768<x<1024
            <ul style={primary_dir === "rtl" ? { left: "5px" } : { right: "5px" }}
              className="profile_menu flexCenter flex-wrap px-6 hidden md:block lg:hidden">
              <li onClick={() => navigate("/setting")}
                className="w-full flexCenter gap-x-2 mb-3 cursor-pointer">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.537" height="17.987" viewBox="0 0 18.537 17.987">
                    <path id="person_FILL0_wght400_GRAD0_opsz48_2_" data-name="person_FILL0_wght400_GRAD0_opsz48 (2)" d="M169.269,283.689a4.167,4.167,0,1,1,3.128-1.217A4.234,4.234,0,0,1,169.269,283.689Zm7.531,9.3H161.738A1.743,1.743,0,0,1,160,291.249v-.985a3.188,3.188,0,0,1,.55-1.883,3.533,3.533,0,0,1,1.419-1.188,21.768,21.768,0,0,1,3.722-1.3,15.044,15.044,0,0,1,3.577-.434,14.412,14.412,0,0,1,3.563.449,24.237,24.237,0,0,1,3.705,1.295,3.5,3.5,0,0,1,1.454,1.187,3.18,3.18,0,0,1,.547,1.878v.985a1.743,1.743,0,0,1-1.738,1.738Zm-15.061-1.738H176.8v-.985a1.587,1.587,0,0,0-.275-.883,1.744,1.744,0,0,0-.681-.623,15.381,15.381,0,0,0-3.389-1.231,14.984,14.984,0,0,0-3.186-.333,15.392,15.392,0,0,0-3.215.333,14.711,14.711,0,0,0-3.389,1.231,1.743,1.743,0,0,0-.927,1.506Zm7.531-9.3a2.526,2.526,0,1,0-1.868-.739A2.532,2.532,0,0,0,169.269,281.951ZM169.269,279.345ZM169.269,291.249Z" transform="translate(-160 -275)" fill={lightingMode === "light" ? "black" : "white"} />
                  </svg>

                </span>
                <span>{t("nav_MobileTablet_c1")}</span>
              </li>

              <li onClick={log_Out}
                className="w-full flexCenter gap-x-2 cursor-pointer">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.801" height="19.119" viewBox="0 0 18.801 19.119">
                    <path id="logout_FILL0_wght400_GRAD0_opsz48_1_" data-name="logout_FILL0_wght400_GRAD0_opsz48 (1)" d="M133.941,229.623a.787.787,0,0,1-.239-.578.744.744,0,0,1,.239-.564l2.124-2.124h-8.5a.768.768,0,0,1-.568-.23.778.778,0,0,1-.229-.571.762.762,0,0,1,.229-.566.777.777,0,0,1,.568-.226h8.444l-2.151-2.151a.735.735,0,0,1-.212-.543.773.773,0,0,1,.245-.571.735.735,0,0,1,.561-.24.784.784,0,0,1,.575.239l3.532,3.532a.74.74,0,0,1,.239.561.77.77,0,0,1-.053.289.809.809,0,0,1-.186.266l-3.505,3.505a.737.737,0,0,1-.544.212A.779.779,0,0,1,133.941,229.623Zm-12.348,5.5A1.634,1.634,0,0,1,120,233.526V217.593A1.634,1.634,0,0,1,121.593,216h6.931a.769.769,0,0,1,.568.23.778.778,0,0,1,.229.571.762.762,0,0,1-.229.566.777.777,0,0,1-.568.226h-6.931v15.933h6.931a.769.769,0,0,1,.568.23.778.778,0,0,1,.229.571.762.762,0,0,1-.229.566.777.777,0,0,1-.568.226Z" transform="translate(-120 -216)" fill="#f63f3f" />
                  </svg>

                </span>
                <span style={{ color: "#f63f3f" }}>{t("nav_MobileTablet_c2")}</span>
              </li>

            </ul>
          )
        }
      </section>




      {/* postion fixed */}
      {/* پرده سیاه برای  حالت موبایل   سمت راست*/}
      {/* x<768 */}
      {
        mobileRight_DarkScreen &&
        (
          <div className="mobileRight_DarkScreen md:hidden">

            <nav className="h-[55px] flexBetween px-5 mb-5">

              <section className="flexBetween gap-x-2">
                {/* <Change_Language position="static" /> */}
                <LightingMode_two />
              </section>



              <span onClick={() => setMobileRight_DarkScreen(false)}
                className="cursor-pointer text-white ">
                <IoMdClose size={28} />
              </span>

            </nav>
            {/*  دکمه */}
            <div className="flex justify-between gap-2 px-5 mb-6">
             
              {/* تنظیمات */}
                <Link to="/setting" className="flexCenter gap-x-2 h-[46px] bg-white rounded-lg grow">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.827" height="20.827" viewBox="0 0 20.827 20.827">
                      <path id="tune_FILL0_wght400_GRAD0_opsz48_2_" data-name="tune_FILL0_wght400_GRAD0_opsz48 (2)" d="M120.868,234.455a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h5.409a.837.837,0,0,1,.618.251.847.847,0,0,1,.249.622.83.83,0,0,1-.249.617.846.846,0,0,1-.618.246Zm0-14.348a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h10.211a.837.837,0,0,1,.618.251.847.847,0,0,1,.249.622.83.83,0,0,1-.249.617.846.846,0,0,1-.618.246Zm8.875,16.72a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-4.773a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v1.533h9.343a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246h-9.343v1.5a.837.837,0,0,1-.251.618A.847.847,0,0,1,129.743,236.827Zm-3.471-7.174a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-1.5h-4.541a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h4.541v-1.562a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v4.8a.837.837,0,0,1-.251.618A.847.847,0,0,1,126.272,229.653Zm3.476-2.372a.837.837,0,0,1-.618-.251.847.847,0,0,1-.25-.622.83.83,0,0,1,.25-.617.846.846,0,0,1,.618-.246h10.211a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246Zm4.8-4.773a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-4.773a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v1.5h4.541a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246h-4.541v1.533a.837.837,0,0,1-.251.618A.847.847,0,0,1,134.545,222.508Z" transform="translate(-120 -216)" fill="#333" />
                    </svg>

                  </span>
                  <span>{t("nav_MobileTablet_1")}</span>
                </Link>

              {/* دعوت دوستان */}
                <Link to={"/invite-friends"} className="flexCenter gap-x-2 h-[46px] bg-white rounded-lg grow">
                    <span>
                    <FaUserFriends />  
                      
                    </span>
                    <span>{t("main_sidebar_7")}</span>
                </Link>
              
            </div>
            

            {/* لیست */}
            <ul className="px-5">
              {/* صفحه اصلی */}
              <li>
                <Link to="/" className="text-center">
                  {t("nav_MobileTablet_3")}
                </Link>
              </li>

              {/* بازار ها */}
              <li>
                <Link to="/markets">
                  {t("nav_MobileTablet_4")}

                </Link>
              </li>

              {/* تبدیل ارز */}
              <li>
                <Link to="/exchange">
                  {t("nav_MobileTablet_5")}

                </Link>
              </li>
              {/* تراکنش ها */}
              <li>
                <Link to="/transaction">
                {t("nav_MobileTablet_11")}

                </Link>
              </li>
              {/* خرید آسان */}
              <li>
                <Link to="/swap">
                  {t("nav_MobileTablet_6")}

                </Link>
              </li>
              {/* بلاگ */}
              {/* <li>
                <Link to="/">
                  {t("nav_MobileTablet_7")}

                </Link>
              </li> */}

              {/* درباره ما */}
              <li>
                <Link to="/aboutus">
                  {t("nav_MobileTablet_8")}

                </Link>
              </li>
              {/* تماس با ما */}
              <li>
                <Link to="/contact">
                  {t("nav_MobileTablet_9")}

                </Link>
              </li>
              {/* قوانین */}
              <li>
                <Link to="/terms">
                  {t("nav_MobileTablet_10")}

                </Link>
              </li>

            </ul>
            {/* absolute */}
            <section className="flex-wrap flexCenter gap-y-2 px-6">
              {/*  */}
              <div className="w-full flexStart gap-x-2">
                <span className="flexCenter w-8 h-8 rounded-md" style={{ background: "#00c0af" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.209" height="16.209" viewBox="0 0 16.209 16.209">
                    <path id="Path_20" data-name="Path 20" d="M13.806,11.1h1.8a4.5,4.5,0,0,0-4.5-4.5V8.4A2.7,2.7,0,0,1,13.806,11.1Zm3.6,0h1.8A8.105,8.105,0,0,0,11.1,3V4.8A6.3,6.3,0,0,1,17.408,11.1Zm.9,3.152a10.23,10.23,0,0,1-3.215-.513,1,1,0,0,0-.279-.045.922.922,0,0,0-.639.261L12.194,15.94A13.548,13.548,0,0,1,6.26,10.006l1.981-1.99a.864.864,0,0,0,.225-.9A10.23,10.23,0,0,1,7.953,3.9a.9.9,0,0,0-.9-.9H3.9a.9.9,0,0,0-.9.9A15.307,15.307,0,0,0,18.308,19.209a.9.9,0,0,0,.9-.9V15.157A.9.9,0,0,0,18.308,14.256ZM4.828,4.8H6.179a12.414,12.414,0,0,0,.405,2.323L5.5,8.214A13.82,13.82,0,0,1,4.828,4.8Zm12.58,12.58a13.419,13.419,0,0,1-3.422-.684l1.081-1.081a11.484,11.484,0,0,0,2.341.405Z" transform="translate(-3 -3)" fill="#fff" />
                  </svg>

                </span>
                <a href="callto:09056915681">09056915681</a>
              </div>
              {/*  */}
              <div className="w-full flexStart gap-x-2">
                <span className="flexCenter w-8 h-8 rounded-md" style={{ background: "#00c0af" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.056" height="13.644" viewBox="0 0 17.056 13.644">
                    <path id="mail_FILL0_wght400_GRAD0_opsz48_3_" data-name="mail_FILL0_wght400_GRAD0_opsz48 (3)" d="M5.279,21.644A1.312,1.312,0,0,1,4,20.365V9.279A1.312,1.312,0,0,1,5.279,8h14.5a1.312,1.312,0,0,1,1.279,1.279V20.365a1.312,1.312,0,0,1-1.279,1.279Zm7.249-6.438L5.279,10.452v9.914h14.5V10.452Zm0-1.279,7.163-4.648H5.386ZM5.279,10.452v0Z" transform="translate(-4 -8)" fill="#fff" />
                  </svg>

                </span>
                <address><a href="mailto:support@moneyex.org">support@moneyex.org</a></address>
              </div>
              {/*  */}
            </section>
          </div>
        )
      }



      {/* postion fixed */}
      {/* پرده سیاه برای حالت موبایل سمت چپ */}
      {/* x<768 */}
      {
        mobileLeft_DarkScreen &&
        (
          <div className="mobileLeft_DarkScreen md:hidden p-5" dir={primary_dir}>

            <div className="flex">
              <span onClick={() => setMobileLeft_DarkScreen(false)}
                className="cursor-pointer flex items-center text-white" >

                <IoMdClose size={28} />

              </span>
            </div>

            <div className="text-white mb-7">
              <span className="flexCenter mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="36.354" height="35.275" viewBox="0 0 36.354 35.275">
                  <g id="Group_3721" data-name="Group 3721" transform="translate(-19.729 8.995)">
                    <path id="person_FILL1_wght400_GRAD0_opsz48" d="M178.177,292.041a8.172,8.172,0,1,1,6.135-2.386A8.3,8.3,0,0,1,178.177,292.041Zm-14.769,18.234A3.394,3.394,0,0,1,160,306.867v-1.931a6.251,6.251,0,0,1,1.079-3.692,6.928,6.928,0,0,1,2.783-2.329,42.707,42.707,0,0,1,7.3-2.556,29.509,29.509,0,0,1,7.015-.852,28.262,28.262,0,0,1,6.987.88,48.374,48.374,0,0,1,7.271,2.528,6.848,6.848,0,0,1,2.84,2.329,6.251,6.251,0,0,1,1.079,3.692v1.931a3.394,3.394,0,0,1-3.408,3.408Z" transform="translate(-140.271 -283.995)" fill="#fff" />
                  </g>
                </svg>

              </span>
              <span className="flexCenter mb-1" style={{ fontSize: "18px" }}>{userData.profile ? (userData.profile?.first_name + " " + userData.profile?.last_name) : userData.mobile}</span>
              <span className="flexCenter ltr">{userData?.mobile}</span>
            </div>

            <div className="flexCenter flex-wrap gap-y-3 px-2">
              {/* احراز هویت (فقط موبایل) */}
              {user_lang === 'ar' &&
                <>
                  {authorization_status === '' && <button onClick={() => navigate('/authorization')} className="h-[46px] w-full rounded-lg bg-red-500 text-white p-1 px-2 hover:bg-red-600">
                    احراز هویت
                  </button>}
                  {authorization_status === 'declined' && <button title="برای مشاهده توضیحات کلیک کنید" onClick={() => navigate('/authorization')} className="justify-center h-[46px] w-full rounded-lg bg-red-500 text-white p-1 px-2 hover:bg-red-600 flex gap-[2px] items-center">
                    احراز هویت رد شده
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="20" fill="#fff"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                  </button>}
                  {authorization_status === 'expired' && <button onClick={() => navigate('/authorization')} className="h-[46px] w-full rounded-lg bg-red-500 text-white p-1 px-2 hover:bg-red-600">
                    احراز هویت مجدد
                  </button>}
                  {(authorization_status === 'reviewing' || authorization_status === 'pending') && <button className="h-[46px] w-full rounded-lg text-white bg-orange-500 cursor-default p-1 px-2">
                    احراز هویت در حال بررسی
                  </button>}
                  {authorization_status === 'verified' && <button className="justify-center h-[46px] w-full rounded-lg text-[#fff] p-1 px-2 bg-[var(--maincolor3)] cursor-default flex gap-[3px] items-center">
                    احراز هویت شده
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="22" fill="#fff"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>
                  </button>}
                </>
              }
              <Link to="/setting" className="flexCenter gap-x-2 w-full h-[46px] bg-white rounded-lg">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.537" height="17.987" viewBox="0 0 18.537 17.987">
                    <path id="person_FILL0_wght400_GRAD0_opsz48_2_" data-name="person_FILL0_wght400_GRAD0_opsz48 (2)" d="M169.269,283.689a4.167,4.167,0,1,1,3.128-1.217A4.234,4.234,0,0,1,169.269,283.689Zm7.531,9.3H161.738A1.743,1.743,0,0,1,160,291.249v-.985a3.188,3.188,0,0,1,.55-1.883,3.533,3.533,0,0,1,1.419-1.188,21.768,21.768,0,0,1,3.722-1.3,15.044,15.044,0,0,1,3.577-.434,14.412,14.412,0,0,1,3.563.449,24.237,24.237,0,0,1,3.705,1.295,3.5,3.5,0,0,1,1.454,1.187,3.18,3.18,0,0,1,.547,1.878v.985a1.743,1.743,0,0,1-1.738,1.738Zm-15.061-1.738H176.8v-.985a1.587,1.587,0,0,0-.275-.883,1.744,1.744,0,0,0-.681-.623,15.381,15.381,0,0,0-3.389-1.231,14.984,14.984,0,0,0-3.186-.333,15.392,15.392,0,0,0-3.215.333,14.711,14.711,0,0,0-3.389,1.231,1.743,1.743,0,0,0-.927,1.506Zm7.531-9.3a2.526,2.526,0,1,0-1.868-.739A2.532,2.532,0,0,0,169.269,281.951ZM169.269,279.345ZM169.269,291.249Z" transform="translate(-160 -275)" fill="#333" />
                  </svg>

                </span>
                <span>{t("nav_MobileTablet_a1")}</span>
              </Link>
              <Link onClick={log_Out}
                to="/" className="flexCenter gap-x-2 w-full h-[46px] bg-white rounded-lg">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.801" height="19.119" viewBox="0 0 18.801 19.119">
                    <path id="logout_FILL0_wght400_GRAD0_opsz48_1_" data-name="logout_FILL0_wght400_GRAD0_opsz48 (1)" d="M133.941,229.623a.787.787,0,0,1-.239-.578.744.744,0,0,1,.239-.564l2.124-2.124h-8.5a.768.768,0,0,1-.568-.23.778.778,0,0,1-.229-.571.762.762,0,0,1,.229-.566.777.777,0,0,1,.568-.226h8.444l-2.151-2.151a.735.735,0,0,1-.212-.543.773.773,0,0,1,.245-.571.735.735,0,0,1,.561-.24.784.784,0,0,1,.575.239l3.532,3.532a.74.74,0,0,1,.239.561.77.77,0,0,1-.053.289.809.809,0,0,1-.186.266l-3.505,3.505a.737.737,0,0,1-.544.212A.779.779,0,0,1,133.941,229.623Zm-12.348,5.5A1.634,1.634,0,0,1,120,233.526V217.593A1.634,1.634,0,0,1,121.593,216h6.931a.769.769,0,0,1,.568.23.778.778,0,0,1,.229.571.762.762,0,0,1-.229.566.777.777,0,0,1-.568.226h-6.931v15.933h6.931a.769.769,0,0,1,.568.23.778.778,0,0,1,.229.571.762.762,0,0,1-.229.566.777.777,0,0,1-.568.226Z" transform="translate(-120 -216)" fill="#f63f3f" />
                  </svg>

                </span>
                <span style={{ color: "red" }}>{t("nav_MobileTablet_a2")}</span>
              </Link>
            </div>

          </div>
        )
      }

      {/* postion fixed */}
      {/* پرده سیاه برای کلیک در حالت تبلت */}
      {/* 768<x<1024 */}
      {
        tablet_DarkScreen && (
          <div className="tablet_DarkScreen hidden md:block lg:hidden">
            {/* نوار */}
            <nav className="h-[60px] flexBetween px-6 mb-5">
              {/* سمت راست */}
              <section
                onClick={() => setTablet_DarkScreen(false)}
                className="w-[106px] h-[46px] flexBetween bg-white rounded-lg px-2 cursor-pointer">
                {/* بازگشت */}
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.981" height="20.581" viewBox="0 0 20.981 20.581">
                    <path id="arrow_back_FILL0_wght400_GRAD0_opsz48_1_" data-name="arrow_back_FILL0_wght400_GRAD0_opsz48 (1)" d="M171.393,288.28l9.288-9.288a1.018,1.018,0,0,0,.234-.334,1.044,1.044,0,0,0,0-.735,1.018,1.018,0,0,0-.234-.334l-9.321-9.321a.907.907,0,0,0-.668-.267.992.992,0,0,0-.7,1.7l7.584,7.584H161a1,1,0,1,0,0,2h16.571l-7.617,7.618a.907.907,0,0,0-.267.668.992.992,0,0,0,1.7.7Z" transform="translate(-160 -268)" />
                  </svg>
                </span>
                <span className="text-sm">{t("nav_MobileTablet_b1")}</span>

              </section>

              {/* سمت چپ */}
              <section className="flexCenter gap-x-2">
                {/* پشتیبانی */}
                <section dir={primary_dir}
                  className="flex items-center justify-center gap-x-2 h-[42px] w-[197px] bg-white"
                  style={{ borderRadius: "10px" }}>
                  {/* svg */}
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.114" height="18.103" viewBox="0 0 20.114 18.103">
                      <path id="support_agent_FILL0_wght400_GRAD0_opsz48" d="M89.806,234.1a.728.728,0,0,1-.537-.218.736.736,0,0,1-.217-.541.722.722,0,0,1,.217-.536.736.736,0,0,1,.537-.214H97.6v-7.643a6.547,6.547,0,0,0-.629-2.8,8.062,8.062,0,0,0-1.685-2.376,8.389,8.389,0,0,0-2.414-1.647,6.744,6.744,0,0,0-5.632,0,8.39,8.39,0,0,0-2.414,1.647,8.062,8.062,0,0,0-1.685,2.376,6.547,6.547,0,0,0-.629,2.8v5.632a.494.494,0,0,1-.5.5A2.017,2.017,0,0,1,80,229.074v-2.011a1.873,1.873,0,0,1,.277-1.018,2.208,2.208,0,0,1,.729-.717L81.081,224a9.122,9.122,0,0,1,1.043-3.319,8.835,8.835,0,0,1,2.037-2.514,9.152,9.152,0,0,1,2.741-1.6,9.153,9.153,0,0,1,6.336,0,8.853,8.853,0,0,1,2.728,1.609,9.183,9.183,0,0,1,2.024,2.514,8.98,8.98,0,0,1,1.043,3.281l.075,1.307a1.829,1.829,0,0,1,1.006,1.634v2.313a1.765,1.765,0,0,1-.277.981,1.828,1.828,0,0,1-.729.654v1.735A1.513,1.513,0,0,1,97.6,234.1Zm-2.766-7.292a.724.724,0,0,1-.528-.226.738.738,0,0,1-.226-.541.7.7,0,0,1,.226-.528.76.76,0,0,1,.541-.214.712.712,0,0,1,.528.217.767.767,0,0,1,0,1.065A.715.715,0,0,1,87.04,226.812Zm6.034,0a.724.724,0,0,1-.528-.226.738.738,0,0,1-.226-.541.7.7,0,0,1,.226-.528.76.76,0,0,1,.541-.214.712.712,0,0,1,.528.217.767.767,0,0,1,0,1.065A.715.715,0,0,1,93.074,226.812ZM84.048,225.5a5.767,5.767,0,0,1,.415-2.69,6.223,6.223,0,0,1,1.383-2.049,6.249,6.249,0,0,1,4.262-1.747,5.444,5.444,0,0,1,3.847,1.446,6.12,6.12,0,0,1,1.911,3.608A7.271,7.271,0,0,1,91.7,222.8a8.368,8.368,0,0,1-2.778-3.231,8.03,8.03,0,0,1-1.7,3.608A7.824,7.824,0,0,1,84.048,225.5Z" transform="translate(-80 -216)" fill={"#000"} />
                    </svg>
                  </span>

                  <div dir={primary_dir} className="text-sm">
                    <span>{t("nav_Desktop_10")}</span>
                    {" "}
                    <span>:</span>
                    {" "}
                    <span className="ltr" dir="ltr" style={{ direction: "ltr" }}>
                      <a href="tel:09056915681">09056915681</a>
                    </span>
                  </div>
                </section>

                {/* زبان */}
                {/* <Change_Language position="static" /> */}

                <LightingMode_two />


              </section>
            </nav>
            {/* list_1 */}
            <div className="flexCenter mb-1">

              <ul className="tablet_list1">
                <li>
                  <Link to="/dashboard" className="flex gap-x-4 items-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20.276" height="20.276" viewBox="0 0 20.276 20.276">
                        <path id="grid_view_FILL0_wght400_GRAD0_opsz48" d="M121.69,225.293A1.695,1.695,0,0,1,120,223.6V217.69a1.695,1.695,0,0,1,1.69-1.69H127.6a1.695,1.695,0,0,1,1.69,1.69V223.6a1.695,1.695,0,0,1-1.69,1.69Zm0,10.983a1.695,1.695,0,0,1-1.69-1.69v-5.914a1.695,1.695,0,0,1,1.69-1.69H127.6a1.695,1.695,0,0,1,1.69,1.69v5.914a1.695,1.695,0,0,1-1.69,1.69Zm10.983-10.983a1.695,1.695,0,0,1-1.69-1.69V217.69a1.695,1.695,0,0,1,1.69-1.69h5.914a1.695,1.695,0,0,1,1.69,1.69V223.6a1.695,1.695,0,0,1-1.69,1.69Zm0,10.983a1.695,1.695,0,0,1-1.69-1.69v-5.914a1.695,1.695,0,0,1,1.69-1.69h5.914a1.695,1.695,0,0,1,1.69,1.69v5.914a1.695,1.695,0,0,1-1.69,1.69ZM121.69,223.6H127.6V217.69H121.69Zm10.983,0h5.914V217.69h-5.914Zm0,10.983h5.914v-5.914h-5.914Zm-10.983,0H127.6v-5.914H121.69ZM132.673,223.6ZM132.673,228.673ZM127.6,228.673ZM127.6,223.6Z" transform="translate(-120 -216)" fill="#f6b33f" />
                      </svg>

                    </span>
                    <span>{t("nav_MobileTablet_b3")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/transaction" className="flex gap-x-4 items-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.892" height="18.475" viewBox="0 0 16.892 18.475">
                        <path id="receipt_long_FILL0_wght400_GRAD0_opsz48_4_" data-name="receipt_long_FILL0_wght400_GRAD0_opsz48 (4)" d="M119.443,200.475A2.433,2.433,0,0,1,117,198.031v-2.21a.686.686,0,0,1,.7-.7h2.257V182.465q0-.233.186-.233a.536.536,0,0,1,.372.186l.838.838,1.14-1.14a.333.333,0,0,1,.252-.116.36.36,0,0,1,.26.116l1.117,1.14,1.14-1.14a.333.333,0,0,1,.252-.116.36.36,0,0,1,.26.116l1.14,1.14,1.14-1.14A.333.333,0,0,1,128.3,182a.36.36,0,0,1,.26.116l1.14,1.14,1.14-1.14A.333.333,0,0,1,131.1,182a.36.36,0,0,1,.26.116l1.14,1.14.838-.838a.536.536,0,0,1,.372-.186q.186,0,.186.239v15.56a2.433,2.433,0,0,1-2.443,2.443Zm12.006-1.4a1,1,0,0,0,1.047-1.047V184.187H121.351v10.936H129.7a.686.686,0,0,1,.7.7v2.207a1,1,0,0,0,1.047,1.051Zm-8.19-11.215a.632.632,0,0,1-.477-.209.7.7,0,0,1-.2-.5.659.659,0,0,1,.2-.489.681.681,0,0,1,.5-.2h4.188a.673.673,0,0,1,.5.2.681.681,0,0,1,.2.5.668.668,0,0,1-.2.5.681.681,0,0,1-.5.2Zm0,3.118a.632.632,0,0,1-.477-.209.7.7,0,0,1-.2-.5.659.659,0,0,1,.2-.489.681.681,0,0,1,.5-.2h4.188a.673.673,0,0,1,.5.2.682.682,0,0,1,.2.5.668.668,0,0,1-.2.5.681.681,0,0,1-.5.2Zm7.073-3.118a.716.716,0,1,1,.489-.209A.67.67,0,0,1,130.332,187.863Zm0,3a.716.716,0,1,1,.489-.209A.67.67,0,0,1,130.332,190.865Zm-10.913,8.214h9.586v-2.559H118.4v1.512a1.016,1.016,0,0,0,.294.756A1,1,0,0,0,119.42,199.079Zm-1.024,0v0Z" transform="translate(-117 -182)" fill="#333" />
                      </svg>


                    </span>
                    <span>{t("nav_MobileTablet_b4")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/wallet" className="flex gap-x-4 items-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="17.173" height="16.227" viewBox="0 0 17.173 16.227">
                        <path id="account_balance_wallet_FILL0_wght400_GRAD0_opsz48" d="M131.99,225.556a1.5,1.5,0,0,0,1.442-1.454,1.357,1.357,0,0,0-.439-1,1.388,1.388,0,0,0-2.006,0,1.357,1.357,0,0,0-.439,1,1.5,1.5,0,0,0,1.442,1.454Zm-10.637,4.124v0Zm0,2.547a1.31,1.31,0,0,1-.935-.406,1.281,1.281,0,0,1-.417-.947V217.352A1.43,1.43,0,0,1,121.352,216h13.522a1.281,1.281,0,0,1,.947.417,1.31,1.31,0,0,1,.406.935v3.02h-1.352v-3.02H121.352v13.522h13.522v-3h1.352v3a1.386,1.386,0,0,1-1.352,1.352Zm8.068-3.9a1.644,1.644,0,0,1-1.217-.451,1.614,1.614,0,0,1-.451-1.194v-5.116a1.606,1.606,0,0,1,.451-1.206,1.668,1.668,0,0,1,1.217-.439h6.085a1.668,1.668,0,0,1,1.217.439,1.606,1.606,0,0,1,.451,1.206v5.116a1.614,1.614,0,0,1-.451,1.194,1.644,1.644,0,0,1-1.217.451Z" transform="translate(-120 -216)" fill="#333" />
                      </svg>
                    </span>
                    <span>{t("nav_MobileTablet_b5")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/setting" className="flex gap-x-4 items-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20.827" height="20.827" viewBox="0 0 20.827 20.827">
                        <path id="tune_FILL0_wght400_GRAD0_opsz48_2_" data-name="tune_FILL0_wght400_GRAD0_opsz48 (2)" d="M120.868,234.455a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h5.409a.837.837,0,0,1,.618.251.847.847,0,0,1,.249.622.83.83,0,0,1-.249.617.846.846,0,0,1-.618.246Zm0-14.348a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h10.211a.837.837,0,0,1,.618.251.847.847,0,0,1,.249.622.83.83,0,0,1-.249.617.846.846,0,0,1-.618.246Zm8.875,16.72a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-4.773a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v1.533h9.343a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246h-9.343v1.5a.837.837,0,0,1-.251.618A.847.847,0,0,1,129.743,236.827Zm-3.471-7.174a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-1.5h-4.541a.837.837,0,0,1-.618-.251.847.847,0,0,1-.249-.622.83.83,0,0,1,.249-.617.846.846,0,0,1,.618-.246h4.541v-1.562a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v4.8a.837.837,0,0,1-.251.618A.847.847,0,0,1,126.272,229.653Zm3.476-2.372a.837.837,0,0,1-.618-.251.847.847,0,0,1-.25-.622.83.83,0,0,1,.25-.617.846.846,0,0,1,.618-.246h10.211a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246Zm4.8-4.773a.83.83,0,0,1-.617-.249.846.846,0,0,1-.246-.618v-4.773a.837.837,0,0,1,.251-.618.847.847,0,0,1,.622-.249.83.83,0,0,1,.617.249.846.846,0,0,1,.246.618v1.5h4.541a.837.837,0,0,1,.618.251.847.847,0,0,1,.25.622.83.83,0,0,1-.25.617.846.846,0,0,1-.618.246h-4.541v1.533a.837.837,0,0,1-.251.618A.847.847,0,0,1,134.545,222.508Z" transform="translate(-120 -216)" fill="#333" />
                      </svg>

                    </span>
                    <span>  {t("nav_MobileTablet_b6")}</span>
                  </Link>
                </li>


                <li>
                  <Link to="/order" className="flex gap-x-4 items-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18.529" height="18.529" viewBox="0 0 18.529 18.529">
                        <path id="add_chart_FILL0_wght400_GRAD0_opsz48" d="M121.544,234.529A1.583,1.583,0,0,1,120,232.985V217.544A1.583,1.583,0,0,1,121.544,216h9.393a.745.745,0,0,1,.55.223.754.754,0,0,1,.222.553.739.739,0,0,1-.222.549.753.753,0,0,1-.55.219h-9.393v15.441h15.441v-9.393a.745.745,0,0,1,.223-.55.754.754,0,0,1,.553-.222.739.739,0,0,1,.549.222.753.753,0,0,1,.219.55v9.393a1.583,1.583,0,0,1-1.544,1.544Zm3.444-11.118a.739.739,0,0,0-.549.222.753.753,0,0,0-.219.55v5.533a.745.745,0,0,0,.223.55.754.754,0,0,0,.553.222.739.739,0,0,0,.549-.222.753.753,0,0,0,.219-.55v-5.533a.745.745,0,0,0-.223-.55A.754.754,0,0,0,124.988,223.412Zm4.272-3.371a.739.739,0,0,0-.549.222.753.753,0,0,0-.219.55v8.9a.745.745,0,0,0,.223.55.754.754,0,0,0,.553.222.739.739,0,0,0,.549-.222.753.753,0,0,0,.219-.55v-8.9a.745.745,0,0,0-.223-.55A.754.754,0,0,0,129.26,220.04Zm4.272,6.64a.739.739,0,0,0-.549.222.753.753,0,0,0-.219.55v2.265a.745.745,0,0,0,.223.55.754.754,0,0,0,.553.222.739.739,0,0,0,.549-.222.753.753,0,0,0,.219-.55v-2.265a.745.745,0,0,0-.223-.55A.754.754,0,0,0,133.532,226.68Zm1.214-6.871h-1.493a.745.745,0,0,1-.55-.223.754.754,0,0,1-.222-.553.739.739,0,0,1,.222-.549.753.753,0,0,1,.55-.219h1.493v-1.493a.745.745,0,0,1,.223-.55.754.754,0,0,1,.553-.222.739.739,0,0,1,.549.222.753.753,0,0,1,.219.55v1.493h1.467a.745.745,0,0,1,.55.223.754.754,0,0,1,.222.553.739.739,0,0,1-.222.549.753.753,0,0,1-.55.219H136.29v1.467a.745.745,0,0,1-.223.55.754.754,0,0,1-.553.222.739.739,0,0,1-.549-.222.753.753,0,0,1-.219-.55ZM129.265,225.265Z" transform="translate(-120 -216)" fill="#333" />
                      </svg>


                    </span>
                    <span>{t("nav_MobileTablet_b8")}</span>
                  </Link>
                </li>

                <li>
                    <Link to={"/invite-friends"} className="flex gap-x-4 items-center">
                        <span>
                        <FaUserFriends />  
                         
                        </span>
                        <span>{t("main_sidebar_7")}</span>
                    </Link>
                </li>





              </ul>
            </div>
            {/* list_2 */}
            <div className="flexCenter">
              <ul className="tablet_list2">
                {/* صفحه اصلی */}
                <li>
                  <Link to="/">
                    <span>{t("nav_MobileTablet_b9")}</span>
                  </Link>
                </li>
                {/* بازار ها */}
                <li>
                  <Link to="/markets">
                    <span>{t("nav_MobileTablet_b10")}</span>
                  </Link>
                </li>
                {/* تبدیل ارز */}
                <li>
                  <Link to="/exchange">
                    <span>{t("nav_MobileTablet_b11")}</span>

                  </Link>
                </li>
                {/* راهنمای  استفاده */}
                {/* <li>
                  <Link to="/helpcenter">
                    <span>{t("nav_MobileTablet_b12")}</span>
                  </Link>
                </li> */}
                {/* خرید آسان */}
                <li>
                  <Link to="/swap">
                    <span>{t("nav_MobileTablet_b13")}</span>
                  </Link>
                </li>
                {/* بلاگ */}

                {/* <li>
                  <span>{t("nav_MobileTablet_b14")}</span>

                </li> */}
                {/* درباره ما */}
                <li>
                  <Link to="/aboutus">
                    <span>{t("nav_MobileTablet_b15")}</span>

                  </Link>
                </li>
                {/* تماس با ما */}
                <li>
                  <Link to="/contact" >
                    <span>{t("nav_MobileTablet_b16")}</span>

                  </Link>
                </li>
                {/* قوانین */}
                <li>
                  <Link to="/terms">
                    <span>{t("nav_MobileTablet_b17")}</span>

                  </Link>
                </li>
                {/*  */}
              </ul>
            </div>
          </div>
        )
      }
      {/* postion fixed */}
      {/* اعلانات برای حالت موبایل */}
      {/* x<768 */}

    </nav>
  )
}
export default Nav_MobileTablet;





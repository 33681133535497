//internal
import { useState, useEffect, useContext } from "react";
//external
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/MainContexts";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TfiUpload, TfiDownload } from "react-icons/tfi";
import { GiCycle } from "react-icons/gi";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style
import "./xchange-withdraw.css"
import "./lightMode.css";
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links";
import LightingMode from "../../components/lighting-mode/LightingMode";
//Image
import logo from "../../image/logo/_company_logo.png";
import { BASE_URL } from '../../utils/url'








const Xchange_Withdraw = () => {

    const baseURL = BASE_URL
    const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
    const { t } = useTranslation();
    const { lightingMode, primary_dir, user_lang } = useContext(MainContext)
    const navigate = useNavigate();
    const selected_Coin = JSON.parse(Cookies.get("selected_Coin"))
    // console.log(selected_Coin)



    //desktop popup
    const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
    const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
    const [desktop_Notifications, setDesktop_Notifications] = useState(false);
    //mobile popup dark_screen
    const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
    const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
    //tablet popup dark_screen
    const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
    const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
    const [tablet_Notifications, setTablet_Notifications] = useState(false);

    //method
    const closeWindowForDesktop = () => {
        if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
        if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
        // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
        // if (tablet_Notifications == true) { setTablet_Notifications(false) }
        if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
    }//end of method

    //POST
    const [address, setAddress] = useState("");
    const [amount, setAmount] = useState("");
    //GET network
    const [coinData, setCoinData] = useState({});


    //////////////////////////////////////////////


    //mounting
    useEffect(() => {

        //اگر توکن نداشت
        if (!Cookies.get("access_token")) {
            navigate("/judge", { replace: true })
        }


        const fetchData = async () => {
            try {
                //Wallet
                //GET User Coin
                const response_1 = await axios.get(`${baseURL}/user/wallets/${selected_Coin.coin_symbol}`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get("access_token")}`,
                        'Content-Type': 'application/json'
                    }
                });
                // console.log(response_1.data.data)
                // console.log("-----------------------")
                setCoinData(response_1.data.data)
            } catch (err) {
                if(err.response.data.message==="Unauthenticated."){
                    Cookies.remove("access_token")
                    navigate("/judge", { replace: true })
                   }
            }
        }
        fetchData();

    }, [])

    //ارسال فرم برای دریافت کد تایید
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.target.disabled = true
        //Wallet
        //POST Withdrawl-crypto

        try {
            const res = await axios.post(`${baseURL}/user/wallets/${selected_Coin.id}/withdrawals`,
                JSON.stringify({ address, amount }),
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get("access_token")}`,
                        'Content-Type': 'application/json'
                    }
                })

            if (res.status === 200) {
                setTansactionId(res.data.data.id)
                setShowConfirmModal(false)
                setShowModal(true)
            }
            
        } catch (err) {
            console.log(err.message);
        } finally {
            e.target.disabled = false
        }


    }//end


    const params = new URLSearchParams(window.location.search)
    const [userWallets, setUserWallets] = useState('')
    const location = useLocation();
    const parts = location.pathname.split('/');
    const lastPart = parts[parts.length - 1];
    const thisCoin = userWallets && userWallets.find(item => item.coin_symbol === lastPart)
    const depositPosibility = thisCoin && thisCoin.deposit_status
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response_1 = await axios.get(`${baseURL}/user/wallets?${params.toString()}`, {
              headers: {
                'Authorization': `Bearer ${Cookies.get("access_token")}`,
                'Content-Type': 'application/json'
              }
            });
            setUserWallets(response_1.data.data)
          } catch (err) {
            if (err.response.data.message === "Unauthenticated.") {
              Cookies.remove("access_token")
              navigate("/judge", { replace: true })
            }
          }
        }//end of method
        fetchData();
    }, [])



    /////
    const [showModal, setShowModal] = useState(false)
    const [userPhoneNumber, setUserPhoneNumber] = useState(0)
    const [tansactionId, setTansactionId] = useState('')
    const [otpInputValue, setOtpInputValue] = useState('')
    
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    useEffect(() => {
        if (showModal || showConfirmModal) {
            document.body.style.overflow = 'hidden'
            document.body.style.paddingRight = '9px'
        } else {
            document.body.style.overflow = 'unset'
            document.body.style.paddingRight = '0'
        }
    }, [showModal, showConfirmModal])

    const fetchUserData = async () => {
        try {
          const res_profile = await axios.get(`${baseURL}/user/profile`, {
            headers: {
              'Authorization': `Bearer ${Cookies.get("access_token")}`,
              'Content-Type': 'application/json'
            }
          });
          setUserPhoneNumber(res_profile.data.mobile)
        } catch {}
    }
    useEffect(() => {
        fetchUserData()
    },[])


    //ارسال فرم نهایی
    const handleSubmitForm2 = async (e) => {
        e.preventDefault();
        e.target.disabled = true
        try {
            const res = await axios.post(`${baseURL}/user/pre-withdrawals/${tansactionId}`,
                JSON.stringify({ otp: otpInputValue }),
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get("access_token")}`,
                        'Content-Type': 'application/json'
                    }
                })

            if (res.status === 200) {
                toast.success(res.data.status, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    navigate("/wallet")
                }, 2000)
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            e.target.disabled = false
        }
    }

    // با تغییر کوین در url از صفحه خارج شود
    const URLLocation = useLocation()
    const URLNavigate = useNavigate()
    const [lastSegment, setLastSegment] = useState("")
    
    useEffect(() => {
        if (selected_Coin.coin_symbol && lastSegment) {
            if (selected_Coin.coin_symbol !== lastSegment) {
                Cookies.remove('selected_Coin')
                navigate("/wallet")
            }
        }
    }, [selected_Coin, lastSegment])

    useEffect(() => {
        const pathname = URLLocation.pathname
        const segments = pathname.split("/")
        setLastSegment(segments[segments.length - 1])
    }, [])



    return (
        <HelmetProvider>
            <div id="xchange" onClick={closeWindowForDesktop} className="flex flex-wrap">
                <Helmet>
                    <title>{t("title_xchangeDeposit")}</title>
                </Helmet>


                {/* نوار فقط برای حالت موبایل فقط موبایل sm:hidden*/}
                {/* دست ساز */}
                {/* x<640 */}
                <nav className="sm:hidden w-full h-[50px] flexBetween px-4 mb-4"
                    style={lightingMode === "light" ? { background: "white" } : { background: "black" }}>
                    <span>{""}</span>
                    <Link to={"/"}><img src={logo} alt="" /></Link>
                    <Link to={"/wallet"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.064" height="25.566" viewBox="0 0 26.064 25.566">
                                <path id="arrow_back_FILL0_wght400_GRAD0_opsz48_1_" data-name="arrow_back_FILL0_wght400_GRAD0_opsz48 (1)" d="M171.911,293.192l-11.538-11.538a1.265,1.265,0,0,1-.291-.415,1.3,1.3,0,0,1,0-.913,1.265,1.265,0,0,1,.291-.415l11.579-11.579a1.126,1.126,0,0,1,.83-.332,1.233,1.233,0,0,1,.872,2.117l-9.421,9.421h20.585a1.245,1.245,0,1,1,0,2.49H164.233l9.463,9.463a1.126,1.126,0,0,1,.332.83,1.233,1.233,0,0,1-2.117.872Z" transform="translate(-160 -268)" fill={lightingMode === "light" ? "black" : "white"} />
                            </svg>

                        </span>
                    </Link>
                </nav>

                {/*  نوار برای تبلت فقط تبلت*/}
                {/* 640< x <1024*/}
                <div className="w-full hidden sm:block lg:hidden">
                    <Nav_MobileTablet
                        mobileRight_DarkScreen={mobileRight_DarkScreen}
                        mobileLeft_DarkScreen={mobileLeft_DarkScreen}
                        tablet_DarkScreen={tablet_DarkScreen}
                        tablet_ProfileMenu={tablet_ProfileMenu}
                        tablet_Notifications={tablet_Notifications}

                        setMobileRight_DarkScreen={setMobileRight_DarkScreen}
                        setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
                        setTablet_DarkScreen={setTablet_DarkScreen}
                        setTablet_ProfileMenu={setTablet_ProfileMenu}
                        setTablet_Notifications={setTablet_Notifications}
                    />
                </div>

                {/* لینک های فیکس برای موبایل sm:hidden*/}
                {/* x <640 */}
                <Fixed_Mobile_Links />


                {/* ساید بار اصلی برای دسکتاپ*/}
                {/* x > 1024 */}
                <Main_Sidebar />

                <main>

                    {/* نوار برای دسکتاپ hidden lg:flex*/}
                    {/* x > 1024 */}
                    <Nav_Desktop
                        desktop_ProfileMenu={desktop_ProfileMenu}
                        desktop_Notifications={desktop_Notifications}
                        desktop_ThreeDots={desktop_ThreeDots}

                        setDesktop_Notifications={setDesktop_Notifications}
                        setDesktop_ThreeDots={setDesktop_ThreeDots}
                        setDesktop_ProfileMenu={setDesktop_ProfileMenu}
                    />

                    {/* تبدیل-ارسال-دریافت */}
                    <section className="Change_Send_Receive">
                        {/* nth-of-type-1 */}
                        <div className="hidden sm:flex justify-between items-center mb-4">
                            <h3 className="text-lg font-bold"
                                style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{t("xchange_withdraw_1")}</h3>
                            <button onClick={() => navigate("/wallet")}
                                className="w-[142px] h-12 flexCenter gap-x-2 rounded-lg"
                                style={lightingMode === "light" ? { background: "#f7f8fa" } : { background: "#141414" }}>

                                <span className="font-normal"
                                    style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{t("xchange_withdraw_2")}</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.201" height="14.313" viewBox="0 0 7.201 14.313">
                                        <path id="arrow-ios-forward-fill" d="M1.022,0A1.02,1.02,0,0,0,.236,1.675l4.57,5.468L.4,12.62a1.02,1.02,0,0,0,.153,1.438,1.02,1.02,0,0,0,1.489-.153l4.927-6.12a1.02,1.02,0,0,0,0-1.3L1.869.369A1.02,1.02,0,0,0,1.022,0Z" transform="translate(7.201 14.313) rotate(180)" fill={lightingMode === "light" ? "black" : "white"} />
                                    </svg>
                                </span>
                            </button>
                        </div>


                        {/* والد برای سه دکمه*/}
                        <div className="three_button flexCenter gap-x-3 mb-5">

                            {/* دکمه تبدیل convert*/}
                            {/* لینک ثابت */}
                            {/* <button onClick={() => navigate("/xchange-convert")}>
                            <span>
                                <GiCycle />
                            </span>
                            <span>{t("xchange_withdraw_3")}</span>
                        </button> */}
                            <button 
                                onClick={() => navigate(`/xchange-deposit/${selected_Coin.coin_symbol}`)} 
                                style={{ pointerEvents: depositPosibility ? 'auto' : 'none', opacity: depositPosibility ? '1' : '.4' }}
                            >
                                <span>
                                    <TfiDownload />
                                </span>
                                <span>{t("xchange_withdraw_4")}</span>
                            </button>
                            <button style={{ color: "white", background: "#f6b33f" }}>
                                <span className="font-bold">
                                    <TfiUpload />
                                </span>
                                <span>{t("xchange_withdraw_5")}</span>
                            </button>
                        </div>




                        {/* باکس ارسال send-box*/}
                        {

                            (
                                <div className="flexCenter">
                                    <div
                                        className="send-box w-[540px] h-[494px] rounded-xl">
                                        {/* مقدار */}
                                        {/* amount*/}
                                        <div className="h-[74px] mb-3">
                                            <div className="flexBetween mb-1">
                                                <label htmlFor="" className="mb-1">{t("xchange_withdraw_6")}</label>

                                            </div>

                                            <div style={{ borderRadius: "10px", border: "1px solid #edeef1", overflow: "hidden" }}
                                                className="h-[46px] flex justify-between">
                                                <input
                                                    type="text"
                                                    value={amount}
                                                    onChange={e => setAmount(e.target.value.replace(/[^0-9.]/g, ''))}
                                                    className="block h-full w-3/5 p-3"
                                                    placeholder={`دارایی شما ${selected_Coin.primary_balance}`}
                                                />

                                                <div className="h-full w-2/5">
                                                    <div className="option_style px-2"
                                                        style={(lightingMode === "light") ? { background: "#f7f8fa", color: "black" } : { background: "#141414", color: "white" }}>
                                                        <img src={`${base_url_img}__${selected_Coin.coin_symbol}.png`} height="30px" width="30px" />
                                                        <span>{selected_Coin.coin_name}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* نوع شبکه */}
                                        <div className="h-[75px] mb-3">
                                            <label className="block mb-2" htmlFor="">{t("xchange_withdraw_7")}</label>

                                            <div className="network_type flex justify-between h-[46px]">
                                                {
                                                    coinData.networks?.map((net, index) =>
                                                    (
                                                        <section key={index} className="w-[47%] h-full">
                                                            <span className="ballet"></span>
                                                            <span style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>{net.name}</span>
                                                        </section>
                                                    ))
                                                }
                                            </div>
                                        </div>


                                        {/* آدرس در شبکه */}
                                        {/* address */}
                                        <div className="h-[74px] mb-3">
                                            <label className="block mb-1" htmlFor="">{t("xchange_withdraw_8")}</label>
                                            <input
                                                type="text"
                                                value={address}
                                                onChange={e => setAddress(e.target.value)}
                                                className="w-full h-[46px] p-3"
                                                style={{ borderRadius: "8px" }}
                                            />
                                        </div>
                                        {/* کارمزد انتقال */}
                                        <div className="h-[92px] mb-3 p-4 rounded-lg"
                                            style={lightingMode === "light" ? { background: "#f7f8fa", color: "black" } : { background: "#141414", color: "white" }}>
                                            <section className="flexBetween mb-2">
                                                <span className="text-sm">{t("xchange_withdraw_9")}</span>
                                                <span dir="ltr"> {selected_Coin.coin_symbol} {coinData?.withdrawal_fee}</span>
                                            </section>
                                            <section className="flexBetween">
                                                <span className="text-sm">{t("xchange_withdraw_10")}</span>
                                                <span dir="ltr">{selected_Coin.coin_symbol} {(amount > 1) ? (amount - coinData?.withdrawal_fee) : 0}</span>
                                            </section>
                                        </div>
                                        {/* ارسال */}
                                        <button onClick={() => setShowConfirmModal(true)} disabled={!amount || +amount === 0 || !address}
                                            className="block w-full h-[46px] rounded-md text-white"
                                            style={{ background: "#00c0af" }}>ادامه</button>
                                        {/*  */}
                                        <p className="text-sm text-center mt-3 hidden sm:block"
                                            style={lightingMode === "light" ? { color: "black" } : { color: "white" }}>
                                            <span>با ارسال وانجام معامله</span>
                                            {" "}
                                            <Link to={"/terms"} className="font-bold">قوانین و مقررات</Link>
                                            {" "}
                                            <span>مانی اکس خوانده را می‌پذیرم</span>
                                        </p>
                                    </div>
                                </div>
                            )
                        }



                    </section>

                </main>
                <LightingMode bottom="20px" right={primary_dir === "rtl" ? "20px" : null} left={primary_dir === "ltr" ? "20px" : null} />
            </div>

            {showConfirmModal && (
                <div className="module-1 fixed inset-0 !z-[999]">
                    <div className="all">
                        <div className="top">
                            <p className="text text-xl">برداشت {selected_Coin?.coin_name}</p>
                        </div>
                        <div className="all-grid !block">
                            <p className="mb-3">
                                مقدار: {amount}
                                <br />
                                آدرس واریز: {address}
                                <br />
                                کارمزد انتقال: {selected_Coin.coin_symbol} {coinData?.withdrawal_fee}
                            </p>
                            <p>
                                کد تایید به شماره موبایل
                                {' '}{userPhoneNumber}{' '}
                                ارسال خواهد شد.
                            </p>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <button onClick={handleSubmitForm} className="add-cart">
                                ارسال کد تایید
                            </button>
                            <button className="add-cart px-4 !bg-white !text-red-500 !border !border-solid !border-red-500" onClick={() => setShowConfirmModal(false)}>
                                انصراف
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div className="module-1 fixed inset-0 !z-[999]">
                    <div className="all">
                        <div className="top">
                            <p className="text text-xl">کد تایید ارسال شد</p>
                        </div>
                        <div className="all-grid !block">
                            <p className="mb-3">
                                کد تایید به شماره موبایل
                                {' '}{userPhoneNumber}{' '}
                                ارسال شد.
                                <br />
                                شما در حال خرید
                                {' '}{amount}{' '}
                                تعداد ارز
                                {' '}{selected_Coin.coin_name}{' '}
                                می‌باشید.
                            </p>
                            <div className="grid-item !p-0">
                                <div className="carts">
                                    <div className="label">کد تایید</div>
                                    <input value={otpInputValue} onChange={e => setOtpInputValue(e.target.value)} type="number" className="pr-3" />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-5">
                            <button onClick={handleSubmitForm2} className="add-cart grow">
                                تایید
                            </button>
                            <button className="add-cart !w-fit px-4 !bg-white !text-red-500 !border !border-solid !border-red-500" onClick={() => setShowModal(false)}>
                                انصراف
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </HelmetProvider>
    )
}


export default Xchange_Withdraw;
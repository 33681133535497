//internall
import { useState, useEffect } from 'react';

//externall
import axios from 'axios';
import { useParams } from "react-router-dom"

//Component and Style and
import "./single-blog.css"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";


const SingleBlog = () => {
  const { t } = useTranslation();

  const baseURL = "https://api.moneyex.org";
  const base_url_img = "https://api.moneyex.org/storage/images/posts";
  const { blogId } = useParams()

  const [data, setData] = useState()


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res_1 = await axios.get(`${baseURL}/blog/${blogId}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });


        setData(res_1.data)
      } catch (error) {

      }
    }//end

    fetchData()
  }, [])


  return (
    <HelmetProvider>
      <Helmet>
        <title>بلاگ</title>
        <meta name="title" content={t("metatag.1")} />
        <meta name="description" content={t("metatag.2")} />
      </Helmet>
      <div className="single-blog">
        <Header />
        <main className='my-20'>
          <div className="content mx-auto">
            <h1 className='font-bold text-4xl text-center mb-9'>{data ? data.title : ""}</h1>
            <figure className='mb-5'>
              <img src={`${base_url_img}/${data?.featured_image}`} alt="" />
            </figure>
            <p>
              {data && <div dangerouslySetInnerHTML={{ __html: data.content }} />}
            </p>
            <div className='flex justify-end'>
              {data ? data.created_at.split("T")[0] : ""}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default SingleBlog
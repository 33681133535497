//internal
import { useContext, useState,useEffect } from "react";

//external
import axios from "axios";

import Cookies from "js-cookie";
import Select from 'react-select';
import {  useNavigate } from "react-router-dom";
import { MainContext } from "../../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';



//Component and Style

import Login_Left from "../login/Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode"
//Image
import canadaFlag from "../../../assests/image/flag/canada.jpg";
import usaFlag from "../../../assests/image/flag/usa.jpg";
import iranFlag from "../../../assests/image/flag/iran.jpg";

import { BASE_URL } from '../../../utils/url'




const Forget = () => {

    const baseURL = BASE_URL
    const navigate = useNavigate();
    const { t } = useTranslation()
    const { primary_dir,
            reverse_dir,
            lightingMode,
            setUser_lang,
            setPrimary_dir,
            setReverse_dir,
            setLightingMode,
            setUser_phone } = useContext(MainContext);
   
  //useEffect mounting
  useEffect(() => {
    //اگر توکن بود بره داشبورد
    if (Cookies.get("access_token")) {
        navigate("/dashboard",{replace:true})
      }
      
    if(Cookies.get("user_phone")){
        setMobile(Cookies.get("user_phone"))
    }
   
  }, [])

    const [mobile, setMobile] = useState("")
    const [country_code, setCountry_code] = useState("+98")
    
 


   
     //handleSubmitForm
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.target.disabled = true

        const _fuck = (mobile[0]==="0") ? mobile.slice(1) : mobile

       const mySendObject={
         mobile: country_code + _fuck
       }

       
        try {
            const res = await axios.post(`${baseURL}/forget-password-by-mobile`,
                JSON.stringify(mySendObject),
                { headers: { 'Content-Type': 'application/json' } })
                // console.log(res.data.data)
            if(res.status==200){
                Cookies.set("user_phone",_fuck,{ expires: 365 })
                Cookies.set("country_code",country_code,{ expires: 365 })
             
                navigate("/verification-two")
                
                e.target.disabled = false
            }
           
        } catch (err) {
            if (err.response.data.errors.mobile[0]) {
                toast.error(err.response.data.errors.mobile[0], {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",    
                });
            }

            e.target.disabled = false
        }


    }//end



    
    ////////////////////////////Select////////////////////
    //style for Select
    const customStyles = {
        control: base => ({
            ...base,
            height: "46px",
            width: "100%",
            background: (lightingMode==="light") ? "#f7f8fa" : "black"
        }),
        option: (base) => ({
            ...base,
            backgroundColor: (lightingMode === 'light') ? '#f7f8fa' : '#000'
        }),
            
    };

    //لیست پرچم ها و پیش شماره
    const areaCode_List = [
        {
            src_flag: iranFlag,
            country_code: "98",
            value: "+98"
        },
        {
            src_flag: usaFlag,
            country_code: "1",
            value: "+1"
        },
        {
            src_flag: canadaFlag,
            country_code: "1",
            value: "+1"
        }
    ]

    //option for Select
    const optionFlagList = areaCode_List.map(mm => {
        return {
            value: mm.value,
            label:
                <div className="option_style flex justify-between">
                    <img src={mm.src_flag} height="30px" width="30px" />
                    <span className="block">+{mm.country_code}</span>
                </div>
        }
    })

    //handleSelect
    const handleSelect = (selectedOption) => {
        setCountry_code(selectedOption.value)
        
    }


    return (
        <HelmetProvider>
        <div className="grand-parent" id="judge">
               <Helmet>
                    <title>{t("title_forget")}</title>
                </Helmet>
                    
            <div className="judge-parent">
                {/* right */}
                <section className="judge-right">

                    <h1>{t("forget_1")}</h1>

                    <form>
                        {/* mobile */}
                        {/* name="mobile" */}
                        <div>
                            <label for="">{t("forget_2")}</label>
                            <div className="rtl h-[46px] flex justify-between">
                                <input
                                    className="judge-input h-[46px] rounded-lg p-3"
                                    style={{ border: "1px solid #e3e4f5" }}
                                    name="mobile"
                                    type="text"
                                    placeholder="9123311445"
                                    value={mobile}
                                    onChange={e =>setMobile(e.target.value)}
                                />
                                <div className="judge_select ltr h-[46px]">
                                    <Select
                                        styles={customStyles}
                                        options={optionFlagList}
                                        placeholder={optionFlagList[0]?.label}
                                        onChange={handleSelect}
                                        isSearchable={false}
                                        isDisabled
                                    />
                                </div>
                            </div>
                        </div>

              
                        {/* submit button */}
                        <div className="submit-form">
                            <button
                                type="submit"
                                onClick={handleSubmitForm}
                            >{t("forget_3")}</button>
                        </div>
                       
                        {/*  */}
                    </form>
                </section>

                {/* left */}
                <Login_Left />
            </div>

            {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
            {/* <LightingMode bottom="20px" left="30px" /> */}
        </div>
        </HelmetProvider>

    )
}

export default Forget


import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import LoadingCircle from "../../../components/loading-circle/LoadingCircle";
import { BASE_URL } from '../../../utils/url'

export default function Transaction2() {
  const navigate = useNavigate();

  const [depositsfiatdata, setDepositsFiatData] = useState([]);
  const { t } = useTranslation();
  const [userWallets, setUserWallets] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [forceRender, setForceRender] = useState("");
  const params = new URLSearchParams(window.location.search);
  // console.log(params.toString());

  //   const paginate_Arr = [];
  //   for (let i = 0; i < totalPages; i++) {
  //     paginate_Arr[i] = i + 1;
  //   }
  const paginate_Arr = totalPages
    ? Array.from({ length: totalPages }, (_, i) => i + 1)
    : [];

  const handleURL = (page_Number) => {
    setForceRender(Math.random());
    navigate(`${window.location.pathname}?page=${page_Number}`);
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response_deposits_data2 = await axios.get(
          BASE_URL + "/user/deposits/crypto",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        setDepositsFiatData(response_deposits_data2.data);
      } catch (error) {
        // console.log(error.message);
      }
    };
    fetchBlog();
  }, []);
  // 000000000000000000000
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          `${BASE_URL}/user/crypto/deposits?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserWallets(response_1.data.data);
        setTotalPages(response_1.data.meta.last_page);
        setCurrentPage(response_1.data.meta.current_page);

        // console.log("------------------------------------------");
        // console.log(response_1.data.meta.last_page)
      } catch (error) {
        // console.log(error.message);
      }
    }; //end of method
    fetchData();
  }, []);
  const [loading, setLoading] = useState(true);
  //updating
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          `${BASE_URL}/user/deposits/crypto?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserWallets(response_1.data.data);
        setLoading(false);
        setTotalPages(response_1.data.meta.last_page);
        setCurrentPage(response_1.data.meta.current_page);
        allCoinsInitial.current = response_1.data.data;
        // console.log("------------------------------------------");
        // console.log(response_1.data.meta.last_page)
      } catch (error) {
        // console.log(error.message);
      }
    }; //end of method
    fetchData();
  }, [forceRender]);
  //
  const [module1, setModule1] = useState(false);
  function showModule1() {
    setModule1(true);
  }
  function hiddenModule1() {
    setModule1(false);
  }
  //
  const allCoinsInitial = useRef([]);
  //  const [allCoins, setAllCoins] = useState([]);

  const sorted_by_Price =
    userWallets &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
    );
  const sorted_by_Price_re = sorted_by_Price && [...sorted_by_Price].reverse();

  const sorted_by_Time =
    userWallets &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.created_at) - parseFloat(a.created_at)
    );
  const sorted_by_Time_re = sorted_by_Time && [...sorted_by_Time].reverse();

  //  const sorted_by_Price_Alphabetical = userWallets && [...sorted_by_Price].sort((a, b) => a.txn_id.localeCompare(b.txn_id));
  const sorted_by_Price_Alphabetical =
    userWallets &&
    sorted_by_Price
      .filter((item) => item.txn_id !== null)
      .sort((a, b) => (a.txn_id || "").localeCompare(b.txn_id || ""));
  const sorted_by_Price_re_Alphabetical =
    sorted_by_Price_Alphabetical && [...sorted_by_Price_Alphabetical].reverse();
  //  const sorted_by_HighPrice = userWallets && [...userWallets].sort((a, b) => parseFloat(b.highPrice) - parseFloat(a.highPrice));
  //  const sorted_by_Change = userWallets && [...userWallets].sort((a, b) => parseFloat(b.change) - parseFloat(a.change));
  const sorted_by_Price_Alphabetical1 =
    userWallets &&
    sorted_by_Price
      .filter((item) => item.txn_id !== null)
      .sort((a, b) => (a.coin_name || "").localeCompare(b.txn_id || ""));
  const sorted_by_Price_re_Alphabetical1 =
    sorted_by_Price_Alphabetical1 &&
    [...sorted_by_Price_Alphabetical1].reverse();

  const sorted_by_Price_Alphabetical2 =
    userWallets &&
    sorted_by_Price
      .filter((item) => item.status !== null)
      .sort((a, b) => (a.coin_name || "").localeCompare(b.status || ""));
  const sorted_by_Price_re_Alphabetical2 =
    sorted_by_Price_Alphabetical2 &&
    [...sorted_by_Price_Alphabetical2].reverse();

  const [nameBtnStatus, setNameBtnStatus] = useState("down");
  const [priceBtnStatus, setPriceBtnStatus] = useState("off");
  const [highPriceBtnStatus, setHighPriceBtnStatus] = useState("off");
  const [changeBtnStatus, setChangeBtnStatus] = useState("off");
  const [changeBtnStatus2, setChangeBtnStatus2] = useState("off");
  function sortByName() {
    if (sorted_by_Price_Alphabetical1) {
      if (nameBtnStatus === "off" || nameBtnStatus === "up") {
        setUserWallets(sorted_by_Price_Alphabetical1);
        setNameBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical1);
        setNameBtnStatus("up");
      }
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setChangeBtnStatus2("off");
    }
  }
  function sortByPrice() {
    if (sorted_by_Price) {
      if (priceBtnStatus === "off" || priceBtnStatus === "up") {
        setUserWallets(sorted_by_Price);
        setPriceBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re);
        setPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setChangeBtnStatus2("off");
    }
  }
  function sortByHighPrice() {
    if (sorted_by_Time) {
      if (highPriceBtnStatus === "off" || highPriceBtnStatus === "up") {
        setUserWallets(sorted_by_Time);
        setHighPriceBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Time_re);
        setHighPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setChangeBtnStatus2("off");
    }
  }
  function sortByChange() {
    if (sorted_by_Price_Alphabetical1) {
      if (changeBtnStatus === "off" || changeBtnStatus === "up") {
        setUserWallets(sorted_by_Price_Alphabetical1);
        setChangeBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical1);
        setChangeBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus2("off");
    }
  }
  function sortByChange2() {
    if (sorted_by_Price_Alphabetical2) {
      if (changeBtnStatus2 === "off" || changeBtnStatus2 === "up") {
        setUserWallets(sorted_by_Price_Alphabetical2);
        setChangeBtnStatus2("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical1);
        setChangeBtnStatus2("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setHighPriceBtnStatus("off");
    }
  }

  return (
    <React.Fragment>
      <div className="a-scroll">
        <table>
          <tr>
            <th>
              <div className="list" onClick={sortByChange}>
                <div className="text">{t("dashbord.transaction.22")}</div>
                {changeBtnStatus === "down" && (
                  <img
                    src={require("../../../image/price-socket/sort-down.png")}
                  />
                )}
                {changeBtnStatus === "up" && (
                  <img src={require("../../../image/price-socket/sort-up.png")} />
                )}
                {changeBtnStatus === "off" && (
                  <img src={require("../../../image/price-socket/sort.png")} />
                )}
              </div>
            </th>
            <th>
              <div className="list" onClick={sortByHighPrice}>
                <div className="text">{t("dashbord.transaction.23")}</div>
                {highPriceBtnStatus === "down" && (
                  <img
                    src={require("../../../image/price-socket/sort-down.png")}
                  />
                )}
                {highPriceBtnStatus === "up" && (
                  <img src={require("../../../image/price-socket/sort-up.png")} />
                )}
                {highPriceBtnStatus === "off" && (
                  <img src={require("../../../image/price-socket/sort.png")} />
                )}
              </div>
            </th>
            <th>
              <div className="list" onClick={sortByPrice}>
                <div className="text">{t("dashbord.transaction.24")}</div>
                {priceBtnStatus === "down" && (
                  <img
                    src={require("../../../image/price-socket/sort-down.png")}
                  />
                )}
                {priceBtnStatus === "up" && (
                  <img src={require("../../../image/price-socket/sort-up.png")} />
                )}
                {priceBtnStatus === "off" && (
                  <img src={require("../../../image/price-socket/sort.png")} />
                )}
              </div>
            </th>
            <th>
              <div className="list" onClick={sortByName}>
                <div className="text">{t("dashbord.transaction.25")}</div>
                {nameBtnStatus === "down" && (
                  <img
                    src={require("../../../image/price-socket/sort-down.png")}
                  />
                )}
                {nameBtnStatus === "up" && (
                  <img src={require("../../../image/price-socket/sort-up.png")} />
                )}
                {nameBtnStatus === "off" && (
                  <img src={require("../../../image/price-socket/sort.png")} />
                )}
              </div>
            </th>
            <th>
              <div className="list" onClick={sortByChange2}>
                <div className="text">{t("dashbord.transaction.27")}</div>
                {changeBtnStatus2 === "down" && (
                  <img
                    src={require("../../../image/price-socket/sort-down.png")}
                  />
                )}
                {changeBtnStatus2 === "up" && (
                  <img src={require("../../../image/price-socket/sort-up.png")} />
                )}
                {changeBtnStatus2 === "off" && (
                  <img src={require("../../../image/price-socket/sort.png")} />
                )}
              </div>
            </th>
            <th>
              <div className="list">
                <div className="text">{t("dashbord.transaction.28")}</div>
              </div>
            </th>
          </tr>
          {/*  */}
          {!loading && (
            <>
              {userWallets && userWallets.length > 0 ? (
                <tbody>
                  {userWallets.map((index) => {
                    const time = index.created_at;
                    const dateParts = time.split("T");
                    const timeParts = dateParts[1].split(".");

                    return (
                      <tr>
                        <td>
                          <div className="pay">
                            <div className="text" style={{ color: "#00C0AF" }}>
                              {index.coin_symbol}{" "}
                              <span style={{ color: "#D1D1D1" }}>|</span>{" "}
                              {index.coin_name}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="time">{timeParts[0]}</div>
                          {/* {index.created_at} */}
                        </td>
                        <td>
                          {index.coin_symbol} {+index.amount}
                        </td>
                        <td> {index.id}</td>

                        <td>
                          <div className="background"> {index.status === 'completed' ? 'انجام شده' : index.status === 'pending' ? 'در حال انجام' : index.status === 'reviewing' ? 'در حال بررسی' : 'ناموفق'}</div>
                        </td>
                        <td>{index.txn_id}</td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                <tr>
                  <td colSpan="4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        style={{ width: "200.6px" }}
                        src={require("../../../assests/image/dashbord/Empty.png")}
                      />
                      <div className="text">{t("noData_1")}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
              )}
            </>
          )}
        </table>
        {loading && <LoadingCircle />}
      </div>
      <div className="flexCenter w-full">
        <ul className="paginate">
          {userWallets && userWallets.length > 0 && paginate_Arr.length > 1 ? (
            paginate_Arr.map((pageNumber) => (
              <li
                onClick={() => handleURL(pageNumber)}
                key={pageNumber}
                className={
                  pageNumber === currentPage ? "active-paginate" : null
                }
              >
                {pageNumber}
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
}

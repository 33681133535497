//internal
import { useContext, useState, useEffect } from "react";

//external
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { MainContext } from "../../../contexts/MainContexts";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style
import "./login.css";
import "./lightingMode.css";
import { toast } from "react-toastify";
import Login_Left from "./Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode"
//Image
import canadaFlag from "../../../assests/image/flag/canada.jpg";
import usaFlag from "../../../assests/image/flag/usa.jpg";
import iranFlag from "../../../assests/image/flag/iran.jpg";

import { BASE_URL } from '../../../utils/url'



const Login = () => {

    const baseURL = BASE_URL
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { primary_dir, lightingMode } = useContext(MainContext);


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [country_code, setCountry_code] = useState("+98");


    const [check_agreement, setCheck_agreement] = useState(false);
    // console.log(check_agreement)

    useEffect(() => {
        if (Cookies.get("user_phone")) {
            setUsername(Cookies.get("user_phone"))
        }
    }, [])


    const [inputType, setInputType] = useState("password");

    const toggleInputType = () => {
        if (inputType === "password") {
            setInputType("text")
        } else {
            setInputType("password")
        }
    }






    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.target.disabled = true

        const _fuck = (username[0] === "0") ? username.slice(1) : username

        const mySendObject = {
            username: country_code + _fuck,
            password: password,
            type: "mobile"
        }

        try {
            const res = await axios.post(`${baseURL}/login`, JSON.stringify(mySendObject), { headers: { 'Content-Type': 'application/json' } })

            if (res.status == 200) {

                //اگر تیک را زده بود
                if (check_agreement) {
                    Cookies.set("access_token", res.data.data.access_token, { expires: 365 })
                } else {
                    Cookies.set("access_token", res.data.data.access_token)
                }

                if (Cookies.get("country_code")) {
                    //برای اینکه شاید از پسورد جدید به این صفحه آمده باشد
                    Cookies.remove("country_code")
                }
                navigate("/verification")

                e.target.disabled = false
            }




        } catch (err) {
            // console.log(err.response.data.message);
            toast.error(err.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",

            });

            e.target.disabled = false
        }


    }//end




    ////////////////////////////Select////////////////////
    //style for Select
    const customStyles = {
        control: base => ({
            ...base,
            height: "46px",
            width: "100%",
            background: (lightingMode === "light") ? "#f7f8fa" : "black"
        }),
        option: (base) => ({
            ...base,
            backgroundColor: (lightingMode === 'light') ? '#f7f8fa' : '#000'
        }),

    };

    //لیست پرچم ها و پیش شماره
    const areaCode_List = [
        {
            src_flag: iranFlag,
            country_code: "98",
            value: "+98"
        },
        {
            src_flag: usaFlag,
            country_code: "1",
            value: "+1"
        },
        {
            src_flag: canadaFlag,
            country_code: "1",
            value: "+1"
        }
    ]

    //option for Select
    const optionFlagList = areaCode_List.map(mm => {
        return {
            value: mm.value,
            label:
                <div className="option_style flex justify-between">
                    <img src={mm.src_flag} height="30px" width="30px" />
                    <span className="block toggleColor">+{mm.country_code}</span>
                </div>
        }
    })

    //handleSelect
    const handleSelect = (selectedOption) => {
        setCountry_code(selectedOption.value)
    }



    return (
        <HelmetProvider>
            <div className="grand-parent" id="login">
                <Helmet>
                    <title>{t("title_login")}</title>
                </Helmet>

                <div className="login-parent"  >
                    {/* right */}
                    <section className="login-right">

                        <h1>{t("login_1")}</h1>

                        <form>

                            {/* mobile */}
                            {/* name="username" */}

                            <div>
                                <label for="">{t("login_2")}</label>
                                <div className="rtl h-[46px] flex justify-between">
                                    <input
                                        className="judge-input h-[46px] rounded-lg p-3 !text-[#aaa]"
                                        disabled
                                        name="username"
                                        type="text"
                                        placeholder="9123311445"
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                    />
                                    <div className="judge_select ltr  h-[46px]">
                                        <Select
                                            styles={customStyles}
                                            options={optionFlagList}
                                            placeholder={optionFlagList[0]?.label}
                                            onChange={handleSelect}
                                            isSearchable={false}
                                            isDisabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* password */}
                            {/* name="password" */}
                            <div>
                                <label for="">{t("login_3")}</label>
                                <input
                                    dir="ltr"
                                    className="w-full ltr"
                                    type={inputType}
                                    name="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <span onClick={toggleInputType}
                                    className="eye cursor-pointer text-2xl"
                                    style={{ right: "7px" }}>
                                    {
                                        (inputType === "password") ? <IoEyeOutline /> : <FaRegEyeSlash />
                                    }
                                </span>
                            </div>

                            {/* section */}
                            <section>
                                <label for="abcd">
                                    <input
                                        id="abcd"
                                        type="checkbox"
                                        onChange={(e) => setCheck_agreement(e.target.checked)} />
                                    <span>{t("login_4")}</span>
                                </label>
                                <Link to={"/forget"}>{t("login_5")}</Link>
                            </section>
                            {/* submit button */}
                            <div className="submit-form">
                                <button
                                    type="submit"
                                    onClick={handleSubmitForm}
                                >{t("login_6")}</button>
                            </div>
                            {/* recently */}
                            {/* <div className="recently">
                                <span>
                                    {t("login_recently")}{" "}
                                    <Link to={"/register"}>{t("login_to_register")}</Link>
                                </span>
                            </div> */}
                            {/*  */}
                        </form>
                    </section>

                    {/* left */}
                    <Login_Left />
                </div>

                {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
                {/* <LightingMode bottom="20px" left="30px" /> */}
            </div>
        </HelmetProvider>

    )
}

export default Login
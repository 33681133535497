import { useTranslation } from "react-i18next";

const Loading_two = () => {

  const { t } = useTranslation();
  return (
    <p className='text-sm toggleColor'>{t("loading_1")}</p>
  )
}

export default Loading_two
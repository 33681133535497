//internal
import { useState, useEffect, useContext } from "react";
//external
import { MainContext } from "../../contexts/MainContexts";
import Cookies from "js-cookie";
import Select from 'react-select';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

//Component and Style and Image
import "./convert-box.css";
import "./lightMode.css";
import LoadingCircle from "../loading-circle/LoadingCircle"
import Loading_two from "../loading-two/Loading_two";

import { BASE_URL } from '../../utils/url'


//متد دست ساز
// function reverse_String(str) {
//   let array = str.split("_");
//   let reversedArray = array.reverse();
//   let reversedString = reversedArray.join("_");

//   return reversedString;
// }





const Convert_Box = (props) => {

  const baseURL = BASE_URL
  const base_url_img = "https://api.moneyex.org/storage/images/coin-icons/";
  const { t } = useTranslation();
  const { lightingMode, primary_dir } = useContext(MainContext);

  ////////////////داستان تبدیل سکه ها///////////////////////////////
  ///////////////////////////////////////////////////////////////////

  const [profileData, setProfileData] = useState({});
  const [pair_balance, setPair_balance] = useState({});
  const [forceRender, setForceRender] = useState();
  const [coinList, setCoinList] = useState([]);
  const [paired_CoinList, setPaired_CoinList] = useState([]);
  // const [featured_List, setFeatured_List] = useState([]);
  const [swap, setSwap] = useState({});
  const [order_type, setOrder_type] = useState("sell");
  const [loading, setLoading] = useState(false);
  const [loading_two, setLoading_two] = useState(false);




  const [input_First, setInput_First] = useState("");
  const [input_Second, setInput_Second] = useState("");


  // حیدر: اینجا بجای 
  // first_placeHolder 
  // این رو ست کردم و پایینیش رو هم اضافه کردم برای موجودی کوین دوم (مورد استفاده در قسمت خرید).
  const [user_base_coin_balance, setUser_base_coin_balance] = useState(0);
  const [user_quote_coin_balance, setUser_quote_coin_balance] = useState(0);


  // const [help_input_First, setHelp_Input_First] = useState("");
  // const [help_input_Second, setHelp_Input_Second] = useState("");

  const [base_Coin, setBase_Coin] = useState("");
  const [quote_Coin, setQuote_Coin] = useState("");

  
  const base_list = paired_CoinList && [...new Set(paired_CoinList.map(x => x.name).map(y => y.split("_")[0]))].sort()
  const quote_option = paired_CoinList && paired_CoinList.filter(pair => pair.name.split('_')[0] === base_Coin)[0]?.name.split('_')[1]
  const quote_option_in_coinList = quote_option && coinList.filter(coin => coin.symbol === quote_option)[0]

  
  const myCheck = (base_Coin && quote_option) ? `${base_Coin}_${quote_option}` : base_list[0]+'_'+quote_option
  const min_base = paired_CoinList.find(z => z.name === myCheck)?.base_min_size
  const max_base = paired_CoinList.find(z => z.name === myCheck)?.base_max_size
  const min_quote = paired_CoinList.find(z => z.name === myCheck)?.quote_min_size
  const max_quote = paired_CoinList.find(z => z.name === myCheck)?.quote_max_size
  const checkList = paired_CoinList.map(x => x.name);
  const myIndex = checkList.findIndex(item => item === myCheck);



  props.onCoinsChange && props.onCoinsChange(myCheck.split('_')[0], myCheck.split('_')[1])



  const handleLoading = () => {
    setInput_First("")
    setInput_Second("")
    setLoading(true)
    setLoading(false)
  }//end

  const handle_BTN = (arg) => {
    //اگر بر روی دکمه خرید و فروش کلیک شد
    if (order_type === "sell" && arg === "buy") {
      setOrder_type("buy")
      // setBase_Coin(quote_option)
      // setQuote_Coin(base_list[0])
      setBase_Coin(base_list[0])
      setQuote_Coin(quote_option)
      handleLoading() //اجرای لودینگ

    } else if (order_type === "buy" && arg === "sell") {
      setOrder_type("sell")
      setBase_Coin(base_list[0])
      setQuote_Coin(quote_option)
      handleLoading() // اجرای لودینگ
    }

    setForceRender(Math.random())
  }//end




  /////////UseEffect////////////////
  /////////UseEffect////////////////

  //مانتینگ
  //mounting--useEffect
  useEffect(() => {

    const fetchData = async () => {

      try {

        setLoading(true) //شروع لودینگ

        //تمامی ارزها
        const response_1 = await axios.get(`${baseURL}/coins`);
        setCoinList(response_1.data)

        //تمامی جفت شد ها
        const res_pair = await axios.get(`${baseURL}/coin-pairs`);
        setPaired_CoinList(res_pair.data)

        const bb_list = [... new Set(res_pair.data.map(x => x.name).map(y => y.split("_")[0]))].sort();
        const qq_list = [... new Set(res_pair.data.map(x => x.name).map(y => y.split("_")[1]))].sort();

  
        //برای بدست آوردن آخرین قیمت
        const res_swap = await axios.get(`${baseURL}/swap/pair-ticker-info/${bb_list[0]}_${qq_list[1]}`);
        setSwap(res_swap.data)

        
        //فقط
        //فقط در حالت مانتینگ
        //چون سلکت های ما گزینه ای به عنوان انتخاب کنید را ندارند
        setBase_Coin(bb_list[0])
        setQuote_Coin(qq_list[1])


        //توکن میخواهد
        //باید بدانیم کابر از آن ارز ،چقدر در کیف پول خود دارد
        const res_balance = await axios.get(`${baseURL}/user/coin-pairs/${bb_list[0]}_${qq_list[1]}/balance`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setPair_balance(res_balance.data)
        setUser_base_coin_balance(res_balance.data.base_coin_balance)
        setUser_quote_coin_balance(res_balance.data.quote_coin_balance)

        //برای ضریب fee
        const res_profile = await axios.get(`${baseURL}/user/profile`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setProfileData(res_profile.data)


        setLoading(false) //پایان لودینگ






      } catch (error) {

        setLoading(false) //پایان لودینگ
      }
    }//end of method

    fetchData();
  }, [])

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //updating useEffect
  // forceRender اجباری 
  useEffect(() => {

    setInput_Second("")
    setInput_First("")
    //  مجبوریم این کار را انجام دهیم چون با تغییر سلکت ها دیتا باید دوباره فچ شود


    const fetchData = async () => {
      try {
        setUser_base_coin_balance(0)
        setUser_quote_coin_balance(0)

        setLoading_two(true); //شروع لودینگ

        //تمامی ارزها
        const response_1 = await axios.get(`${baseURL}/coins`);
        setCoinList(response_1.data)

        //تمامی جفت شد ها
        const res_pair = await axios.get(`${baseURL}/coin-pairs`);
        setPaired_CoinList(res_pair.data)

        //برای بدست آوردن آخرین قیمت
        const res_swap = await axios.get(
          order_type === "sell" ? `${baseURL}/swap/pair-ticker-info/${myCheck}` :
            // `${baseURL}/swap/pair-ticker-info/${reverse_String(myCheck)}`
            `${baseURL}/swap/pair-ticker-info/${myCheck}`
        );
        setSwap(res_swap.data)

        //توکن میخواهد
        //باید بدانیم کابر از آن ارز چقدر در کیف پول خود دارد
        const res_balance = await axios.get(order_type === "sell" ?
          `${baseURL}/user/coin-pairs/${myCheck}/balance` :
          // `${baseURL}/user/coin-pairs/${reverse_String(myCheck)}/balance`, {
          `${baseURL}/user/coin-pairs/${myCheck}/balance`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        });
        setPair_balance(res_balance.data)
        setUser_base_coin_balance(res_balance.data.base_coin_balance)
        setUser_quote_coin_balance(res_balance.data.quote_coin_balance)


        setLoading_two(false) //پایان لودینگ


      } catch (error) {

        setLoading_two(false) //پایان لودینگ

      }
    }


    fetchData();
  }, [forceRender])


  ////////////////////////////////////////////////////////////////////////////////////////////////
  //تغییر اینپوت اول 
  useEffect(() => {

    //حالت فروش
    if (myIndex !== -1 && order_type === "sell") {
      if (input_First) {
        //ضریب فرمول
        const x = profileData.taker_fee_percent ? parseFloat(swap?.latest_price) - parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100) : parseFloat(swap?.latest_price)
        const xx = input_First * x
        setInput_Second(xx.toFixed(4))
      } else {
        setInput_Second("")
      }


      //حالت خرید 
    } else if (order_type === "buy") {
      if (input_First && input_First !== "0") {
        //ضریب فرمول
        const y = parseFloat(swap?.latest_price) + parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100)
        const yy = input_First / y
        setInput_Second(yy.toFixed(6))
      }

    } else if (myIndex == -1) {
      setInput_Second("")
    }

  }, [input_First])









  ///////////////////داستان/////////////////
  /////////////////// select////////////////
  //optionList_first
  //مجبوریم چون فقط در لیست ارزها عکس را داریم
  const optionList_first =
    coinList.filter(coin => base_list.indexOf(coin.symbol) > -1).map(coin => {
      return {
        value: coin.symbol,
        label:
          <div className="option_style">
            <img src={base_url_img + coin.icon} height="30px" width="30px" />
            <span>{coin.name}</span>
          </div>
      }
    })


  const customStyles = {
    control: base => ({
      ...base,
      height: "46px",
      width: "100%",
      borderRadius: "10px",
      background: (lightingMode === "light") ? "#f7f8fa" : "black"

    }),
    option: (base) => ({
      ...base,
      backgroundColor: (lightingMode === 'light') ? '#f7f8fa' : '#000'
    }),
  };

  //مدیریت تغییرات سلکت ها selectedOption
  //first
  const handleSelect_first = (selectedOption) => {
    setBase_Coin(selectedOption.value)
    setForceRender(Math.random())
  }

  const placeH_1 = optionList_first[0]?.label
  /////////////////////////////////////////////////////////////////////////

  //ارسال فرم
  const handleSubmitForm = async (e) => {

    e.preventDefault();
    e.target.disabled = true

    try {
      const res = await axios.post(`${baseURL}/user/orders/market`,
        JSON.stringify({
          order_type,
          // trade_pair: (order_type === "sell") ? myCheck : reverse_String(myCheck),
          trade_pair: myCheck,
          amount: input_First
        }),
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get("access_token")}`,
            'Content-Type': 'application/json'
          }
        })

      if (res.data.status === "success") {
        e.target.disabled = false

        setInput_First(0)
        setInput_Second(0)
        toast.success(res.data.status, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

        });
      } else {
        e.target.disabled = false

        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

        });
      }


    } catch (error) {
      e.target.disabled = false
    }
  }


  return (
    <div className="convert-box">
      {/* دکمه */}
      <section className="two_btn">
        {/* فروش */}
        <button onClick={() => handle_BTN("sell")}
          style={(order_type === "sell") ? { background: "var(--maincolorred)", color: "white" } : {border: '1px solid #ddd'}}
        >{t("convertBox_1")}</button>

        {/* خرید */}
        <button onClick={() => handle_BTN("buy")}
          style={(order_type === "buy") ? { background: "var(--maincolor3)", color: "white" } : {border: '1px solid #ddd'}}
        >{t("convertBox_2")}</button>
      </section>

      {
        loading ? (<LoadingCircle />) :
          (
            <form>


              {/* input-first*/}
              <div className="h-[74px] mb-8 relative">
                <div className="flex justify-between">
                  <label htmlFor="" className="h-[19px] block mb-2">{t("convertBox_3")}</label>
                  {props && props.dontShowUserBalance ? null :
                    <span className="user-balance cursor-pointer" onClick={() => { order_type === 'sell' ? setInput_First(user_base_coin_balance) : setInput_First(user_quote_coin_balance) }}>
                      {t("convertBox_6")}: {(order_type === "sell") ? user_base_coin_balance : user_quote_coin_balance}
                    </span>
                  }
                </div>

                <div className="h-[46px] flex justify-between">
                  <input
                    type="text"
                    className="block h-full p-3 w-[calc(100%-160px)]"
                    style={{ borderRadius: "10px" }}

                    value={isNaN(input_First) ? "" : input_First}
                    onChange={(e) => {
                      setInput_First(e.target.value)
                      props.onInputChange && props.onInputChange(e.target.value)
                    }}

                    // placeholder={`${t("convertBox_6")} ${user_base_coin_balance}`}
                    placeholder={'0.00'}
                  />

                  {order_type === "sell" ?
                    <div className="ltr h-full w-[150px] ">
                        <Select
                          options={optionList_first}
                          onChange={handleSelect_first}
                          styles={customStyles}
                          placeholder={placeH_1}
                          isSearchable={false}
                        />
                    </div>
                    :
                    <div className="ltr h-full w-[150px] border border-dashed rounded-xl p-3" style={lightingMode === 'light' ? {background: '#f7f8fa'} : {background: '#1a1a1a'}}>
                      {quote_option_in_coinList &&
                        <div className="option_style">
                          <img src={base_url_img + quote_option_in_coinList.icon} height="30px" width="30px" />
                          <span>{quote_option_in_coinList.name}</span>
                        </div>
                      }
                    </div>
                  }

                  <span style={primary_dir === "rtl" ? { right: "10px" } : { left: "10px" }}
                    className="show-min-max">
                    <span>{t("convertBox_8")}: {myIndex !== -1 && (order_type === "sell" ? min_base : min_quote)} _</span>
                    {" "}
                    <span>{t("convertBox_9")}: {myIndex !== -1 && (order_type === "sell" ? max_base : max_quote)}</span>
                  </span>
                </div>

              </div>
              {/* rotate logo */}
              {/* <div class="flexCenter">
                <span class="flexCenter w-[43px] h-[43px] rounded-md"
                  style={{ background: "#f9cc0b" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24.414" height="32.578" viewBox="0 0 24.414 32.578">
                    <path id="autorenew_FILL1_wght400_GRAD0_opsz48" d="M160.687,170.409a9.493,9.493,0,0,1-.534-2.022,14.038,14.038,0,0,1-.153-2.06,12.309,12.309,0,0,1,12.207-12.207h1.64l-2.327-2.327a.95.95,0,0,1-.286-.744,1.083,1.083,0,0,1,.324-.744,1.059,1.059,0,0,1,1.488,0l4.12,4.158a1.162,1.162,0,0,1,.267.381,1.192,1.192,0,0,1,0,.839,1.162,1.162,0,0,1-.267.381l-4.12,4.12a1.079,1.079,0,0,1-1.526-1.526l2.251-2.251h-1.564a9.964,9.964,0,0,0-9.918,9.918,9.8,9.8,0,0,0,.153,1.755,14.765,14.765,0,0,0,.381,1.6,1.091,1.091,0,0,1,0,.572,1.041,1.041,0,0,1-.267.5,1.19,1.19,0,0,1-1.068.4A1.012,1.012,0,0,1,160.687,170.409Zm10.643,11.864-4.158-4.12a1.163,1.163,0,0,1-.267-.381,1.192,1.192,0,0,1,0-.839,1.163,1.163,0,0,1,.267-.381l4.158-4.158a1.052,1.052,0,0,1,1.488,1.488l-2.327,2.327h1.717a9.964,9.964,0,0,0,9.918-9.918,11.528,11.528,0,0,0-.134-1.774,9.161,9.161,0,0,0-.4-1.621,1.159,1.159,0,0,1-.019-.553.909.909,0,0,1,.248-.477,1.19,1.19,0,0,1,1.068-.4,1.012,1.012,0,0,1,.8.744,10.366,10.366,0,0,1,.553,2.022,12.51,12.51,0,0,1,.172,2.06A12.309,12.309,0,0,1,172.207,178.5H170.49l2.327,2.327a1.013,1.013,0,0,1,0,1.45,1.059,1.059,0,0,1-1.488,0Z" transform="translate(-160 -150)" fill="#fff" />
                  </svg>

                </span>
              </div> */}
              {/*input-second */}

              <div className="h-[74px] mb-3">
                <label htmlFor="" className="h-[19px] block mb-2">{t("convertBox_4")}</label>

                <div className="h-[46px] flex justify-between relative">
                  <input
                    type="text"
                    className="block h-full p-3 w-[calc(100%-160px)] !border-dashed"
                    value={isNaN(input_Second) ? "" : input_Second}
                    disabled
                    style={{ borderRadius: "10px" }}
                    placeholder="0.00"
                  />
                  {order_type !== "sell" ?
                    <div className="ltr h-full w-[150px] ">
                        <Select
                          options={optionList_first}
                          onChange={handleSelect_first}
                          styles={customStyles}
                          placeholder={placeH_1}
                          isSearchable={false}
                        />
                    </div>
                    :
                    <div className="ltr h-full w-[150px] border border-dashed rounded-xl p-3" style={lightingMode === 'light' ? {background: '#f7f8fa'} : {background: '#1a1a1a'}}>
                      {quote_option_in_coinList &&
                        <div className="option_style">
                          <img src={base_url_img + quote_option_in_coinList.icon} height="30px" width="30px" />
                          <span>{quote_option_in_coinList.name}</span>
                        </div>
                      }
                    </div>
                  }
                  {myIndex == -1 &&
                    (<span style={primary_dir === "rtl" ? { right: "10px" } : { left: "10px" }}
                      className="show-error">{t("convertBox_7")}</span>)}
                </div>

              </div>


              {/* نرخ تبدیل */}
              {
                myIndex !== -1 ?
                  (
                    <div className="text-center text-sm mb-5">
                      {
                        loading_two ? <Loading_two /> :
                          (
                            <span className="toggleColor">
                              <span>{t("convertBox_5")}</span>
                              <span>: </span>
                              {
                                order_type === "sell" ? 
                                  <span>{quote_option} { profileData.taker_fee_percent ?
                                    parseFloat(swap?.latest_price) - parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100) :
                                    parseFloat(swap?.latest_price)
                                  }</span>
                                  :
                                  <span>{quote_option} {parseFloat(swap?.latest_price) + parseFloat(swap?.latest_price * (profileData?.taker_fee_percent) / 100)}</span>
                              }
                              <span> ~ </span>
                              <span>{base_Coin} 1 </span>
                            </span>
                          )
                      }

                    </div>
                  ) : (
                    <span className="block w-full h-5 mb-5">{" "} </span>
                  )
              }
              {/* submit button */}
              <button onClick={handleSubmitForm}
                className="h-[46px] w-full text-white mt-[12px]" type="submit"
                disabled={myIndex === -1}
                style={order_type === "sell" ? { background: "var(--maincolorred)", borderRadius: "10px" } : { background: "var(--maincolor3)", borderRadius: "10px" }}
              >
                {order_type === "sell" ? t("convertBox_1") : t("convertBox_2")}
              </button>
              {/*  */}
            </form>)
      }
    </div>


  )
}

export default Convert_Box
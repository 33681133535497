import { useEffect } from "react"
import { useParams,useNavigate } from "react-router-dom"
import Cookies from "js-cookie";

const Ref = () => {

    const { referral_code } = useParams()
    const navigate = useNavigate()


    useEffect(() => {
      if(referral_code){
        Cookies.set("referral_code",referral_code,{ expires: 365 })
      }
      navigate("/", { replace: true })
    }, [])
    

    
    return (
        <div></div>
    )
}

export default Ref
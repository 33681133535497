//internal
import { useContext, useState,useEffect } from "react";
//external
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../contexts/MainContexts";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style and Image
import "./lightingMode.css";
import "./verification.css";
import mySrc from "../../../assests/image/Phone-code.png";
import Login_Left from "../login/Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode"

import { BASE_URL } from '../../../utils/url'




const Verification_two = () => {

    const baseURL = BASE_URL
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { primary_dir, user_phone } = useContext(MainContext);

    const [key, setKey] = useState("");
    
    //Mounting 
    useEffect(() => {
        if (Cookies.get("access_token")) {
            navigate("/dashboard",{replace:true})
          }
    }, [])


    const handleSubmitForm = async (e) => {
        e.preventDefault();
        e.target.disabled = true

        try {
            const res = await axios.post(`${baseURL}/forget-password-check-verification`
                , JSON.stringify({
                    type: "mobile",
                    email: null,
                    mobile: Cookies.get("country_code") + Cookies.get("user_phone"),
                    verification_code: key
                })

                , { headers: { 'Content-Type': 'application/json' } })


            if (res.status == 200) {
                Cookies.set("verification_code", key)
                setKey("")
                navigate("/new-password")

                e.target.disabled = false
            }
        } catch (err) {
            toast.error(err.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",

            });
            e.target.disabled = false
        }

    }




    return (
        <HelmetProvider>
            <div className="grand-parent" id="verification">
                <Helmet>
                    <title>{t("title_verify")}</title>
                </Helmet>
                <div className="verification-parent" >
                    {/* right */}
                    <section className="verification-right">

                        <div className="back">
                            <Link to="/forget">
                                <span>
                                    {(Cookies.get("primary_dir") === "ltr") ? <FaArrowLeft /> : <FaArrowRight />}
                                </span>
                            </Link>
                        </div>


                        <figure className="flexCenter">
                            <img src={mySrc} alt="" />
                        </figure>

                        <h1>{t("verification_1")}</h1>
                        {(Cookies.get("primary_dir") === "rtl") ?
                            (
                                <p>
                                    <span className="block">کاربر گرامی یک پیامک حاوی کد تایید ۶ رقمی به</span>
                                    {" "}
                                    <span>شماره موبایل</span>
                                    {" "}
                                    <span style={{ color: "#f6b33f" }}>{Cookies.get("user_phone")}</span>
                                    {" "}
                                    <span>ارسال شده است</span>
                                </p>
                            )
                            :
                            (
                                <p>
                                    <span>Dear user, an SMS containing a 6-digit confirmation code has been sent to mobile number</span>
                                    {" "}
                                    <span style={{ color: "#f6b33f" }}>{Cookies.get("user_phone")}</span>

                                </p>
                            )
                        }

                        {/*  form  */}
                        <form>
                            <div className="mini-parent">
                                <label for="">{t("verification_2")}</label>
                                <input
                                    className="p-2"
                                    type="text"
                                    value={key}
                                    onChange={(e) => setKey(e.target.value)} />
                            </div>

                            <div className="submit-form">
                                <button type="submit" onClick={handleSubmitForm}>{t("verification_3")}</button>
                            </div>

                            {/* <div className="recently">
                                <span>
                                    {t("verification_2")}
                                    ?
                                    {" "}
                                    <Link to={"/register"}>{t("verification_3")}</Link>
                                </span>
                            </div> */}
                        </form>

                    </section>

                    {/* left */}
                    <Login_Left />
                </div>
                {/* postion absolute */}
                {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
                {/* <LightingMode bottom="20px" left="30px" /> */}
            </div>
        </HelmetProvider>
    )
}

export default Verification_two
import React, { useState, useEffect, useRef, useContext } from "react";
import Nav_MobileTablet from "../components/nav-mobile-tablet/Nav_MobileTablet";
import Nav_Desktop from "../components/nav-desktop/Nav_Desktop";
import "./order.css";
import Main_Sidebar from "../components/sidebar/Main_Sidebar";
import Fixed_Mobile_Links from "../components/fixed-mobile-links/Fixed_Mobile_Links";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "axios";
import LoadingCircle from "../components/loading-circle/LoadingCircle";
import LightingMode from "../components/lighting-mode/LightingMode";
import { MainContext } from "../contexts/MainContexts";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BASE_URL } from '../utils/url'

export default function Order() {
  const { primary_dir } = useContext(MainContext);


  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  //method
  const closeWindowForDesktop = () => {
    if (desktop_ProfileMenu == true) {
      setDesktop_ProfileMenu(false);
    }
    if (desktop_ThreeDots == true) {
      setDesktop_ThreeDots(false);
    }
    if (desktop_Notifications == true) {
      setDesktop_Notifications(false);
    }
  };

  //
  const { t } = useTranslation();
  //
  const params = new URLSearchParams(window.location.search);
  const [forceRender, setForceRender] = useState("");
  //
  const [userWallets, setUserWallets] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  //
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          `${BASE_URL}/user/orders/open`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserWallets(response_1.data.data);
        setLoading(false);
        allCoinsInitial.current = response_1.data.data;
      } catch (error) {
        // console.log(error.message);
      }
    }; //end of method
    fetchData();
  }, []);
  // soooooooooooooooort

  //
  ///////////// Sorting
  const allCoinsInitial = useRef([]);
  //  const [allCoins, setAllCoins] = useState([]);

  const sorted_by_Price =
    userWallets &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
    );
  const sorted_by_Price_re = sorted_by_Price && [...sorted_by_Price].reverse();

//   const sorted_by_Price2 =
//   userWallets &&
//   [...allCoinsInitial.current].sort(
//     (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
//   );
// const sorted_by_Price_re2= sorted_by_Price2 && [...sorted_by_Price2].reverse();

  const sorted_by_Time =
    userWallets &&
    [...allCoinsInitial.current].sort(
      (a, b) => parseFloat(b.created_at) - parseFloat(a.created_at)
    );
  const sorted_by_Time_re = sorted_by_Time && [...sorted_by_Time].reverse();

  //  const sorted_by_Price_Alphabetical = userWallets && [...sorted_by_Price].sort((a, b) => a.txn_id.localeCompare(b.txn_id));
  const sorted_by_Price_Alphabetical =
    userWallets &&
    sorted_by_Price
      .filter((item) => item.txn_id !== null)
      .sort((a, b) => (a.txn_id || "").localeCompare(b.txn_id || ""));
  const sorted_by_Price_re_Alphabetical =
    sorted_by_Price_Alphabetical && [...sorted_by_Price_Alphabetical].reverse();
  //  const sorted_by_HighPrice = userWallets && [...userWallets].sort((a, b) => parseFloat(b.highPrice) - parseFloat(a.highPrice));
  //  const sorted_by_Change = userWallets && [...userWallets].sort((a, b) => parseFloat(b.change) - parseFloat(a.change));
  const sorted_by_Price_Alphabetical1 =
    userWallets &&
    sorted_by_Price
      .filter((item) => item.status !== null)
      .sort((a, b) => (a.status || "").localeCompare(b.status || ""));
  const sorted_by_Price_re_Alphabetical1 =
    sorted_by_Price_Alphabetical1 &&
    [...sorted_by_Price_Alphabetical1].reverse();
// 
// console.log(sorted_by_Price)
const sorted_by_Price_Alphabetical2 =
    userWallets &&
    sorted_by_Price
      .filter((item) => item.trade_pair !== null)
      .sort((a, b) => (a.trade_pair || "").localeCompare(b.trade_pair || ""));
  const sorted_by_Price_re_Alphabetical2 =
    sorted_by_Price_Alphabetical2 &&
    [...sorted_by_Price_Alphabetical2].reverse();

  const [nameBtnStatus, setNameBtnStatus] = useState("down");
  const [priceBtnStatus, setPriceBtnStatus] = useState("off");
  const [priceBtnStatus2, setPriceBtnStatus2] = useState("off");
  const [priceBtnStatus3, setPriceBtnStatus3] = useState("off");
  const [highPriceBtnStatus, setHighPriceBtnStatus] = useState("off");
  const [changeBtnStatus, setChangeBtnStatus] = useState("off");
  const [changeBtnStatus1, setChangeBtnStatus1] = useState("off");

  function sortByName() {
    if (sorted_by_Price_Alphabetical1) {
      if (nameBtnStatus === "off" || nameBtnStatus === "up") {
        setUserWallets(sorted_by_Price_Alphabetical1);
        setNameBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical1);
        setNameBtnStatus("up");
      }
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
      setChangeBtnStatus1("off")
    }
  }
  function sortByPrice() {
    if (sorted_by_Price) {
      if (priceBtnStatus === "off" || priceBtnStatus === "up") {
        setUserWallets(sorted_by_Price);
        setPriceBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re);
        setPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByHighPrice() {
    console.log(highPriceBtnStatus);

    if (sorted_by_Time) {
      if (highPriceBtnStatus === "off" || highPriceBtnStatus === "up") {
        setUserWallets(sorted_by_Time);
        setHighPriceBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Time_re);
        setHighPriceBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByChange() {
    if (sorted_by_Price_Alphabetical) {
      if (changeBtnStatus === "off" || changeBtnStatus === "up") {
        setUserWallets(sorted_by_Price_Alphabetical);
        setChangeBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical);
        setChangeBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
    }
  }
  function sortByChange() {
    if (sorted_by_Price_Alphabetical) {
      if (changeBtnStatus === "off" || changeBtnStatus === "up") {
        setUserWallets(sorted_by_Price_Alphabetical);
        setChangeBtnStatus("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical);
        setChangeBtnStatus("up");
      }
      setNameBtnStatus("off");
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
    }
  }
  function sortByPrice2() {
    if (sorted_by_Price) {
      if (priceBtnStatus2 === "off" || priceBtnStatus2 === "up") {
        setUserWallets(sorted_by_Price);
        setPriceBtnStatus2("down");
      } else {
        setUserWallets(sorted_by_Price_re);
        setPriceBtnStatus2("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByPrice3() {
    if (sorted_by_Price) {
      if (priceBtnStatus3 === "off" || priceBtnStatus2 === "up") {
        setUserWallets(sorted_by_Price);
        setPriceBtnStatus3("down");
      } else {
        setUserWallets(sorted_by_Price_re);
        setPriceBtnStatus3("up");
      }
      setNameBtnStatus("off");
      setHighPriceBtnStatus("off");
      setChangeBtnStatus("off");
    }
  }
  function sortByalf2() {
    console.log(sorted_by_Price_Alphabetical2)
    
    if (sorted_by_Price_Alphabetical2) {
      if (changeBtnStatus1 === "off" || changeBtnStatus1 === "up") {
        setUserWallets(sorted_by_Price_Alphabetical2);
        setChangeBtnStatus1("down");
      } else {
        setUserWallets(sorted_by_Price_re_Alphabetical2);
        setChangeBtnStatus1("up");
      }
      setNameBtnStatus("off");
      setChangeBtnStatus("off");
      setPriceBtnStatus("off");
      setHighPriceBtnStatus("off");
    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
      <Helmet>
        <title>{t("title.13")}</title>
      </Helmet>
      <Nav_MobileTablet
        mobileRight_DarkScreen={mobileRight_DarkScreen}
        mobileLeft_DarkScreen={mobileLeft_DarkScreen}
        tablet_DarkScreen={tablet_DarkScreen}
        tablet_ProfileMenu={tablet_ProfileMenu}
        tablet_Notifications={tablet_Notifications}
        setMobileRight_DarkScreen={setMobileRight_DarkScreen}
        setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
        setTablet_DarkScreen={setTablet_DarkScreen}
        setTablet_ProfileMenu={setTablet_ProfileMenu}
        setTablet_Notifications={setTablet_Notifications}
      />
      <div id="everything">
        {/* side bar */}
        <Main_Sidebar />
        {/* all */}
        <section id="all">
          <Nav_Desktop
            desktop_ProfileMenu={desktop_ProfileMenu}
            desktop_Notifications={desktop_Notifications}
            desktop_ThreeDots={desktop_ThreeDots}
            setDesktop_Notifications={setDesktop_Notifications}
            setDesktop_ThreeDots={setDesktop_ThreeDots}
            setDesktop_ProfileMenu={setDesktop_ProfileMenu}
          />
          <div className="body order">
            <table>
              <thead>
              <tr>
                <th>
                  <div className="list" onClick={sortByalf2}>
                    <div className="text">{t("dashbord.order.1")}</div>
                    {changeBtnStatus1 === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {changeBtnStatus1 === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {changeBtnStatus1 === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                    
                  </div>
                </th>
                <th>
                  <div className="list" onClick={sortByName}>
                    <div className="text">{t("dashbord.order.2")}</div>
                    {nameBtnStatus === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {nameBtnStatus === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {nameBtnStatus === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                  </div>
                </th>
                <th>
                  <div className="list" onClick={sortByChange}>
                    <div className="text">{t("dashbord.order.3")}</div>
                    {changeBtnStatus === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {changeBtnStatus === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {changeBtnStatus === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                  </div>
                </th>
                <th>
                  <div className="list" onClick={sortByPrice}>
                    <div className="text">{t("dashbord.order.4")}</div>
                    {priceBtnStatus === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {priceBtnStatus === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {priceBtnStatus === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                  </div>
                </th>
                <th>
                  <div className="list" onClick={sortByPrice2}>
                    <div className="text">{t("dashbord.order.5")}</div>
                    {priceBtnStatus2 === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {priceBtnStatus2 === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {priceBtnStatus2 === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                  </div>
                </th>
                <th>
                  <div className="list" onClick={sortByPrice3}>
                    <div className="text">{t("dashbord.order.6")}</div>
                    {priceBtnStatus3 === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {priceBtnStatus3 === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {priceBtnStatus3 === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                  </div>
                </th>
                <th>
                  <div className="list" onClick={sortByName}>
                    <div className="text">{t("dashbord.order.7")}</div>
                    {nameBtnStatus === "down" && (
                      <img
                        src={require("../image/price-socket/sort-down.png")}
                      />
                    )}
                    {nameBtnStatus === "up" && (
                      <img src={require("../image/price-socket/sort-up.png")} />
                    )}
                    {nameBtnStatus === "off" && (
                      <img src={require("../image/price-socket/sort.png")} />
                    )}
                  </div>
                </th>
              </tr>
              </thead>
    
                {!loading &&
              <>
              {userWallets && userWallets.length > 0 ? (
                <tbody>{
                  userWallets.map((index) => {
                  const time = index.created_at;
                  const dateParts = time.split("T");
                  const timeParts = dateParts[1].split(".");
                  return (
                    <tr key={index.id}>
                      <td>{index.trade_pair} </td>
                      <td>{index.type}</td>
                      <td>{index.category}</td>
                      <td>{index.total} {index.quote_coin}</td>
                      <td>{index.total} {index.quote_coin}</td>
                      {/* <td>
                        <div className="time">{timeParts[0]}</div>
                        {index.created_at}
                      </td> */}

                    <td> {index.quote_coin} {index.total} / {+index.amount} {index.base_coin} </td>

                      <td>
                        <div
                          id="ab1"
                          className="background "
                          style={
                            index.status === "completed"
                              ? { background: "#d8f3f1", color: "#00c0af" }
                              : index.status === "pending"
                              ? { background: "#f3f1d8", color: "#c0ad00" }
                              : { background: "#f3d9d8", color: "#c00000" }
                          }
                        >
                          {" "}
                          {index.status}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                </tbody>):(
                   <tbody>
                   <tr>
                     <td colSpan="4">
                       <div
                         style={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           flexDirection: "column",
                         }}
                       >
                         <img
                           style={{ width: "200.6px" }}
                           src={require("../assests/image/dashbord/Empty.png")}
                         />
                         <div className="text">{t("nothing.1")}</div>
                       </div>
                     </td>
                   </tr>
                 </tbody>
                )}
       

</> }
            </table>
            {loading && <LoadingCircle/>}
          </div>
        </section>
        <Fixed_Mobile_Links />

        <LightingMode bottom="20px" left="30px" />
      </div>
    </HelmetProvider>
    </React.Fragment>
  );
}

//internal
import { useContext, useState } from "react";
//external
import {  Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/MainContexts";
//Component and Style
import "./fixed-mobile-links.css";
import "./lightMode.css"
import smLogo from "../../assests/image/logo/logo-sm.png";
import smLogo_black from "../../assests/image/logo/logo-sm-black.png";
import smLogo_white from "../../assests/image/logo/logo-sm-white.png";









const Fixed_Mobile_Links = () => {

  const { lightingMode } = useContext(MainContext)
  const { t } = useTranslation()
  

  const mm = (lightingMode === "light") ? "black" : "white";
  const { pathname } = useLocation();
 
   
  let src=""
  if(lightingMode==="light" && pathname==="/dashboard"){
      src=smLogo
  }else if(lightingMode==="light" && pathname!=="/dashboard"){
      src=smLogo_black
  }else if(lightingMode==="dark" && pathname==="/dashboard"){
      src=smLogo
  }else{
    src=smLogo_white
  }
 





  return (
    <ul className="fixed-mobile-links sm:hidden  w-full h-[56px] flex justify-evenly toggleBg">


      {/* سفارشات */}
      <li>
        <Link to={"/order"} >
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.055" height="20.055" viewBox="0 0 20.055 20.055">
              <path id="add_chart_FILL0_wght400_GRAD0_opsz48" d="M121.671,236.055A1.714,1.714,0,0,1,120,234.383V217.671A1.714,1.714,0,0,1,121.671,216h10.167a.806.806,0,0,1,.6.242.816.816,0,0,1,.24.6.8.8,0,0,1-.24.594.815.815,0,0,1-.6.237H121.671v16.712h16.712V224.217a.806.806,0,0,1,.242-.6.816.816,0,0,1,.6-.24.8.8,0,0,1,.594.24.815.815,0,0,1,.237.6v10.167a1.714,1.714,0,0,1-1.671,1.671Zm3.728-12.033a.8.8,0,0,0-.594.24.815.815,0,0,0-.237.6v5.989a.806.806,0,0,0,.242.6.816.816,0,0,0,.6.24.8.8,0,0,0,.594-.24.815.815,0,0,0,.237-.6v-5.989a.806.806,0,0,0-.242-.6A.816.816,0,0,0,125.4,224.022Zm4.624-3.649a.8.8,0,0,0-.594.24.815.815,0,0,0-.237.6v9.637a.806.806,0,0,0,.242.6.816.816,0,0,0,.6.24.8.8,0,0,0,.594-.24.815.815,0,0,0,.237-.6v-9.637a.806.806,0,0,0-.242-.6A.816.816,0,0,0,130.022,220.373Zm4.624,7.186a.8.8,0,0,0-.594.24.815.815,0,0,0-.237.6v2.451a.806.806,0,0,0,.242.6.816.816,0,0,0,.6.24.8.8,0,0,0,.594-.24.814.814,0,0,0,.237-.6v-2.451a.806.806,0,0,0-.242-.6A.816.816,0,0,0,134.646,227.559Zm1.314-7.437h-1.616a.806.806,0,0,1-.6-.242.816.816,0,0,1-.24-.6.8.8,0,0,1,.24-.594.815.815,0,0,1,.6-.237h1.616v-1.616a.806.806,0,0,1,.242-.6.816.816,0,0,1,.6-.24.8.8,0,0,1,.594.24.815.815,0,0,1,.237.6v1.616h1.588a.806.806,0,0,1,.6.242.816.816,0,0,1,.24.6.8.8,0,0,1-.24.594.815.815,0,0,1-.6.237h-1.588v1.588a.806.806,0,0,1-.242.6.816.816,0,0,1-.6.24.8.8,0,0,1-.594-.24.815.815,0,0,1-.237-.6ZM130.027,226.027Z" transform="translate(-120 -216)" fill={pathname==="/order"? "#f6b33f": mm} />
            </svg>
          </span>
          <span style={pathname==="/order" ? {color:"#f6b33f"}:{color:mm}}>{t("fixed-mobile-links_3")}</span>
        </Link>
      </li>




      {/* داشبورد */}
      <li>
        <Link to={"/dashboard"} >
          <span>
            <img  
              src={src} 
              width={25} height={25}  
            />
          </span>
           

          <span style={pathname==="/dashboard" ? {color:"#f6b33f"}:{color:mm}}>{t("fixed-mobile-links_5")}</span>
        </Link>
      </li>




      {/* کیف پول */}
      <li>
        <Link to={"/wallet"} >
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.458" height="20.275" viewBox="0 0 21.458 20.275">
              <path id="account_balance_wallet_FILL0_wght400_GRAD0_opsz48" d="M134.981,227.94a1.879,1.879,0,0,0,1.8-1.816,1.7,1.7,0,0,0-.549-1.253,1.735,1.735,0,0,0-2.506,0,1.7,1.7,0,0,0-.549,1.253,1.879,1.879,0,0,0,1.8,1.816Zm-13.292,5.153v0Zm0,3.182a1.637,1.637,0,0,1-1.169-.507,1.6,1.6,0,0,1-.521-1.183v-16.9a1.787,1.787,0,0,1,1.69-1.69h16.9a1.6,1.6,0,0,1,1.183.521,1.637,1.637,0,0,1,.507,1.169v3.773h-1.69V217.69h-16.9v16.9h16.9V230.84h1.69v3.745a1.732,1.732,0,0,1-1.69,1.69Zm10.081-4.872a2.055,2.055,0,0,1-1.521-.563,2.017,2.017,0,0,1-.563-1.492v-6.392a2.007,2.007,0,0,1,.563-1.507,2.085,2.085,0,0,1,1.521-.549h7.6a2.085,2.085,0,0,1,1.521.549,2.007,2.007,0,0,1,.563,1.507v6.392a2.017,2.017,0,0,1-.563,1.492,2.055,2.055,0,0,1-1.521.563Z" transform="translate(-120 -216)" fill={pathname==="/wallet"? "#f6b33f": mm} />
            </svg>


          </span>
          <span style={pathname==="/wallet" ? {color:"#f6b33f"}:{color:mm}}>{t("fixed-mobile-links_4")}</span>
        </Link>
      </li>


    </ul>
  )
}


export default Fixed_Mobile_Links
//internal
import { useContext, useState, useEffect } from "react";
//external
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../../contexts/MainContexts";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style and Image
import "./lightingMode.css";
import "./verification.css";
import mySrc from "../../../assests/image/Phone-code.png";
import Login_Left from "../login/Login_Left";
import Change_Language from "../../../components/change-language/Change_Language";
import LightingMode from "../../../components/lighting-mode/LightingMode"
import { toast } from "react-toastify";

import { BASE_URL } from '../../../utils/url'



const Verification_one = () => {
    const baseURL = BASE_URL
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { primary_dir, user_phone } = useContext(MainContext);

    const [key, setKey] = useState("");

    //Mounting
    useEffect(() => {
        if (Cookies.get("access_token")) {
            navigate("/dashboard", { replace: true })
        }
    }, [])


    // const handleSubmitForm = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const res = await axios.post(`${baseURL}/registration/verify`
    //             , JSON.stringify({
    //                 pre_registration_id: Cookies.get("pre_registration_id"),
    //                 key
    //             })

    //             , { headers: { 'Content-Type': 'application/json' } })


    //         if (res.status == 200) {
    //             Cookies.set("access_token", res.data.data.access_token, { expires: 365 })
    //             Cookies.remove("pre_registration_id")
    //             if (Cookies.get("user_phone")) {
    //                 Cookies.remove("user_phone")
    //             }
    //             setKey("")
    //             navigate("/dashboard")
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // } 
    const handleSubmitForm = (e) => {
        e.preventDefault();
        e.target.disabled = true
    
        fetch(`${baseURL}/registration/verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pre_registration_id: Cookies.get("pre_registration_id"),
                key
            })
        })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                toast.error('Failed to verify registration')
            }
            e.target.disabled = false
        })
        .then(data => {
            Cookies.set("access_token", data.data.access_token, { expires: 365 });
            Cookies.remove("pre_registration_id");
            if (Cookies.get("user_phone")) {
                Cookies.remove("user_phone");
            }
            setKey("");
            navigate("/dashboard");
        })
        .catch(err => {
            console.log(err.message);
            e.target.disabled = false
        });
    }




    return (
        <HelmetProvider>
            <div className="grand-parent" id="verification">
                <Helmet>
                    <title>{t("title_verify")}</title>
                </Helmet>
                <div className="verification-parent" >
                    {/* right */}
                    <section className="verification-right">

                        <div className="back">
                            <Link to={"/register"}>
                                <span>
                                    {(Cookies.get("primary_dir") === "ltr") ? <FaArrowLeft /> : <FaArrowRight />}
                                </span>
                            </Link>
                        </div>


                        <figure className="flexCenter">
                            <img src={mySrc} alt="" />
                        </figure>

                        <h1>{t("verification_1")}</h1>
                        {(Cookies.get("primary_dir") === "rtl") ?
                            (
                                <p>
                                    <span className="block">کاربر گرامی یک پیامک حاوی کد تایید 6 رقمی به</span>
                                    {" "}
                                    <span>شماره موبایل</span>
                                    {" "}
                                    <span style={{ color: "#f6b33f" }}>{Cookies.get("user_phone")}</span>
                                    {" "}
                                    <span>ارسال شده است</span>
                                </p>
                            )
                            :
                            (
                                <p>
                                    <span>Dear user, an SMS containing a 6-digit confirmation code has been sent to mobile number</span>
                                    {" "}
                                    <span style={{ color: "#f6b33f" }}>{Cookies.get("user_phone")}</span>

                                </p>
                            )
                        }

                        {/*  form  */}
                        <form>
                            <div className="mini-parent">
                                <label for="">{t("verification_2")}</label>
                                <input
                                    className="p-2"
                                    type="text"
                                    value={key}
                                    onChange={(e) => setKey(e.target.value)} />
                            </div>

                            <div className="submit-form">
                                <button type="submit" onClick={handleSubmitForm}>{t("verification_3")}</button>
                            </div>

                            {/* <div className="recently">
                            <span>
                            {t("verification_2")}
                                ?
                                {" "}
                                <Link to={"/login"}>{t("verification_3")}</Link>
                            </span>
                        </div> */}
                        </form>

                    </section>

                    {/* left */}
                    <Login_Left />
                </div>
                {/* postion absolute */}
                {/* <Change_Language position="absolute" top="30px" right="30px" /> */}
                {/* <LightingMode bottom="20px" left="30px" /> */}
            </div>
        </HelmetProvider>
    )
}

export default Verification_one
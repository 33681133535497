import React from 'react';
import "./page404.css";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet, HelmetProvider } from "react-helmet-async";
export default function Page404() {
  const { t } = useTranslation();
  return (
    
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.7")}</title>
          <meta name="title" content={t("metatag.1")} />
          <meta name="description" content={t("metatag.2")} />
        </Helmet>
      <Header />
        {/* page404-section-one */}
        <section id='page404-section-one'>
            <div className='all'>
                <img src={require("../image/404/404.png")}/>
                <img src={require("../image/404/404-mobile.png")} className='mobile'/>
                <div className='title'>
                {t("page404.1")}
                </div>
                <p className='des'>
                {t("page404.2")}
                </p>
                <Link to='/'>
                {t("page404.3")}
                </Link>
            </div>
        </section>
        <Footer />
        </HelmetProvider>
    </React.Fragment>
  )
}

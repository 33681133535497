import React, { useEffect, useState, useRef, useContext } from 'react'
import './Exchange.css'
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loading from "../components/loading-circle/LoadingCircle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { MainContext } from "../contexts/MainContexts";
import { BASE_URL } from '../utils/url'


const Exchange = () => {
    const { t } = useTranslation()
    const lightingMode = getCookie('lightingMode')
    const { user_lang } = useContext(MainContext)


    /////////////////////////////////////////////////// Socket for selectbox
    const [allCoins, setAllCoins] = useState()
    const coinsArrayRef = useRef([])
    const [socketData, setSocketData] = useState()

    const createWebSocket = () => {
        const socket = new WebSocket('wss://ws.moneyex.org/')

        socket.onopen = () => {
            const request = { type: 'marketTicker', symbol: '*' }
            socket.send(JSON.stringify(request))
        }

        socket.onmessage = (event) => {
            const jsonData = JSON.parse(event.data)

            const allCoinsArray = Array.from(coinsArrayRef.current)
            allCoinsArray.forEach((cn) => {
                if (jsonData.base_coin === cn.symbol) {
                    cn.img = 'https://api.moneyex.org/storage/images/coin-icons/__' + jsonData.base_coin + '.png'
                    cn.symbol = jsonData.base_coin
                    cn.name = jsonData.base_coin
                    cn.price = jsonData.latest_price
                    cn.change = jsonData.change
                    cn.high = jsonData.high_price
                    cn.low = jsonData.low_price
                    cn.amount = jsonData.base_coin_volume
                    cn.volume = jsonData.quote_coin_volume
                }
            })

            setSocketData(allCoinsArray)
        }
    }
    ////////////////////////////////////////////////////


    //////////////////////////////////////////////////// Select Box
    const [isCoinSelectboxVisible, setIsCoinSelectboxVisible] = useState(false)
    const handleDocumentClick = (event) => {
        if (event.target.closest('.ex-selectbox')) {
            setIsCoinSelectboxVisible(!isCoinSelectboxVisible)
        } else {
            setIsCoinSelectboxVisible(false)
        }
    }

    const [coin, setCoin] = useState('BTC')

    const coinData = socketData && socketData.find(i => i.symbol === coin) || {}
    const coin_img = coinData.img || 'https://api.moneyex.org/storage/images/coin-icons/__BTC.png'
    const coin_symbol = coinData.symbol || 'BTC'
    const coin_name = coinData.name || 'BTC'
    const coin_price = coinData.price || '...'
    const coin_change = coinData.change || ''
    const coin_high = coinData.high || '...'
    const coin_low = coinData.low || '...'
    const coin_amount = coinData.amount || '...'
    const coin_volume = coinData.volume || '...'

    function selectboxChangeHandel(c) {
        setCoin(c)
        setIsCoinSelectboxVisible(false)
    }
    //////////////////////////////////////////////////////


    ////////////////////////////////////////////////////// Socket for Trade volumes
    const [volumesArray, setVolumesArray] = useState()
    const bids = volumesArray && volumesArray.bids
    const asks = volumesArray && volumesArray.asks
    const average_asks_bids = volumesArray && ([...volumesArray.asks, ...volumesArray.bids].reduce((acc, item) => acc + parseFloat(item[0]), 0)) / [...volumesArray.asks, ...volumesArray.bids].length || 0
    const socketRef = useRef(null)

    // محاسبه نسبت مقدار هر فرد آرایه به بزرگترین آنها
    function ratioCalc(inputArray) {
        const maxNumber = Math.max(...inputArray.map(item => parseFloat(item[1])))
        const ratios = inputArray.map(item => {
            const value = parseFloat(item[1])
            return value / maxNumber
        })
        const scaledRatios = ratios.map(ratio => ratio * 100)
        const outputArray = inputArray.map((item, index) => {
            const scaledRatio = scaledRatios[index]
            return [item[0], item[1], scaledRatio.toFixed(2)]
        })
        return outputArray
    }

    const createWebSocket2 = (theCoin) => {
        if (socketRef.current) {
            socketRef.current.close()
            setVolumesArray(null)
        }

        const newSocket = new WebSocket('wss://ws.moneyex.org/')
        socketRef.current = newSocket

        newSocket.onopen = () => {
            const request = { type: 'level2', symbol: theCoin + '_USDT' }
            newSocket.send(JSON.stringify(request))
        }

        newSocket.onmessage = (event) => {
            const jsonData = JSON.parse(event.data)

            const transformedData = {
                asks: [],
                bids: [],
            }
            transformedData.asks = transformedData.asks.concat(jsonData.asks)
            transformedData.bids = transformedData.bids.concat(jsonData.bids)

            transformedData.asks = ratioCalc(transformedData.asks)
            transformedData.bids = ratioCalc(transformedData.bids)

            setVolumesArray(transformedData)
        }
    }

    useEffect(() => {
        createWebSocket2(coin)
        getUserData()
    }, [coin])
    //////////////////////////////////////////////////////


    ////////////////////////////////////////////////////// Trading
    const [isLogined, setIsLogined] = useState(false)

    const [exVolumeSectionToggle, setExVolumeSectionToggle] = useState(true)
    function exVolumeSectionToggleFunc() {
        setExVolumeSectionToggle(!exVolumeSectionToggle)
    }
    const [buySell, setBuySell] = useState(true)
    function setBuySellFunc() {
        setBuySell(!buySell)
    }

    const [isInMarketType, setIsInMarketType] = useState(true)
    const [orderType, setOrderType] = useState('market')
    function setOrderTypeFunc(type) {
        setOrderType(type)
        if (type === 'limit' || type === 'stopLimit') {
            setIsInMarketType(false)
        } else {
            setIsInMarketType(true)
        }
    }

    const [base_balance, setBase_balance] = useState()
    const [quote_balance, setQuote_balance] = useState()


    //// Buy (Market)
    const [quantityValue, setQuantityValue] = useState('')
    function quantityImputHandler(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setQuantityValue(e.target.value)
        }
    }

    const [total, setTotal] = useState(0)
    useEffect(() => {
        const calculatedTotal = socketData && buySell && quantityValue ? (coin_price * quantityValue) : 0

        const calculatedFinalTotal = calculatedTotal > quote_balance ? quote_balance : calculatedTotal
        setTotal(calculatedFinalTotal)

        if (buySell && calculatedTotal > calculatedFinalTotal) {
            setQuantityValue((calculatedFinalTotal / coin_price) % 1 !== 0 ? (calculatedFinalTotal / coin_price).toFixed(8) : calculatedFinalTotal / coin_price)
        }

    }, [socketData, quantityValue, buySell, coin_price, quote_balance, base_balance])

    const [rangeValueState, setRangeValueState] = useState(0)
    let rangeValue = socketData && buySell && quote_balance && (((quantityValue * coin_price) / quote_balance) * 100).toFixed(0) || 0

    function rangeValueFunc(e) {
        setRangeValueState(e.target.value)
        rangeValue = e.target.value
    }

    useEffect(() => {
        if (socketData) {
            if ((quote_balance * (rangeValueState / 100)) / coin_price) {
                setQuantityValue(((quote_balance * (rangeValueState / 100)) / coin_price).toFixed(8))
            } else {
                setQuantityValue((quote_balance * (rangeValueState / 100)) / coin_price)
            }
        }
    }, [rangeValueState])


    // Buy (Limit & Stop Limit)
    const [quantityLimitValue, setQuantityLimitValue] = useState('')
    function quantityLimitImputHandler(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setQuantityLimitValue(e.target.value)
        }
    }

    const [orderPrice, setOrderPrice] = useState('')
    function orderImputHandler(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setOrderPrice(e.target.value)
        }
    }

    const [limitTotal, setLimitTotal] = useState(0)
    useEffect(() => {
        const calculatedTotal = socketData && quantityLimitValue && buySell ? (orderPrice * quantityLimitValue) : 0

        const calculatedFinalTotal = calculatedTotal > quote_balance ? quote_balance : calculatedTotal
        setLimitTotal(calculatedFinalTotal)

        if (calculatedTotal > calculatedFinalTotal) {
            setQuantityLimitValue((calculatedFinalTotal / orderPrice) % 1 !== 0 ? (calculatedFinalTotal / orderPrice).toFixed(8) : calculatedFinalTotal / orderPrice)
        }
    }, [socketData, quantityLimitValue, buySell, quote_balance, orderPrice])

    const [rangeValueStateLimit, setRangeValueStateLimit] = useState(0)
    let rangeValueLimit = socketData && buySell && quote_balance && (((quantityLimitValue * orderPrice) / quote_balance) * 100).toFixed(0) || 0

    function rangeValueFuncLimit(e) {
        setRangeValueStateLimit(e.target.value)
        rangeValueLimit = e.target.value
    }

    useEffect(() => {
        if (socketData) {
            if ((quote_balance * (rangeValueStateLimit / 100)) / orderPrice) {
                setQuantityLimitValue(((quote_balance * (rangeValueStateLimit / 100)) / orderPrice).toFixed(8))
            } else {
                setQuantityLimitValue((quote_balance * (rangeValueStateLimit / 100)) / orderPrice)
            }
        }
    }, [rangeValueStateLimit])


    // Stop Limit
    const [stopLimitPrice, setStopLimitPrice] = useState('')

    function stopLimitImputHandler(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setStopLimitPrice(e.target.value)
        }
    }


    //// Sell (Market)
    const [quantityValue_sell, setQuantityValue_sell] = useState('')
    function quantityImputHandler_sell(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setQuantityValue_sell(e.target.value)
        }
    }

    const [total_sell, setTotal_sell] = useState(0)
    useEffect(() => {
        const calculatedTotal = socketData && !buySell && quantityValue_sell ? (coin_price * quantityValue_sell) : 0
        setTotal_sell(calculatedTotal)

        if (!buySell && quantityValue_sell > base_balance) setQuantityValue_sell(base_balance)

    }, [socketData, quantityValue_sell, buySell, coin_price, base_balance])

    const [rangeValueState_sell, setRangeValueState_sell] = useState(0)
    let rangeValue_sell = socketData && !buySell && base_balance && ((100 / base_balance) * quantityValue_sell).toFixed(0) || 0

    function rangeValueFunc_sell(e) {
        setRangeValueState_sell(e.target.value)
        rangeValue_sell = e.target.value
    }

    useEffect(() => {
        if (socketData) {
            setQuantityValue_sell((base_balance / 100) * rangeValueState_sell)
        }
    }, [rangeValueState_sell])


    // Sell (Limit & Stop Limit)
    const [quantityLimitValue_sell, setQuantityLimitValue_sell] = useState('')
    function quantityLimitImputHandler_sell(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setQuantityLimitValue_sell(e.target.value)
        }
    }

    const [orderPrice_sell, setOrderPrice_sell] = useState('')
    function orderImputHandler_sell(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setOrderPrice_sell(e.target.value)
        }
    }

    const [limitTotal_sell, setLimitTotal_sell] = useState(0)
    useEffect(() => {
        const calculatedTotal = socketData && !buySell && quantityLimitValue_sell && orderPrice_sell ? (orderPrice_sell * quantityLimitValue_sell) : 0
        setLimitTotal_sell(calculatedTotal)

        if (!buySell && quantityLimitValue_sell > base_balance) setQuantityLimitValue_sell(base_balance)

    }, [socketData, quantityLimitValue_sell, buySell, base_balance, orderPrice_sell])

    const [rangeValueStateLimit_sell, setRangeValueStateLimit_sell] = useState(0)
    let rangeValueLimit_sell = socketData && !buySell && base_balance && ((100 / base_balance) * quantityLimitValue_sell).toFixed(0) || 0

    function rangeValueFuncLimit_sell(e) {
        setRangeValueStateLimit_sell(e.target.value)
        rangeValueLimit_sell = e.target.value
    }

    useEffect(() => {
        if (socketData) {
            setQuantityLimitValue_sell((base_balance / 100) * rangeValueStateLimit_sell)
        }
    }, [rangeValueStateLimit_sell])


    // Stop Limit
    const [stopLimitPrice_sell, setStopLimitPrice_sell] = useState('')

    function stopLimitImputHandler_sell(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '')

        if (/^\d*\.?\d{0,8}$/.test(e.target.value)) {
            setStopLimitPrice_sell(e.target.value)
        }
    }


    ////// Trade Post
    function tradePost(e) {
        e.target.disabled = true

        const data = buySell && orderType === 'market' ? {
            order_type: 'buy',
            trade_pair: `${coin}_USDT`,
            amount: quantityValue
        } :
            buySell && orderType === 'limit' ? {
                order_type: 'buy',
                category: 'limit',
                trade_pair: `${coin}_USDT`,
                price: orderPrice,
                amount: quantityLimitValue
            } :
                buySell && orderType === 'stopLimit' ? {
                    order_type: 'buy',
                    category: 'stop_limit',
                    trade_pair: `${coin}_USDT`,
                    price: orderPrice,
                    amount: quantityLimitValue,
                    stop: stopLimitPrice
                } :
                    !buySell && orderType === 'market' ? {
                        order_type: 'sell',
                        trade_pair: `${coin}_USDT`,
                        amount: quantityValue_sell
                    } :
                        !buySell && orderType === 'limit' ? {
                            order_type: 'sell',
                            category: 'limit',
                            trade_pair: `${coin}_USDT`,
                            price: orderPrice_sell,
                            amount: quantityLimitValue_sell
                        } :
                            {
                                order_type: 'sell',
                                category: 'stop_limit',
                                trade_pair: `${coin}_USDT`,
                                price: orderPrice_sell,
                                amount: quantityLimitValue_sell,
                                stop: stopLimitPrice_sell
                            }

        fetch(BASE_URL+'/user/orders/market', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokenFromCookie.current}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                e.target.disabled = false
                return response.json()
            })
            .then(responseData => {
                if (responseData.status) {
                    toast.success(responseData.status)
                } else {
                    toast.error(responseData.message)
                }

                getUserData()
                getOrders()
            })
            .catch(error => {
                toast.warn('Something went wrong!')
                // console.error(error.message)
            })
    }
    //////////////////////////////////////////////////////


    ////////////////////////////////////////////////////// Orders
    const [showPairs, setShowPairs] = useState(false)
    const [openOrders, setOpenOrders] = useState()
    const openOrdersInitial = useRef()

    function getOrders() {
        const headers = new Headers({
            'Authorization': `Bearer ${tokenFromCookie.current}`,
            'Content-Type': 'application/json',
        })
        fetch(BASE_URL+'/user/orders/open', { method: 'GET', headers: headers })
            .then(res => res.json())
            .then(data => {
                setOpenOrders(data.data)
                openOrdersInitial.current = data.data
                if (data.data.length) {
                    setShowPairs(true)
                } else {
                    setShowPairs(false)
                }
            })
        // .catch((err) => console.log(err))
    }


    // Sorting
    const orders_by_symbol = openOrdersInitial.current || []
    const orders_by_symbol_re = orders_by_symbol && [...orders_by_symbol].reverse()

    const orders_by_price = openOrders && [...openOrdersInitial.current].sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
    const orders_by_price_re = orders_by_price && [...orders_by_price].reverse()

    const orders_by_amount = openOrders && [...openOrdersInitial.current].sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
    const orders_by_amount_re = orders_by_amount && [...orders_by_amount].reverse()

    const orders_by_filled = openOrders && [...openOrdersInitial.current].sort((a, b) => parseFloat(b.exchanged) - parseFloat(a.exchanged))
    const orders_by_filled_re = orders_by_filled && [...orders_by_filled].reverse()

    const orders_by_openAmount = openOrders && [...openOrdersInitial.current].sort((a, b) => parseFloat(b.open_amount) - parseFloat(a.open_amount))
    const orders_by_openAmount_re = orders_by_openAmount && [...orders_by_openAmount].reverse()

    const orders_by_total = openOrders && [...openOrdersInitial.current].sort((a, b) => parseFloat(b.total) - parseFloat(a.total))
    const orders_by_total_re = orders_by_total && [...orders_by_total].reverse()

    const orders_by_type = openOrders && [...openOrdersInitial.current].sort((a, b) => (a.type === 'buy' ? -1 : 1))
    const orders_by_type_re = orders_by_type && [...orders_by_type].reverse()

    const orders_by_date = openOrders && [...openOrdersInitial.current].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    const orders_by_date_re = orders_by_date && [...orders_by_date].reverse()

    const [symbolBtnStatus, setSymbolBtnStatus] = useState('-down')
    const [priceBtnStatus, setPriceBtnStatus] = useState('')
    const [amountBtnStatus, setAmountBtnStatus] = useState('')
    const [filledBtnStatus, setFilledBtnStatus] = useState('')
    const [openAmountBtnStatus, setOpenAmountBtnStatus] = useState('')
    const [totalBtnStatus, setTotalBtnStatus] = useState('')
    const [typeBtnStatus, setTypeBtnStatus] = useState('')
    const [dateBtnStatus, setDateBtnStatus] = useState('')

    const normal_reverse = useRef(false)
    const sortingTypeRef = useRef('symbol')

    function ordersSortHandler(sortingType) {
        const previousSortingType = sortingTypeRef.current
        sortingTypeRef.current = sortingType

        if (previousSortingType === sortingTypeRef.current) {
            normal_reverse.current = !normal_reverse.current
        } else {
            normal_reverse.current = false
        }

        setSymbolBtnStatus('')
        setPriceBtnStatus('')
        setAmountBtnStatus('')
        setFilledBtnStatus('')
        setOpenAmountBtnStatus('')
        setTotalBtnStatus('')
        setTypeBtnStatus('')
        setDateBtnStatus('')

        if (sortingType === 'symbol') {
            setOpenOrders(!normal_reverse.current ? orders_by_symbol : orders_by_symbol_re)
            setSymbolBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'price') {
            setOpenOrders(!normal_reverse.current ? orders_by_price : orders_by_price_re)
            setPriceBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'amount') {
            setOpenOrders(!normal_reverse.current ? orders_by_amount : orders_by_amount_re)
            setAmountBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'filled') {
            setOpenOrders(!normal_reverse.current ? orders_by_filled : orders_by_filled_re)
            setFilledBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'openAmount') {
            setOpenOrders(!normal_reverse.current ? orders_by_openAmount : orders_by_openAmount_re)
            setOpenAmountBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'total') {
            setOpenOrders(!normal_reverse.current ? orders_by_total : orders_by_total_re)
            setTotalBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'type') {
            setOpenOrders(!normal_reverse.current ? orders_by_type : orders_by_type_re)
            setTypeBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
        else if (sortingType === 'date') {
            setOpenOrders(!normal_reverse.current ? orders_by_date : orders_by_date_re)
            setDateBtnStatus(normal_reverse.current ? '-up' : '-down')
        }
    }


    // Cancel Order
    function cencelOrder(event, id) {
        event.target.disabled = true

        const headers = new Headers({
            'Authorization': `Bearer ${tokenFromCookie.current}`,
            'Content-Type': 'application/json',
        })
        fetch(`${BASE_URL}/user/orders/${id}`, { method: 'DELETE', headers: headers })
            .then(res => res.json())
            .then(data => {
                event.target.disabled = false

                toast.info(data.message)

                getOrders()
            })
            .catch((err) => event.target.disabled = false)
    }
    //////////////////////////////////////////////////////


    ////////////////////////////////////////////////////// access_token cookie
    function getCookie(cookieName) {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1)
            }
        }
        return null
    }
    //////////////////////////////////////////////////////


    const tokenFromCookie = useRef()

    function getUserData() {
        const headers = new Headers({
            'Authorization': `Bearer ${tokenFromCookie.current}`,
            'Content-Type': 'application/json',
        })
        fetch(`${BASE_URL}/user/coin-pairs/${coin}_USDT/balance`, { method: 'GET', headers: headers })
            .then(res => res.json())
            .then(data => {
                setBase_balance(data.base_coin_balance)
                setQuote_balance(data.quote_coin_balance)
            })
        // .catch((err) => console.log(err))
    }


    useEffect(() => {
        createWebSocket()
        createWebSocket2(coin)

        fetch(BASE_URL+'/coins')
            .then((res) => res.json())
            .then((data) => {
                const filteredArray = data.filter(i => !['TOMAN', 'USDT'].includes(i.symbol))
                setAllCoins(filteredArray)

                coinsArrayRef.current = [
                    ...coinsArrayRef.current,
                    ...filteredArray.map((i) => ({ symbol: i.symbol, name: i.name })),
                ]
            })
        // .catch((err) => console.log(err))

        // document.cookie = 'access_token=104|jovFIAOw7Ld78sw8rrIp7QNbs5Uyhfl5wqqk07t0'
        tokenFromCookie.current = getCookie('access_token')
        setIsLogined(tokenFromCookie.current)
        if (tokenFromCookie.current) {
            getUserData()
            getOrders()
        }

        document.addEventListener('click', handleDocumentClick)
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])


    return (
        <HelmetProvider>
            <Helmet>
                <title>{user_lang === 'ar' ? 'تبدیل ارز' : 'Exchange'}</title>
            </Helmet>
            <Header />
            <div className="ex-container">
                <div className="ex-top">
                    <div className="ex-top-one">
                        <div className="ex-top-coin">
                            <div className="ex-selectbox">
                                <img src={coin_img} />
                                <div className="ex-select">
                                    <div className="ex-select-coin">
                                        <div className="ex-select-coin-top">{coin_symbol + '/USDT'}</div>
                                        <div className="ex-select-coin-bottom">{coin_name}</div>
                                    </div>
                                    <div className="ex-select-arrow" style={{ transform: isCoinSelectboxVisible ? 'rotate(180deg)' : '' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.52" height="9.318" viewBox="0 0 18.52 9.318"><path d="M1.322,18.518A1.32,1.32,0,0,1,.306,16.353L6.219,9.278.517,2.19A1.32,1.32,0,0,1,.715.329a1.32,1.32,0,0,1,1.927.2l6.375,7.92a1.32,1.32,0,0,1,0,1.676l-6.6,7.92A1.32,1.32,0,0,1,1.322,18.518Z" transform="translate(18.52) rotate(90)" /></svg>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="coin-selectbox"
                                style={{ display: isCoinSelectboxVisible ? 'flex' : 'none' }}
                            >
                                {allCoins && allCoins.map((item, index) => {
                                    return (
                                        <div className="coin-selectbox-option" key={index} onClick={() => selectboxChangeHandel(item.symbol)}>
                                            <img src={`https://api.moneyex.org/storage/images/coin-icons/${item.icon}`} />
                                            <div className="ex-select">
                                                <div className="ex-select-coin">
                                                    <div className="ex-select-coin-top">{item.symbol + '/USDT'}</div>
                                                    <div className="ex-select-coin-bottom">{item.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="ex-top-price">{coin_price}</div>
                    </div>
                    <div className="ex-top-two">
                        <div className="ex-top-price-mobile">{coin_price}</div>
                        <div>
                            <div className="ex-top-two-title">{t('تغییر در ۲۴ ساعت')}</div>
                            {coin_change ? (
                                coin_change > 0 ? (
                                    <div className="ex-top-two-num ex-top-two-num-changes up">{coin_change}%<i className='p-up'></i></div>
                                ) : (
                                    <div className="ex-top-two-num ex-top-two-num-changes down">{Math.abs(coin_change)}%<i className='p-down'></i></div>
                                )
                            ) : (
                                <span>...</span>
                            )}
                        </div>
                        <div>
                            <div className="ex-top-two-title">{t("بالاترین قیمت در ۲۴ ساعت")}</div>
                            <div className="ex-top-two-num">{coin_high}</div>
                        </div>
                        <div>
                            <div className="ex-top-two-title">{t("پایین‌ترین قیمت در ۲۴ ساعت")}</div>
                            <div className="ex-top-two-num">{coin_low}</div>
                        </div>
                        <div>
                            <div className="ex-top-two-title">{t("مقدار در ۲۴ ساعت")} ({coin})</div>
                            <div className="ex-top-two-num">{coin_amount}</div>
                        </div>
                        <div>
                            <div className="ex-top-two-title">{t("حجم در ۲۴ ساعت")} (USDT)</div>
                            <div className="ex-top-two-num">{coin_volume}</div>
                        </div>
                    </div>
                    <div className="ex-top-three">
                        {/* <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25.902" height="24.725" viewBox="0 0 25.902 24.725"><path d="M41.442,194.573,40,193.513l5.534-8.83,3.519,4.121,4.693-7.594,3.208,4.768a5.577,5.577,0,0,0-.912.191q-.442.132-.883.309l-1.325-2.031-4.49,7.329-3.575-4.15Zm23.194,6.152-3.944-3.944a4.916,4.916,0,0,1-1.339.677,4.759,4.759,0,0,1-1.486.235,5.014,5.014,0,1,1,5-5,4.5,4.5,0,0,1-.25,1.486,6.844,6.844,0,0,1-.662,1.382l3.944,3.9Zm-6.774-4.8a3.209,3.209,0,1,0-2.3-.942A3.136,3.136,0,0,0,57.862,195.927Zm2.182-9.654a3.674,3.674,0,0,0-.912-.235q-.471-.059-.971-.118L64.46,176l1.442,1.06Z" transform="translate(-40 -176)" /></svg>
                        </div> */}
                    </div>
                </div>

                <div className="ex-bottom">
                    <div className="ex-chart">
                        <div className="ex-chart-top">
                            <button className='active'>{t("چارت معاملات")}</button>
                        </div>
                        <iframe src={`https://s.tradingview.com/widgetembed/?frameElementId=tradingview_b3f1b&symbol=${coin}USDT&interval=D&hidesidetoolbar=0&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=${lightingMode}&style=1&timezone=Etc%2FUTC&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=mojbit.com&utm_medium=widget&utm_campaign=chart&utm_term=BTCUSDT#%7B%22page-uri%22%3A%22mojbit.com%2Fexchange%22%7D`} frameBorder="0" allowtransparency="true" scrolling="no" allowFullScreen=""></iframe>
                    </div>

                    <div className="ex-trading">
                        <div className="ex-tading-top">
                            <div className="ex-trading-top-left">{t('exchange.7')}</div>
                            <div className="ex-trading-top-right">
                                <button onClick={setBuySellFunc} className={!buySell ? 'active' : ''}>{t('exchange.8')}</button>
                                <button onClick={setBuySellFunc} className={buySell ? 'active' : ''}>{t('exchange.9')}</button>
                            </div>
                        </div>
                        <div className="ex-trading-middle">
                            <span onClick={() => setOrderTypeFunc('market')} className={orderType === 'market' ? 'active' : ''}>{t('exchange.10')}</span>
                            <span onClick={() => setOrderTypeFunc('limit')} className={orderType === 'limit' ? 'active' : ''}>{t('exchange.11')}</span>
                            <span onClick={() => setOrderTypeFunc('stopLimit')} className={orderType === 'stopLimit' ? 'active' : ''}>{t('exchange.12')}</span>
                        </div>
                        <div className="ex-tading-bottom">
                            <div className="ex-t-balance">
                                <span>{t('exchange.13')}</span>
                                {buySell && <span>{quote_balance && quote_balance.toLocaleString()} USDT</span>}
                                {!buySell && <span>{base_balance && base_balance.toLocaleString()} {coin}</span>}
                            </div>
                            <div className="ex-t-amount">
                                <div>
                                    <span>{t('exchange.14')}</span>
                                    <input type="text" placeholder='0'
                                        disabled={isInMarketType}
                                        onChange={buySell ? orderImputHandler : orderImputHandler_sell}
                                        value={!isInMarketType && buySell ? orderPrice : !isInMarketType && !buySell ? orderPrice_sell : ''}
                                    />
                                </div>
                                {buySell && orderType === 'stopLimit' &&
                                    <div>
                                        <span>{t('exchange.15')}</span>
                                        <input type="text" placeholder='0'
                                            onChange={stopLimitImputHandler}
                                            value={stopLimitPrice}
                                        />
                                    </div>
                                }
                                {!buySell && orderType === 'stopLimit' &&
                                    <div>
                                        <span>{t('exchange.15')}</span>
                                        <input type="text" placeholder='0'
                                            onChange={stopLimitImputHandler_sell}
                                            value={stopLimitPrice_sell}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="ex-t-number">
                                <span>{t('exchange.16')}</span>
                                {buySell &&
                                    <input type="text" placeholder='0'
                                        disabled={!socketData}
                                        onChange={isInMarketType ? quantityImputHandler : quantityLimitImputHandler}
                                        value={isInMarketType ? quantityValue : quantityLimitValue}
                                    />
                                }
                                {!buySell &&
                                    <input type="text" placeholder='0'
                                        disabled={!socketData}
                                        onChange={isInMarketType ? quantityImputHandler_sell : quantityLimitImputHandler_sell}
                                        value={isInMarketType ? quantityValue_sell : quantityLimitValue_sell}
                                    />
                                }
                                <span>{coin}</span>
                            </div>
                            <div className="ex-t-radio">
                                {isInMarketType && buySell &&
                                    <input
                                        type='range'
                                        min='0'
                                        max='100'
                                        value={rangeValue}
                                        onChange={rangeValueFunc}
                                        disabled={!socketData}
                                    />
                                }
                                {isInMarketType && !buySell &&
                                    <input
                                        type='range'
                                        min='0'
                                        max='100'
                                        value={rangeValue_sell}
                                        onChange={rangeValueFunc_sell}
                                        disabled={!socketData}
                                    />
                                }
                                {!isInMarketType && buySell &&
                                    <input
                                        type='range'
                                        min='0'
                                        max='100'
                                        value={rangeValueLimit}
                                        onChange={rangeValueFuncLimit}
                                        disabled={!socketData || !orderPrice || orderPrice == 0}
                                    />
                                }
                                {!isInMarketType && !buySell &&
                                    <input
                                        type='range'
                                        min='0'
                                        max='100'
                                        value={rangeValueLimit_sell}
                                        onChange={rangeValueFuncLimit_sell}
                                        disabled={!socketData || !orderPrice_sell || orderPrice_sell == 0}
                                    />
                                }
                                {buySell &&
                                    <>
                                        <span>{isInMarketType ? rangeValue : rangeValueLimit}%</span>
                                        <span>{t('exchange.17')}</span>
                                    </>
                                }
                                {!buySell &&
                                    <>
                                        <span>{isInMarketType ? rangeValue_sell : rangeValueLimit_sell}%</span>
                                        <span>{t('exchange.17')}</span>
                                    </>
                                }
                            </div>
                            <div className="ex-t-total">
                                <span>{t('exchange.18')}</span>
                                {buySell &&
                                    <span>{isInMarketType ? total.toLocaleString() : limitTotal.toLocaleString()} USDT</span>
                                }
                                {!buySell &&
                                    <span>{isInMarketType ? total_sell.toLocaleString() : limitTotal_sell.toLocaleString()} USDT</span>
                                }
                            </div>
                            <button
                                onClick={tradePost}
                                className={buySell ? 'ex-t-buynow' : 'ex-t-sellnow'}
                                disabled={
                                    buySell && orderType === 'market' ? !quantityValue || quantityValue == 0 :
                                        buySell && orderType === 'limit' ? !quantityLimitValue || quantityLimitValue == 0 || !orderPrice || orderPrice == 0 :
                                            buySell && orderType === 'stopLimit' ? !quantityLimitValue || quantityLimitValue == 0 || !orderPrice || orderPrice == 0 || !stopLimitPrice || stopLimitPrice == 0 :
                                                !buySell && orderType === 'market' ? !quantityValue_sell || quantityValue_sell == 0 :
                                                    !buySell && orderType === 'limit' ? !quantityLimitValue_sell || quantityLimitValue_sell == 0 || !orderPrice_sell || orderPrice_sell == 0 :
                                                        !quantityLimitValue_sell || quantityLimitValue_sell == 0 || !orderPrice_sell || orderPrice_sell == 0 || !stopLimitPrice_sell || stopLimitPrice_sell == 0
                                }
                            >{buySell ? t('exchange.34') : t('exchange.35')}</button>
                        </div>
                        {!isLogined &&
                            <div className="ex-trading-overlay">
                                <p>{t('exchange.19')}</p>
                                <div>
                                    <Link to={'/register'} className='ex-t-o-register'>{t('exchange.20')}</Link>
                                    <Link to={'/login'} className='ex-t-o-login'>{t('exchange.21')}</Link>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="ex-orders">
                        <div className="ex-o-top">
                            <div className="ex-o-top-left">
                                <span className='active'>{t('exchange.22')}</span>
                                {/* <span>Transaction</span> */}
                            </div>
                        </div>
                        <div className="ex-o-middle">
                            <div onClick={() => ordersSortHandler('symbol')}>
                                <span>{t('exchange.23')}</span>
                                <img src={require(`../image/price-socket/sort${symbolBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('price')}>
                                <span>{t('exchange.24')}</span>
                                <img src={require(`../image/price-socket/sort${priceBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('amount')}>
                                <span>{t('exchange.25')}</span>
                                <img src={require(`../image/price-socket/sort${amountBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('filled')}>
                                <span>{t('exchange.26')}</span>
                                <img src={require(`../image/price-socket/sort${filledBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('openAmount')}>
                                <span>{t('exchange.27')}</span>
                                <img src={require(`../image/price-socket/sort${openAmountBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('total')}>
                                <span>{t('exchange.28')}</span>
                                <img src={require(`../image/price-socket/sort${totalBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('type')}>
                                <span>{t('exchange.29')}</span>
                                <img src={require(`../image/price-socket/sort${typeBtnStatus}.png`)} />
                            </div>
                            <div onClick={() => ordersSortHandler('date')}>
                                <span>{t('exchange.30')}</span>
                                <img src={require(`../image/price-socket/sort${dateBtnStatus}.png`)} />
                            </div>
                            <div>
                                <span>{t('exchange.31')}</span>
                            </div>
                        </div>
                        <div className="ex-o-bottom">
                            {!showPairs &&
                                <div className="ex-o-empty">
                                    <img src={require('../image/price-socket/Empty@2x.png')} />
                                    <p>{t('exchange.32')}</p>
                                </div>
                            }

                            {showPairs &&
                                openOrders.map((item, index) => (
                                    <div className="ex-o-bottom-row" key={index}>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.23')}</span>
                                            <span>{item.base_coin}</span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.24')}</span>
                                            <span>{item.price}</span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.25')}</span>
                                            <span>{item.amount}</span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.26')}</span>
                                            <span>{item.exchanged}</span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.27')}</span>
                                            <span>{item.open_amount}</span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.28')}</span>
                                            <span>{item.total}</span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.29')}</span>
                                            {item.type === 'buy' ? <button>{t('exchange.9')}</button> : <button style={{ color: 'red' }}>{t('exchange.8')}</button>}
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.30')}</span>
                                            <span>
                                                {(() => {
                                                    const dateString = item.created_at
                                                    const dateParts = dateString.split("T")
                                                    const datePart = dateParts[0]
                                                    return datePart
                                                })()}
                                                <br />
                                                {(() => {
                                                    const dateString = item.created_at
                                                    const dateParts = dateString.split("T")
                                                    const timePart = dateParts[1].replace(".000000Z", "")
                                                    return timePart
                                                })()}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='ex-o-b-r-title'>{t('exchange.31')}</span>
                                            <button onClick={(event) => cencelOrder(event, item.id)}>{t('exchange.31')}</button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="ex-volumes">
                        <div className="ex-v-top">
                            {/* <button onClick={exVolumeSectionToggleFunc} className={!exVolumeSectionToggle ? 'active' : ''}>Recent Trades</button> */}
                            <button onClick={exVolumeSectionToggleFunc} className={exVolumeSectionToggle ? 'active' : ''}>{t('exchange.33')}</button>
                        </div>
                        {!exVolumeSectionToggle &&
                            <div className="ex-v-bottom">
                                <div className="ex-v-bottom-top">
                                    <span>Price</span>
                                    <span>Amount</span>
                                    <span>Time</span>
                                </div>
                                <div className="ex-v-bottom-bottom">
                                    <div className="ex-v-b-b-row">
                                        <span>23,191</span>
                                        <span>23,191</span>
                                        <span>6:36:12</span>
                                    </div>
                                    <div className="ex-v-b-b-row">
                                        <span>23,191</span>
                                        <span>23,191</span>
                                        <span>6:36:12</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {exVolumeSectionToggle &&
                            <>
                                {!socketData && <Loading />}
                                {socketData &&
                                    <div className="ex-v-bottom ex-v-bottom-2">
                                        <div className="ex-v-v-top">
                                            <div className="ex-v-v-t-bottom">
                                                <div className="ex-v-v-t-b-top">
                                                    <span>{t('exchange.24')}(USDT)</span>
                                                    <span>{t('exchange.25')}({coin})</span>
                                                    <span>{t('exchange.28')}</span>
                                                </div>
                                                <div className="ex-v-v-volumes">
                                                    {asks && asks.map((item, index) => (
                                                        <div key={index} className="ex-v-v-volumes-row" style={{ '--percent': item[2] + '%' }}>
                                                            <span>{item[0]}</span>
                                                            <span>{item[1]}</span>
                                                            <span>{(item[0] * item[1]).toFixed(2)}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ex-v-v-bottom">
                                            <div className="ex-v-v-b-top">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z" /></svg>
                                                <span>{average_asks_bids.toLocaleString()}</span>
                                            </div>
                                            <div className="ex-v-v-volumes">
                                                {bids && bids.map((item, index) => (
                                                    <div key={index} className="ex-v-v-volumes-row" style={{ '--percent': item[2] + '%' }}>
                                                        <span>{item[0]}</span>
                                                        <span>{item[1]}</span>
                                                        <span>{(item[0] * item[1]).toFixed(2)}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div >
            <Footer />
        </HelmetProvider>
    )
}

export default Exchange;
import React from "react";
import "./page504.css";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Page504() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.6")}</title>
          <meta name="title" content={t("metatag.1")} />
          <meta name="description" content={t("metatag.2")} />
        </Helmet>
      <Header />
      {/* page504-section-one */}
      <section id="page504-section-one">
        <div className="all">
          <div className="left">
            <img src={require("../image/504/Repair-image.png")} />
          </div>
          <div className="right">
            <div className="t1">   {t("page504.1")}</div>
            <div className="t2">  {t("page504.2")}</div>
            <p className="des">
            {t("page504.3")}
            </p>
            <p className="des">
            {t("page504.4")}
            </p>
          </div>
        </div>
        <div className="done">
          <div className="gmail"></div>
          <div className="number"></div>
        </div>
      </section>
      <Footer />
      </HelmetProvider>
    </React.Fragment>
  );
}

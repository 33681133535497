//internal
import { useState, useEffect } from 'react';

//external
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

//Component and Style and Image
import "./blog.css";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingCircle from "../../components/loading-circle/LoadingCircle";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";





const Blog = () => {
  const { t } = useTranslation();

  const baseURL = "https://api.moneyex.org";
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search)


  const [btnMode, setBtnMode] = useState("all");


  const [blogList, setBlogList] = useState([]);
  const [latest_posts, setLatest_posts] = useState([]);
  const [blogCount, setBlogCount] = useState();
  const [category_List, setCategory_List] = useState([]);

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [forceRender, setForceRender] = useState("");
  const [loading, setLoading] = useState(false);

  let paginate_Arr = [];
  for (let i = 0; i < totalPages; i++) {
    paginate_Arr[i] = i + 1;

  }



  const handleURL = (page_Number) => {
    setForceRender(Math.random())
    navigate(`${window.location.pathname}?page=${page_Number}`)
  }


  //گرفتن تمام بلاگ ها و دسته بندی ها
  const fetchAllBlogs = async () => {
    try {

      setLoading(true) //شروع لودینگ

      /////////////////////////////////////////////////////////////
      //همه پست ها
      const res_1 = await axios.get(`${baseURL}/blog`, { headers: { 'Content-Type': 'application/json' } });
      setBlogList(res_1.data.results)
      setBlogCount(res_1.data.total)
      setCurrentPage(res_1.data.current_page)
      setTotalPages(res_1.data.last_page)
      /////////////////////////////////////////////////////////////
      //همه دسته بندی ها
      const res_2 = await axios.get(`${baseURL}/blog/categories`, { headers: { 'Content-Type': 'application/json' } });
      // console.log(res_2.data)
      setCategory_List(res_2.data)
      /////////////////////////////////////////////////////////////

      setLoading(false) //پایان لودینگ

    } catch (error) {
      setLoading(false) //پایان لودینگ
    }
  }//end


  //گرفتن بلاگ ها با توجه به دسته بندی انتخاب شده توسط کاربر
  const fetchBy_CategoryMode = async () => {
    try {
      setLoading(true) //شروع لودینگ

      /////////////////////////////////////////////////////////////////////////
      const res_1 = await axios.get(`${baseURL}/blog/${btnMode}/posts`, { headers: { 'Content-Type': 'application/json' } });
      setBlogList(res_1.data.results)
      setBlogCount(res_1.data.total)
      setCurrentPage(res_1.data.current_page)
      setTotalPages(res_1.data.last_page)
      /////////////////////////////////////////////////////////////////////////

      setLoading(false) //پایان لودینگ
    } catch (error) {
      setLoading(false) //پایان لودینگ
    }
  }

  //Mounting
  useEffect(() => {
    fetchAllBlogs()

    const fetchLatest = async () => {
      try {
        /////////////////////////////////////////////////////////////
        //همه آخرین ها
        const res_1 = await axios.get(`${baseURL}/blog/latest-posts`, { headers: { 'Content-Type': 'application/json' } });
        setLatest_posts(res_1.data)

      } catch (error) {
      }
    }//end


    fetchLatest()
  }, [])

  //Updating  for دسته بندی
  useEffect(() => {

    if (btnMode === "all") {
      fetchAllBlogs()
    } else {
      fetchBy_CategoryMode()
    }
  }, [btnMode])


  //Updating for صفحه بندی
  useEffect(() => {

    const fetchAll_paginate = async () => {
      try {

        setLoading(true) //شروع لودینگ
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        const res_1 = await axios.get(`${baseURL}/blog?${params.toString()}`, { headers: { 'Content-Type': 'application/json' } });
        setBlogList(res_1.data.results)
        setCurrentPage(res_1.data.current_page)
        setTotalPages(res_1.data.last_page)
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        setLoading(false) //پایان لودینگ

      } catch (error) {
        setLoading(false) //پایان لودینگ

      }
    }
    const fetchByCategory_paginate = async () => {
      try {

        setLoading(true) //شروع لودینگ
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        const res_1 = await axios.get(`${baseURL}/blog/${btnMode}/posts?${params.toString()}`, { headers: { 'Content-Type': 'application/json' } });
        setBlogList(res_1.data.results)
        setCurrentPage(res_1.data.current_page)
        setTotalPages(res_1.data.last_page)
        ///////////////////////////////////////////////////////////////////////////////////////////////////
        setLoading(false) //پایان لودینگ

      } catch (error) {
        setLoading(false) //پایان لودینگ

      }
    }

    if (btnMode === "all") {
      fetchAll_paginate()
    } else {
      fetchByCategory_paginate()
    }

  }, [forceRender])






  return (
    <HelmetProvider>
      <Helmet>
        <title>بلاگ</title>
        <meta name="title" content={t("metatag.1")} />
        <meta name="description" content={t("metatag.2")} />
      </Helmet>
      <div id='blog'>
        <Header />
        <main>
          <h1 className='text-center font-bold text-4xl my-7'>وبلاگ های مانی‌اکســ</h1>
          <h2 className='text-center mb-4'>اخبار، رویدادها و اتفاقات مهم مربوط به دنیای ارزهای دیجیتال</h2>
          {/* اسلایدر */}
          <div className="blog-slider mx-auto max-md:flex-col-reverse">
            <div className="slider_desc">
              <div>

                <h4 className='font-bold text-xl mb-4 text-orange-500'>اخبار مانی‌اکســ</h4>
                <span className='block font-bold text-2xl mb-5' >{latest_posts.length > 0 ? latest_posts[0].title : ""}</span>
                <p className='mb-5 leading-6'>{latest_posts.length > 0 ? latest_posts[0].summary : ""}</p>
              </div>



              <div className='flexBetween'>
                <Link target='_blank' to={latest_posts[0] && '/post-blog/' + latest_posts[0].id} className='text-orange-500'>مشاهده کامل</Link>
                <span>{latest_posts.length > 0 ? latest_posts[0].created_at : ""}</span>
              </div>


            </div>
            <figure>
              <img src={latest_posts[0] && latest_posts[0].image} alt="" />
            </figure>

          </div>
          {/* دو قلو */}
          <div className='blog-twin mx-auto'>
            <section className='article_item relative flex flex-col'>
              <figure className='mb-2'>
                <img src={latest_posts.length > 0 ? latest_posts[1].image : ""} className='w-full h-[197px]' />
              </figure>
              <div className='px-3 flex flex-col justify-between grow'>
                <div>
                  <h4 className='font-bold mb-2'>{latest_posts.length > 0 ? latest_posts[1].title : ""}</h4>
                  <p className='mb-3 text-sm'>{latest_posts.length > 0 ? latest_posts[1].summary : ""}</p>
                </div>
                <div className='flexBetween w-full px-2' >
                  <Link to={latest_posts[1] && `/post-blog/${latest_posts[1].id}`} target='_blank' className='text-orange-500'>مشاهده کامل</Link>
                  <span>{latest_posts.length > 0 ? latest_posts[1].created_at : ""}</span>
                </div>
              </div>
            </section>
            <section className='article_item relative'>
              <figure className='mb-2'>
                <img src={latest_posts.length > 0 ? latest_posts[2].image : ""} className='w-full h-[197px]' />
              </figure>
              <div className='px-3 flex flex-col justify-between grow'>
                <div>
                  <h4 className='font-bold mb-2'>{latest_posts.length > 0 ? latest_posts[2].title : ""}</h4>
                  <p className='mb-3 text-sm'>{latest_posts.length > 0 ? latest_posts[2].summary : ""}</p>
                </div>
                <div className='flexBetween w-full px-2' >
                  <Link to={latest_posts[2] && `/post-blog/${latest_posts[2].id}`} target='_blank' className='text-orange-500'>مشاهده کامل</Link>
                  <span>{latest_posts.length > 0 ? latest_posts[2].created_at : ""}</span>
                </div>
              </div>
            </section>


          </div>
          {/* لیست مقالات */}
          <div className='parent_article_list flex justify-center mb-10 '>
            {/* بک گراند */}
            <div className='bg-gray'></div>
            {/* محتوا */}
            <div className="content">
              {/* دسته بندی ها */}
              <div className='categories mb-7'>
                <span className='flexBetween my-5 text-white'>
                  <span>دسته بندی ها</span>
                  <span>{blogCount} مقاله</span>
                </span>
                <ul className='flex items-center gap-x-4 mhd overflow-x-auto'>
                  <li onClick={() => setBtnMode("all")}
                    className={btnMode === "all" && "activeBtn"}
                  >all</li>
                  {
                    category_List?.map(ctg => (
                      <li onClick={() => setBtnMode(ctg.slug)}
                        className={(btnMode === ctg.slug) && "activeBtn"}
                      >
                        {ctg.slug}
                      </li>
                    ))
                  }
                </ul>
              </div>
              {/* لیست */}
              <div className="article_list">
                {loading ? <LoadingCircle /> :
                  blogList?.map((item) => (
                    <section className='article_item relative' key={item.id}>
                      <figure className='mb-2'>
                        <img src={item.image} className='w-full h-[197px]' />
                      </figure>
                      <div className='px-3'>
                        <div>
                          <h4 className='font-bold mb-2'>{item.title}</h4>
                          <p className='mb-3 text-sm'>{`${item.summary.slice(0, 60)}...`}</p>
                        </div>
                        <div className='flexBetween w-full px-2' >
                          <Link to={`/post-blog/${item.id}`} target='_blank' className='text-orange-500'>مشاهده کامل</Link>
                          <span>{item.created_at}</span>
                        </div>
                      </div>
                    </section>
                  ))
                }
              </div>
              {/* صفحه بندی */}
              {
                !loading && totalPages && totalPages > 1 &&
                (
                  <div className="flexCenter w-full mt-5">
                    <ul className="paginate" >
                      {
                        paginate_Arr.map((pageNumber) => (
                          <li onClick={() => handleURL(pageNumber)} key={pageNumber}
                            className={pageNumber === currentPage ? "active-paginate " : ""}>
                            {pageNumber}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                )
              }




            </div>
          </div>
          {/*  */}
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default Blog
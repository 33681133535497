//internal
import { useState, useEffect } from "react";
//external
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style and Image
import "./lightingMode.css";
import "./verification.css";
import mySrc from "../../../assests/image/Phone-code.png";
import Login_Left from "../login/Login_Left";
import { toast } from "react-toastify";

import { BASE_URL } from '../../../utils/url'

const Verification_Login = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [key, setKey] = useState("");
    const [timer, setTimer] = useState(120);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setCanResend(true);
        }
    }, [timer]);

    const handleResendOTP = async (e) => {
        e.preventDefault();
        e.target.disabled = true;

        try {
            const response = await fetch(BASE_URL + '/resend-otp', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('access_token')}`,
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (data.status === 'success') {
                toast.success('پیامک حاوی کد ارسال شد');
                setTimer(120); // Reset timer
                setCanResend(false);
            } else {
                if (data.message) {
                    toast.error(data.message);
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred. Please try again.');
        } finally {
            e.target.disabled = false;
        }
    }

    async function handleSubmitForm(e) {
        e.preventDefault();
        e.target.disabled = true;

        try {
            const response = await fetch(BASE_URL + '/login-otp', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('access_token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ otp: key })
            });

            const data = await response.json();

            if (data.success) {
                if (Cookies.get("user_phone")) {
                    Cookies.remove("user_phone");
                }
                navigate("/dashboard");
            } else {
                if (data.message) {
                    toast.error(data.message);
                }
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setKey("");
            e.target.disabled = false;
        }
    }

    return (
        <HelmetProvider>
            <div className="grand-parent" id="verification">
                <Helmet>
                    <title>{t("title_verify")}</title>
                </Helmet>
                <div className="verification-parent" >
                    {/* right */}
                    <section className="verification-right">

                        <div className="back">
                            <Link to={"/login"}>
                                <span>
                                    {(Cookies.get("primary_dir") === "ltr") ? <FaArrowLeft /> : <FaArrowRight />}
                                </span>
                            </Link>
                        </div>


                        <figure className="flexCenter">
                            <img src={mySrc} alt="" />
                        </figure>

                        <h1>{t("verification_1")}</h1>
                        {(Cookies.get("primary_dir") === "rtl") ?
                            (
                                <p>
                                    <span className="block">کاربر گرامی یک پیامک حاوی کد تایید 6 رقمی به</span>
                                    {" "}
                                    <span>شماره موبایل</span>
                                    {" "}
                                    <span style={{ color: "#f6b33f" }}>{Cookies.get("user_phone")}</span>
                                    {" "}
                                    <span>ارسال شده است.</span>
                                </p>
                            )
                            :
                            (
                                <p>
                                    <span>Dear user, an SMS containing a 6-digit confirmation code has been sent to mobile number</span>
                                    {" "}
                                    <span style={{ color: "#f6b33f" }}>{Cookies.get("user_phone")}</span>

                                </p>
                            )
                        }

                        {/*  form  */}
                        <form>
                            <div className="mini-parent">
                                <label htmlFor="otp">{t("verification_2")}</label>
                                <input
                                    id="otp"
                                    className="p-2"
                                    type="text"
                                    value={key}
                                    onChange={(e) => setKey(e.target.value)} />
                            </div>

                            <div className="submit-form">
                                <button type="submit" disabled={key.length !== 6} onClick={handleSubmitForm}>{t("verification_3")}</button>
                            </div>
                        </form>

                        <div className="flex items-center justify-center min-h-[40px] -translate-y-1">
                            {canResend ? (
                                <button className="bg-[--light-yellow-1] text-white p-2 px-5 rounded-[10px]" onClick={handleResendOTP}>ارسال مجدد</button>
                            ) : (
                                <p className="!text-gray-300">ارسال مجدد در {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}</p>
                            )}
                        </div>

                    </section>

                    {/* left */}
                    <Login_Left />
                </div>
            </div>
        </HelmetProvider>
    )
}

export default Verification_Login;
import React, { useState, useEffect, useRef, useContext } from "react";
import Main_Sidebar from "../components/sidebar/Main_Sidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DatePicker, { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import "./setting.css";
// import Unsuccessful from "../components/allert/Unsuccessful";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import Nav_MobileTablet from "../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../components/fixed-mobile-links/Fixed_Mobile_Links";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LightingMode from "../components/lighting-mode/LightingMode";
import { toast } from "react-toastify";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { MainContext } from "../contexts/MainContexts";
import Nav_Desktop from "../components/nav-desktop/Nav_Desktop";
import NoData from "../components/no-data/NoData";
import LoadingCircle from "../components/loading-circle/LoadingCircle";
import { BASE_URL } from '../utils/url'
import moment from "moment-jalaali";

// table
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// end table
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#F6B33F",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Setting() {
  const { primary_dir, reverse_dir, lightingMode, user_lang } = useContext(MainContext);
  const [apiData, setApiData] = useState([0]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response_deposits_data2 = await axios.get(
          BASE_URL + "/user/bank-accounts",
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        setApiData(response_deposits_data2.data.data);
        setLoading(false);
      } catch (error) {}
    };
    fetchBlog();
  }, []);
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);

  const [userWallets, setUserWallets] = useState({});
  const [userWallets1, setUserWallets1] = useState({});
  const [name, setName] = useState();
  const [code, setCode] = useState();
  const [number, setNumber] = useState();
  const [birthday, setBirthday] = useState();
  const birthday_in_shamsi = birthday && moment(birthday, 'YYYY-MM-DD').format('jYYYY/jMM/jDD') || ''

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          `${BASE_URL}/user/preference`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserWallets(response_1.data.data);
      } catch (error) {}
    }; //end of method
    fetchData();
  }, []);
  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_1 = await axios.get(
          `${BASE_URL}/user/profile`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setUserWallets1(response_1.data.profile);
        // setTotalPages(response_1.data.meta.last_page);
        // setCurrentPage(response_1.data.meta.current_page);
        setName(
          response_1.data.profile.first_name +
            " " +
            response_1.data.profile.last_name
        );
        setCode(response_1.data.profile.national_id);
        setNumber(response_1.data.mobile);
        setBirthday(response_1.data.profile.birthday);
      } catch (error) {}
    }; //end of method
    fetchData();
  }, []);

  //
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const [formData, setFormData] = useState({
    password: null,
    new_password: null,
    new_password_confirmation: null,
  });
  const handlePutRequest = (formData) => {
    axios
      .put(BASE_URL + "/user/password/update", formData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token")}`,
          // "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response:", response);
        toast.success(response.data.status, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        // انجام اعمال لازم بعد از موفقیت در ارسال درخواست PUT
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.response.data.errors.new_password[0], {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        // انجام اعمال لازم بعد از بروز خطا در ارسال درخواست PUT
      });
  };
  const [borderError, setBorderError] = useState(false);
  const handleButtonClick = () => {
    // const inputValue = inputRef.current.value;
    // const inputValue1 = inputRef1.current.value;
    // const inputValue2 = inputRef2.current.value;
    // setFormData({
    //   password:inputValue,
    //   new_password:inputValue1,
    //   new_password_confirmation:inputValue2,
    // });
    // const handlePutRequest = () => {
    //   axios.put(BASE_URL+'/user/password/update', formData, {
    //     headers: {
    //       Authorization: `Bearer ${Cookies.get("access_token")}`,
    //       // "Content-Type": "application/json",
    //     },
    //   })
    //     .then(response => {
    //       console.log('Response:', response);
    //       // انجام اعمال لازم بعد از موفقیت در ارسال درخواست PUT
    //     })
    //     .catch(error => {
    //       console.error('Error:', error);
    //       // انجام اعمال لازم بعد از بروز خطا در ارسال درخواست PUT
    //     });

    // };
    // handlePutRequest()
    const inputValue = inputRef.current.value;
    const inputValue1 = inputRef1.current.value;
    const inputValue2 = inputRef2.current.value;
    setFormData({
      password: inputValue,
      new_password: inputValue1,
      new_password_confirmation: inputValue2,
    });

    // فراخوانی تابع handlePutRequest با مقادیر جدید formData
    if (inputValue1 === inputValue2) {
      handlePutRequest({
        password: inputValue,
        new_password: inputValue1,
        new_password_confirmation: inputValue2,
      });
      inputRef.current.value = null;
      inputRef1.current.value = null;
      inputRef2.current.value = null;
      setBorderError(false);
    } else {
      setBorderError(true);
    }
  };

  //   console.log(formData)
  //   useEffect(() => {
  //     handlePutRequest()

  // }, [/* متغیرهای وابسته */]);
  const [inputType, setInputType] = useState("password");
  const [inputType1, setInputType1] = useState("password");
  const [inputType2, setInputType2] = useState("password");

  const toggleInputType = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const toggleInputType1 = () => {
    if (inputType1 === "password") {
      setInputType1("text");
    } else {
      setInputType1("password");
    }
  };

  const toggleInputType2 = () => {
    if (inputType2 === "password") {
      setInputType2("text");
    } else {
      setInputType2("password");
    }
  };
  const [showm1, setShowM1] = useState(false);
  function show1() {
    setShowM1(true);
  }
  function disable1() {
    setShowM1(false);
  }
  //
  const inputRef00 = useRef(null);
  const inputRef0 = useRef(null);

  const [formData0, setFormData0] = useState({
    bank_name: null,
    card_number: null,
  });
  const handlePutRequest0 = (formData0) => {
    axios
      .post(BASE_URL + "/user/bank-accounts", formData0, {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token")}`,
          // "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response:", response);
        toast.success(response.data.status, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        // انجام اعمال لازم بعد از موفقیت در ارسال درخواست PUT
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.response.data.errors.new_password[0], {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        // انجام اعمال لازم بعد از بروز خطا در ارسال درخواست PUT
      });
  };

  const [error, setError] = useState("");
  const handleButtonClick0 = () => {
    const inputValue0 = inputRef0.current.value;
    const inputValue00 = inputRef00.current.value;
    setFormData0({
      bank_name: inputValue00,
      card_number: inputValue0,
    });
    if (inputValue0.length === 16) {
      handlePutRequest0({
        bank_name: inputValue00,
        card_number: inputValue0,
      });
      inputRef00.current.value = null;
      inputRef0.current.value = null;
      setError(null);
    } else {
      setError(t("eror.2"));
    }
  };
  const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
  const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
  const [desktop_Notifications, setDesktop_Notifications] = useState(false);
  const [loading, setLoading] = useState(true);
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{t("title.4")}</title>
        </Helmet>

        {showm1 && (
          <div className="module-1">
            <div className="all">
              <div className="top">
                <div className="right">
                  <img
                    src={require("../assests/image/dashbord/credit_card_FILL0_wght400_GRAD0_opsz48.png")}
                  />
                  <div className="text">{t("dashbord.setting.23")}</div>
                </div>
                <div className="left" onClick={disable1}>
                  <img
                    src={require("../assests/image/dashbord/close_FILL0_wght400_GRAD0_opsz48 (4).png")}
                  />
                  <div className="text">{t("dashbord.transaction.2")}</div>
                </div>
              </div>
              <div className="all-grid">
                <div className="grid-item">
                  <div className="carts">
                    <div className="label">{t("dashbord.setting.18")}</div>
                    <input type="text" ref={inputRef00} />
                  </div>
                </div>
                <div className="grid-item">
                  <div className="carts">
                    <div className="label">{t("dashbord.setting.19")}</div>
                    <input type="number" ref={inputRef0} />
                  </div>
                  {error && <div style={{ color: "red" }}>{error}</div>}
                </div>
              </div>
              <button className="add-cart" onClick={handleButtonClick0}>
                {t("dashbord.setting.23")}
              </button>
            </div>
          </div>
        )}
        <Nav_MobileTablet
          mobileRight_DarkScreen={mobileRight_DarkScreen}
          mobileLeft_DarkScreen={mobileLeft_DarkScreen}
          tablet_DarkScreen={tablet_DarkScreen}
          tablet_ProfileMenu={tablet_ProfileMenu}
          tablet_Notifications={tablet_Notifications}
          setMobileRight_DarkScreen={setMobileRight_DarkScreen}
          setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
          setTablet_DarkScreen={setTablet_DarkScreen}
          setTablet_ProfileMenu={setTablet_ProfileMenu}
          setTablet_Notifications={setTablet_Notifications}
        />
        <div id="everythings">
          {/* <Unsuccessful /> */}

          {/* side bar */}
          <Main_Sidebar />
          <section id="all" className="setting">
            <Nav_Desktop
              desktop_ProfileMenu={desktop_ProfileMenu}
              desktop_Notifications={desktop_Notifications}
              desktop_ThreeDots={desktop_ThreeDots}
              setDesktop_Notifications={setDesktop_Notifications}
              setDesktop_ThreeDots={setDesktop_ThreeDots}
              setDesktop_ProfileMenu={setDesktop_ProfileMenu}
            />
            <div className="top">
              <div className="tabs">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={
                        <span>
                          {" "}
                          <img
                            src={require("../assests/image/dashbord/person_FILL0_wght400_GRAD0_opsz48 (2).png")}
                          />{" "}
                          {t("dashbord.setting.1")}
                        </span>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <span>
                          {" "}
                          <img
                            src={require("../assests/image/dashbord/credit_card_FILL0_wght400_GRAD0_opsz48.png")}
                          />{" "}
                          {t("dashbord.setting.2")}
                        </span>
                      }
                      {...a11yProps(1)}
                    />
                    {/* <Tab
                      label={
                        <span>
                          {" "}
                          <img
                            src={require("../assests/image/dashbord/badge_FILL0_wght400_GRAD0_opsz48.png")}
                          />{" "}
                          {t("dashbord.setting.3")}
                        </span>
                      }
                      {...a11yProps(2)}
                    /> */}
                    {/* <Tab
                      label={
                        <span>
                          {" "}
                          <img
                            src={require("../assests/image/dashbord/lock_FILL0_wght400_GRAD0_opsz48.png")}
                          />{" "}
                          {t("dashbord.setting.4")}
                        </span>
                      }
                      {...a11yProps(3)}
                    /> */}
                    {/* <Tab
                      label={
                        <span>
                          {" "}
                          <img
                            src={require("../assests/image/dashbord/notifications_active_FILL0_wght400_GRAD0_opsz48 (3).png")}
                          />{" "}
                          {t("dashbord.setting.5")}
                        </span>
                      }
                      {...a11yProps(4)}
                    /> */}
                    <Tab
                      label={
                        <span>
                          {" "}
                          <img
                            src={require("../assests/image/dashbord/tune_FILL0_wght400_GRAD0_opsz48 (2).png")}
                          />{" "}
                          {t("dashbord.setting.6")}
                        </span>
                      }
                      {...a11yProps(5)}
                    />
                  </Tabs>
                </Box>
              </div>
            </div>
            <div className="body">
              <CustomTabPanel value={value} index={0}>
                <section id="information">
                  <div className="first-inputs">
                    <div className="name">
                      <label> {t("dashbord.setting.7")} </label>
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder={name}
                          disabled
                          className="!border-none"
                        />
                        {/* <img
                          src={require("../assests/image/dashbord/svgexport-23 (1).png")}
                        /> */}
                      </div>
                    </div>
                    <div className="code-meli">
                      <label> {t("dashbord.setting.9")} </label>
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder={code}
                          disabled
                          className="!border-none"
                        />
                        {/* <img
                          src={require("../assests/image/dashbord/svgexport-23 (1).png")}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="first-inputs" style={{ marginTop: "25px" }}>
                    <div className="name">
                      <label> {t("dashbord.setting.12")} </label>
                      <input
                        type="text"
                        className="input-box"
                        placeholder={number}
                        style={user_lang === 'ar' ? { width: "100%", direction: 'ltr', textAlign: 'right' } : { width: "100%" }}
                        disabled
                      />
                    </div>
                    <div className="code-meli">
                      <label> {t("dashbord.setting.10")}</label>
                      <input
                        type="text"
                        className="input-box"
                        placeholder={birthday_in_shamsi}
                        style={user_lang === 'ar' ? { width: "100%", direction: 'ltr', textAlign: 'right' } : { width: "100%" }}
                        disabled
                      />
                    </div>
                  </div>
                </section>
                {/* <button className="add-account">
                  {t("dashbord.setting.17")}
                </button> */}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <table>
                  <tr>
                    <th>{t("dashbord.setting.18")}</th>
                    <th>{t("dashbord.setting.19")}</th>
                    {/* <th>{t("dashbord.setting.20")}</th> */}
                    <th>{t("dashbord.setting.21")}</th>
                    <th>{t("dashbord.setting.22")}</th>
                    <th></th>
                  </tr>

                  {!loading && 
                    <>
                      {apiData && apiData.length > 0 ? (
                        
                        <tbody>{
                        apiData.map((item) => {
                          return(
                          <tr key={item.bank_name}>
                            <td>{item.bank_name}</td>
                            <td>{item.card_number}</td>
                            <td>{item.iban}</td>
                            <td>
                              <div className="box">{item.is_verified}</div>
                            </td>
                            <td>
                              <img
                                src={require("../assests/image/dashbord/delete_FILL0_wght400_GRAD0_opsz48.png")}
                              />
                            </td>
                          </tr>
                       );
                      })}
                       </tbody>) : (
                        
                        <tr>
                          <td colSpan="4">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                style={{ width: "200.6px" }}
                                src={require("../assests/image/dashbord/Empty.png")}
                              />
                              <div className="text">{t("nothing.1")}</div>
                            </div>
                          </td>
                        </tr>
                     
                      )}
                    </>
                  }
                </table>
                {loading && <LoadingCircle />}
                <button className="add-account" onClick={show1}>
                  <img
                    src={require("../assests/image/dashbord/add_card_FILL0_wght400_GRAD0_opsz48.png")}
                  />
                  <div className="text">{t("dashbord.setting.23")}</div>
                </button>
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={2}>
                <div className="a-h">
                  <div className="grid-item">
                    <div className="carts">
                      <img src={require("../assests/image/dashbord/22.png")} />
                      <div className="title">{t("dashbord.setting.24")}</div>
                      <p className="des">{t("dashbord.setting.25")}</p>
                      <div
                        className="state"
                        style={{
                          border: "1px solid #00C0AF",
                          color: "#00C0AF",
                        }}
                      >
                        <img
                          src={require("../assests/image/dashbord/svgexport-23 (1).png")}
                        />
                        <div className="text">{t("dashbord.setting.26")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="carts">
                      <img src={require("../assests/image/dashbord/21.png")} />
                      <div className="title">{t("dashbord.setting.27")}</div>
                      <p className="des">{t("dashbord.setting.28")}</p>
                      <div className="state">{t("dashbord.setting.29")}</div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="carts">
                      <img src={require("../assests/image/dashbord/2.png")} />
                      <div className="title">{t("dashbord.setting.30")}</div>
                      <p className="des">{t("dashbord.setting.31")}</p>
                      <div
                        className="state"
                        style={{
                          border: "1px solid #F6B33F",
                          color: "#F6B33F",
                        }}
                      >
                        {t("dashbord.setting.32")}
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel> */}
              <CustomTabPanel value={value} index={2}>
                <div className="security">
                  <div className="change-password">
                    <div className="title">{t("dashbord.setting.33")}</div>
                    <div className="grids">
                      <div className="grid-items">
                        <div className="carts">
                          <label>{t("dashbord.setting.34")}</label>
                          <div className="input-eyes">
                            <input type={inputType2} ref={inputRef} />

                            <span
                              onClick={toggleInputType2}
                              className="eye cursor-pointer text-2xl"
                              style={
                                primary_dir === "rtl"
                                  ? { left: "7px" }
                                  : { right: "7px" }
                              }
                            >
                              {inputType === "password" ? (
                                <IoEyeOutline />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="grid-items">
                        <div className="carts">
                          <label> {t("dashbord.setting.35")}</label>
                          <div className="input-eyes">
                            <input type={inputType1} ref={inputRef1} />
                            <span
                              onClick={toggleInputType1}
                              className="eye cursor-pointer text-2xl"
                              style={
                                primary_dir === "rtl"
                                  ? { left: "7px" }
                                  : { right: "7px" }
                              }
                            >
                              {inputType === "password" ? (
                                <IoEyeOutline />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="grid-items">
                        <div className="carts">
                          <label>{t("dashbord.setting.36")}</label>
                          <div className="input-eyes">
                            <input type={inputType} ref={inputRef2} />
                            <span
                              onClick={toggleInputType}
                              className="eye cursor-pointer text-2xl"
                              style={
                                primary_dir === "rtl"
                                  ? { left: "7px" }
                                  : { right: "7px" }
                              }
                            >
                              {inputType === "password" ? (
                                <IoEyeOutline />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {borderError && (
                        <p
                          style={{
                            color: "red",
                            fontFamily: "shabnam",
                            paddingTop: "15px",
                          }}
                        >
                          {t("eror.1")}
                        </p>
                      )}
                    </div>
                    <button className="add-account" onClick={handleButtonClick}>
                      <div className="text">{t("dashbord.setting.17")}</div>
                    </button>
                  </div>
                </div>
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={4}>
                <div className="notifications">
                  <div className="divs">
                    <div className="pile">
                      <div className="text">{t("dashbord.setting.42")}</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        />
                      </FormGroup>
                    </div>
                    <div className="pile">
                      <div className="text">{t("dashbord.setting.43")}</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                        />
                      </FormGroup>
                    </div>
                    <div className="pile">
                      <div className="text">{t("dashbord.setting.44")}</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        />
                      </FormGroup>
                    </div>
                    <div className="pile">
                      <div className="text"> {t("dashbord.setting.45")}</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        />
                      </FormGroup>
                    </div>
                    <div className="pile">
                      <div className="text"> {t("dashbord.setting.46")}</div>
                      <FormGroup>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </CustomTabPanel> */}
              <CustomTabPanel value={value} index={5}>
                <div className="setting">
                  <div className="all">
                    <div className="grid-items">
                      <div className="carts">
                        <div className="title">{t("dashbord.setting.47")}</div>
                        <select>
                          <option>
                            {userWallets && userWallets.default_coin_pair}
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="grid-items">
                    <div className="carts">
                      <div className="title">{t("dashbord.setting.48")}</div>
                      <select>
                        <option>{t("dashbord.setting.49")}</option>
                        <option>{t("dashbord.setting.50")}</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="grid-items"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="carts">
                      <div className="ini">
                        <div className="text">
                          <img
                            src={require("../assests/image/dashbord/dark_mode_FILL0_wght400_GRAD0_opsz48.png")}
                          />
                          <span>{t("dashbord.setting.51")}</span>
                        </div>
                        <FormGroup>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </CustomTabPanel>
            </div>
          </section>
          <Fixed_Mobile_Links />
          <LightingMode bottom="20px" left="30px" />
        </div>
      </HelmetProvider>
    </React.Fragment>
  );
}

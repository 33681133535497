//internal
import { useState, useContext, useEffect } from "react";

//external
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/MainContexts";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TfiUpload, TfiDownload } from "react-icons/tfi";
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Component and Style
import "./lightMode.css";
import "./wallet-rial-withdraw.css"
import Main_Sidebar from "../../components/sidebar/Main_Sidebar";
import Nav_Desktop from "../../components/nav-desktop/Nav_Desktop";
import Nav_MobileTablet from "../../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../../components/fixed-mobile-links/Fixed_Mobile_Links";
import LightingMode from "../../components/lighting-mode/LightingMode";

//Image
import logo from "../../image/logo/_company_logo.png";
import irr from "../../assests/image/wallet/__TOMAN.png"

import { BASE_URL } from '../../utils/url'



const Wallet_Rial_Withdraw = () => {

    const baseURL = BASE_URL

    const { t } = useTranslation();
    const { lightingMode, primary_dir } = useContext(MainContext)
    const navigate = useNavigate()

    const selected_Coin = JSON.parse(Cookies.get("selected_Coin"))
    // console.log(selected_Coin)


    //desktop popup
    const [desktop_ProfileMenu, setDesktop_ProfileMenu] = useState(false);
    const [desktop_ThreeDots, setDesktop_ThreeDots] = useState(false);
    const [desktop_Notifications, setDesktop_Notifications] = useState(false);
    //mobile popup dark_screen
    const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
    const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
    //tablet popup dark_screen
    const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
    const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
    const [tablet_Notifications, setTablet_Notifications] = useState(false);


    //method
    const closeWindowForDesktop = () => {
        if (desktop_ProfileMenu == true) { setDesktop_ProfileMenu(false) }
        if (desktop_ThreeDots == true) { setDesktop_ThreeDots(false) }
        // if (desktop_Notifications == true) { setDesktop_Notifications(false) }
        // if (tablet_Notifications == true) { setTablet_Notifications(false) }
        if (tablet_ProfileMenu == true) { setTablet_ProfileMenu(false) }
    }//end of method

    //POST
    const [amount, setAmount] = useState("");
    const [bankId, setBankId] = useState("");
    //GET LIST active_BankAccount  لیست
    const [active_BankAccount, setActive_BankAccount] = useState([]);

    //////////////////////////////////////////////
    //mounting
    useEffect(() => {

        //اگر توکن نبود
        if (!Cookies.get("access_token")) {
            navigate("/judge", { replace: true })
        }

        //BankAccount
        //GET User Active Bank Accounts

        const fetchData = async () => {
            try {
                const response_1 = await axios.get(`${baseURL}/user/bank-accounts/actives`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get("access_token")}`,
                        'Content-Type': 'application/json'
                    }
                });
                // console.log("--useEffect data---")
                // console.log(response_1.data.data)

                setActive_BankAccount(response_1.data.data)
                setBankId(response_1.data.data[0].id)

            } catch (err) {
                if(err.response && err.response.data.message==="Unauthenticated."){
                    Cookies.remove("access_token")
                    navigate("/judge", { replace: true })
                   }
            }
        }//end of method
        fetchData();
    }, [])

    /////////////////////////////////////////////////////////

    // console.log(bankId)
    //ارسال فرم
    const handleSubmitForm = async (e) => {
        e.preventDefault();


        //Wallet
        //POST Withdraw-fiat
        // console.log( JSON.stringify({
        //         amount,
        //         bank_account_id:bankId
        //        }))
        try {
            const res = await axios.post(`${baseURL}/user/wallets/${selected_Coin.id}/withdrawals`,
                JSON.stringify(
                    {
                        amount,
                        bank_account_id: bankId
                    }),
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get("access_token")}`,
                        'Content-Type': 'application/json'
                    }
                })

            // console.log(res)
            if (res.status === 200) {
                setAmount("")
                toast.success(res.data.status, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",

                });
                navigate("/wallet")

            }
        }
        catch (err) {
            if (err.response.data.errors.amount[0]) {

                toast.error(err.response.data.errors.amount[0], {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
            // console.log(err.response.data.errors.bank_account_id)
            if (err.response.data.errors.bank_account_id) {

                toast.error(err.response.data.errors.bank_account_id[0], {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }


        }//end err

    }//end


    const params = new URLSearchParams(window.location.search)
    const [userWallets, setUserWallets] = useState('')
    const location = useLocation();
    const parts = location.pathname.split('/');
    const lastPart = parts[parts.length - 1];
    const thisCoin = userWallets && userWallets.find(item => item.coin_symbol === lastPart)
    const depositPosibility = thisCoin && thisCoin.deposit_status
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response_1 = await axios.get(`${baseURL}/user/wallets?${params.toString()}`, {
              headers: {
                'Authorization': `Bearer ${Cookies.get("access_token")}`,
                'Content-Type': 'application/json'
              }
            });
            setUserWallets(response_1.data.data)
          } catch (err) {
            if (err.response.data.message === "Unauthenticated.") {
              Cookies.remove("access_token")
              navigate("/judge", { replace: true })
            }
          }
        }//end of method
        fetchData();
    }, [])




    return (
        <HelmetProvider>
            <div id="wallet-rial" onClick={closeWindowForDesktop} className="flex flex-wrap">
                <Helmet>
                    <title>{t("title_walletRialWithdraw")}</title>
                </Helmet>
                {/* نوار فقط برای حالت موبایل فقط موبایل sm:hidden*/}
                {/* x<640 */}
                <nav
                    className="sm:hidden w-full h-[50px] flexBetween px-4 toggleBg">
                    <span>{" "}</span>
                    <Link to={"/"}><img src={logo} alt="" /></Link>
                    <Link to={"/wallet"}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.064" height="25.566" viewBox="0 0 26.064 25.566">
                                <path id="arrow_back_FILL0_wght400_GRAD0_opsz48_1_" data-name="arrow_back_FILL0_wght400_GRAD0_opsz48 (1)" d="M171.911,293.192l-11.538-11.538a1.265,1.265,0,0,1-.291-.415,1.3,1.3,0,0,1,0-.913,1.265,1.265,0,0,1,.291-.415l11.579-11.579a1.126,1.126,0,0,1,.83-.332,1.233,1.233,0,0,1,.872,2.117l-9.421,9.421h20.585a1.245,1.245,0,1,1,0,2.49H164.233l9.463,9.463a1.126,1.126,0,0,1,.332.83,1.233,1.233,0,0,1-2.117.872Z" transform="translate(-160 -268)" fill={lightingMode ? "white" : "black"} />
                            </svg>

                        </span>
                    </Link>
                </nav>

                {/*  نوار برای تبلت فقط تبلت*/}
                {/* 640< x <1024*/}
                <div className="w-full hidden sm:block lg:hidden">
                    <Nav_MobileTablet
                        mobileRight_DarkScreen={mobileRight_DarkScreen}
                        mobileLeft_DarkScreen={mobileLeft_DarkScreen}
                        tablet_DarkScreen={tablet_DarkScreen}
                        tablet_ProfileMenu={tablet_ProfileMenu}
                        tablet_Notifications={tablet_Notifications}

                        setMobileRight_DarkScreen={setMobileRight_DarkScreen}
                        setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
                        setTablet_DarkScreen={setTablet_DarkScreen}
                        setTablet_ProfileMenu={setTablet_ProfileMenu}
                        setTablet_Notifications={setTablet_Notifications}
                    />
                </div>

                {/* لینک های فیکس برای موبایل sm:hidden*/}
                {/* x <640 */}
                <Fixed_Mobile_Links />


                {/* ساید بار اصلی برای دسکتاپ*/}
                {/* x > 1024 */}
                <Main_Sidebar />

                <main>
                    {/* نوار برای دسکتاپ hidden lg:flex*/}
                    <Nav_Desktop
                        desktop_ProfileMenu={desktop_ProfileMenu}
                        desktop_Notifications={desktop_Notifications}
                        desktop_ThreeDots={desktop_ThreeDots}

                        setDesktop_Notifications={setDesktop_Notifications}
                        setDesktop_ThreeDots={setDesktop_ThreeDots}
                        setDesktop_ProfileMenu={setDesktop_ProfileMenu}
                    />


                    {/* واریز-برداشت */}
                    <section className="deposit_withdraw toggleBg">
                        {/* nth-of-1 */}
                        <div className="hidden sm:flex justify-between items-center mb-4">
                            <h3 className="text-lg font-bold toggleColor">{t("wallet_rial_withdraw_1")}</h3>
                            <button onClick={() => navigate("/wallet")}
                                className="w-[142px] h-12 flexCenter gap-x-2 rounded-lg"
                                style={lightingMode === "light" ? { background: "#f7f8fa" } : { background: "#141414", color: "white" }}>
                                <span className="font-normal">{t("wallet_rial_withdraw_2")}</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.201" height="14.313" viewBox="0 0 7.201 14.313">
                                        <path id="arrow-ios-forward-fill" d="M1.022,0A1.02,1.02,0,0,0,.236,1.675l4.57,5.468L.4,12.62a1.02,1.02,0,0,0,.153,1.438,1.02,1.02,0,0,0,1.489-.153l4.927-6.12a1.02,1.02,0,0,0,0-1.3L1.869.369A1.02,1.02,0,0,0,1.022,0Z" transform="translate(7.201 14.313) rotate(180)" fill={lightingMode === "light" ? "black" : "white"} />
                                    </svg>
                                </span>
                            </button>
                        </div>

                        {/* دو عدد دکمه */}
                        <div className="two_button flexCenter gap-x-3 mb-5">

                            {/* واریز deposit */}
                            <button onClick={() => navigate(`/wallet-rial-deposit/${selected_Coin.coin_symbol}`)}
                                style={{ pointerEvents: depositPosibility ? 'auto' : 'none', opacity: depositPosibility ? '1' : '.4' }}>
                                <span>
                                    <TfiDownload />
                                </span>
                                <span>{t("wallet_rial_withdraw_3")}</span>
                            </button>

                            {/* برداشت withdraw */}
                            {/* لینک ندارد */}
                            <button
                                style={{ color: "white", background: "#f6b33f" }}>
                                <span className="font-bold">
                                    <TfiUpload />
                                </span>
                                <span>{t("wallet_rial_withdraw_4")}</span>
                            </button>
                        </div>


                        {/* باکس برداشت */}
                        {

                            (
                                <div className="flexCenter">
                                    <section className="withdraw-box  w-[540px] !pb-7 rounded-xl">

                                        <form onSubmit={handleSubmitForm} className="" >
                                            {/*مبلغ قابل برداشت  */}
                                            <div className="h-[74px] mb-3" >
                                                <label htmlFor="" className="mb-2 block">{t("wallet_rial_withdraw_5")}</label>
                                                <div className="h-[46px] flexCenter gap-x-2 rounded-lg"
                                                    style={lightingMode === "light" ? { background: "#f7f8fa", border: "1px solid #edeef1" } : { background: "#141414", color: "white" }}>
                                                    <span className="text-lg font-bold">{selected_Coin.primary_balance}</span>
                                                    <img src={irr} width={25} />
                                                </div>
                                            </div>
                                            {/* مبلغ */}
                                            <div className="h-[74px] mb-3">
                                                <label htmlFor="" className="block mb-1.5">{t("wallet_rial_withdraw_6")} </label>

                                                <div className="h-[46px] flex justify-between overflow-hidden"
                                                    style={{ borderRadius: "10px", border: "1px solid #edeef1" }}>
                                                    <input
                                                        type="number"
                                                        name="amount"
                                                        value={amount}
                                                        onChange={e => e.target.value > selected_Coin.primary_balance ? setAmount(selected_Coin.primary_balance) : setAmount(e.target.value)}
                                                        className="block h-full w-3/5 px-3"
                                                        style={{ borderRadius: "10px" }}
                                                        placeholder="0" />

                                                    <div className="h-full w-2/5 px-3 flex items-center justify-end gap-x-1 toggleColor">
                                                        <img src={irr} width={25} />
                                                        <span>تومان</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* text */}
                                            <p className="text-sm mb-5 toggleColor">
                                                <span className="text-red-500">*</span>
                                                {" "}
                                                <span>{t("wallet_rial_withdraw_7")}</span>
                                            </p>
                                            {/*  مدیریت حساب ها*/}
                                            <div className="p-3 rounded-xl mb-7" style={lightingMode === "light" ? {background: '#f7f8fa'} : {background: '#222'}}>
                                                <div className="flex items-center justify-between flex-wrap gap-3">
                                                    <label htmlFor="select" className="!text-base">حساب:</label>
                                                    {active_BankAccount.length === 0 && <p className="text-red-500 text-sm">ابتدا باید یک حساب اضافه کنید.</p>}
                                                    {active_BankAccount.length !== 0 && 
                                                        <select id="select" value={bankId} onChange={e => setBankId(e.target.value)}>
                                                            {
                                                                active_BankAccount && active_BankAccount.map((m, index) =>
                                                                (
                                                                    <option value={m.id} key={m.id}>
                                                                        <div className="h-full flex items-center gap-x-3">
                                                                            <span>{m.bank_name}</span>
                                                                            {" "}
                                                                            <span className="text-xs">{m.iban}</span>
                                                                            {" "}
                                                                        </div>
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    }
                                                    <Link to={'/setting'} className="p-2 px-4 block mr-auto w-fit rounded-lg bg-[#2560ff] text-white">افزودن حساب</Link>
                                                </div>
                                            </div>

                                            {/* submit */}
                                            <button type="submit"
                                                className="h-[46px] w-full text-white mb-3"
                                                style={{ background: "#00c0af", borderRadius: "10px" }}
                                                disabled={active_BankAccount.length === 0 || !amount || amount == 0}
                                            >{t("wallet_rial_withdraw_8")}</button>

                                        </form>




                                        <p className="text-sm text-center mt-3"
                                            style={lightingMode === "light" ? { color: "#707070" } : { color: "#a5a5a5" }}>
                                            <span>با انجام معامله</span>
                                            {" "}
                                            <Link to={"/terms"} className="font-bold toggleColor">قوانین و مقررات</Link>
                                            {" "}
                                            <span>مانی‌اکســ را خوانده و می‌پذیرم</span>
                                        </p>
                                        {/*  */}
                                    </section>
                                </div>
                            )
                        }


                    </section>

                </main>
                <LightingMode bottom="20px" left="30px" />
            </div>
        </HelmetProvider>
    )
}

export default Wallet_Rial_Withdraw;
import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie';

import { BASE_URL } from '../../utils/url'


const SingleNotification = ({ notif }) => {
    
    const baseURL = BASE_URL

    const [fullView, setFullView] = useState(false)

    const handleClick= async ()=>{
        //اولین کار
        setFullView(true)

        //دومین کار
        try {
            const res = await axios.put(`${baseURL}/user/notifications/${notif.id}/read`,{} ,{
                headers: {
                  'Authorization': `Bearer ${Cookies.get("access_token")}`,
                  'Content-Type': 'application/json'
                }
              });
            // console.log(res)
        } catch (error) {
            
        }
    }




    return (
        <li className='toggleBg'>
            <h4 className="font-bold mb-2">{notif.title}</h4>
            <p className="text-sm mb-2">{ fullView ? `${notif.message}` :   `${notif.message.slice(0, 50)}...`}</p>

            <div className="flex justify-end mb-2">
                <button onClick={handleClick}
                className='toggleColor'>مشاهده بیشتر</button>
            </div>


            <div className="flex justify-end">
                <span className="notif-date"
                style={{color:"gray"}}>
                    {notif.created_at}
                </span>
            </div>
        </li>
    )
}

export default SingleNotification
// export default SingleNotification
import React, { useState } from "react";
import Main_Sidebar from "../components/sidebar/Main_Sidebar";
import "./agree.css";
import Successful from "../components/allert/Successful";
import { useTranslation } from "react-i18next";
import Nav_MobileTablet from "../components/nav-mobile-tablet/Nav_MobileTablet";
import Fixed_Mobile_Links from "../components/fixed-mobile-links/Fixed_Mobile_Links";
import { Helmet, HelmetProvider } from "react-helmet-async";
export default function Agree() {
  const { t } = useTranslation();
  const [mobileRight_DarkScreen, setMobileRight_DarkScreen] = useState(false);
  const [mobileLeft_DarkScreen, setMobileLeft_DarkScreen] = useState(false);
  const [tablet_DarkScreen, setTablet_DarkScreen] = useState(false);
  const [tablet_ProfileMenu, setTablet_ProfileMenu] = useState(false);
  const [tablet_Notifications, setTablet_Notifications] = useState(false);
  return (
    <React.Fragment>
       <HelmetProvider>
      <Helmet>
          <title>{t("title.12")}</title>
        </Helmet>
      {/* <Successful /> */}
      <Nav_MobileTablet
        mobileRight_DarkScreen={mobileRight_DarkScreen}
        mobileLeft_DarkScreen={mobileLeft_DarkScreen}
        tablet_DarkScreen={tablet_DarkScreen}
        tablet_ProfileMenu={tablet_ProfileMenu}
        tablet_Notifications={tablet_Notifications}
        setMobileRight_DarkScreen={setMobileRight_DarkScreen}
        setMobileLeft_DarkScreen={setMobileLeft_DarkScreen}
        setTablet_DarkScreen={setTablet_DarkScreen}
        setTablet_ProfileMenu={setTablet_ProfileMenu}
        setTablet_Notifications={setTablet_Notifications}
      />
      <div id="everything">
        <Main_Sidebar />

        <section id="agree-section-one">
          <div className="back">
            <div className="text">{t("dashbord.agree.1")}</div>
            <img
              src={require("../assests/image/dashbord/arrow-ios-forward-fill.png")}
            />
          </div>
          <div className="all">
            <img src={require("../assests/image/dashbord/Success.png")} />
            <div className="title">{t("dashbord.agree.2")}</div>
            <p className="des">{t("dashbord.agree.3")}</p>
          </div>
          <div className="bottom">
            {t("dashbord.agree.4")}{" "}
            <span style={{ color: "#00C0AF" }}> {t("dashbord.agree.5")} </span>
          </div>
        </section>
        <Fixed_Mobile_Links />
      </div>
      </HelmetProvider>
    </React.Fragment>
  );
}
